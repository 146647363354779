import React, { Component } from 'react'
import EditableLabel from 'react-inline-editing'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/FreshSpire-Brandmark_Combination-White.png';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import TermsOfUse from '../../components/TermsOfUse';
import { observer, inject } from 'mobx-react'
import Menu from '../../components/Menu'
import pencil from '../../assets/Edit-Profile-Pencil-Green.png'
import {LoadingSpinnerPage} from '../../components/LoadingSpinner'
import Topbar from '../../components/CommonTopBar'
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import { FoodOptions } from '../../core/core';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

class ViewLegal extends Component {

	getPage(url) {
		return url.substring(url.lastIndexOf("/")+1);
	}

	getContents() {
		const pathname = this.props.location.pathname;
		const page = this.getPage(pathname);

		switch(page) {
			case 'privacy-policy':
				return {title: 'Privacy Policy', component: <PrivacyPolicy/>}
			case 'terms-of-use':
				return {title: 'Terms Of Use', component: <TermsOfUse/>}
			default:
				return {title: '', component: null}
		}
	}

	render() {
		const contents = this.getContents();
		console.log(contents);

		return (
			<div className="legal-container">
				<AppBar color="primary" className="nav-appbar" position="static">
					<Toolbar className="nav-container">
						<img src={logo} alt="logo" className="freshspire-logo" />
					</Toolbar>
				</AppBar>
				<div className="header-container">
					<Typography className="text" >
						{contents.title}
					</Typography>
				</div>
				<div className="info-container">
					<div className="legal-content">
						{contents.component}
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(ViewLegal)