import React, { Component, PropTypes } from 'react';
import { observer, inject } from 'mobx-react';
import MaskedInput from 'react-text-mask';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { PaymentOptions, States } from '../core/core'
import Input from '@material-ui/core/Input';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { userStore } from '../stores';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { firstTimeSurveyStore } from '../pages/common/FirstTimeSurvey';
import moment from 'moment';

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        // showMask
        />
    );
}

@inject(() => ({
    store: firstTimeSurveyStore
}))

@observer
export class FTSF1 extends Component {
    
    componentWillMount() {
        const  { phoneNumber, email } = userStore.user
        this.props.store.onFieldChange('phone', phoneNumber);
        this.props.store.onFieldChange('email', email);
    }

    state = Object.assign(
        {},
        Object.values(PaymentOptions).reduce((o, key) => Object.assign(o, { [key]: false }), {}),
        { state: '' },
        { opening: '07:30 a' },
        { closing: '05:30 p' },
        { phone: '' },
        { type: 'buyer'});

    //regions = ["AK","AL","AR","AZ","CA","CO","CT","DC","DE","FL","GA","GU","HI","IA","ID", "IL","IN","KS","KY","LA","MA","MD","ME","MH","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY", "OH","OK","OR","PA","PR","PW","RI","SC","SD","TN","TX","UT","VA","VI","VT","WA","WI","WV","WY"]
    //regions = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming']

    createMenuItem = label => (
        <MenuItem key={`${label}-menu`} value={label}>
            {label}
        </MenuItem>
    )

    createMenuItems = (arr) => (
        arr.map(this.createMenuItem)
    )

    handleCheckboxChange = label => event => {
        this.setState({ [label]: event.target.checked });
        if (label != 'Other') {
            this.props.store.onCheckboxChange('paymentOptions', label);
        } else {
            // this.props.store.onCheckboxChange('paymentOptions', null);
            this.props.store.onFieldChange('paymentOptionsOther', '');
            // Reset the error
            this.props.store.form.fields.paymentOptions.error = null;
        }
    }

    createCheckbox = label => (
        <Grid key={`${label}-checkbox`} item container justify="center" xs={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        value={label}
                        checked={this.state[label]}
                        onChange={this.handleCheckboxChange(label)}
                    />
                }
                className="label"
                style={{ "display": "flex" }}
                label={label}
            />
        </Grid>
    )
    onDrop = file  => {
        // Currenlty returns BLOBs
        this.props.store.onFieldChange('picture', file[0]);
    }
    onClearDrop = event  => {
        // Currenlty returns BLOBs
        event.stopPropagation();
        this.props.store.onFieldChange('picture', null);
    }
    changeStateValue = event => {
        const { name, value } = event.target
        this.setState({ [name]: value });
        console.log(value)
        this.props.store.onFieldChange([name], value);
    }

    createCheckboxes = () => {
        return Object.values(PaymentOptions).map(this.createCheckbox);
    }

    formatTime = (name,value) => {
        this.setState({[name]:value})
        let formatted24HrTime = (value.includes("-") || (!value.toLowerCase().includes('am') && !value.toLowerCase().includes('pm'))) ? '25:00' : moment(value, "h:mm A").format("HH:mm");
        this.props.store.onFieldChange(name, formatted24HrTime)
    }

    onTypeChange = (e) => {
        this.setState({ type: e.target.value });
        this.props.store.onFieldChange('type', e.target.value);
    }

    render() {
        let { store } = this.props;
        let other = this.props.store.form.fields.paymentOptionsOther.value;
        return (
            <div className="form__group">
                <div className="form__control">
                    <Grid container justify="center" direction="column">
                        <Grid item>
                            <FormControl fullWidth='true'>
                                <InputLabel required error={store.form.fields.name.error !== null}>Business Name</InputLabel>
                                <Input
                                    name="name"
                                    value={store.form.fields.name.value}
                                    onChange={(e) => store.onFieldChange(e.target.getAttribute('name'), e.target.value)}
                                    error={store.form.fields.name.error !== false && store.form.fields.name.error != null}
                                />
                                <FormHelperText error >{store.form.fields.name.error}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth='true'>
                                <InputLabel required error={store.form.fields.streetAddress.error !== null}>Street Address</InputLabel>
                                <Input
                                    name="streetAddress"
                                    value={store.form.fields.streetAddress.value}
                                    onChange={(e) => store.onFieldChange(e.target.getAttribute('name'), e.target.value)}
                                    error={store.form.fields.streetAddress.error !== false && store.form.fields.streetAddress.error != null}
                                />
                                <FormHelperText error >{store.form.fields.streetAddress.error}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth='true'>
                                <InputLabel required error={store.form.fields.city.error !== null}>City</InputLabel>
                                <Input
                                    name="city"
                                    value={store.form.fields.city.value}
                                    onChange={(e) => store.onFieldChange(e.target.getAttribute('name'), e.target.value)}
                                    error={store.form.fields.city.error !== false && store.form.fields.city.error != null}
                                />
                                <FormHelperText error >{store.form.fields.city.error}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container item justify="space-between">
                            <Grid container direction="column" justify="center" alignItems="stretch" xs={5} item>
                                <FormControl fullWidth={true}>
                                    <InputLabel required error={store.form.fields.state.error !== null}>State</InputLabel>
                                    <Select
                                        name="state"
                                        value={this.state.state}
                                        onChange={(e) => this.changeStateValue(e)}
                                        error={store.form.fields.state.error !== null}
                                    >
                                        <MenuItem value="" disabled>
                                            <em>State</em>
                                        </MenuItem>
                                        {this.createMenuItems(States)}
                                    </Select>
                                    <FormHelperText error >{store.form.fields.state.error}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid container direction="column" justify="center" alignItems="stretch" xs={5} item>
                                <FormControl fullWidth={true}>
                                    <InputLabel required error={store.form.fields.zipcode.error !== false && store.form.fields.zipcode.error != null}>Zipcode</InputLabel>
                                    <Input
                                        name="zipcode"
                                        value={store.form.fields.zipcode.value}
                                        onChange={(e) => store.onFieldChange(e.target.getAttribute('name'), e.target.value)}
                                        error={store.form.fields.zipcode.error !== false && store.form.fields.zipcode.error != null}
                                    />
                                    <FormHelperText error >{store.form.fields.zipcode.error}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth={true}>
                                <InputLabel required error = {store.form.fields.phone.error !== null}>Business Phone</InputLabel>
                                <Input
                                    name="phone"
                                    placeholder="(201) 555-5555"
                                    value={store.form.fields.phone.value}
                                    onChange={(e) => this.changeStateValue(e)}
                                    inputComponent={TextMaskCustom}
                                    error = {store.form.fields.phone.error !== null}
                                />
                                <FormHelperText error >{store.form.fields.phone.error}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth={true}>
                                <InputLabel required error = {store.form.fields.email.error !== null}>Business Email</InputLabel>
                                <Input
                                    name="email"
                                    value={store.form.fields.email.value}
                                    onChange={(e) => store.onFieldChange(e.target.getAttribute('name'), e.target.value)}
                                    error = {store.form.fields.email.error !== null}
                                />
                                <FormHelperText>{`This is the email that will be displayed to your affiliates if they want to contact your business.  This can be different from the email you use to login.`}</FormHelperText>
                                <FormHelperText error >{store.form.fields.email.error}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item>
                            <FormControl fullWidth={true}>
                                <TextField
                                    name="description"
                                    label="Description"
                                    required
                                    multiline
                                    rows="5"
                                    placeholder="Write a description of your business here."
                                    value={store.form.fields.description.value}
                                    onChange={(e) => store.onFieldChange(e.target.getAttribute('name'), e.target.value)}
                                    error={store.form.fields.description.error !== false && store.form.fields.description.error != null}
                                />
                                <FormHelperText>{`Write a short description telling your affiliates what you're about!`}</FormHelperText>
                                <FormHelperText error >{store.form.fields.description.error}</FormHelperText>
                            </FormControl>
                        </Grid>
                    <Grid item>
                        <FormControl fullWidth={true}>
                            <FormLabel component="legend">Are you a buyer or seller?</FormLabel>
                            	<RadioGroup
                            		value={this.state.type}
                            		onChange={this.onTypeChange}
                            		>
                                    <FormControlLabel value="buyer" control={<Radio/>} label="Buyer" />
                                    <FormControlLabel value="seller" control={<Radio/>} label="Seller" />
                            	</RadioGroup>
                        </FormControl>
                    </Grid>
                </div>
            </div>

        )
    }
}
