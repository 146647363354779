import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

const StyleTableRow = styled(TableRow)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const StyleIcon = styled(Icon)`
  border: none;
  display: flex;
  align-items: center; 
`

const StyleTableHead = styled(TableHead)`
  display: flex;
`

const StyleButton = styled(Button)`
  margin-left: auto; 
`

class CustomToolbar extends React.Component {
  render() {
    const { addItem, fullscreen } = this.props

    return (
      <React.Fragment>
        {/* <IconButton onClick={fullscreen}>
          <ZoomOutMapIcon size="medium"/>
        </IconButton> */}
        <IconButton id="sheet_toolbar_add_item" onClick={addItem}>
          <AddIcon size="medium"/>
        </IconButton>
      </React.Fragment>
    );
  }
}

export default CustomToolbar;
