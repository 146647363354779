import { action, observable, computed } from 'mobx'
import { userStore } from '../../stores'
import DatabaseClient from '../../core/DatabaseClient'
import { getFlattenedValues } from '../FormHelper';
import store from 'store';
import { States, PaymentOptions, CommunicationOptions } from '../../core/core';
import validate from 'core/utilities/FormValidator';
import ClientRelationship from '../../core/model/ClientRelationship';
import { Business } from '../../core/model';
import { unmarshal } from 'core/utilities/ModelUtils';
import { shoppingCartStore, messagingStore, notificationStore, basketStore, clientListStore, managementStore } from '..'
import { BusinessService } from "core/api";

const FRESHSPIRE_BUSINESS_LOGIN = '__freshspire_business_login'
const testNote = true

class ProfileStore {
    @observable business = null;
    @observable certifications = [];
    @observable clientRelationship = {
        status: null
    };
    @observable buttonDisplay = 'hidden';
    @observable form
    @observable orderNotes = "hey man I can't find him"
    defaultForm = {
        fields: {
            name: {
                value: null,
                validation: {
                    presence: true,
                    length: {
                        minimum: 1,
                        maximum: 50,
                        message: '^This field is required and must be less than 50 characters',
                    },
                },
                error: null,
            },
            streetAddress: {
                value: null,
                validation: {
                    presence: true,
                    length: {
                      minimum: 1,
                      maximum: 100,
                      message: '^This field is required and must be less than 100 characters',
                    },
                },
                error: null,
            },
            city: {
                value: null,
                validation: {
                    presence: true,
                    length: {
                      minimum: 1,
                      maximum: 50,
                      message: '^This field is required and must be less than 50 characters',
                    },
                },
                error: null,
            },
            state: {
                value: null,
                validation: {
                    presence: true,
                    inclusion: States,
                },
                error: null,
            },
            zipcode: {
                value: null,
                validation: {
                    presence: true,
                    format: {
                        pattern: "[0-9]{5}",
                        message: "^Must be a valid zipcode",
                    },
                },
                error: null,
            },
            opening: {
                value: '08:00',
                validation: {
                    presence: true,
                    format: {
                        pattern: "([01]?[0-9]|2[0-3]):[0-5][0-9]",
                        message: "^Must be a valid time in the 12 hour format XX:XX XM",
                    }
                },
                error: null,
            },
            closing: {
                value: '17:00',
                validation: {
                    presence: true,
                    format: {
                        pattern: "([01]?[0-9]|2[0-3]):[0-5][0-9]",
                        message: "^Must be a valid time in the 12 hour format XX:XX XM",
                    }
                },
                error: null,
            },
            paymentOptions: {
                value: [],
                validation: {
                    presence: false,
                },
                error: null,
            },
            paymentOptionsOther: {
                value: null,
                validation: {
                    presence: false,
                    length: {
                        maximum: 25,
                        message: 'must be less than 25 characters',
                      },
                },
                error: null,
            },
            phone: {
                value: null, // TODO: validate phone numbers and have nice masked input
                validation: {
                    presence: true,
                    format: {
                       pattern: "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
                       message: "^Must be in the format (XXX) XXX-XXXX",
                    },
                },
                error: null,
            },
            email: {
                value: null, // TODO: validate phone numbers and have nice masked input
                validation: {
                    presence: true,
                    email: {
                        message: "^Must be a valid email",
                    }
                },
                error: null,
            },
            description: {
                value: null,
                validation: {
                    presence: true,
                    length: {
                        min: 1,
                        maximum: 300,
                    },
                },
                error: null,
            },
            orderNotes: {
                value: null,
                validation: {
                    // presence: true,
                    length: {
                        min: 1,
                        maximum: 300,
                    },
                },
                error: null,
            },
            foodOptions: {
                value: [],
                validation: {
                    presence: false,
                },
                error: null,
            },
            foodOptionsOther: {
                value: null,
                validation: {
                    presence: false,
                    length: {
                        maximum: 25,
                        message: 'must be less than 25 characters',
                      },
                },
                error: null,
            },
            picture: {
                value: null,
                validation: {
                  presence: false,
                },
                error: null,
            },
            communicationOptions: {
                value: [],
                validation: {
                    presence: false,
                },
                error: null,
            },
            communicationOptionsOther: {
                value: null,
                validation: {
                    presence: false,
                    length: {
                        maximum: 25,
                        message: 'must be less than 25 characters',
                      },
                },
                error: null,
            }
        },
        validation: {
            error: null,
        },
    }

    @computed get initialized() {
        return userStore.isAuthenticated && this.business != null;
    }

    @computed get isSeller() {
        return (this.business !== null && this.business.isSeller())
    }
    @computed get isBuyer() {
        return (this.business !== null && this.business.isBuyer())
    }

    @action
    resetForm() {
        this.form = Object.assign({}, this.defaultForm);
    }

    @action
    onFieldChange = (field, value) => {
        if (value === '') {
            value = null;
        }
        console.log(field)
        this.form.fields[field].value = value;
        this.validateField(field);
    };

    @action
    onCheckboxChange = (field, subfield) => {
        var index = this.form.fields[field].value.indexOf(subfield);
        if (index > -1) {
            this.form.fields[field].value.splice(index, 1);
        }
        else {
            this.form.fields[field].value.push(subfield);
        }
        this.validateOptionsWithOther(field, field + 'Other');
    };

    @action
    validateField = (field) => {
        let data = {}; // The data to validate
        data[field] = this.form.fields[field].value;
        let validationRules = {}; // The rules to use in validation
        validationRules[field] = this.form.fields[field].validation;
        let err = validate(data, validationRules);

        // Update error message
        if (err !== undefined) {
            this.form.fields[field].error = err[field][0];
        }
        else {
            this.form.fields[field].error = null;
        }
        
        if (field === 'paymentOptionsOther') {
            this.validateOptionsWithOther('paymentOptions', 'paymentOptionsOther');
        }
        if (field === 'foodOptionsOther') {
            this.validateOptionsWithOther('foodOptions', 'foodOptionsOther');
        }
        if (field === 'communicationOptionsOther') {
            this.validateOptionsWithOther('communicationOptions', 'communicationOptionsOther');
        }
    }

    @action
    validateOptionsWithOther = (field1, field2) => {
        if (this.form.fields[field1].value.length === 0 && !this.form.fields[field2].value) {
            this.form.fields[field1].error = 'Please make a selection.';
            return false;
        } 
        else {
            this.form.fields[field1].error = null;
            return true;
        }
    }

    @action
    loadBusiness() {
        let communicationsObj = Object.assign({}, CommunicationOptions);
        delete communicationsObj.OTHER;
        let communications = Object.values(communicationsObj);
        if (this.business['communicationOptions']) {
          for (let i = 0; i < this.business['communicationOptions'].length; i++) {
            if (!communications.includes(this.business['communicationOptions'][i])) {
              // We have an 'Other' reason
              this.business['communicationOptionsOther'] = this.business['communicationOptions'][i];
              this.business['communicationOptions'].splice(i, 1);
            }
          }
        }

        let paymentsObj = Object.assign({}, PaymentOptions);
        delete paymentsObj.OTHER;
        let payments = Object.values(paymentsObj);
        if (this.business['paymentOptions']) {
          for (let i = 0; i < this.business['paymentOptions'].length; i++) {
            if (!payments.includes(this.business['paymentOptions'][i])) {
              // We have an 'Other' reason
              this.business['paymentOptionsOther'] = this.business['paymentOptions'][i];
              this.business['paymentOptions'].splice(i, 1);
            }
          }
        }

        // Populate the form with the given business details
        Object.keys(this.form.fields).forEach( (field) => {
            this.form.fields[field].value = this.business[field] !== undefined ? this.business[field] : this.defaultForm.fields[field].value;
        });

        this.resetErrors();
        return;
    }

    @action
    resetErrors() {
        Object.keys(this.form.fields).forEach( (field) => {
        this.form.fields[field].error = null;
        });
        this.form.validation.error = null;
    }

    @action
    validateAll = () => {
        Object.keys(this.form.fields).map(this.validateField);

        this.form.validation.error = null;
        Object.keys(this.form.fields).forEach( (field) => {
            if (this.form.fields[field].error) {
                    this.form.validation.error = this.form.fields[field].error;
            }
        });

        // Do the options
        this.validateOptionsWithOther('paymentOptions', 'paymentOptionsOther');
        if (this.form.fields['paymentOptions'].error) {
            this.form.validation.error = this.form.fields['paymentOptions'].error;
        }

        this.validateOptionsWithOther('communicationOptions', 'communicationOptionsOther');
        if (this.form.fields['communicationOptions'].error) {
            this.form.validation.error = this.form.fields['communicationOptions'].error;
        }
    }

    @action
    async init() {
        let businessLogin = store.get(FRESHSPIRE_BUSINESS_LOGIN);
        if (businessLogin && !userStore.user.businesses[businessLogin]) {
            store.remove(FRESHSPIRE_BUSINESS_LOGIN);
            businessLogin = null;
        }
        if (!businessLogin) {
            if (Object.keys(userStore.user.businesses).length > 0) {
                businessLogin = Object.keys(userStore.user.businesses)[0];
                store.set(FRESHSPIRE_BUSINESS_LOGIN, businessLogin);
            }
            else {
            	console.log("No businesses are attached to the user object");
                return;
            }
        }

        const [ business, certifications ] = await Promise.all([
            BusinessService.get(businessLogin),
            DatabaseClient.getPendingActiveCertifications(businessLogin)
        ]);
        if (business) {
            business.certifications = certifications;
        }
        this.business = business;

        BusinessService.subscribe(businessLogin, async (business) => {
            const certifications = await DatabaseClient.getPendingActiveCertifications(this.business.id);
            this.certifications = certifications;
            this.business = business;
            this.business.certifications = certifications;
        });
        messagingStore.init();
        notificationStore.init();

        await Promise.all([managementStore.init(), basketStore.init(), clientListStore.init()]);

    }

    @action
    reset() {
        // Deregister listener
        BusinessService.unsubscribe(this.business.id);
        this.business = null;
        shoppingCartStore.resetShoppingCart();
    }

    @action
    async refreshBusiness() {
    	const [ business, certifications ] = await Promise.all([
    	    BusinessService.get(this.business.id),
            DatabaseClient.getPendingActiveCertifications(this.business.id)
        ]);
    	if (business) {
    	    business.certifications = certifications;
        }
        this.business = business;

    }

    @action
    async getBusiness(uid) {
        const [ business, certifications ] = await Promise.all([
            BusinessService.get(uid),
            DatabaseClient.getPendingActiveCertifications(uid)
        ]);
        if (business) {
            business.certifications = certifications;
        }

        if (this.business.isBuyer()) {
            this.clientRelationship = await DatabaseClient.getClientRelationship(this.business.id, uid);
        }
        else {
            this.clientRelationship = await DatabaseClient.getClientRelationship(uid, this.business.id);
        }

        return business;
    }

    @action
    async switchBusiness(BizUID) {
        BusinessService.unsubscribe(this.business.id);
        shoppingCartStore.resetShoppingCart();
        await this.setBusiness(BizUID)
	    // If re-rendering doesn't work, use this as a backup
        // const redirectURL = '/login';
        // window.location.href = redirectURL;
    }

    @action
    async setBusiness(businessUID) {
        store.set(FRESHSPIRE_BUSINESS_LOGIN, businessUID);
        await this.init();
    }

    @action
    async sendRequest(uid) {
        let buyerUID, sellerUID;
        if (this.business.isBuyer()) {
            buyerUID = this.business.id;
            sellerUID = uid;
            await DatabaseClient.requestClientRelationship(buyerUID, sellerUID, "buyer");
            this.clientRelationship = new ClientRelationship(buyerUID, sellerUID, 'requested', 'buyer');
        }
        else {
            buyerUID = uid;
            sellerUID = this.business.id;
            await DatabaseClient.requestClientRelationship(buyerUID, sellerUID, "seller");
            this.clientRelationship = new ClientRelationship(buyerUID, sellerUID, 'requested', 'seller');
        }
        
    }

    @action
    prepareEntity() {
        let data = getFlattenedValues(this.form, 'value');
        if (data['paymentOptionsOther']) {
            data['paymentOptions'].push(data['paymentOptionsOther']);
        }
        delete data['paymentOptionsOther'];
        if (data['communicationOptionsOther']) {
            data['communicationOptions'].push(data['communicationOptionsOther']);
        }
        if (!data['orderNotes']) {
            data['orderNotes'] = ''
        }
        delete data['communicationOptionsOther'];
        delete data['picture']
        return data;
    }

    @action
    async deleteCertifications(ids) {
        await DatabaseClient.deleteCertifications(this.business.id, ids);
    }

    @action
    async updateBusiness() {
        this.validateAll();
        if (this.form.validation.error) {
            throw new Error('Form failed validation.');
        }

        let business = this.prepareEntity();
        let uid = this.business.id;
        let picture = this.form.fields.picture.value;

        //Update the picture
        if (picture) {
            if (typeof picture === 'string') {
            	await BusinessService.update(uid, business);
            }
            else { // TODO: actually check that these are files
                const pictureID = await DatabaseClient.createProfilePicture(this.form.fields.picture.value);
                business.picture = pictureID;
                await BusinessService.update(uid, business);
            }
        } else {
        	business.picture = null;
            await BusinessService.update(uid, business);
        }
    }

    @action
    async acceptConnectRequest(buyerUID, sellerUID) {
        await DatabaseClient.acceptClientRelationship(buyerUID, sellerUID);
        if (this.clientRelationship) {
            this.clientRelationship.status = "active"
        }
        await clientListStore.getAllClients();
    }

    @action
    async deleteClientRelationship(buyerUID, sellerUID) {
        await DatabaseClient.removeClientRelationship(buyerUID, sellerUID);
        this.clientRelationship = null
        await clientListStore.getAllClients();
    }
}


export default new ProfileStore()
