import React, { Component, Fragment } from 'react'
import logoFab from '../assets/logo.png';
import logo from '../assets/FreshSpire-Brandmark_Combination-White.png';
import charlies_chicken from '../assets/leafy.jpg';
import bens_bagels from '../assets/glass.jpg';
import { userStore } from '../stores';
import Divider from '@material-ui/core/Divider';
import DatabaseClient from '../core/DatabaseClient';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { observer, inject } from 'mobx-react';
import { withRouter, Link, Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import LogoutButton from './atomic/buttons/LogoutButton';
import BusinessSwitch from './organisms/BusinessSwitch';
import styled from 'styled-components';
import { BusinessService } from 'core/api';

const StyledLogoutButton = styled(LogoutButton)`
  width: 100%;
`

const SwitchContainer = styled.div`
  margin-top: 10px;
`

const BusinessSwitchStyled = styled(BusinessSwitch)`
  flex: 1;
`

const SwitchContainerMbl = styled.div`
  min-height: 35px;
  margin-bottom: 10px;
`;

const sellerMenu = {
  Items: [
    {
      path: '/profile',
      icon: 'person',
      text: 'Profile'
    },
    {
      path: '/deals',
      pathMatch: ['/storefront','/preview'],
      icon: 'dns',
      text: 'Store'
    },
    {
      path: '/notes',
      icon: 'note',
      text: 'Notes'
    },
    {
      path: '/request',
      pathMatch: ['/completed','/active'],
      icon: 'send',
      text: 'Orders'
    },
    {
      path: '/affiliated',
      pathMatch: ['/invitations','/pending', '/clientlist'],
      icon: 'list_alt',
      text: 'View Buyers'
    }
  ]
};

const buyerMenu = {
  Items: [
    {
      path: '/profile',
      icon: 'person',
      text: 'Profile'
    },
    {
      path: '/shelf',
      pathMatch: ['/shelf/explore'],
      icon: 'dns',
      text: 'View Products'
    },
    {
      path: '/shoppingcart',
      icon: 'shopping_basket',
      text: 'My Basket'
    },
    {
      path: '/notes',
      icon: 'note',
      text: 'Notes'
    },
    {
      path: '/request',
      pathMatch: ['/completed','/active'],
      icon: 'send',
      text: 'Orders'
    },
    {
      path: '/affiliated',
      pathMatch: ['/invitations','/pending','/clientlist'],
      icon: 'list_alt',
      text: 'View Sellers'
    }
  ]
};

@inject('userStore', 'profileStore', 'managementStore', 'globalUIStore')
@observer
class Menu extends Component {
  state = {
    isMenuOpen: false,
    location: '/',
    anchorEl: null,
    businesses: [],
    isLoadingBusinesses: true,
  }

  async componentWillMount(){
    const { userStore } = this.props;

    if(userStore.isAuthenticated) {
      this.setState({isLoadingBusinesses: true})
      const businesses = await this.loadBusinesses(userStore.user.businesses);
      this.setState({ businesses, isLoadingBusinesses: false })
    }
  }


  componentDidMount() {
    const { userStore, location } = this.props;
    if (!userStore.isAuthenticated) {
      return;
    }
    this.setState({ location: location.pathname })
  }

  componentWillUnmount() {
    this.lockScroll(false);
  }

  onToggleMenu = (bool = !this.state.isMenuOpen) => {
    this.lockScroll(bool)
    this.setState({ isMenuOpen: !this.state.isMenuOpen});
  }

  lockScroll = (bool) => {
    if (document.body.clientWidth <= 549) {
      document.body.style.overflow = bool? 'hidden':'auto';
    }
  }

  handleCheckActive = (item) => {
    if (this.props.location.pathname === item.path){
      return 'active';
    } else if (item.pathMatch){
      if (item.pathMatch.includes(this.props.location.pathname)){
        return 'active'
      } else {
        return '';
      }
    } else {
      return '';
    }

  }

  manageMenuItems = (items) => {
  	const business = this.props.profileStore.business;
    const mockItems = [...items];
    if (business && business.airtable) {
      mockItems.push({
        path: '/data',
        icon: 'bar_chart',
        text: 'Data'
      })
    }
    if (business && business.customPages) {
      business.customPages.forEach(({name}) => {
        mockItems.push({
          path: `/custom/${name}`,
          icon: 'call_to_action',
          text: name
        });
      })
    }
    //
    // if(this.props.managementStore.organization) {
    //   mockItems.splice(1, 0, {
    //     path: '/management',
    //     icon: 'store',
    //     text: 'Management'
    //   })
    // }
    return mockItems;
  };

  async logout() {
    await userStore.logout();
  }

  createSideBarMainMenuItems = () => {
    const userMenu = this.props.profileStore.isSeller ? sellerMenu : buyerMenu;
    let items = [...userMenu.Items];
    items = this.manageMenuItems(items);

    return items.map((item) => (
      <Link key={item['text']} to={item.path}>
        <ListItem button className={`list-item ${this.handleCheckActive(item)}`}>
          <ListItemIcon className="list-icon">
            <Icon>{item.icon}</Icon>
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>{item.text}</Typography>}
            className="list-text"
          />
        </ListItem>
      </Link>
    ))
  }

  createMobileMenu = () => {
    const userMenu = this.props.profileStore.isSeller? sellerMenu : buyerMenu;
    let items = [...userMenu.Items];

    items = this.manageMenuItems(items);

    return items.map((item) => (
      <Link key={`${item.text}mobile`} to={item.path}>
        <li className={` menu-item ${this.state.isMenuOpen ? 'open' : ''}`}>
          {item.text}
        </li>
      </Link>
    ))
  }

  showSwitch = () => {
    const { userStore } = this.props;
    if (!userStore.isAuthenticated) {
      return false;
    }
	const bizIDs = Object.keys(userStore.user.businesses);
	if(bizIDs.length > 1) {
		return true;
	}
    return false;
  }

  switchProfile = async (bizID) => {
    const { globalUIStore, profileStore } = this.props;
    globalUIStore.isLoading = true;
    await profileStore.switchBusiness(bizID);        
    globalUIStore.isLoading = false;
  }

  loadBusinesses = async (businesses) => {
    const allBiz = Object.keys(businesses).map(bizId => BusinessService.get(bizId))
    return await Promise.all(allBiz);
  }

  render() {
    const { business } = this.props.profileStore;
    const { businesses, isLoadingBusinesses } = this.state;
    const businessesAlt = businesses.filter(item => business.id !== item.id);
    let profilePic = require('../assets/placeholder_user.png');
    let profileName = null;
    if(business){
      if(business.picture){
        profilePic = business.picture;
      }
      profileName = business.name;
    }
    return (
      <React.Fragment>
        <div className={`menu-bar ${this.state.isMenuOpen ? '' : 'close'}`}>
          <div className="topBar">
            <img src={logo} alt="logo" className="freshspire-logo" />
          </div>
          <div className="profile">
            <div className="picture-container">
              <img src={profilePic} alt="user picture" className="profile-picture" style={{ backgroundColor: '#fff' }}/>
            </div>
            <div className="name">
              {profileName ? profileName : ''}
            </div>
            <SwitchContainer>
              {
                this.showSwitch() &&
                <BusinessSwitchStyled
                    businesses={businessesAlt}
                    isLoading={isLoadingBusinesses}
                    onSelect={this.switchProfile}
                    minimized={!this.state.isMenuOpen}
                />
              }
            </SwitchContainer>
          </div>
          <Divider className="divider"/>
          <List className="list-container">
            {this.createSideBarMainMenuItems()}
          </List>
          <List className="list-container bottom">
            <Link to="/help">
              <ListItem className={`logout-link list-item ${this.handleCheckActive({path: '/help'})}`}>
                <ListItemIcon className="list-icon">
                  <Icon>headset_mic</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>Help</Typography>}
                  className="list-text"
                />
              </ListItem>
            </Link>
            <Link to="/settings/account">
              <ListItem className={`logout-link list-item ${this.handleCheckActive({path: '/settings/account'})}`}>
                <ListItemIcon className="list-icon">
                  <Icon>settings</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>Settings</Typography>}
                  className="list-text"
                />
              </ListItem>
            </Link>
            <StyledLogoutButton withText={false} withIcon={false} className="logout-button" style={{ backgroundColor: 'transparent', textTransform: 'none', padding: '0' }}>
              <ListItem className="logout-link list-item">
                <ListItemIcon className="list-icon">
                  <Icon>subdirectory_arrow_right</Icon>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>Logout</Typography>}
                  className="list-text"
                />
              </ListItem>
            </StyledLogoutButton>

          </List>
        </div>
        <div className={`bar-toggle ${this.state.isMenuOpen ? '' : 'close'}`} onClick={this.onToggleMenu}>
          {this.state.isMenuOpen ? (<Icon>close</Icon>) : <Icon>menu</Icon>}
        </div>
        <div className="menu-fab" style={{zIndex: this.state.isMenuOpen?5:3 , visibility: this.props.hideFab?'hidden':'inherit'}}>
          <div className={`fab-overlay ${this.state.isMenuOpen? 'open':''}`}></div>
          <div className={`top-logo ${this.state.isMenuOpen ? 'open' : ''}`}>
            <div className="fab-background"></div>
            <div onClick={() => this.onToggleMenu(false)} className="close-icon">
              <Icon className="icon">close</Icon>
            </div>
            <img src={logoFab} alt="logo" onClick={()=>this.onToggleMenu(!this.state.isMenuOpen)} className={`${this.state.isMenuOpen ? 'open' : ''}`} />
            <ul className={`dropdown-menu ${this.state.isMenuOpen ? 'open' : ''}`}>
              {
                this.showSwitch() &&
                <SwitchContainerMbl className={` menu-item ${this.state.isMenuOpen ? 'open' : ''}`}>
                  <BusinessSwitchStyled
                      businesses={businessesAlt}
                      isLoading={isLoadingBusinesses}
                      onSelect={this.switchProfile}
                      color={true}
                  />
                </SwitchContainerMbl>
              }
              {this.createMobileMenu()}
              <Link to="/help">
                <li className={` menu-item ${this.state.isMenuOpen ? 'open' : ''}`}>
                  Help
                </li>
              </Link>
              <Link to="/settings/account">
                <li className={` menu-item ${this.state.isMenuOpen ? 'open' : ''}`}>
                  Settings
                </li>
              </Link>
              <StyledLogoutButton withText={false} withIcon={false} className="logout-button" style={{ backgroundColor: 'transparent', textTransform: 'none', padding: '0' }}>
                <li className={` menu-item ${this.state.isMenuOpen ? 'open' : ''}`}>
                  Logout
                </li>
              </StyledLogoutButton>
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Menu)
