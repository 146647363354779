import { action, observable, computed } from 'mobx'
import * as searchjs from 'searchjs';
import { FoodOptions } from '../../core/core';
import { profileStore, shoppingStore, inventoryStore, clientListStore } from '..';

class SearchStore {
    @observable list = []
    @observable openList = []
    @observable clientList = []
    @observable foodList = Object.values(FoodOptions);
    @observable detailList = ['Delivery','Pickup','Local','Organic'];
    @observable query = null
    @observable sortCondition = 'name-asc'
    @observable listIsOpen = false;
    @observable distance = 100;
    @observable queryDistance = 100;
    @observable filterConditions = {
        Public: {
            field: 'public',
            value: true,
            active: false,
            strict: false,
        },
    }

    @computed get List() {
        let _matches = [];
        if(this.listIsOpen) {
            if (!this.openList) return [];
    
            _matches = this.openList;
        } else {
            if (!this.list) return [];

            _matches = this.list;
        }

        if (this.query)
            _matches = this.matchQuery(_matches);

        const filtered = this.isFiltered();
        if (filtered){
            _matches = this.matchFilterConditions(_matches);
            _matches = this.matchFilterConditions(_matches,true);
        }

        _matches = this.applySort(_matches);

        if(_matches === undefined)
            return [];

        return _matches;
    }

    isFiltered() {
        return Object.values(this.filterConditions).reduce((a,b) => {
            return  {active: a.active || b.active};
        }).active
    }

    matchQuery(list) {
        return searchjs.matchArray(list, { "product.name": this.query, _propertySearch: true, _text: true })
    }

    matchFilterConditions(list, strict = false) {
        let newList = strict? list : [];
        const conditionList = Object.values(this.filterConditions).filter((condition) => {return (condition.strict === strict && condition.active)})
        if(conditionList.length > 0){
            conditionList.map((condition) => {
                let morphList = strict? newList: list; 
                let { field, value } = condition;
                let arr = searchjs.matchArray(morphList, { [`product.${field}`]: value, _propertySearch: true});
                newList = strict? arr : [...new Set([...newList, ...arr])];
            })
            return newList;
        } else {
            return list;
        }
    }

    nameSort(list, ascending) {
        console.log(list)
        if (ascending) {
            list = list.sort((a, b) => {
                return a.product.name.toLowerCase() > b.product.name.toLowerCase() ? 1 : -1;
            })
        } else {
            list = list.sort((a, b) => {
                return a.product.name.toLowerCase() > b.product.name.toLowerCase() ? -1 : 1;
            })
        }
        return list;
    }

    dateSort(list, ascending) {
        if (ascending) {
            list = list.sort((a, b) => {
                return a.product.timestamp > b.product.timestamp ? 1 : -1;
            })
        } else {
            list = list.sort((a, b) => {
                return a.product.timestamp > b.product.timestamp ? -1 : 1;
            })
        }
        return list;
    }

    applySort(list) {
        switch (this.sortCondition) {
            case 'name-asc':
                list = this.nameSort(list, true);
                break;
            case 'name-desc':
                list = this.nameSort(list, false);
                break;
            case 'use-by-date-asc':
                list = this.dateSort(list, true);
                break;
            case 'use-by-date-desc':
                list = this.dateSort(list, false);
                break;
            default:
                break;
        }
        return list;
    }

    setUpConditions() {
        for(let i=0; i<this.foodList.length; i++) {
            const food = this.foodList[i]
            this.filterConditions[food] = observable({
                field: 'foodCategory',
                value: food,
                active: false,
                strict: false,
            })
        }
        for(let i=0; i<this.clientList.length; i++) {
            const client = this.clientList[i]
            this.filterConditions[client] = observable({
                field: 'seller.name',
                value: client,
                active: false,
                strict: false,
            })
        }
        for(let i=0; i<this.detailList.length; i++) {
            const detail = this.detailList[i]
            this.filterConditions[detail] = observable({
                field: detail.toLowerCase(),
                value: true,
                active: false,
                strict: true,
            })
        }
    }

    @action
    resetConditions() {
        this.resetFarmerConditions();
        this.resetFoodConditions();
        this.resetDetailConditions();
        this.resetSearch();
    }

    @action
    resetFarmerConditions() {
        for(let i=0; i<this.clientList.length; i++) {
            const client = this.clientList[i]
            this.filterConditions[client].active = false;
        }
    }

    @action
    resetFoodConditions() {
        for(let i=0; i<this.foodList.length; i++) {
            const food = this.foodList[i]
            this.filterConditions[food].active = false;
        }
    }

    @action
    resetDetailConditions() {
        for(let i=0; i<this.detailList.length; i++) {
            const detail = this.detailList[i]
            this.filterConditions[detail].active = false;
        }
    }

    @action
    resetSearch() {
        this.query = null;
        Object.values(this.filterConditions).map((condition) => condition.active = false);
        this.sortCondition = 'name-asc';
    }

    @action
    switchToOpenList(bool) {
        this.listIsOpen = bool;
    }

    @action
    updateSort(sort) {
        this.sortCondition = sort;
    }

    @action
    setValidator = (validator, active) => {
        this.filterConditions[validator].active = active;
    }
    
    @action
    onRadioChange = (validator, value, active) => {
        this.filterConditions[validator].active = active;
        this.filterConditions[validator].value = value;
    }

    @action
    updateQuery(query) {
        this.query = query;
    }

    @action
    updateQueryDistance(dist) {
        this.queryDistance = dist;
    }

    @action
    updateDistance() {
        this.distance = this.queryDistance;
    }

    @action
    setList(list,openList,clientList) {
        this.list  = !list ? [] : list; 
        this.openList  = !openList ? [] : openList; 
        this.clientList  = !clientList ? [] : clientList.map(client => client.name);
        this.setUpConditions();
    }

    @action
    async refresh() {
        if(profileStore.business.isBuyer()){
            this.list = shoppingStore.affiliatedItems;
            this.openList = shoppingStore.nearbyItems;
            this.clientList = clientListStore.totalClientList.map(client => client.name);
            this.setUpConditions();
        } else {
            this.list = await inventoryStore.getDeals();
        }
    }

}
const searchStore = new SearchStore();
export default searchStore
