import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseButton from '../../atomic/buttons/CloseButton';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff9999;
    :hover {
        opacity: 0.8;
    }
    padding: 0 10px;
`;

const StyledCloseButton = styled(CloseButton)`
    font-size: 1em;
    position: absolute;
    right: 5px;
`;


class Header extends React.Component {
    state = {
        hidden: false
    }

    onClose = async (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }        
        this.setState({ hidden: true });
        await this.props.onClose();
    }

    onClick = async (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        await this.props.onClick();
    }

    render() {
        return (
            !this.state.hidden && 
            <Container className={this.props.className} onClick={this.onClick}>
                {this.props.children}
                <StyledCloseButton onClick={this.onClose} fontSize="inherit" />
            </Container>
        );
    }
}

Header.propTypes = {
    allowClose: PropTypes.bool,
    onClick: PropTypes.func,
    onClose: PropTypes.func
}

Header.defaultProps = {
    allowClose: true,
    onClick: () => {},
    onClose: () => {}
}

export default Header;