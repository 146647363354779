import React from 'react';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledIcon = styled(MailIcon)`
    color: rgba(0, 0, 0, 0.87);
`;

@inject("messagingStore")
@observer
class MessagesLink extends React.Component {
    getLink(className) {
        return (
            <Link id="messages_link" to={"/messages"} className={className}>
                <StyledIcon/>
            </Link>
        );
    }
    render() {
        const num = this.props.messagingStore.numUnread;
        if (num) {
            return (
                <Badge badgeContent={num} color="secondary" className={this.props.className}>
                    {this.getLink()}
                </Badge>
            )
        }
        else {
            return this.getLink(this.props.className);
        }
    }
}

export default MessagesLink;