import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import breakpoint from 'styled-components-breakpoint'
import Button from '../../atomic/buttons/Button'
import { Link } from 'react-router-dom'

const StyledButton = styled(Button)`
    :not(:first-child) {
        margin-left: ${props => props.mobile? '0':'12px'}
    }
    min-height: ${props => props.mobile? 'none':'26px'}
    line-height: normal;
    text-transform: none;
`

const Divider = styled.div`
    border-right: 1px solid rgb(116, 116, 116);
    margin: auto 4px;
    height: 15px;
`

class ButtonFactory extends Component {

    createButton = (item,mobile) => {
        if(item.path) {
            return (
                <StyledButton
                    mobile={mobile}
                    key={`${item.text}-bottom`}
                    size="small"
                    variant={mobile?"":"contained"}
                    color={item.color}
                    onClick={() => item.onClick()}
                    component={Link} 
                    to={item.path}
                    disabled={item.disabled}
                >{item.text}</StyledButton>
            )
        } else {
            return (
                <StyledButton
                    mobile={mobile}
                    key={`${item.text}-bottom`}
                    size="small"
                    variant={mobile?"text":"contained"}
                    color={item.color}
                    onClick={() => item.onClick()}
                    disabled={item.disabled}
                >{item.text}</StyledButton>
            )
        }
    }

    retrieveButtons = () => {
        const{ buttons,mobile } = this.props
        const multi = buttons.length>1;
        let buttonIndex = 0;
        if (!buttons) {
            return;
        }
        return buttons.map((item,index) => {
            if (!item.mobile && mobile) {
                buttonIndex--;
                return;
            }
            return (
                <React.Fragment>
                    {((multi && mobile) && buttonIndex++ >0)?(<Divider></Divider>):''}
                    {this.createButton(item,mobile)}
                </React.Fragment>
            )
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.retrieveButtons()}
            </React.Fragment>
        )
    }
}

export default ButtonFactory