import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import CloseButton from '../../atomic/buttons/CloseButton';
import CloseIcon from '@material-ui/icons/Close';
import DateInput from '../../atomic/inputs/DateInput';
import QuantityInput from '../../atomic/inputs/QuantityInput';
import Dropdown from '../../atomic/selectors/Dropdown';
import Modal from '../../Modal';
import ProductListingGroupModal from '../../organisms/modals/ProductListingGroupModal';
import ProductListingGroup from '../../../core/model/ProductListingGroup';
import moment from 'moment'

const CloseButtonStyled = styled(CloseButton)`
  @media (max-width: 768px) {
    margin-left: auto;
  }
`

const CheckboxStyled = styled(Checkbox)`

`
const TextLabel = styled.div`
  font-size: 12px;
  margin-bottom: -2px;
  opacity: 0.9;
`

const LinkStyled = styled(Link)`
  color: inherit;
  font-size: 12px;
  font-family: ${props => props.theme.fonts.ms_font500};
  @media (min-width: 769px) {
    color: ${props => props.theme.colors.spearmint_main};
  }
`

const HyperLinkIcon = styled(Icon)`
  font-size: 10px;
  margin-bottom: 4px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex: none;
    order: 2;
    width: 100%;
    justify-content: space-between; 
    flex-direction: column;
  }
  @media (max-width: 550px) {
    transform: scale(0.9);
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    justify-content: space-between; 
  }
`;

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 769px) {
    margin-right: 20px;
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameContainer = styled.div`
  font-family: ${props => props.theme.fonts.ms_font700};
  ${InfoContainer}:hover & {
    text-decoration: underline;
  }
`;

const DetailContainer = styled.div`
  color: ${props => props.theme.colors.opposite_main};
  font-family: ${props => props.theme.fonts.ms_font500};
`;

const PriceContainer = styled.div`
  font-family: ${props => props.theme.fonts.ms_font500};
`;

const UnitContainer = styled.div`
  color: ${props => props.theme.colors.spearmint_main};
  font-family: ${props => props.theme.fonts.ms_font300};
`;

const CostContainer = styled.div`
  display: flex;
`;

const ExchangeText = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 14px;
  width: 110px;
  @media (max-width: 768px) {
    width: 100px;
  }
  @media (min-width: 769px) {
    margin-right: 15px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    justify-content: space-between; 
  }
`;

const ListItemContainer = styled(ListItem)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  @media (max-width: 768px) {
    padding-top: 0px;
  }
  @media (max-width: 550px) {
    padding-left: 0px;
    padding-right: 0px;
    ${'' /* margin-bottom: 10px; */}
  }
  ${props => props.highlight && css`
    background-color: #f5baba;
  `}
`

class ProductListItem extends Component {
  constructor (props) {
    super(props)
    this.modal = React.createRef();
    this.state = {
      needByDate: null,
      exchange: null,
      quantityRequested: null,
      watchme: false,
      modalOpen: false,
      highlight: false
    }
  }

  componentWillMount() {
    const { product } = this.props;
    this.setState({ 
      needByDate: product.needByDate,
      exchange: product.exchange,
      quantityRequested: product.quantityRequested,
    });
  }

  getDetails = () => {
    const { product } = this.props
    let details = '';
    details += product.productListing.product.organic? 'Organic' : '';
    details += product.productListing.product.local ? ' Local' : '';
    return details.trim();
  }

  openModal = (type) => {
    this.setState({ modalOpen: true });
    //this.modal.handleOpen(type);
  }

  createModal = (props) => {
  }

  checkQty = (value) => {
    if (value == null || value === '') {
      value = null;
    }
    else {
      value = parseInt(value);
      if (isNaN(value) || value < 1) {
        value = 1;
      }
    }
    return value;
  }

  getExchangeOptions = () => {
    const { product } = this.props
    if(product.productListing.product.delivery && product.productListing.product.pickup) {
      return (
        <Dropdown
          value={product.exchange}
          options={['delivery','pickup']}
          updateField={val => this.updateState(val,'exchange')}
        />
      )
    } else {
      let exchange = '';
      exchange += product.productListing.product.delivery ? 'Delivery' : 'Pick Up';
      return (
        <ExchangeText>
          <TextLabel>
            Exchange
          </TextLabel>
          {exchange}
        </ExchangeText>
      )
    }
  }

  updateState = (value,type) => {
    const { product } = this.props
    switch(type) {
      case 'quantity':
        value = this.checkQty(value);
        this.props.onUpdateItem(value,product.needByDate,product.exchange);
        break;
      case 'exchange':
        this.props.onUpdateItem(product.quantityRequested,product.needByDate,value);
        break;
      case 'date':
        this.props.onUpdateItem(product.quantityRequested,value,product.exchange);
        break;
      default:
        break;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const toRender = nextProps.singleRender !== null ? (nextProps.singleRender === nextProps.product.listIndex) : true;
    if(toRender){
      return true;
    } else {
      return false;
    }
  }

  handleSellerClick = (event) => {
    if (document.body.clientWidth <= 768) {
      event.preventDefault();
    }
  }

  keyPressed = (code) => {
    const { productIndex, inputShift } = this.props
    if(code === 13 || code === 9) {
      inputShift(productIndex)
      return true;
    }
    return false;
  }

  render() {
    const {
      product,
      onDelete,
      checked,
      error,
      onChecked,
      placeInput,
    } = this.props;

    let highlight_val = false;
    let value = product.quantityRequested;
    if (value == null || value === '') {
      highlight_val = true;
    }
    else {
      highlight_val = false;
    }

    value = product.needByDate;
    let highlight_date = false;
    const current = +moment().startOf("day");
    const ts = +moment(value, 'YYYY-MM-DD').startOf("day");
    if (!value || value.length === 0) {
      highlight_date = false;
    }
    else if (current > ts) {
      highlight_date = true;
    }
    else {
      highlight_date = false;
    }

    const highlight = highlight_val || highlight_date;
    return (
      <ListItemContainer key={`basket-item-${product.productListing.id}`} highlight={highlight}>
        <CheckboxStyled
          checked={checked}
          onChange={onChecked}
        />
        <ContentContainer>
          <InfoContainer onClick={() => this.openModal('basket')}>
            <TitlesContainer>
              {/* <LinkStyled to={`/profile/${product.deal.uid}`} onClick={(e) => this.handleSellerClick(e)}>
                {product.deal.seller.name}<HyperLinkIcon>open_in_new</HyperLinkIcon>
              </LinkStyled> */}
              <NameContainer>{product.productListing.product.seller.name}</NameContainer>
              <NameContainer>{product.productListing.product.name}</NameContainer>
            </TitlesContainer>
            <DataContainer>
              {
                product.productListing.price ? (
                  <CostContainer>
                    <PriceContainer>
                    {`$${product.productListing.price.toFixed(2)}`}&nbsp;
                    </PriceContainer>
                    <UnitContainer>
                    {`/ ${product.productListing.unit}`}
                    </UnitContainer>
                  </CostContainer>
                ) : (
                  <UnitContainer>{`Request Quote -- ${product.productListing.unit}`}</UnitContainer>
                )
              }
              <DetailContainer>{this.getDetails()}</DetailContainer>
            </DataContainer>
          </InfoContainer>
          <InputContainer>
            <DateInput
              value={product.needByDate}
              updateField={val => this.updateState(val,'date')}
            />
            {this.getExchangeOptions()}
            <QuantityInput 
              inputWidth={80}
              value={product.quantityRequested}
              updateField={val => this.updateState(val,'quantity')}
              unit={product.productListing.unit}
              qty={product.productListing.getBaseQuantity()}
              onKeyPress={(code) => this.keyPressed(code)}
              captureRef={placeInput}
            />
          </InputContainer>
        </ContentContainer>
        <CloseButtonStyled fontSize="small" color='secondary' onClick={async() => onDelete()}/>
        {this.state.modalOpen && <ProductListingGroupModal open={this.state.modalOpen} productListingGroup={new ProductListingGroup(product.productListing.product, [product.productListing])} hideSelector type="basket" onClose={() => this.setState({ modalOpen: false })}/>}
        {this.createModal(this.props)}
      </ListItemContainer>
    )
  }
}

ProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  error: PropTypes.bool.isRequired,
  onChecked: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
};

ProductListItem.defaultProps = {
  checked: false,
};

export default ProductListItem;