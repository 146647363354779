import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const StyledTypography = styled.div`
    text-align: center;
    margin: 6px 0;
    font-size: 1.1em;
    font-family: ${props => props.theme.fonts.ms_font300};
    ${props => {
        switch(props.size) {
            case 'sm':
                return css`font-size: 0.75em;`;
            case 'md':
                return css`font-size: 1.1em;`;
            case 'lg':
                return css`font-size: 2em;`;
            default:
                return;
        }
    }}
`

const StyledIcon = styled(Icon)`
    font-size: 10px;
    margin-bottom: 5px;
`

const LinkStyled = styled(Link)`
  color: ${props => props.color || props.theme.colors.spearmint_main};
  text-decoration: none;
  text-decoration-color: ${props => props.theme.colors.spearmint_main};
  &:hover {
      text-decoration: underline;
  }
`;

class ProfileLink extends Component {
    render() {
        const { className, ...other } = this.props;
        return (
            <LinkStyled {...other} to={`/profile/${this.props.uid}`}>
                <StyledTypography size={this.props.size} className={className}>
                    {this.props.text} {this.props.showIcon && (<React.Fragment>&nbsp;<StyledIcon>open_in_new</StyledIcon></React.Fragment>)}
                </StyledTypography>
            </LinkStyled>
        )
    }
}

ProfileLink.propTypes = {
    size: PropTypes.string,
    showIcon: PropTypes.bool,
    text: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired
}

ProfileLink.defaultProps = {
    size: 'md',
    showIcon: true
}

export default ProfileLink