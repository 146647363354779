import React from 'react';

const TermsOfUse = props => {
    return (
      <div>
        <p align="CENTER" style={{marginBottom: '0in', lineHeight: '100%', pageBreakInside: 'avoid', widows: 2, orphans: 2, pageBreakAfter: 'avoid'}}><a name="_GoBack" />
          <font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><span style={{fontVariant: 'small-caps'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Last
                          Revised</b></font></font></font></span><span style={{fontVariant: 'small-caps'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>:</b></font></font></font></span><span style={{fontVariant: 'small-caps'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>
                      </font></font></font></span><span style={{fontVariant: 'small-caps'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>September
                          25, 2018</b></font></font></font></span></font></font></font></p>
        <p style={{marginBottom: '0.14in', lineHeight: '100%'}}><br /><br />
        </p>
        <p align="JUSTIFY" style={{lineHeight: '100%'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Freshspire
                      (“</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><u>Freshspire</u></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>,”</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>
                      </b></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>“</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><u>we</u></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>,”
                      “</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><u>ours</u></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>,”
                      “</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><u>us</u></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>”)
                      respects and values your privacy, and we are committed to protecting
                      it through our compliance with this privacy policy (“</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><u>Policy</u></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>”).
                      This Policy describes the types of information we may collect from
                      you or that you may provide when you visit or utilize
                    </font></font></font> <a href="http://www.getfreshspired.com"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>www.getfreshspired.com</font></font></a>, <a href="https://www.freshspire.io/"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>www.freshspire.io</font></font></a><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>,
                      or any other products, applications, or features provided by us
                      (collectively, the “</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><u>Platform</u></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>”),
                      as well as our practices for collecting, using, maintaining,
                      protecting, and disclosing that information. Any capitalized terms
                      used herein that are not defined herein shall be given the meaning
                      ascribed to them in our Terms of Use. </font></font></font></font></font></font>
        </p>
        <p align="JUSTIFY" style={{lineHeight: '100%'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>By
                      accessing or using the Platform, you fully agree to all the terms of
                      this Policy.&nbsp;If you do not agree with this Policy, or our other
                      policies and practices, you must not use the Platform. This Policy
                      may change from time to time. Your continued use of this Platform
                      after such changes are made is deemed to be acceptance of those
                      changes. It is your responsibility to check this Policy periodically
                      for updates.</font></font></font></font></font></font></p>
        <center>
          <table cellPadding={2} cellSpacing={0}>
            <colgroup><col width={111} />
              <col width={507} />
            </colgroup><tbody><tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>How
                                  does this Policy Apply?</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>This
                                Policy applies to information we collect: (1) on this Platform;
                                (2) in any electronic communication between you and the Platform;
                                and (3) when you interact with our advertising and applications
                                on third-party websites and Platforms.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><br />
                  </p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>This
                                Policy does NOT apply to information collected by: (1) us offline
                                or through any other means, including on any other website or
                                service operated by Freshspire; or (2) any third party (including
                                our affiliates and subsidiaries), including through any service,
                                application, content, or advertisement that may be linked to the
                                Platform.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Children
                                  Under the Age of 13&nbsp;&nbsp;</b></font></font></font></font></font></font></p>
                  <p><br />
                  </p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY"><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>The
                                Platform is not intended for children under 13 years of age. No
                                one under age 13 may provide any information to the Platform. We
                                do not knowingly collect personal information from children under
                                13. If we learn we have collected or received personal
                                information from a child under 13 without verification of
                                parental consent, we will delete that information. If you believe
                                we might have any information from or about a child under 13,
                                please contact us at help@getfreshspired.com. </font></font></font></font></font></font>
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Information
                                  We Collect Includes Personal Information; Non-Personal
                                  Information; and Information Related to Your Access and Use of
                                  the Platform</b></font></font></font></font></font></font></p>
                  <p><br />
                  </p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>We
                                collect information directly and voluntarily from you when you
                                use the Platform. We consider the information we collect in two
                                broad categories: “</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><u>Personal
                                  Information</u></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>”
                                and “</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><u>Non-Personal
                                  Information</u></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>”.
                              </font></font></font></font></font></font>
                  </p>
                  <p className="western" align="JUSTIFY" style={{marginBottom: '0in'}}><br />
                  </p>
                  <p className="western" align="JUSTIFY" style={{marginBottom: '0in'}}><em><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><span style={{fontStyle: 'normal'}}><b>a.
                              Personal Information</b></span></font></font></em><em><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>.
                        </font></font></em><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}> Personal
                          Information is generally information that can be used to identify
                          you, either by itself or in conjunction with other information.
                          Personal Information includes, without limitation:</font></font></font></p>
                  <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
                    <li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font face="Times New Roman, serif"><font color="#000000"><font size={2} style={{fontSize: '11pt'}}>
                            </font></font><font color="#000000" size={2} style={{fontSize: '11pt'}}>Your
                            name, address, e-mail address, and telephone number;</font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginRight: '0.05in', marginBottom: '0in'}}>
                        <font face="Times New Roman, serif"><font color="#000000" size={2} style={{fontSize: '11pt'}}>Your
                            business name and address;</font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginRight: '0.05in', marginBottom: '0in'}}>
                        <font face="Times New Roman, serif"><font color="#000000" size={2} style={{fontSize: '11pt'}}>Your
                            credit card information and billing address;</font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#000000" face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Information
                            and content that you provide or upload for use in connection
                            with the Platform, which may include photographs, data, text,
                            and other information; and</font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#000000" face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Demographic
                            information such as date of birth, gender, and geographic area,
                            which when linked to other information that allows that
                            individual to be identified.</font></font></p>
                    </li></ul>
                  <p className="western" align="JUSTIFY" style={{marginBottom: '0in'}}><br />
                  </p>
                  <p className="western" align="JUSTIFY" style={{marginBottom: '0in'}}><em><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><span style={{fontStyle: 'normal'}}><b>b.
                              Non-Personal Information</b></span></font></font></em><em><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>.
                        </font></font></em><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}> Non-Personal
                          Information includes information about you, but does not identify
                          you personally, including, without limitation (i) anonymous usage
                          data; (ii) general demographic information; (iii) referring/exit
                          pages and URLs; (iv) preferences you submit and that are
                          generated based on your usage data (such as purchases or sales of
                          Produce); </font></font></font><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>(v)
                      </font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>browser
                          or platform type (vi) internet connection; (vii) equipment used
                          to access the Platform; (viii) language preference, (ix)
                          referring site, (x) additional websites requested, and (xi) the
                          date and time of each user’s request. </font></font></font>
                  </p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>How
                                  We Collect Information from You </b></font></font></font></font></font></font>
                  </p>
                  <p style={{marginBottom: '0in'}}><br />
                  </p>
                  <p style={{marginBottom: '0in'}}><br />
                  </p>
                  <p><br />
                  </p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>We
                                collect information from you in the following ways:</font></font></font></font></font></font></p>
                  <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
                    <li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Directly
                                    from you when you provide it to us or authorize or prompt us to
                                    obtain it;</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Automatically
                                    as you navigate through our Platform through the use of cookies,
                                    pixels, scripts, device identifiers, and other tracking
                                    technologies; and</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>From
                                    third parties, for example, our Payment Processor, social media
                                    sites to which we are connected and with which you interact, and
                                    the software providers that facilitate our newsletter.</font></font></font></font></font></font></p>
                    </li></ul>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Automatic
                                  Data Collection and How We Use It</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>In
                                an effort to improve the quality of the Platform, Freshspire (and
                                our service providers) may track information provided to us by
                                your device or browser, or by our analytics tools when you use
                                the Platform. As you access, use, and interact with the Platform,
                                we may use automatic data collection technologies to collect
                                certain information about your equipment, browser, operating
                                system, IP address, browsing actions, and patterns, including
                                details of your visits to the Platform (including traffic data),
                                location data, the device used to access the Platform, your
                                internet connection, the website from which you came (known as
                                the “referring URL”), other communication data, and the
                                resources that you access and use on the Platform. We also may
                                use these technologies to collect information about your online
                                activities over time and across third-party websites or other
                                online services (behavioral tracking). </font></font></font></font></font></font>
                  </p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><br />
                  </p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>The
                                information we collect automatically is statistical data and may
                                include Personal Information, or we may maintain it or associate
                                it with Personal Information we collect in other ways or receive
                                from third parties. It helps us to improve the Platform and
                                deliver a better and more personalized user experience.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Automatic
                                  Data Collection Technologies May Include </b></font></font></font></font></font></font>
                  </p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>The
                                technologies we use for the automatic data collection described
                                above may include </font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Cookies
                                (or browser cookies).</font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>
                                A cookie is a small file placed on the hard drive of your
                                computer. You may refuse to accept browser cookies by activating
                                the appropriate setting on your browser. However, if you select
                                this setting you may be unable to access certain parts of the
                                Platform. Unless you have adjusted your browser setting so that
                                it will refuse cookies, our system will issue cookies when you
                                direct your browser to the Platform.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Location
                                  Information</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>We
                                do not track your location.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>How
                                  We Use Personal Information</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><a name="_gjdgxs" />
                    <font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Except
                                as otherwise stated in this Policy, we do not sell, trade, rent,
                                or otherwise share for marketing purposes your Personal
                                Information with third parties without your consent. We may share
                                Personal Information with vendors who are performing services for
                                Freshspire, such as our Payment Processor or providers of servers
                                for our email communications who provide us the ability to send
                                emails.&nbsp;Those vendors use your Personal Information </font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><i><b>only
                                    at our direction and in accordance with our Policy</b></i></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><br />
                  </p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>We
                                may use your Personal Information to:</font></font></font></font></font></font></p>
                  <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
                    <li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Communicate
                                    with you by text message, phone call, or email (for example, we
                                    may use your Personal Information to contact you with questions
                                    concerning feedback, billing, technical support, promotional
                                    offers or otherwise);</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Facilitate
                                    and carry out the functions of the Platform and the Service
                                    (such as facilitating orders, deliveries, pick-ups, payment for
                                    Produce, etc.);</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Verify
                                    your identity (such as during account creation and password
                                    resets);</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Enforce
                                    our Terms, and prevent prohibited or illegal activities;</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Improve
                                    the Platform, including by customizing your user experience;</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Provide
                                    targeted marketing, provide service update notices, and deliver
                                    promotional offers based on your communication preferences;</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Administer
                                    referral programs, rewards, surveys, contests, or other
                                    promotional activities or sponsored events;</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>Comply
                                    with our legal obligations, resolve any disputes that we may
                                    have with any of our users, and enforce our agreements; and</font></font></font></font></font></font></p>
                    </li><li><p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>For
                                    any other purpose disclosed to you and to which you consent.</font></font></font></font></font></font></p>
                    </li></ul>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><br />
                  </p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>We
                                may also disclose your Personal Information: (1) to comply with
                                any court order, law, or legal process, including to respond to
                                any government or regulatory request; (2) to ensure compliance
                                with our Terms and other agreements; and (3) to protect the
                                rights, property, or safety of Freshspire, our customers, or
                                others. In addition, we retain the right to share Personal
                                Information with other companies and organizations for purposes
                                of fraud prevention.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><br />
                  </p>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Note:
                                </b></font></font></font><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}> In
                                the event we undergo a business transaction such as a merger,
                                acquisition by another company, or sale of all or a portion of
                                our assets, your Personal Information may be among the assets
                                transferred.&nbsp;You acknowledge and consent that such transfers
                                may occur and are permitted by this Policy, and that any acquirer
                                of our assets may continue to process your Personal Information
                                as set forth in this Policy.&nbsp;If our information practices
                                change at any time in the future, we will post the Policy changes
                                to the Platform so that you may opt out of the new information
                                practices. &nbsp;We suggest that you check the Platform
                                periodically if you are concerned about how your information is
                                used. </font></font></font></font></font></font>
                  </p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>How
                                  We Use Non-Personal Information and Other Information</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>In
                                general, we use Non-Personal Information to help us improve the
                                Platform and customize the user experience.&nbsp;We also
                                aggregate Non-Personal Information in order to track trends and
                                analyze use patterns on the Platform.&nbsp;This Policy does not
                                limit in any way our use or disclosure of Non-Personal
                                Information and we reserve the right to use and disclose such
                                Non-Personal Information to our partners, advertisers and other
                                third parties at our discretion. We may disclose aggregated
                                information about our users and any information that does not
                                identify any specific individual, without restriction. </font></font></font></font></font></font>
                  </p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Third
                                  Party Use of Cookies and Other Tracking Technologies</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>In
                                accordance with this Policy, certain third party services or
                                applications may be given access to data (which may include
                                Personal Information) from your account or generated in
                                connection with your use of the Platform. While we will only
                                disclose information to such third parties in accordance with the
                                terms of this Policy and as necessary to facilitate the Platform
                                and Service, we cannot control or verify how any such third
                                parties may collect, use, or track information. Freshspire shall
                                not be responsible or liable for the policies or practices of any
                                third party service provider. We  strongly encourage you to
                                review the terms of use and privacy policies of any third-party
                                application or service with which we integrate or otherwise use
                                in connection with the Platform, including without limitation,
                                our Payment Processor. </font></font></font></font></font></font>
                  </p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Data
                                  Security</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>We
                                implement security measures designed to protect your information
                                from unauthorized access.&nbsp;Your account is protected by your
                                account password and we urge you to take steps to keep your
                                personal information safe by not disclosing your password and by
                                logging out of your account after each use.&nbsp;We further
                                protect your information from potential security breaches by
                                implementing certain security measures including encryption,
                                firewalls and secure socket layer technology.&nbsp;However, these
                                measures do not guarantee that your information will not be
                                accessed, disclosed, altered or destroyed by breach of such
                                firewalls and secure server software.&nbsp;By using our Platform,
                                you acknowledge that you understand and agree to assume these
                                risks, and Freshspire shall not be responsible for the
                                circumvention of any of the Platform’s privacy settings or
                                security measures. </font></font></font></font></font></font>
                  </p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Links
                                  to Other Websites</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>We
                                are not responsible for the privacy practices employed by
                                websites or services to which the Platform links. This Policy
                                applies solely to our practices concerning your information.
                                Therefore, this Policy does not apply to your use of a third
                                party website accessed by selecting a link on our Platform. To
                                the extent that you access or use the Platform through or on
                                another website or application, then the privacy policy of that
                                other website or application will apply to your access or use of
                                that site or application.&nbsp;We encourage our users to read the
                                privacy statements of those websites before proceeding to use
                                them.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} height={105} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Changes
                                  to Our Policy</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p align="JUSTIFY"><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>We
                                reserve the right to change this Policy and our Terms of Use at
                                any time. &nbsp;We will notify you of significant changes to our
                                Policy by sending a notice to the primary email address specified
                                in your account or by placing a prominent notice on our site.
                                &nbsp;Significant changes will go into effect thirty (30) days
                                following such notification. &nbsp;Non-material changes or
                                clarifications will take effect immediately. You should
                                periodically check the Platform and this privacy page for
                                updates.</font></font></font></font></font></font></p>
                </td>
              </tr>
              <tr valign="TOP">
                <td width={111} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#00000a"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}><b>Contact
                                  Us</b></font></font></font></font></font></font></p>
                </td>
                <td width={507} style={{border: '1px solid #00000a', paddingTop: '0.02in', paddingBottom: '0.02in', paddingLeft: '0.02in', paddingRight: '0.02in'}}>
                  <p className="western" align="JUSTIFY" style={{marginBottom: '0in'}}><font color="#4d4d4d"><font face="Arial, serif"><font size={2} style={{fontSize: '9pt'}}><font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>If
                                you have any questions regarding this Policy or the practices of
                                this Platform, please contact us by sending an email to
                                help@getfreshspired.com.</font></font></font></font></font></font></p>
                  <p align="JUSTIFY"><br />
                  </p>
                </td>
              </tr>
            </tbody></table>
        </center>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0.14in', lineHeight: '125%'}}>
          <br /><br />
        </p>
      </div>
    )
}

export default TermsOfUse;