import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Menu from '../../components/Menu'
import Topbar from '../../components/CommonTopBar'
import {LoadingSpinnerPage} from '../../components/LoadingSpinner'
import { message } from 'antd'
import Typography from '@material-ui/core/Typography';
import BusinessItem from '../../components/molecules/BusinessItem';
import OrganizationBundleListItem from '../../components/molecules/OrganizationBundleListItem';
import Bottombar from '../../components/CommonBottomBar'
import Add from '@material-ui/icons/Add';
import Button from '../../components/atomic/buttons/Button';
import LinkButton from '../../components/atomic/buttons/LinkButton';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import ClientSearchBar from '../../components/ClientSearchBar';
import { clientSearchStore } from '../../stores';
import ProfileLink from '../../components/atomic/ProfileLink';
import { toJS } from 'mobx'

const SellerTitle = styled(ProfileLink)`
  font-size: 1.5em !important;
  font-weight: bold;
  margin-left: 15px;
  color: black;
`;

const SellerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => !props.top && css`margin-top: 20px`}
`;

const OrganizationBundleListItemStyled = styled(OrganizationBundleListItem)`
  margin: 8px 10px;
  border-radius: 10px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  box-shadow: none;
`;

const buyerTabs = [
  {
    name: `Affiliated`,
    child: false,
    path: '/affiliated'
  },
  {
    name: `Nearby`,
    child: false,
    path: '/clientlist'
  },
  {
    name: `Requests`,
    child: false,
    path: '/invitations'
  },
  {
    name: `Pending`,
    child: false,
    path: '/pending'
  },
];

const buyerPageInfo = [
  {
    sub: 'You are affiliated and can buy from these sellers'
  },
  {
    sub: 'Visit a seller\'s profile to request to be on their list and view their items.'
  },
  {
    sub: 'These sellers want to be able to sell to you'
  },
  {
    sub: 'Sellers you’ve requested to enlist you'
  },
]

const sellerTabs = [
  {
    name: `Current Buyers`,
    child: false,
    path: '/affiliated'
  },
  {
    name: `Nearby`,
    child: false,
    path: '/clientlist'
  },
  {
    name: `Requests`,
    child: false,
    path: '/invitations'
  },
  {
    name: `Pending`,
    child: false,
    path: '/pending'
  },
];

const sellerPageInfo = [
  {
    sub: 'The following buyers are on your client list and can buy the items you are selling'
  },
  {
    sub: 'These buyers see your products but can only buy if they are on your client list.'
  },
  {
    sub: 'These are buyers who have asked to join your client list'
  },
  {
    sub: 'These are buyers who you’ve invited to join your client list'
  },
]

const sellerNavButtons = [
  {
    text: null
  },
  {
    text: 'Invite Buyers',
    icon: (<Add/>),
    onClick: (() => null),
    path: '/clientlist/add'
  },
]

const sellerTopBarButton = {
  text: 'Invite Buyers',
  icon: 'add',
  path: '/clientlist/add',
  fixed: true,
}

const buyerNavButtons = [
  {
    text: null
  },
  {
    text: 'Invite Sellers',
    icon: (<Add/>),
    onClick: (() => null),
    path: '/clientlist/add'
  },
]

const buyerTopBarButton = {
  text: 'Invite Sellers',
  icon: 'add',
  path: '/clientlist/add',
  fixed: true,
}

const EmptyText = (props) => {
  return(
    <div className="empty-text">
      <Typography variant="title" className="text">
        {props.text}
      </Typography>
    </div>
  )
}


@inject('clientListStore', 'clientSearchStore', 'profileStore')
@observer
class List extends Component {
  state = {
    pendingEmails: [],
    loading: true,
    queuedList: [],
    filterClients: false,
    groupped: []
  }

	async componentDidMount() {
		const { clientListStore, clientSearchStore } = this.props
		clientListStore.loading = true;
		try {
      await clientListStore.refresh()
      let pendingEmails = [];
      let filterClients = this.enableFilter();
      pendingEmails = clientListStore.pendingEmails
      this.setState({
        loading: false,
        pendingEmails,
        filterClients
      })
      clientListStore.loading = false;
      clientSearchStore.refresh();
			window.scroll(0, 0);
		}
		catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
		}
  }

  componentDidUpdate() {
    const filterClients = this.enableFilter();
    if(this.state.filterClients !== filterClients){
      this.setState({ filterClients });
    }
  }
  
  // This now only returns unrelated items in a geographic region instead of both related/unrelated
	getClientItems() {
    const { clientSearchStore,clientListStore } = this.props;
    // const clientList = clientSearchStore.CombinedClientList;
    const clientList = clientSearchStore.UnrelatedList;
    if(clientList.length === 0){
      return (
        <EmptyText text="Nothing Found"/>
      )
    }

    // Don't show "On list" if we are a seller, since that should be obvious
    // TODO: this will always be un-affiliated
    const orgIds = clientListStore.organizationList.map(item => item.id);
    const capturedIds = [];
    
		const comboList = clientList.map((item, i) => {
      if(item.organization && orgIds.length > 0) {
        if(capturedIds.indexOf(item.organization.id) > -1) return;
        capturedIds.push(item.organization.id)
        const foundIndex = orgIds.indexOf(item.organization.id);
        const org = this.filteredOrg(clientListStore.organizationList[foundIndex], clientList);
        if(org && org.stores.length > 1) {
          return ( <OrganizationBundleListItemStyled organization={org}/> )
        } else {
          return ( <BusinessItem key={`${i}-unaffiliated`} business={item} type='connected'/> )
        }
      } else {
        return ( <BusinessItem key={`${i}-unaffiliated`} business={item} type='connected'/> )
      }
    })

    return comboList;
  }

  getAffiliatedClientItems() {
    const { clientSearchStore, clientListStore } = this.props;
    const clientList = clientSearchStore.TotalClientList;
    if(clientList.length === 0){
      return (
        <EmptyText text="Nothing Found"/>
      )
    }

    const orgIds = clientListStore.affOrganizationList.map(item => item.id);
    const capturedIds = [];


		const affiliatedList = clientList.map( (item, i) => {
      if(item.organization && orgIds.length > 0) {
        if(capturedIds.indexOf(item.organization.id) > -1) return;
        capturedIds.push(item.organization.id)
        const foundIndex = orgIds.indexOf(item.organization.id);
        const org = this.filteredOrg(clientListStore.affOrganizationList[foundIndex], clientList);
        if(org && org.stores.length > 1) {
          return ( <OrganizationBundleListItemStyled organization={org}/> )
        } else {
          return ( <BusinessItem key={`${i}-affiliated`} business={item} type='connected'/> )
        }
      } else {
        return ( <BusinessItem key={`${i}-affiliated`} business={item} type='connected'/> )
      }
    })

    return affiliatedList;
  }

  getPendingClientItems() {
    const { clientListStore, clientSearchStore } = this.props;
    const pendingList = clientSearchStore.PendingList;
    let pendingUsers = [], pendingEmails = [];

    if(pendingList.length === 0  && clientListStore.pendingInvites.length === 0){
      return (
        <EmptyText text="No Pending Connections"/>
      )
    }
		pendingUsers = pendingList.map( (item, i) => 
			<BusinessItem key={`${i}-user_pending`} business={item} type='pending'/>
    )
    pendingEmails = clientListStore.pendingInvites.map( (item, i) => 
			<BusinessItem key={`${i}-email_pending`} invite={item} type='pending'/>
    )
    return pendingUsers.concat(pendingEmails);
  }

  getInvitedClientItems() {
    const { clientSearchStore } = this.props;
    const invitationList = clientSearchStore.InvitationList;
    let invitedUsers = [];

    if(invitationList.length === 0){
      return (
        <EmptyText text="No Invites Found"/>
      )
    }
		invitedUsers = invitationList.map( (item, i) => 
			<BusinessItem key={`${i}-invitation`} business={item} type='request'/>
    )
    return invitedUsers;
  }

  getClients = () => {
    const path = this.getPage(this.props.location.pathname);
    switch(path) {
      case 'clientlist':
        return (
          <React.Fragment>
            { this.getClientItems() }
          </React.Fragment>
        )
        break;
      case 'invitations':
        return (
          <React.Fragment>
            { this.getInvitedClientItems()}
          </React.Fragment>
        )
        break;
      case 'affiliated':
        return (
          <React.Fragment>
            { this.getAffiliatedClientItems()}
          </React.Fragment>
        )
        break;
      case 'pending':
        return (
          <React.Fragment>
            { this.getPendingClientItems()}
          </React.Fragment>
        )
        break;
      default:
        break;
    }
  }

  filteredOrg = (org, clientList) => {
    const orgList = (org && org.stores) ? [...org.stores] : []
    const filteredStores = clientList.filter(item => orgList.includes(item.id))
    const dupOrg = { ...toJS(org) }
    return Object.assign(dupOrg, {stores: filteredStores});
  }

  getPage(url) {
    return url.substring(url.lastIndexOf("/")+1);
  }

  enableFilter() {
    const { location } = this.props;
    const path = this.getPage(location.pathname);

    return path.match(/(clientlist)/) !== null;
  }
  
  getTabIndex(tabs) {
    const path = this.props.location.pathname;
    for (let i of tabs.keys()) {
      if(tabs[i].path.indexOf(path) > -1){
        return i;
      }
    }
  }
		
  render() {
    const { clientListStore, profileStore, clientSearchStore } = this.props
    const { filterClients, loading } = this.state
    let tabs, tabInfo, title;
    if (profileStore.isSeller){
      title = 'View Buyers';
      tabs = sellerTabs;
      tabInfo = sellerPageInfo;
    } else {
      title = 'View Sellers';
      tabs = buyerTabs;
      tabInfo = buyerPageInfo;
    }
    const tabIndex = this.getTabIndex(tabs);
    
    return (
      <React.Fragment>
        <Menu />
        <Topbar
          title={title}
          sub={tabInfo[tabIndex].sub}
          tabs={tabs}
          tabIndex={tabIndex}
          mobilft={true}
          isChildPath={false}
          searchBar={true}
          topBarButton={profileStore.isSeller?<LinkButton text='Invite Buyers' icon='add' path='/clientlist/add' tabletHide={true}/>:<LinkButton text='Invite Sellers' icon='add' path='/clientlist/add' tabletHide={true}/>}
        />
        <div className="client-list-container">
        <ClientSearchBar enableFilter={filterClients}/> {/* <ClientSearchBar enableFilter={filterClients} groups={clientListStore.affOrganizationList} updateGroupped={data => this.setState({groupped: data})}/> */}
        <Bottombar buttons={profileStore.isSeller?sellerNavButtons:buyerNavButtons}/>
        {
          loading ?
            <LoadingSpinnerPage />
            :
            <ul className={`client-list lg-margin ${filterClients?'filter-present':''}`}>
              {
                filterClients &&
                <React.Fragment>
                  <Typography className="result-label">{`${profileStore.isSeller? 'Buyers':'Sellers'} within ${clientSearchStore.distance?clientSearchStore.distance:0} mile(s)`}</Typography>
                  <Typography className="result-label sm">{`Change your search settings to show more results`}</Typography>
                </React.Fragment>
              }
              { this.getClients() }
            </ul>
        }
          <div className="tool-tip-container">
            <Tooltip title="Add Client" placement="bottom-end">
              {/*TODO: add a link to add a deal page*/}
              <Link to="/clientlist/add">
                <Button variant="fab" color="secondary">
                  <Icon>group_add</Icon>
                </Button>
              </Link>
            </Tooltip>
          </div>
        </div>
      </React.Fragment>
    )
	}

}

export default withRouter(List);
