import { action, observable, computed } from 'mobx'
import { profileStore } from '..'
import DatabaseClient from '../../core/DatabaseClient'
import store from 'store'
import moment from 'moment'
import validate from 'core/utilities/FormValidator'
import { Order, ShoppingCartItem } from '../../core/model'

const SHOPPING_CART = '__freshspire_shopping_cart';
// TODO: LINK SHOPPING_CART TO A UID
class ShoppingCartStore {

    /**
     * An element in the shopping cart array is specified as the following format:
     * {
     *      deal: Deal
     *      quantityRequested: number
     *      needByDate: string
     *      exchange: string (delivery or pickup)
     *      totalCost: number
     * }
     */
    @observable shoppingCart = []
    @observable checkoutLine = []

    /**
     * The total running cost of all items in the shopping cart
     */
    @observable totalCost = 0

    // TODO: Get the notification sysem working correctly
    @observable latestChange = null;
    @observable hasSynced = {};

    @observable form = null
    defaultForm = {
        fields: {
        quantityRequested: {
            value: 0,
            validation: {
            numericality: {
                isInteger: true,
                greaterThan: 0,
            },
            },
            error: null,
        },
        needByDate: {
            value: '',
            validation: {
            presence: {
                message: '^Need by Date is invalid'
            },
            datetime: {
                earliest: moment().format('YYYY-MM-DD'),
                dateOnly: true,
                message: '^Need by Date is invalid'
            },
            },
            error: null,
        },
        exchange: {
            value: '',
            validation: {
            presence: true,
            inclusion: {
                within: ['delivery', 'pickup']
            },
            },
            error: null,
        },
        },
        validation: {
        error: null,
        }
    }

    constructor() {
        this.resetForm();
    }
    

    resetForm() {
        this.form = Object.assign({}, this.defaultForm);
    }

    @action
    initializeShoppingCart() {
        this.hasSynced = {};
        try {
            // Grab the shopping cart in local storage
            let localStorage = store.get(SHOPPING_CART);
            if (localStorage) {
                let shoppingCart = Object.values(localStorage);
                for (let i = 0; i < shoppingCart.length; i++) {
                    let data = shoppingCart[i];
                    let shoppingCartItem = new ShoppingCartItem(data.deal, data.quantityRequested, data.needByDate, data.exchange, data.totalCost);
                    // if (!shoppingCartItem.validate()) {
                    //     this.resetShoppingCart();
                    //     return;
                    // }
                }
                this.shoppingCart = shoppingCart;
                // Set listeners
                this.shoppingCart.forEach((item) => {
                    this.setDealListener(item['deal']['id']);
                });

                this.calculateTotalCost();
            }
            else {
                this.resetShoppingCart();
            }
        }
        catch (e) {
            console.error(e);
            Sentry.captureException(e);
            // If the shopping cart is corrupted, reset it
            this.resetShoppingCart();
        }
    }

    @action
    populateForm = (qty, date, exchange) => {
      // Go ahead and update the value
      // Treat empty strings as null
      if (qty === '') {
        qty = null;
      }
      if (date === '') {
        date = null;
      }
      if (exchange === '') {
        exchange = null;
      }
      this.form.fields['quantityRequested'].value = qty;
      this.form.fields['needByDate'].value = date;
      this.form.fields['exchange'].value = exchange;
      this.validateAll();
    }

    @action
    validateField = (field) => {
      let data = {}; // The data to validate
      data[field] = this.form.fields[field].value;
      let validationRules = {}; // The rules to use in validation
      validationRules[field] = this.form.fields[field].validation;
      let err = validate(data, validationRules);
  
      // Update error message
      if (err !== undefined) {
        this.form.fields[field].error = err[field][0];
        // throw new Error(err[field][0]);
      }
      else {
        this.form.fields[field].error = null;
        return true;
      }
    }
  
    @action
    validateAll = () => {
      // Start off assuming no errors are present
      this.form.validation.error = null;
      // let data = getFlattenedValues(this.form, 'value');
      //let validationRules = getFlattenedValues(this.form, 'validation');
      Object.keys(this.form.fields).forEach( (field) => {
        let validated = this.validateField(field);
        if (!validated) {
          this.form.validation.error = "Form failed validation.";
        }
      })
      return (this.form.validation.error === null);
      //this.form.validation.error = validate(data, validationRules);
    }

    @action
    validateCart = () => {
        let isValid = true;
        let newCart = [];
        let validCart = this.shoppingCart.filter( (item) => {
            this.populateForm(item.quantityRequested,item.needByDate,item.exchange)
            this.validateAll();
            if(this.form.validation.error === null) {
                return true
            } else {
                isValid = false;
                newCart.push(item);
            }
        });
        this.checkoutLine = validCart;
        this.shoppingCart = newCart;
        return isValid;
    }

    @action
    async checkout(notes) {
        let isValid = true;
        isValid = this.validateCart()
        let buyerUID = profileStore.business.id;
        let orders = this.checkoutLine.map( (item) => {
            let order = new Order(undefined, undefined, buyerUID, undefined, item.deal.id, item.deal.version,
                                    item.exchange, false, undefined, item.needByDate, false, item.quantityRequested,
                                    undefined, item.deal.uid, 'created', +moment(), item.totalCost);
            return order;
        });
        await DatabaseClient.createOrderBundles(orders, notes);
        this.resetShoppingCart();
        if(!isValid) {
            throw new Error('Invalid Requests');
        }
    }

    @action
    calculateTotalCost() {
        let total = 0;
        this.shoppingCart.forEach( (item) => {
            total += item['totalCost'];
        })
        this.totalCost = total;
        return this.totalCost;
    }

    setDealListener(dealID) {
        DatabaseClient.setDealListener(dealID, 
            (deal) => {
                this.updateShoppingCart(deal);
                if (!this.hasSynced[dealID]) {
                    this.hasSynced[dealID] = true;
                    return; // Don't set latestChange if this is the first time syncing
                }
                this.latestChange = 'updated';
            },
            (dealID) => {
                this.removeFromShoppingCart(dealID);
                this.latestChange = 'removed';
            });
    }

    getShoppingCart() {
        return this.shoppingCart;
    }
  
    @action
    async editShoppingCart(dealID, quantityRequested, needByDate, exchange) {
        let index = this.getIndexOfDeal(dealID);
        if (index < 0) {
            // Probably meant to do an addToShoppingCart
            await this.addToShoppingCart(dealID, quantityRequested, needByDate, exchange);
        }
        else {
            let deal = await DatabaseClient.getDeal(dealID);
            for (var key in deal) {
                this.shoppingCart[index]['deal'][key] = deal[key];
            }
            this.shoppingCart[index]['quantityRequested'] = quantityRequested;
            this.shoppingCart[index]['needByDate'] = needByDate;
            this.shoppingCart[index]['exchange'] = exchange;
            let totalCost = (quantityRequested * this.shoppingCart[index]['deal']['price']);
            if (!totalCost) {
                totalCost = null;
            }
            this.shoppingCart[index]['totalCost'] = totalCost;
            store.set(SHOPPING_CART, this.shoppingCart);
            this.calculateTotalCost();
        }
    }
  
    @action
    async addToShoppingCart(dealID, quantityRequested, needByDate, exchange) {
        // If already in the store, edit it instead
        if (this.getIndexOfDeal(dealID) > -1) {
            await this.editShoppingCart(dealID, quantityRequested, needByDate, exchange);
        }
        else {
            let deal = await DatabaseClient.getDeal(dealID);
            let item = new ShoppingCartItem(deal, quantityRequested, needByDate, exchange, !this.totalCost ? null : this.totalCost);
            // Set an appropriate listener
            // Could fail (throw an error) if tried to add an invalid deal that
            // did not exist in the database
            this.setDealListener(item.deal.id);
            this.shoppingCart.push(item);
            store.set(SHOPPING_CART, this.shoppingCart);
            this.calculateTotalCost();
        }
    }
  
    @action
    removeFromShoppingCart(dealID) {
        let index = this.getIndexOfDeal(dealID);
        if (index > -1) {
            this.shoppingCart.splice(index, 1);
            store.set(SHOPPING_CART, this.shoppingCart);
            DatabaseClient.removeDealListener(dealID);
            this.calculateTotalCost();
        }
    }

    @action
    resetShoppingCart() {
        try {
            this.checkoutLine.forEach( (item) => {
                DatabaseClient.removeDealListener(item['deal']['id']);
            })
        }
        catch (e) {
            console.error(e);
            Sentry.captureException(e);
            console.log('Error while removing deal listeners in shoppingCartStore');
            console.log(e.message);
            // Ignore, we'll just have a deadweight listener
            // but it shouldn't cause any problems
        }
        this.checkoutLine = [];
        store.set(SHOPPING_CART, this.shoppingCart);
        this.calculateTotalCost();
        this.hasSynced = {};
    }

    @action
    updateShoppingCart(deal) {
        let dealID = deal['id'];
        let prevDealIdx = this.getIndexOfDeal(dealID);
        if (prevDealIdx < 0) {
            return;
        }
        for (var key in deal) {
            this.shoppingCart[prevDealIdx]['deal'][key] = deal[key];
        }
        let totalCost = this.shoppingCart[prevDealIdx]['quantityRequested'] * this.shoppingCart[prevDealIdx]['deal']['price'];

        this.shoppingCart[prevDealIdx]['totalCost'] = !totalCost ? null : totalCost;
        
        store.set(SHOPPING_CART, this.shoppingCart);
        this.calculateTotalCost(); 
    }

    getIndexOfDeal(dealID) {
        for (let i = 0; i < this.shoppingCart.length; i++) {
          if (this.shoppingCart[i]['deal']['id'] == dealID) {
            return i;
          }
        }
        return -1;
      }
    
    getItem(dealID) {
        let index = this.getIndexOfDeal(dealID);
        if (index > -1) {
            return this.shoppingCart[index];
        }
        else {
            return null;
        }
    } 
}
export default new ShoppingCartStore()
