import { observable, computed, toJS, action } from 'mobx'
import { productListingSchema as schema } from '../schemas'
import joi from 'joi-browser'

class ProductListing {
    @observable endListingDate
    @observable error
    @observable id
    @observable notes    
    @observable product
    @observable productID
    @observable price
    @observable timestamp
    @observable uid
    @observable unit
    @observable visibility

    @computed
    get visibilityUIDs() {
        return Object.keys(this.visibility);
    }

    constructor(endListingDate = null, error = null, id = null, notes = "", product = null, productID = null, price = 0, timestamp = 0, uid = null, unit = null, visibility = {}) {
        Object.assign(this, { endListingDate, error, id, notes, product, productID, price, timestamp, uid, unit, visibility });
    }

    @action
    addVisibility(uid) {
        this.visibility[uid] = true;
    }

    @action
    removeVisibility(uid) {
        if (uid in this.visibility) {
            delete this.visibility[uid];
        }
        // const newVisibility = {};
        // for (let key in this.visibility) {
        //     if (key !== uid) {
        //         newVisibility[key] = this.visibility[key];
        //     }
        // }
        // this.visibility = newVisibility;
    }

    formatForDB() {
        if (!this.uid || this.uid.length === 0) {
            throw new Error("No associated user");
        }
        if (this.price !== null && this.price < 0) {
            throw new Error(`Invalid price of ${this.price}`);
        }
        if (this.endListingDate !== null && isNaN(this.endListingDate)) {
            throw new Error("End listing date must either by empty or a timestamp");
        }
        let data = observable({
            defaultVisibility: (this.visibilityUIDs.length === 0),
            endListingDate: this.endListingDate,
            error: this.error,
            notes: this.notes,
            productID: this.productID,
            price: this.price,
            timestamp: this.timestamp,
            uid: this.uid,
            unit: this.unit,
            visibility: this.visibility
        });
        const jsData = toJS(data);
        const result = joi.validate(jsData, schema);
        if (result.error) {
            throw new Error(result.error.details[0].message)
        }
        return jsData;
    }

    validate() {
        const result = joi.validate(this.formatForDB(), schema);
        return !result.error;
    }

    getDisplayPrice(short) {
        if (this.price == null) {
            const text = short ? '$RfQ / ' : "Request Quote per ";
            return text + this.unit;
        }
        else {
            return "$" + this.price.toFixed(2) + " / " + this.unit;
        }
    }

    getPricePerUnitString() {
        if (this.unit in this.product.packFormats) {
            return `${this.getDisplayPrice()} [ea. ${this.product.packFormats[this.unit].conversionRatio} ${this.product.packFormats[this.unit].conversionUnit}(s)]`;
        }
        else if (this.unit in this.product.quantity) {
            return `${this.getDisplayPrice()}`;
        }
        else return '';
    }

    /**
     * Returns the quantity for the given unit, whether or not it is a base
     * unit or a pack format
     * E.g. if 25 boxes are available, and a pallet is 10 boxes, then
     * getBaseQuantity called on a palle listing returns 2 pallets available
     * @param qty? optional - defines a specific unit to look for instead of the product listing unit
     */
    getBaseQuantity(searchUnit) {
        const unit = searchUnit || this.unit;
        if (unit in this.product.packFormats) {
            if (this.product.quantity[this.product.packFormats[unit].conversionUnit] === null) {
                return "Unlimited";
            }
            return Math.floor(this.product.quantity[this.product.packFormats[unit].conversionUnit] / this.product.packFormats[unit].conversionRatio);
        }
        else if (unit in this.product.quantity) {
            if (this.product.quantity[unit] === null) {
                return "Unlimited";
            }
            return this.product.quantity[unit];
        }
        else {
            return 0;
        }
    }

}

ProductListing.displayName = "ProductListing";
export default ProductListing;
