import React from 'react'
import * as PropTypes from "prop-types";
import styled from "styled-components";
import GenericModal from "components/organisms/GenericModal";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DatePicker from "components/atomic/pickers/DatePicker";
import moment from "moment";
import { BASE_URL } from "core/clients/conf";
import { inject, observer } from "mobx-react";
import Button from "components/atomic/buttons/Button";
import Add from "@material-ui/core/SvgIcon/SvgIcon";


const StyledModal = styled(GenericModal)`
    width: 600px !important;
    @media (max-width: 600px) {
        width: 550px;
    }
`;

const DownloadButton = (props) => {
    const StyledButton = styled(Button)`
    `;
    const { link, ...other } = props;
    return (
        <StyledButton variant={"contained"} color={"primary"} {...other} target={"_blank"} href={link}>
            Download
        </StyledButton>
    )
};

DownloadButton.propTypes = {
    link: PropTypes.string.isRequired
};

@inject("profileStore")
@observer
class SpreadsheetDownloadModal extends React.Component {
    state = {
        statusSelection: "all",
        timeSelection: "week",
        startDate: moment().subtract(1, "week").toDate(),
        endDate: moment().toDate(),
        format: "xlsx"
    };

    handleStatusSelectionChange = (e) => {
        this.setState({ statusSelection: e.target.value });
    };

    handleTimeSelectionChange = (e) => {
        this.setState({ timeSelection: e.target.value });
    };

    handleStartDateChange = (d) => {
        this.setState({ startDate: d });
    };

    handleEndDateChange = (d) => {
        this.setState({ endDate: d });
    };

    handleFormatChange = (e) => {
        this.setState({ format: e.target.value });
    };

    getDownloadLink = () => {
        const recipientID = this.props.profileStore.business.id;
        const { format, statusSelection, timeSelection, startDate, endDate } = this.state;
        let downloadLink = BASE_URL + `/order/spreadsheet?format=${encodeURIComponent(format)}&requester=${encodeURIComponent(recipientID)}`;

        if (statusSelection === "new") {
            downloadLink += "&status=created";
        } else if (statusSelection === "progress") {
            downloadLink += "&status=accepted";
        } else if (statusSelection === "new_progress") {
            downloadLink += "&status[in]=created&status[in]=accepted";
        } else if (statusSelection === "completed") {
            downloadLink += "&status=completed";
        }

        if (timeSelection === "week") {
            const beginningOfWeek = +moment().startOf("day").subtract(1, "week").toDate();
            const eod = +moment().endOf("day").toDate();
            downloadLink += `&timestamp[gte]=${beginningOfWeek}&timestamp[lte]=${eod}`;
        } else if (timeSelection === "month") {
            const beginningOfMonth = +moment().startOf("day").subtract(1, "month").toDate();
            const eod = +moment().endOf("day").toDate();
            downloadLink += `&timestamp[gte]=${beginningOfMonth}&timestamp[lte]=${eod}`;
        } else if (timeSelection === "date") {
            const startTS = startDate && +moment(startDate).startOf("day").toDate();
            const endTS = endDate && +moment(endDate).endOf("day").toDate();
            if (startTS) {
                downloadLink += `&timestamp[gte]=${startTS}`;
            }
            if (endTS) {
                downloadLink += `&timestamp[lte]=${endTS}`;
            }
        }

        downloadLink += `&orderBy[timestamp]=desc`;
        return downloadLink;
    };

    render() {
        const { statusSelection, timeSelection, startDate, endDate, format } = this.state;
        return (
            <StyledModal {...this.props} showSubmitButton={false}>
                <Grid container justify={"center"} spacing={8}>
                    <Grid item xs={12} justify={"center"}>
                        <Typography variant={"subtitle1"} align={"center"}>
                            Select which orders you want to download as a spreadsheet
                        </Typography>
                    </Grid>
                    <Grid item xs={12} justify={"center"}>
                        <FormControl fullWidth>
                            <InputLabel>Order Status</InputLabel>
                            <Select value={statusSelection} onChange={this.handleStatusSelectionChange}>
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"new"}>New Orders</MenuItem>
                                <MenuItem value={"progress"}>In Progress Orders</MenuItem>
                                <MenuItem value={"new_progress"}>New & In Progress Orders</MenuItem>
                                <MenuItem value={"completed"}>Completed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} justify={"center"}>
                        <FormControl fullWidth>
                            <InputLabel>Date Range</InputLabel>
                            <Select value={timeSelection} onChange={this.handleTimeSelectionChange}>
                                <MenuItem value={"week"}>Past Week</MenuItem>
                                <MenuItem value={"month"}>Past Month</MenuItem>
                                <MenuItem value={"all"}>Since the Beginning of Time</MenuItem>
                                <MenuItem value={"date"}>Custom Date Range</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {timeSelection === "date" && <Grid container item xs={12} justify={"center"}>
                        <Grid container item xs={12} justify={"space-evenly"}>
                            <Grid item xs={4}>
                                Start:
                            </Grid>
                            <Grid item xs={4}>
                                End:
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justify={"space-evenly"}>
                            <Grid item xs={4} justify={"center"}>
                                <DatePicker
                                    useDate={true}
                                    value={startDate}
                                    onChange={this.handleStartDateChange}
                                    maxDate={moment(endDate).endOf("day").toDate()}
                                    disablePast={false}
                                />
                            </Grid>
                            <Grid item xs={4} justify={"center"}>
                                <DatePicker
                                    useDate={true}
                                    value={endDate}
                                    onChange={this.handleEndDateChange}
                                    maxDate={moment().endOf("day").toDate()}
                                    disablePast={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>File Format</InputLabel>
                            <Select value={format} onChange={this.handleFormatChange}>
                                <MenuItem value={"xlsx"}>.xlsx (Excel)</MenuItem>
                                <MenuItem value={"csv"}>.csv</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={12} justify={"center"}>
                        <DownloadButton link={this.getDownloadLink()}/>
                    </Grid>
                </Grid>
            </StyledModal>
        );
    }
}

SpreadsheetDownloadModal.propTypes = {
};

SpreadsheetDownloadModal.defaultProps = {
};

export default SpreadsheetDownloadModal;
