import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
    border: 3px solid #fff;
    border-radius: 100%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    background-color: #fff;
`;

class CircularImage extends React.Component {
    render() {
        const { src, ...other} = this.props;
        return (
            <StyledImage
                {...other}
                src={this.props.src || require('../../assets/placeholder_user.png')}
            />
        );
    }
}

export default CircularImage