import { observable, toJS } from 'mobx'

class ClientRelationship {
    @observable buyerUID
    @observable sellerUID
    @observable status
    @observable sender

    constructor(buyerUID = null, sellerUID = null, status = null, sender = null) {
        this.buyerUID = buyerUID
        this.sellerUID = sellerUID
        this.status = status
        this.sender = sender
    }

    formatForDB() {
        let data = observable({
            buyerUID: this.buyerUID,
            sellerUID: this.sellerUID,
            status: this.status,
            sender: this.sender
        })
        return toJS(data)
    }
}

ClientRelationship.displayName = "ClientRelationship";
export default ClientRelationship;
