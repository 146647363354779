import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import UploadButton from '../buttons/UploadButton';
import PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Text = styled.div`
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipses;
    margin-top: 30px;
`

const StyledUploadButton = styled(UploadButton)`
`;

const StyledDropzone = styled(Dropzone)`
    border: none;
`

class FileUploader extends React.Component {
    state = {
        files: []
    }

    async onSelect(acceptedFiles) {
        this.setState({files: acceptedFiles});
        this.props.onSelect && (await this.props.onSelect(acceptedFiles));
    }

    render() {
        let text;
        if (this.state.files.length > 0) {
            text = this.state.files.map((file) => file.name).join();
        }
        else {
            text = this.props.multiple ? "Upload files here" : "Upload a file here";
            if (this.props.required) {
                text += "*"
            }
        }

        return (
            <Container className={this.props.className}>
                {this.props.withInstructions && <Text> {text} </Text>}
                <StyledDropzone accept={this.props.acceptedFileTypes || undefined} onDrop={this.onSelect.bind(this)} multiple={this.props.multiple} disabled={this.props.disabled}>
                    <StyledUploadButton text={this.props.withText ? "Attach File" : undefined} disabled={this.props.disabled}/>
                </StyledDropzone>
            </Container>
        );
    }
}

FileUploader.propTypes = {
    acceptedFileTypes: PropTypes.array,
    multiple: PropTypes.bool,
    onSelect: PropTypes.func,
    required: PropTypes.bool,
    withText: PropTypes.bool,
    withInstructions: PropTypes.bool,
    disabled: PropTypes.bool
}

FileUploader.defaultProps = {
    multiple: false,
    required: false,
    withText: true,
    withInstructions: true,
    disabled: false
}

export default FileUploader;