import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import Button from './atomic/buttons/Button';
import Typography from '@material-ui/core/Typography';
import {inventoryStore, profileStore, shoppingCartStore} from '../stores'
import Modal from './Modal';
import Chip from '@material-ui/core/Chip';
import {AlertMessage} from './AlertMessage';
import DoneIcon from '@material-ui/icons/Done';
import { message } from 'antd';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import RequestModal from './organisms/RequestModal'
import ProductListingGroupModal from './organisms/modals/ProductListingGroupModal';
import ProductListingGroup from '../core/model/ProductListingGroup';
import styled from 'styled-components';
import IconButton from 'components/atomic/buttons/IconButton'
import Product from '../core/model/Product';
import { unmarshal } from '../core/utilities/ModelUtils'
import DatabaseClient from "core/DatabaseClient";

const Wrapper = styled.div`
  margin: 8px 10px 8px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
`;

const OpenInNewIcon = styled(Icon)`
  font-size: 11px;
  @media (max-width: 649px) {
    margin-top: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  margin: 0 auto 0 auto;
  margin-left: auto;
  padding: 0;
  border-radius: 10px !important;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  cursor: pointer;
  overflow: hidden;
  margin: 8px;
  border-radius: 10px;
`;

const ItemImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

const Information = styled.section`
  width: 100%;
  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const MainSection = styled.section`
  flex-grow: 1;
  margin-right: 8px;
`;

const lineHeight = 1;

const ItemWrapper = styled.div`
  display: flex;
`;

const ItemName = styled.div`
  margin-bottom: auto;
  font-family: ${props => props.theme.fonts.ms_font700};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  font-size: 1.3em;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  font-size: 1em;
  max-height: ${lineHeight * 1}em;
  line-height: ${lineHeight}em;
  @media (max-width: 649px) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const StyledName = styled(Typography)`
  margin-bottom: auto;
  font-family: ${props => props.theme.fonts.ms_font700};
  display: flex;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  font-size: 1.3em;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20em;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
  max-width: 160px;
  @media (max-width: 649px) {
    width: auto;
    flex-grow: 1;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
`;

const Label = styled(Typography)`
  font-size: 1em;
  color: ${props => props.theme.colors.spearmint_main};
  ${props => props.sm && `font-size: 0.9em`}
  ${props => props.md && `font-size: 1.2em;`}
  white-space: nowrap;
`

const Value = styled(Typography)`
  margin-left: auto;
  font-size: 1em;
  ${props => props.sm && `font-size: 0.9em`}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  margin-left: 8px;
  @media (max-width: 649px) {
    justify-content: flex-end;
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ActionDescription = styled.div`
  font-size: 0.7em;
  color: rgba(0,0,0,.6);
`;

const ActionButton = styled(IconButton)`
  padding: 8px;
`;

const ActionButtonIcon = styled(Icon)`
`;

const ContentDivider = styled.div`
  height: 90%;
  margin-left: 10px;
  margin-right: 10px;
  border-right: 1px solid #ddd;
  @media (max-width: 649px) {
    display: none;
  }
`;

const HorizontalContentDivider = styled.div`
  @media (min-width: 650px) {
    display: none;
  }
  width: calc(100% + 40px);
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-left: -48px;
`;

const Summary = styled.div`
  height: auto;
  text-align: center;
  min-width: 110px;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 9px;
`;

const RightSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  @media (max-width: 649px) {
    margin-left: -48px;
    height: auto;
  }
`;

@inject('profileStore', 'orderStore','inventoryStore')
@observer
class ListItem extends Component {
  state = {
    requestModalOpen: false,
    productModalOpen: false
  }

  handleAcceptRequest = async (id) => {
    try {
      await DatabaseClient.acceptRequest(id);
      this.props.onRemove && await this.props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  handleDeclineRequest = async (id) => {
    try {
      await DatabaseClient.updateOrder(id, {
        status: "declined",
        lastUpdatedBy: this.props.profileStore.business.id
      });
      this.props.onRemove && await this.props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }
  
  handleCancelRequest = async (id) => {
    try {
      await DatabaseClient.updateOrder(id, {
        status: "cancelled",
        lastUpdatedBy: this.props.profileStore.business.id
      });
      this.props.onRemove && await this.props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  handleMarkAsPaid = async (id) => {
    try {
      await DatabaseClient.updateOrder(id, {
          paymentStatus: true,
        lastUpdatedBy: this.props.profileStore.business.id
      });
      this.props.onRemove && await this.props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  handleMarkAsExchanged = async (id) => {
    try {
      await DatabaseClient.updateOrder(id, {
        exchangeStatus: true,
        status: "completed",
        lastUpdatedBy: this.props.profileStore.business.id
      });
      this.props.onRemove && await this.props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  handleCancelOrder = async (id) => {
    try {
      await DatabaseClient.updateOrder(id, {
        status: "cancelled",
        lastUpdatedBy: this.props.profileStore.business.id
      });
      this.props.onRemove && await this.props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  handleCardClick = (e) => {
    this.openRequestModal(e, true);
  }

  createDetail = (label, value, webFeature) => {
    return (
      <Detail>
        <Label variant="subheading" sm>
          {label}
        </Label>
        <Value variant="subheading" sm>
          {value}
        </Value>
      </Detail>
    )
  }


  openRequestModal = (e, hideController = false) => {
    if (e) e.stopPropagation();
    this.setState({ requestModalOpen: true, hideController });
  }

  closeRequestModal = (e) => {
    this.setState({ requestModalOpen: false, hideController: false });
  }


  getButtons = (isSeller, type, order) => {
    let {exchangeStatus, paymentStatus, exchange, id } = order;
    
    switch(type) {
      case 'request':
        return  isSeller? (
          <React.Fragment>
            <ActionButtonContainer>
              <ActionButton id="request_listing_accept" size="small" color="action" onClick={ async () => await this.handleAcceptRequest(id)}>
                <ActionButtonIcon>done</ActionButtonIcon>
              </ActionButton>
              <ActionDescription>
                Accept
              </ActionDescription>
            </ActionButtonContainer>
            <ActionButtonContainer>
              <ActionButton id="request_listing_decline" size="small" color="action" onClick={() => AlertMessage('Decline Request','Are you sure you want to decline this request?', async () => await this.handleDeclineRequest(id))}>
                <ActionButtonIcon>clear</ActionButtonIcon>
              </ActionButton>
              <ActionDescription>
                Decline
              </ActionDescription>
            </ActionButtonContainer>
            <ActionButtonContainer>
            <ActionButton id="request_listing_edit"  size="small" color="action" onClick={this.openRequestModal}>
              <ActionButtonIcon>edit</ActionButtonIcon>
            </ActionButton>
              <ActionDescription>
                Edit
              </ActionDescription>
            </ActionButtonContainer>
          </React.Fragment>
        )
        :
        (
          <React.Fragment>
            <ActionButtonContainer>
              <ActionButton id="request_listing_accept_buyer"  size="small" color="action" onClick={this.openRequestModal}>
                <ActionButtonIcon size="small">edit</ActionButtonIcon>
              </ActionButton>
              <ActionDescription>
                Edit
              </ActionDescription>
            </ActionButtonContainer>
            <ActionButtonContainer>
              <ActionButton id="request_listing_cancel" size="small" color="action" onClick={() => AlertMessage('Cancel Request','Are you sure you want to cancel this request?', async () => await this.handleCancelRequest(id))}>
                <ActionButtonIcon>clear</ActionButtonIcon>
              </ActionButton>
              <ActionDescription>
                Cancel
              </ActionDescription>
            </ActionButtonContainer>
          </React.Fragment>
        )
        break;
      case 'active':
        return (
          <React.Fragment>
            <ActionButtonContainer>
              <ActionButton id="in_progress_listing_delivered" size="small" color="action" disabled={exchangeStatus} onClick={async () => await this.handleMarkAsExchanged(id)}>
                <ActionButtonIcon>local_shipping</ActionButtonIcon>
              </ActionButton>
              <ActionDescription>
                Delivered
              </ActionDescription>
            </ActionButtonContainer>
            
            {isSeller && 
              <React.Fragment>
                <ActionButtonContainer>
                  <ActionButton id="in_progress_listing_edit" size="small" color="action" onClick={this.openRequestModal}>
                    <ActionButtonIcon>edit</ActionButtonIcon>
                  </ActionButton>
                  <ActionDescription>
                    Edit
                  </ActionDescription>
                </ActionButtonContainer>
              </React.Fragment>
            }
            <ActionButtonContainer>
              <ActionButton id="in_progress_listing_cancel" size="small" color="action" onClick={() => AlertMessage('Cancel Request','Are you sure you want to cancel this order?', async () => await this.handleCancelOrder(id))}>
                <ActionButtonIcon>clear</ActionButtonIcon>
              </ActionButton>
              <ActionDescription>
                Cancel
              </ActionDescription>
            </ActionButtonContainer>
          </React.Fragment>
        )
        break;
      case 'completed':
        return (
          <ActionButtonContainer>
              <ActionButton id="completed_listing_mark_paid" size="small" color="action" style={{visibility: paymentStatus ? 'hidden' : undefined }} disabled={paymentStatus} onClick={async () => await this.handleMarkAsPaid(id)}>
                <ActionButtonIcon>attach_money</ActionButtonIcon>
              </ActionButton>
              <ActionDescription>
                Mark paid
              </ActionDescription>
          </ActionButtonContainer>  
          
        )
        break;
      default:
        break;
    }
  }

  textSnipper = (text, limit) => {
    if (text.length > limit) {
      text = (text.substring(0, limit)) + "...";
    }
    return text;
  }

  priceTag = (order, productListing) => {
    let price,
        unit,
        total;

    if (order) {
      total = true;
      if(order.totalCost) {
        price = `$${order.totalCost.toFixed(2)}`;
      } else {
        total = false;
        price = `Request Quote`;
      }
    } else {
      unit = true;
      if(productListing.price) {
        price = `$${productListing.price.toFixed(2)}`;
      } else {
        unit = false;
        price = `Request Quote`;
      }
    }

    return (
      <div className="price-container">
        {
          total?
          <Typography variant="subheading" className="label md">
            Total:&nbsp;
          </Typography>
          :
          ''
        }
        <Typography variant="subheading" className={`price-value ${unit? "md":"sm"}`}>
          {price}
        </Typography>
        {
          unit?
          <Typography variant="subheading" className="label md">
            /{order.unit}
          </Typography>
          :
          ''
        }
      </div>  
    )
  }

  render() {
    const order = this.props.item;
    const productListing = order.productListing;
    const product = unmarshal(productListing.product,Product);
    const seller = productListing.product.seller;
    const buyer = productListing.product.buyer;
    return (
      <React.Fragment>
        <Wrapper>
          <Container onClick={(e) => this.handleCardClick(e)}>
            <ImageContainer onClick={(e) => this.openRequestModal(e,true)}>
              <ItemImage src={product.getPictureSrc()}/>
            </ImageContainer>
            <Information>
              <MainSection>
                <ItemWrapper>
                  <ItemName onClick={(e) => this.openRequestModal(e,true)}>
                    {product.name}
                    {/* <StyledName variant="title" onClick={(e) => this.openRequestModal(e,true)}> */}
                        {/* {this.textSnipper(product.name, 25)} */}
                        {/* {this.textSnipper("Green Kale Lettuce Spring Mix and Stuff Like That", 25)} */}
                    {/* </StyledName> */}
                  </ItemName>
                  &nbsp;<OpenInNewIcon className="text_icon">open_in_new</OpenInNewIcon>
                </ItemWrapper>
                <Value>
                  {
                    order.totalCost == null ? 
                      <span style={{"opacity": "0.5"}}>Request Quote</span>
                      :
                      <React.Fragment>
                        <span style={{fontWeight: "bold"}}> Total: </span>
                        &nbsp;<span style={{color: "rgb(0,0,0,0.75)"}}>${order.totalCost.toFixed(2)}</span>
                        &nbsp;<span style={{color: "rgb(0,0,0,0.5)", fontSize: "0.85em", marginLeft: "5px"}}>{`($${(order.totalCost/order.quantityRequested).toFixed(2)} ea.)`}</span>
                      </React.Fragment>
                  }
                </Value>
              </MainSection>
              <HorizontalContentDivider/>
              <RightSection>
                <DetailsContainer>
                  {this.createDetail('Amount:', `${order.quantityRequested} ${order.unit}(s)`)}
                  {this.createDetail('Exchange:', `${order.exchange.replace(/^\w/, c => c.toUpperCase())}`)}
                  {order.needByDate && this.createDetail('Need by:', `${moment(order.needByDate).format('MM-DD-YYYY')}`)}
                </DetailsContainer>
                <ContentDivider className="contenContentDividerider web-ft"></ContentDivider>
                <ButtonsContainer onClick={ e => e.stopPropagation()}>
                  {this.getButtons(this.props.profileStore.isSeller, this.props.type, order)}
                </ButtonsContainer>
              </RightSection>
            </Information>
          </Container>
          {this.props.type.match("request|active|completed") && this.state.requestModalOpen && 
            <RequestModal
                open={this.state.requestModalOpen}
                onClose={this.closeRequestModal}
                hideController={this.state.hideController}
                request={order}
                onEdit={this.props.onEdit}
            />
          }
        </Wrapper>
        
      </React.Fragment>
    )
  }
}

export default ListItem;
