import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Popup from 'reactjs-popup'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import BottomBar from '../CommonBottomBar'
import Check from '@material-ui/icons/Check'
import Button from '../atomic/buttons/Button'
import { LoadingSpinnerPage } from '../LoadingSpinner';


// modal-generic
const StyledPopup = styled(Popup)`
    padding: 0 !important;
    background: rgba(0,0,0,0) !important;
    border-style: none !important;
    font-family: ${props => props.theme.fonts.ms_font300};
    @media (max-width: 550px) {
      width: 100vw !important;
    }
    z-index: 99;
`

// modal-scroll
const ScrollContainer = styled.div`
  overflow-y: scroll;‚
  background: rgba(0,0,0,0);
  display: flex;    
  flex-direction: column;
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 550px) {
    padding-bottom: 0;
  }
`

// popup
const Container = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  ${props => props.showSubmitButton && "margin-bottom: 0;"}
  @media (max-width: 550px) {
    padding-bottom: 0;
    margin: 0;
  }
`


// modal-container
const ModalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 48px 1rem 1.4rem;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  ${props => !props.showSubmitButton && css`
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  `}
  margin-bottom: auto;
  @media (max-width: 550px) {
    min-height: calc(100vh - 40px);
    margin: 40px 0 0;
    padding-bottom: 112px;
    padding-top: 56px;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-grow: 1;
    ${props => !props.showSubmitButton && css`
      border-radius: 0px;
    `}
  }
`


const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0 !important;
  @media (max-width: 550px) {
    display: none !important;
  }
`

const Header = styled(Typography)`
  margin: 7px auto;
  text-align: center; 
  font-size: 2em;
  text-align: center;
  width: 100%;
  @media (max-width: 550px) {
        display: none;
  }
`

const TopBarContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  top: env(safe-area-inset-top);
  width: 100%;
  z-index: 300;
  @media (max-width: 550px) {
    display: block;
  }
`

const StyledAppBar = styled(AppBar)`
  background-color: #fff !important;
`

const StyledToolbar = styled(Toolbar)`
  padding-left: 0 !important;
`

const StyledBottomBar = styled(BottomBar)`
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;

  &.highest-stack {
      z-index: 3;
  }
  @media (max-width: 550px) {
    display: block;
  }
`

const SubmitButtonContainer = styled.div`
  width: 100%;
  margin: 0;
  margin-bottom: auto;
  padding: 0;

  @media (max-width: 550px) {
    display: none;
    position: fixed;
    bottom: 0;
  }
`

const SubmitButton = styled(Button)`
  width: 100% !important;
  height: 50px !important;
  font-size: 1.5em !important;
  font-family: ${props => props.theme.fonts.ms_font700} !important;
  text-transform: none !important;
  text-align: center !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  background: ${props => props.theme.colors.spearmint_main} !important;  

  @media (max-width: 550px) {
    border-radius: 0 !important;
    font-size: 1.3em !important;
  }
`

class GenericModal extends Component {
  state = {
    open: true
  }

  constructor(props) {
    super(props);
    if (this.props.open !== undefined) {
      this.state.open = this.props.open;
      if (this.props.open) {
        this.lockScroll(true);
      }
      else {
        this.lockScroll(false);
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.open !== undefined) {
      if (props.open) {
        this.handleOpen();
      }
      else {
        this.handleClose();
      }
    }
  }

  /**
   * To throw an error and error message, pass your prop as
   * this.props.onSubmit = () => {
   *  try {
   *     something
   *  }
   *  catch (e) {
   *    message.error(e.message);
   *    throw e; // Will be caught by this onSubmit function and stop the closing of the modal
   * }
   * }
   */
  onSubmit = async () => {
    if (this.props.onSubmit) {
      try {
        await this.props.onSubmit();
      } catch (e) {
        return;
      }
    }
    await this.onClose();
  }

  onClose = async () => {
    this.props.onClose && (await this.props.onClose());
    this.handleClose();
  }

  handleOpen = (type) => {
    this.setState({ open: true });
    this.lockScroll(true);
  }

  handleClose = () => {
    this.setState({ open: false });
    this.lockScroll(false);
  }

  lockScroll = (bool) => document.body.style.overflow = bool? 'hidden':'auto';

  render() {
      return (
        <React.Fragment>
            <StyledPopup
              modal
              closeOnDocumentClick
              open={this.state.open}
              onClose={this.onClose}
              className={this.props.className}>
                {
                    close => (
                        <ScrollContainer>  
                          <TopBarContainer>
                            <StyledAppBar position="static" color="default">
                              <StyledToolbar>
                                <IconButton className='return' color="inherit" onClick={this.onClose}>
                                  <Icon>
                                    arrow_back
                                  </Icon>
                                </IconButton>
                                <Typography variant="subheading" color="inherit" className="main">
                                  {!this.props.loading && this.props.heading}
                                </Typography>
                              </StyledToolbar>
                            </StyledAppBar>
                          </TopBarContainer>  
                          {(!this.props.loading && this.props.showSubmitButton) && <StyledBottomBar buttons={[{
                              text: this.props.submitText,
                              icon: this.props.submitIcon,
                              onClick: this.props.disabled ? () => {} : this.onSubmit,
                          }]}
                            />  }
                          <Container showSubmitButton={this.props.showSubmitButton}>
                            <CloseButton onClick={this.onClose}>
                              <Icon>close</Icon>
                            </CloseButton>
                            <ModalContainer showSubmitButton={this.props.showSubmitButton}>
                              {this.props.loading ? 
                                <LoadingSpinnerPage/>
                                :
                                <React.Fragment>
                                  <Header>{this.props.heading}</Header>
                                  {this.props.children}
                                </React.Fragment>
                              }
                              
                              {/**
                              <Display>
                                
                              </Display>
                              <Info>
                                <Content item={request.deal}/>    
                              </Info>                          
                            */}
                            </ModalContainer>
                          </Container>
                          {this.props.showSubmitButton && <SubmitButtonContainer>
                            <SubmitButton id="generic_modal_submit" disabled={this.props.disabled} variant="contained" color="primary" onClick={this.onSubmit}>
                              {this.props.submitText}
                            </SubmitButton>
                          </SubmitButtonContainer>}
                        </ScrollContainer>
                    )
                }
            </StyledPopup>
        </React.Fragment>            
      )
  }
}

GenericModal.propTypes = {
  disabled: PropTypes.bool,
  heading: PropTypes.string,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submitText: PropTypes.string,
  submitIcon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  showSubmitButton: PropTypes.bool
};

GenericModal.defaultProps = {
  disabled: false,
  loading: false,
  submitText: "Submit",
  submitIcon: (<Check/>),
  showSubmitButton: true
};
export default GenericModal