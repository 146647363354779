import { observable, toJS } from 'mobx'
import { MAX_STRING_EXTRA_LONG } from '../schemas'
import joi from 'joi-browser'

const schema = joi.object().keys({
    attachmentID: joi.string().allow(null).required(),
    message: joi.string().max(MAX_STRING_EXTRA_LONG).allow(null).required(),
    senderUID: joi.string().required(),
    timestamp: joi.number().required(),
});

class Message {
    @observable attachmentID
    @observable id
    @observable message
    @observable senderUID
    @observable snapshot
    @observable timestamp

    constructor(attachmentID = null, id = null, message = null, senderUID = null, snapshot = null, timestamp = 0) {
        this.attachmentID = attachmentID;
        this.id = id;
        this.message = message;
        this.senderUID = senderUID;
        this.snapshot = snapshot;
        this.timestamp = timestamp;
    }

    formatForDB() {
        let data = observable({
            attachmentID: this.attachmentID,
            message: this.message,
            senderUID: this.senderUID,
            timestamp: this.timestamp,
        });
        return toJS(data);
    }

    validate() {
        const result = joi.validate(this.formatForDB(), schema);
        return !result.error;
    }
}

Message.displayName = "Message";
export default Message;
