import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';

const ChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`

const StyleChip = styled(Chip)`
  margin-bottom: 8px;
`

const StyledFormHelper = styled(FormHelperText)`
  min-width: 109px;
`

const TextContainer = styled.div`
  width: 108px;
`

class ColumnInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: []
    }
  }

  handleChange = value => {
    const { values } = this.state
    let indx = values.indexOf(value);
    let newArry;
    if(indx > -1){
      newArry = values.filter(word => word !== value);
    } else {
      newArry = [...values, value]
    }

    newArry.sort();
    this.setState({values: newArry})
  }

  componentDidMount() {
    const { value } = this.props;

    this.setState({ values: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { values } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,values)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ values: nextProps.value });
    }
  }

  createChips = (value,options) => {
    return Object.values(options).map(item => {
      let checked = false;
      if(value.indexOf(item)>-1){
        checked = true;
      }
      return (<ChipSelect label={item} checked={checked} onClick={()=>this.handleChange(item)}/>)
    })
  }

  render () {
    const { editMode, error, name, options, ...other } = this.props;
    const { values } = this.state;
    return editMode ? (
      <React.Fragment>
        <ChipContainer {...other}>
          {this.createChips(values,options)}
        </ChipContainer>
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </React.Fragment>
    ) : (
      <TextContainer>
        {values.join(', ')}
      </TextContainer>
    )
  }
}

const ChipSelect = (props) => {
  const { label, checked, onClick } = props;

  let Icon = checked?<RadioButtonCheckedIcon/>:<RadioButtonUncheckedIcon/>;
  
  return (
    <StyleChip
      label={label}
      onDelete={onClick}
      deleteIcon={Icon}
      onClick={onClick}
    />
  )
}

ColumnInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
  subheader: PropTypes.string,
  value: PropTypes.array,
  editMode: PropTypes.bool,
};

ColumnInput.defaultProps = {
  editMode: false,
};

export default ColumnInput;