import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';
import { Message } from '../../../core/model';

const Container = styled.div`
    display: inline-block;
    font-size: 1.3em;
    border: none;
    padding: 10px;
    margin: 10px;
    margin-top: 0px;
    background-color: ${props => props.colored ? props.theme.colors.spearmint_light : "gainsboro"};
    color: ${props => props.colored ? "white" : "black"};
    word-break: break-word;
    white-space: pre-wrap;
    text-align: left;
    width: auto;
    max-width: 100%;
    ${props => {
        if (props.right) {
            return css`
                border-top-left-radius: 0px;
                border-top-right-radius: 20px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 0px;
            `;
        }
        else {
            return css`
                border-top-left-radius: 20px;
                border-top-right-radius: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 20px;
            `;
        }
    }}
`;

class MessageLabel extends React.Component {
    render() {
        
        return (
            <React.Fragment>
                <Container className={this.props.className} colored={this.props.colored} left={this.props.left} right={this.props.right}>
                    {this.props.message.message}
                </Container>
            </React.Fragment>
        );
    }
}

MessageLabel.propTypes = {
    message: PropTypes.instanceOf(Message).isRequired,
    colored: PropTypes.bool
}

MessageLabel.defaultProps = {
    colored: false
}

export default MessageLabel