import React from 'react'
import PropTypes from 'prop-types'
import { Conversation } from '../../../core/model'
import styled, { css } from 'styled-components'
import CircularImage from '../../atomic/CircularImage';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageOverlap from '../ImageOverlap';

const StyledCircularImage = styled(CircularImage)`
    width: 50px;
    height: 50px;
    margin-left: 5px;
`;

const StyledImageOverlap = styled(ImageOverlap)`
    margin-left: 5px;
`;

const StyledListItem = styled(ListItem)`
    width: 100%;
`;


@inject('profileStore', 'messagingStore')
@observer
class ConversationListItem extends React.Component {
    state = {
        unread: false
    }

    async componentDidMount() {
        await this.markAsRead();
    }

    async componentDidUpdate() {
        await this.markAsRead();
    }

    async markAsRead() {
        if (this.props.selected && this.props.conversation.unread.includes(this.props.profileStore.business.id)) {
            await this.props.conversation.markAsRead();
        }
    }

    getPictures() {
        return Object.values(this.props.conversation.businesses).map((business, index) => (
            <React.Fragment>
                <StyledCircularImage src={business.picture} key={index}/>
            </React.Fragment>
        ))
    }

    getLastMessage() {
        let str = '';
        const messages = this.props.conversation.messages;
        if (messages.length === 0) {
            return str;
        }
        const lastSender = this.props.conversation.businesses[messages[messages.length - 1].senderUID];
        if (!lastSender) {
            str += "You: "
        }
        str += messages[messages.length - 1].message;
        return str;
    }

    getNames() {
        const businesses = Object.values(this.props.conversation.businesses);
        const names = businesses.map((business) => business.name).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        let str = "";
        for (let i = 0; i < names.length - 1; i++) {
            str += names[i] + ", ";
        }
        str += names[names.length - 1];
        return str;
    }

    async onClick() {
        this.props.onClick(this.props.conversation);
    }

    render() {
        const unread = this.props.conversation.unread.includes(this.props.profileStore.business.id);
        const { classes } = this.props;
        const image = require("../../../assets/placeholder_user.png");
        if (this.props.conversation.businesses.length > 0) {
            image = this.props.conversation.businesses[0].picture;
        }
        return (
            <StyledListItem className={this.props.className} key={this.props.key} divider button selected={this.props.selected} onClick={this.onClick.bind(this)}>
                <ListItemIcon>
                    <StyledImageOverlap images={Object.values(this.props.conversation.businesses).map((business) => business.picture)} dim={50}/>
                </ListItemIcon>
                <ListItemText primary={this.getNames()} primaryTypographyProps={{noWrap: true}} secondary={this.getLastMessage()} secondaryTypographyProps={{noWrap: true, style: {fontWeight: unread ? "bold" : "normal"}}} />
            </StyledListItem>
            
        );
    }
}

// <Typography gutterBottom variant="h5" component="h2">
// {this.getNames()}
// </Typography>
// <Typography component="p">
// {this.getLastMessage()}
// </Typography>

ConversationListItem.propTypes = {
    conversation: PropTypes.instanceOf(Conversation).isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func
}

ConversationListItem.defaultProps = {
    selected: false,
    onClick: () => {}
}
export default ConversationListItem;
