import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observe } from 'mobx';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PackingInput from '../../molecules/PackagingInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import { PackFormat } from '../../../core/model';
import { FoodUnits } from '../../../core/core';

const PackingInputStyled = styled(PackingInput)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const ChipContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`

const StyledFormHelper = styled(FormHelperText)`
  min-width: 109px;
`

const Label = styled.div`
  display: flex;
  margin: 5px 0px;
  padding: 5px 10px;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  background-color: #ccc;
  border-radius: 5px;
  flex-grow: 1;
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`

@inject('sheetStore')
@observer
class ColumnText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: [],
      hash: 0,
    }
    this.disposer1 = observe(props.sheetStore.unsavedUnits, (change) => this.setState({hash: this.state.hash + 1}));
    this.disposer2 = observe(props.sheetStore.unsavedPackingUnits, (change) => this.setState({hash: this.state.hash + 1}));
  }

  componentWillMount() {
    const { value, index, sheetStore } = this.props;
    let newValue = value || [];
    if(value) {
      let packUnitsObj = new Object();
      Object.keys(newValue).map(key => packUnitsObj[key] = key);
      sheetStore.setPackingUnitsAtIndex(index,packUnitsObj);
    }
    this.setState({values: this.toPackageFormat(newValue)})
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { values } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,values)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({values: this.toPackageFormat(nextProps.value)})
    }
  }

  componentDidUpdate() {
    const { sheetStore, index } = this.props;
    const { hash } = this.state;
    if(index < sheetStore.isDeletingUnits.length && sheetStore.isDeletingUnits[index]) {
      let modVals = [...this.state.values]
      sheetStore.isDeletingUnits[index] = false;
      const { units, modified } = this.checkModified(index < sheetStore.unsavedUnits.length ? sheetStore.unsavedUnits[index] : {});
      if(modified) {
        modVals = units
      }
      this.setState({ values: modVals })
    } else if(index < sheetStore.isAddingUnits.length && sheetStore.isAddingUnits[index]) {
      sheetStore.isAddingUnits[index] = false;
    }
  }

  toPackageFormat = (obj) => {
    return Object.keys(obj).map((key) => new PackFormat( obj[key].unit, obj[key].conversionRatio, obj[key].conversionUnit ));
  }

  checkModified = units => {
    const { values } = this.state;
    let modified = false;
    const avalibleUnits = Object.keys(units)

    const modValues = values.filter(item => {
      if(item.conversionUnit === null) {
        return true;
      } else if(!avalibleUnits.includes(item.conversionUnit)){
        modified = true;
        return false;
      }
      return true;
    })

    return {
      units: modValues,
      modified
    }
  }

  update = (values, updatehash = true) => {
    const { sheetStore, index } = this.props
    console.log(values)
    let packUnitsObj = new Object();
    values.map(packformat => {
      const unit = packformat.unit;
      if(unit)
        packUnitsObj[unit] = unit;
    })
    sheetStore.setPackingUnitsAtIndex(index,packUnitsObj)
    this.setState({ values, hash: updatehash ? this.state.hash + 1 : this.state.hash});
  }

  getChips = (value) => {
    return value.map(unit => this.createChip(unit))
  }

  createChip = (item) => {
    return (
      <Label>
        <LabelWrapper>
          {`A ${item.unit} is ${item.conversionRatio === null ? 'unlimited' : item.conversionRatio} ${item.conversionUnit}(s)`}
        </LabelWrapper>
      </Label>
    )
  }

  render () {
    const { editMode, options, value, index, error, onChange, sheetStore, ...other } = this.props;
    const { values, hash } = this.state;
    let unitOptions = new Object();
    if(index < sheetStore.unsavedUnits.length && sheetStore.unsavedUnits[index] && index < sheetStore.unsavedPackingUnits.length && sheetStore.unsavedPackingUnits[index]) {
      Object.keys(FoodUnits).map(key => {
        const unit = FoodUnits[key];
        if( sheetStore.unsavedUnits[index][unit] || sheetStore.unsavedPackingUnits[index][unit] ){
          return false;
        }
        unitOptions[key] = unit;
      })
    }
    return editMode ? (
      <React.Fragment>
        <PackingInputStyled
          values={values}
          options={Object.freeze(unitOptions)}
          unitOptions={(index < sheetStore.unsavedUnits.length && sheetStore.unsavedUnits[index]) || {}}
          onChange={this.update}
          hash={hash}
          {...other}
        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </React.Fragment>
    ) : (
      <ChipContainer>
        {this.getChips(values)}
      </ChipContainer>
    )
  }
}

ColumnText.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  editMode: PropTypes.bool,
};

ColumnText.defaultProps = {
  editMode: false,
};

export default ColumnText;