import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';
import Button from '../../../atomic/buttons/Button';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-width: 98px;
`;

const ButtonStyled = styled(Button)`
  flex: 1;
  margin: 3px 7px;
  padding: 0;
  text-transform: none;
  font-size: 0.9em;
  display: ${props => props.webft ? 'block' : 'none'};
  @media (max-width: 768px) {
    margin: 0;
    display: ${props => props.webft ? 'none' : 'block'};
  }
`;

const ButtonStyledMobile = styled(Button)`
  flex: 1;
  margin: 7px;
  text-transform: none;
  display: ${props => props.webft ? 'block' : 'none'};
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    margin-left: auto;
    display: ${props => props.webft ? 'none' : 'block'};
  }
`;

class ListItemActions extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { onDetailsClicked, inBasket, onAddToBasketClicked, restricted, ...other } = this.props

    return (
      <Container {...other}>
        <ButtonStyled
          id="product_listing_details"
          webft
          variant
          onClick={onDetailsClicked}
        >
          Details
        </ButtonStyled>
        {
          !restricted &&
          <React.Fragment>
            <ButtonStyled
              id="product_listing_basket"
              webft
              variant='contained'
              onClick={onAddToBasketClicked}
              disabled={inBasket}
              color="primary"
            >
              {inBasket?"In Basket":"Add To Basket"}
            </ButtonStyled>
            <ButtonStyledMobile
              id="product_listing_basket_mobilft"
              onClick={onAddToBasketClicked}
              disabled={inBasket}
              color="primary"
            >
              {inBasket?"In Basket":"Add To Basket"}
            </ButtonStyledMobile>
          </React.Fragment>
        }
      </Container>
    )
  }

}

ListItemActions.propTypes = {
  inBasket: PropTypes.bool,
}

ListItemActions.defaultPropTypes ={ 
  inBasket: false,
}

export default ListItemActions;