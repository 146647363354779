import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '../../molecules/Tooltip';
import Bubble from '../../molecules/Bubble';
import Button from '../../atomic/buttons/Button';

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #ccc;
`

const TextArea = styled(Bubble)`
`

const ButtonStyled = styled(Button)`
  max-height: 30px;
  padding: 0;
  width: 50px;
  font-size: 13px;
  text-transform: none;
`

const StyleIcon = styled(Icon)`
  font-size: 16px;
`


const StyleCheckbox = styled(Checkbox)`

`

@inject('sheetStore')
@observer
class ColumnText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bool: false
    }
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ bool: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { bool } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,bool)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ bool: nextProps.value });
    }
  }

  render () {
    const { editMode, value, ...other } = this.props;
    const { bool } = this.state;

    // const tipError = this.getError();
    
    return editMode ? (
      <TextContainer>
        View Mode Only
      </TextContainer>
    ) : value ? (
      <TextArea>
        <Icon color={'primary'}>{'check'}</Icon>
      </TextArea>
    ) : (
      <ButtonStyled id="inventory_listing_add_storefront" variant="outlined" onClick={this.props.onAdd}>
        Add<StyleIcon>add</StyleIcon>
      </ButtonStyled>
    )
  }
}

ColumnText.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  editMode: PropTypes.bool,
};

ColumnText.defaultProps = {
  editMode: false,
};

export default ColumnText;