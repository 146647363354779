import React from 'react'
import Proptypes from 'prop-types'
import styled from 'styled-components';
import Button from '../../atomic/buttons/Button';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
const TopBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 8px;
`
const TextArea = styled.div`
  padding: 12px 18px;
  background: #f7f7f7;
  border-radius: 5px;
`
const Title = styled.div`
  font-family: MuseoSans500;
  font-size: 18px;
  margin-right: auto;
`
const Styled = styled.h4``
const ButtonStyled = styled(Button)`
  max-height: 30px;
  min-height: 30px;
  padding: 0px 15px;
  text-transform: none;
`;



const FootNoteDisplay = ({ orderNotes, onEdit, ...other }) => {
  return (
    <Container { ...other }>
      <TopBar>
        <Title>Order Details:</Title>
        <ButtonStyled variant="contained" onClick={onEdit}>Edit</ButtonStyled>
      </TopBar>
      <TextArea>
        {orderNotes}
      </TextArea>
    
    </Container>
  )
}

FootNoteDisplay.propTypes = {
  orderNotes: Proptypes.string,
  onEdit: Proptypes.func,
}

FootNoteDisplay.defaultProps = {
}


export default FootNoteDisplay