import React from 'react';
import styled, {css} from 'styled-components';
import breakpoint from 'styled-components-breakpoint'

const StyledImage = styled.img`
    ${props => {
        if (props.size === 'small') {
            return css`
                max-width: 400px;
                ${breakpoint('mobile_max', 'tablet_max')`
                    max-width: 425px;
                `}
                ${breakpoint('mobile_min', 'mobile_max')`
                    max-width: 400px;
                `}
            `
        }
        else if (props.size === "medium") {
            return css`
                max-width: 505px;
                ${breakpoint('mobile_max', 'tablet_max')`
                    max-width: 450px;
                `}
                ${breakpoint('mobile_min', 'mobile_max')`
                    max-width: 500px;
                `}
            `
        }
        else {
            return css`
                max-width: 510px;
                ${breakpoint('mobile_max', 'tablet_max')`
                    max-width: 500px;
                `}
                ${breakpoint('mobile_min', 'mobile_max')`
                    max-width: 600px;
                `}
            `
        }
    }}
    height: auto;
    width: 55%;
    ${breakpoint('mobile_max', 'tablet_max')`
        width: 50%;
    `}
    ${breakpoint('mobile_min', 'mobile_max')`
        width: 50%;
    `}
    
`

const Image = (props) => (
    <StyledImage {...props} src={props.image} alt='' />
)

export default Image;