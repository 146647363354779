import React from 'react'
import ChipInput from 'material-ui-chip-input'
import DatabaseClient from '../../core/DatabaseClient';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BusinessService } from "core/api"

const styles = theme => ({
    root: {
      flexGrow: 1,
      height: 250,
    },
    container: {
      position: 'relative',
      width: "100%"
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
      overflow: "auto",
      maxHeight: "300px"
    },
    chip: {
      margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: "100%",
      flexGrow: 1,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
  });

function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;
  
    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
}

const renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem }) => {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1;

    return (
        <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
            fontWeight: isSelected ? 500 : 400,
        }}
        >
        {suggestion.name}
        </MenuItem>
    );
}

class BusinessChipInput extends React.Component {
    state = {
        value: '',
        loading: false,
        suggestions: [],
        errorText: undefined,
        chips: [],
        inputValue: '',
        selectedItem: []
    }

    constructor(props) {
        super(props);
        if (props.initialValue || props.initialArray) {
            if (props.initialValue && !props.initialValue.id) {
                throw new Error("Initial value must have field ID");
            }
            if (props.initialValue && !props.initialValue.name) {
                throw new Error("initial value must have field name");
            }
            this.state = {
                value: '',
                loading: false,
                suggestions: [],
                errorText: undefined,
                chips: [],
                inputValue: '',
                selectedItem: props.initialArray ? props.initialArray : [props.initialValue]
            }
        }
    }

    onSuggestionsFetched = (results) => {
        this.setState({
            suggestions: results,
            loading: false
        });
    }

    handleKeyDown = event => {
        const { inputValue, selectedItem } = this.state;
        if (selectedItem.length && !inputValue.length && keycode(event) === 'backspace') {
            this.setState({
            selectedItem: selectedItem.slice(0, selectedItem.length - 1),
            });
        }
    };
    
    handleInputChange = async event => {
        this.setState({ inputValue: event.target.value, loading: true });
        const suggestions = await BusinessService.getAll({
            "status": "active",
            "orderBy": {
                "nameInsensitive": "asc"
            },
            "startAt": event.target.value,
            "endAt": event.target.value + '\uf8ff',
            "limit": 10
        });
        console.log(suggestions)
        this.onSuggestionsFetched(suggestions);
    };
    
    handleChange = item => {
        let { selectedItem } = this.state;
        const ids = selectedItem.map((i) => i.id);
        if (ids.indexOf(item.id) === -1) {
            selectedItem = [...selectedItem, item];
        }

        this.setState({
            inputValue: '',
            selectedItem,
        });
        this.props.onAdd(item);
    };
    
    handleDelete = item => () => {
        const ids = this.state.selectedItem.map((i) => i.id);
        const newState = [...this.state.selectedItem];
        newState.splice(ids.indexOf(item.id), 1);
        this.setState({ selectedItem: newState });
        this.props.onRemove(item);
    };

    render() {
        const { classes } = this.props;
        const { inputValue, selectedItem } = this.state;
        return (
            <Downshift
                inputValue={inputValue}
                onChange={this.handleChange}
                selectedItem={selectedItem}
                itemToString={(i) => {
                    if (i) {
                        return i.name;
                    }
                    else {
                        return '';
                    }
                }}
                >
                {({
                getInputProps,
                getItemProps,
                isOpen,
                inputValue: inputValue2,
                selectedItem: selectedItem2,
                highlightedIndex,
                }) => (
                <div className={classes.container}>
                    {renderInput({
                    fullWidth: true,
                    classes,
                    InputProps: getInputProps({
                        startAdornment: selectedItem.map(item => (
                        <Chip
                            key={item.id}
                            tabIndex={-1}
                            label={item.name}
                            className={classes.chip}
                            onDelete={this.handleDelete(item)}
                        />
                        )),
                        onChange: this.handleInputChange,
                        onKeyDown: this.handleKeyDown,
                        placeholder: 'Start typing for suggestions',
                    }),
                    label: 'Invitees',
                    })}
                    {isOpen ? (
                    <Paper className={classes.paper} square>
                        {this.state.loading ?
                            (
                                <MenuItem>
                                    <CircularProgress size={24} thickness={7} className="loading-spin-button" />
                                </MenuItem>
                            )
                            :
                            this.state.suggestions.length === 0 ?
                                (
                                    <MenuItem disabled>
                                        Nothing found
                                    </MenuItem>
                                )
                                :
                                this.state.suggestions.map((suggestion, index) =>
                                renderSuggestion({
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({ item: suggestion }),
                                    highlightedIndex,
                                    selectedItem: selectedItem2,
                                })
                        )}
                    </Paper>
                    ) : null}
                </div>
                )}
            </Downshift>
        );
    }
}

BusinessChipInput.propTypes = {
    classes: PropTypes.object.isRequired,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func,
    initialValue: PropTypes.object,
    initialArray: PropTypes.array,
    blacklist: PropTypes.oneOfType([PropTypes.object, PropTypes.array]) //observable array allowed
};

BusinessChipInput.defaultProps = {
    onAdd: () => {},
    onRemove: () => {},
    initialArray: []
}
  
  export default withStyles(styles)(BusinessChipInput);
