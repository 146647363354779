import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { LocationIcon } from '../../components/Icons'
import {LoadingSpinnerPage, LoadingSpinnerButton} from '../../components/LoadingSpinner'
import Topbar from '../../components/CommonTopBar'
import Menu from '../../components/Menu';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Add from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from '../../components/atomic/buttons/Button';
import Bottombar from '../../components/CommonBottomBar';
import Item from 'antd/lib/list/Item';
import ListItem from '../../components/ListItem';
import BasketPanel from '../../components/organisms/BasketPanel'
import { message } from 'antd'
import { withRouter} from 'react-router-dom';

const buyerNavButtons = [
  {
    text: null
  },
  {
    text: 'Check Out',
    icon: (<Add/>),
    onClick: (() => null),
  },
]

@inject('shoppingCartStore', 'basketStore')
@observer
class ShoppingCart extends Component {
	state = {
		isLoadingBasket: false,
		isEmpty: false,
		sortSeller: false
	}

	// componentWillMount() {
	// 	this.state = {
	// 		isLoadingBasket: false,
	// 	}
	// 	window.scroll(0, 0);
	// }

	redirectToOrders = () => {
		this.props.history.push('/request');
	}

	getCartItems() {
		if(this.props.basketStore.basket.length === 0 && !this.state.isEmpty){
			this.setState({isEmpty: true});
			return;
		}
	}

	handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

	render() {
		const { isLoadingBasket, isEmpty, sortSeller } = this.state
		const tabs = [
			{
				name: 'Basket',
				child: false,
				path: '/shoppingcart'
			},
		];
		return (
			<Fragment>
				<Menu />
				<Topbar title="Basket" tabs={tabs} sub="View your collected items" tabIndex={0} isChildPath={false}/>
				<div className="baskets-container">
					
					{
						isLoadingBasket ?
							<LoadingSpinnerPage />
							:
							<Fragment>
								{this.getCartItems()}
								{
									isEmpty ?
										(
											<div className="empty-text">
												<Typography variant="title" className="text">
													There Is Nothing In Your Basket
												</Typography>
											</div>
										) : (
											<BasketPanel
												sortSeller={sortSeller}
												cartItems={this.props.basketStore.basket}
												redirect={this.redirectToOrders}
											/>
										)
								}
							</Fragment>
					}
				</div>
			</Fragment>
		)
	}
}

export default withRouter(ShoppingCart)
