import { action, observable, computed } from 'mobx'
import { profileStore } from '..'
import DatabaseClient from '../../core/DatabaseClient'
import AuthManager from '../../core/AuthManager'
import { UserService } from "core/api"

class UserStore {
  @observable user = null;
  @observable emailVerified = false;
  @observable authToken = null;

  @computed get userHasBusinesses() {
    return this.user != null && this.user.businesses != null && Object.keys(this.user.businesses).length > 0;
  }

  @action
  async refreshAuthToken(forceRefresh = false) {
    // if (this.user === null) {
    //   return null;
    // }
    const token = await AuthManager.getToken(forceRefresh);
    this.authToken = token;
    return token;
  }

  @action
  async init() {
    if (this.user === null) {
      return;
    }

    // Sync with the most recent user from the DB - crucial for breaking updates that change the user object
    const user = await UserService.get(this.user.id);
    this.user = user;
    // Get the auth token
    await this.refreshAuthToken();
    UserService.subscribe(this.user.id, (user) => {
      this.user = user;
    });
    await profileStore.init();
  }

  @action
  async setUser(user) {
    this.user = user;
    await this.init();
  }

  @action
  async signUp(firstName, lastName, phoneNumber, email, password, invitationID = null) {
    if (this.isAuthenticated) {
      throw new Error('User is already logged in');
    }
    await AuthManager.signUp(firstName, lastName, phoneNumber, email, password, invitationID);
  }

  @action
  async logout() {
    DatabaseClient.removeAllListeners();
    UserService.unsubscribe(this.user.id);
    await AuthManager.logout();
    // Upon authStateChanged fired from AuthManager.logout, the listener 
    // defined in App.js will remove the userStore.user and direct to /login
  }

  @action
  removeUser() {
    this.user = null;
  }

  @action
  async login(username, password) {
    await AuthManager.login(username, password);
    // await this.setUser(loggedInUser);
    // return loggedInUser;
  }

  @computed get isAuthenticated() {
    return (this.user !== null);
  }

  @action
  async updateNotificationPreferences(clientRequests, orderRequests, orderUpdates, messages) {
    const existingPref = Object.assign({}, this.user.preferences.notifications);
    if (clientRequests != null) {
      existingPref.clientRequests = clientRequests;
    }
    if (orderRequests != null) {
      existingPref.orderRequests = orderRequests;
    }
    if (orderUpdates != null) {
      existingPref.orderUpdates = orderUpdates;
    }
    if (messages != null) {
      existingPref.messages = messages;
    }
    await UserService.update({
      preferences: {
        notifications: existingPref
      }
    });
    this.user.preferences.notifications = existingPref;
  }
}

export default new UserStore()
