import { observable} from 'mobx'

class PackFormat {
    @observable unit
    @observable conversionRatio
    @observable conversionUnit

    constructor(unit = null, conversionRatio = 1, conversionUnit = null) {
        this.unit = unit;
        this.conversionRatio = conversionRatio;
        this.conversionUnit = conversionUnit;
    }

    getBaseQty(qty) {
        return qty*conversionRatio;
    }

    verifyFields() {
        if(!this.unit) {
            return false;
        } else if(!this.conversionUnit) {
            return false;
        } else if(this.conversionRatio <= 0 || isNaN(this.conversionRatio)) {
            return false;
        }
        return true;
    }
}

PackFormat.displayName = "PackFormat";
export default PackFormat;
