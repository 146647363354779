export const privacyPolicy = `
Last Revised: September 25, 2018




Freshspire (“Freshspire,”“we,” “ours,” “us”) respects and values your privacy, and we are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide when you visit or utilize www.getfreshspired.com, www.freshspire.io, or any other products, applications, or features provided by us (collectively, the “Platform”), as well as our practices for collecting, using, maintaining, protecting, and disclosing that information. Any capitalized terms used herein that are not defined herein shall be given the meaning ascribed to them in our Terms of Use.

By accessing or using the Platform, you fully agree to all the terms of this Policy. If you do not agree with this Policy, or our other policies and practices, you must not use the Platform. This Policy may change from time to time. Your continued use of this Platform after such changes are made is deemed to be acceptance of those changes. It is your responsibility to check this Policy periodically for updates.

How does this Policy Apply?

This Policy applies to information we collect: (1) on this Platform; (2) in any electronic communication between you and the Platform; and (3) when you interact with our advertising and applications on third-party websites and Platforms.



This Policy does NOT apply to information collected by: (1) us offline or through any other means, including on any other website or service operated by Freshspire; or (2) any third party (including our affiliates and subsidiaries), including through any service, application, content, or advertisement that may be linked to the Platform.



Children Under the Age of 13  



The Platform is not intended for children under 13 years of age. No one under age 13 may provide any information to the Platform. We do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at help@getfreshspired.com.

Information We Collect Includes Personal Information; Non-Personal Information; and Information Related to Your Access and Use of the Platform



We collect information directly and voluntarily from you when you use the Platform. We consider the information we collect in two broad categories: “Personal Information” and “Non-Personal Information”.



a. Personal Information. Personal Information is generally information that can be used to identify you, either by itself or in conjunction with other information. Personal Information includes, without limitation:

Your name, address, e-mail address, and telephone number;

Your business name and address;

Your credit card information and billing address;

Information and content that you provide or upload for use in connection with the Platform, which may include photographs, data, text, and other information; and

Demographic information such as date of birth, gender, and geographic area, which when linked to other information that allows that individual to be identified.



b. Non-Personal Information. Non-Personal Information includes information about you, but does not identify you personally, including, without limitation (i) anonymous usage data; (ii) general demographic information; (iii) referring/exit pages and URLs; (iv) preferences you submit and that are generated based on your usage data (such as purchases or sales of Produce); (v)browser or platform type (vi) internet connection; (vii) equipment used to access the Platform; (viii) language preference, (ix) referring site, (x) additional websites requested, and (xi) the date and time of each user’s request.



How We Collect Information from You







We collect information from you in the following ways:

Directly from you when you provide it to us or authorize or prompt us to obtain it;

Automatically as you navigate through our Platform through the use of cookies, pixels, scripts, device identifiers, and other tracking technologies; and

From third parties, for example, our Payment Processor, social media sites to which we are connected and with which you interact, and the software providers that facilitate our newsletter.



Automatic Data Collection and How We Use It

In an effort to improve the quality of the Platform, Freshspire (and our service providers) may track information provided to us by your device or browser, or by our analytics tools when you use the Platform. As you access, use, and interact with the Platform, we may use automatic data collection technologies to collect certain information about your equipment, browser, operating system, IP address, browsing actions, and patterns, including details of your visits to the Platform (including traffic data), location data, the device used to access the Platform, your internet connection, the website from which you came (known as the “referring URL”), other communication data, and the resources that you access and use on the Platform. We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking).



The information we collect automatically is statistical data and may include Personal Information, or we may maintain it or associate it with Personal Information we collect in other ways or receive from third parties. It helps us to improve the Platform and deliver a better and more personalized user experience.



Automatic Data Collection Technologies May Include

The technologies we use for the automatic data collection described above may include Cookies (or browser cookies).A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of the Platform. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to the Platform.



Location Information

We do not track your location.



How We Use Personal Information

Except as otherwise stated in this Policy, we do not sell, trade, rent, or otherwise share for marketing purposes your Personal Information with third parties without your consent. We may share Personal Information with vendors who are performing services for Freshspire, such as our Payment Processor or providers of servers for our email communications who provide us the ability to send emails. Those vendors use your Personal Information only at our direction and in accordance with our Policy.



We may use your Personal Information to:

Communicate with you by text message, phone call, or email (for example, we may use your Personal Information to contact you with questions concerning feedback, billing, technical support, promotional offers or otherwise);

Facilitate and carry out the functions of the Platform and the Service (such as facilitating orders, deliveries, pick-ups, payment for Produce, etc.);

Verify your identity (such as during account creation and password resets);

Enforce our Terms, and prevent prohibited or illegal activities;

Improve the Platform, including by customizing your user experience;

Provide targeted marketing, provide service update notices, and deliver promotional offers based on your communication preferences;

Administer referral programs, rewards, surveys, contests, or other promotional activities or sponsored events;

Comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements; and

For any other purpose disclosed to you and to which you consent.



We may also disclose your Personal Information: (1) to comply with any court order, law, or legal process, including to respond to any government or regulatory request; (2) to ensure compliance with our Terms and other agreements; and (3) to protect the rights, property, or safety of Freshspire, our customers, or others. In addition, we retain the right to share Personal Information with other companies and organizations for purposes of fraud prevention.



Note: In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Policy. If our information practices change at any time in the future, we will post the Policy changes to the Platform so that you may opt out of the new information practices.  We suggest that you check the Platform periodically if you are concerned about how your information is used.



How We Use Non-Personal Information and Other Information

In general, we use Non-Personal Information to help us improve the Platform and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Platform. This Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion. We may disclose aggregated information about our users and any information that does not identify any specific individual, without restriction.



Third Party Use of Cookies and Other Tracking Technologies

In accordance with this Policy, certain third party services or applications may be given access to data (which may include Personal Information) from your account or generated in connection with your use of the Platform. While we will only disclose information to such third parties in accordance with the terms of this Policy and as necessary to facilitate the Platform and Service, we cannot control or verify how any such third parties may collect, use, or track information. Freshspire shall not be responsible or liable for the policies or practices of any third party service provider. We strongly encourage you to review the terms of use and privacy policies of any third-party application or service with which we integrate or otherwise use in connection with the Platform, including without limitation, our Payment Processor.



Data Security

We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Platform, you acknowledge that you understand and agree to assume these risks, and Freshspire shall not be responsible for the circumvention of any of the Platform’s privacy settings or security measures.



Links to Other Websites

We are not responsible for the privacy practices employed by websites or services to which the Platform links. This Policy applies solely to our practices concerning your information. Therefore, this Policy does not apply to your use of a third party website accessed by selecting a link on our Platform. To the extent that you access or use the Platform through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of those websites before proceeding to use them.



Changes to Our Policy

We reserve the right to change this Policy and our Terms of Use at any time.  We will notify you of significant changes to our Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site.  Significant changes will go into effect thirty (30) days following such notification.  Non-material changes or clarifications will take effect immediately. You should periodically check the Platform and this privacy page for updates.

Contact Us

If you have any questions regarding this Policy or the practices of this Platform, please contact us by sending an email to help@getfreshspired.com.`