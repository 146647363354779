import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '../../atomic/buttons/Button';

const BarContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const CheckboxStyled = styled(Checkbox)`
  visibility: ${props => props.hide? 'hidden':'visible'};
`

const ButtonStyled = styled(Button)`
  margin-left: auto;
  @media (max-width: 768px) {
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: ${props => props.theme.fonts.ms_font500};
  @media (max-width: 550px) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

class TotalDisplay extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { 
      cost,
      hideCheckbox,
      onRequest,
      checked,
      onSelected,
      indeterminate
    } = this.props;

    return (
      <ContentContainer>
        <CheckboxStyled
          hide={hideCheckbox}
          checked={checked}
          indeterminate={indeterminate}
          onChange={(e) => onSelected(e.target.checked)}
        />
        <TextContainer>{`Total: $${cost.toFixed(2)}`}</TextContainer>
      </ContentContainer>
    )
  }
}

TotalDisplay.propTypes = {
  onRequest: PropTypes.func.isRequired,
  onSelected: PropTypes.func,
  hideCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  cost: PropTypes.number,
};

TotalDisplay.defaultProps = {
  hideCheckbox: false,
  checked: false,
  cost: 0,
};

export default TotalDisplay;