import axios from 'axios';
import UserStore from 'stores/common/UserStore';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Qs from 'qs';
import { BASE_URL } from "./conf";

const FreshspireClient = axios.create({
	// Do not edit the line below
	baseURL: BASE_URL,
	// withCredentials: true // Not working as expected -> cookies aren't reflecting latest token
	// responseType: 'json'
	paramsSerializer: function (params) {
		return Qs.stringify(params, {arrayFormat: 'brackets'})
	},
});


// Encapsulate into a function to help with retry logic
const getAuthToken = async () => {
    return UserStore.refreshAuthToken(false);
};

FreshspireClient.interceptors.request.use(async (config) => {
	const token = await getAuthToken();
	if (token != null) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});


// Function that will be called to refresh authorization
const refreshAuthLogic = async (failedRequest) => {
	const token = await UserStore.refreshAuthToken(true);
	failedRequest.response.config.headers.Authorization = `Bearer ${token}`;
};

createAuthRefreshInterceptor(FreshspireClient, refreshAuthLogic);

// If authentication error, refresh auth token
// FreshspireClient.interceptors.response.use(null, (error) => {
// 	if (error.config && error.response && error.response.status === 401) {
// 		return (async () => {
// 			const token = await UserStore.refreshAuthToken(true);
// 			error.config.headers.Authorization = `Bearer ${token}`;
// 			return FreshspireClient.request(error.config);
// 		})();
// 	}
//
// 	throw error;
// });

export default FreshspireClient;
