import { observable, toJS } from 'mobx'

class Label {
  @observable id
  @observable label
  @observable value

  constructor(id = null, label = null, value = null) {
    this.id = id;
    this.label = label;
    this.value = value;
  }

}

Label.displayName = "Label";
export default Label;