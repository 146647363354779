import React from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import MultiSelect from './MultiSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../atomic/buttons/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const ListContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
`
const ListStyled = styled(List)`
  width: 100%;
  overflow-y: auto;
  max-height: 260px;
`
const ListItemStyled = styled(ListItem)`
  padding: 0 16px;
`
const FormControlLabelStyled = styled(FormControlLabel)`
  width: 100%;
  span {
    line-height: 1.2em !important;
  }
`
const CheckboxStyled = styled(Checkbox)`
  padding-top: 5px;
  padding-bottom: 5px;
`

class FarmerFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
    }
  }

  setChecked = checked => this.setState({checked});
  
  clear = () => this.setState({checked: []})

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setChecked(newChecked);
  };

  render () {
    const { items, checked, onChange, ...other } = this.props;

    return (
      <ListContainer {...other}>
        <ListStyled>
          {items.map(label => (
            <ListItemStyled>
              <FormControlLabelStyled
                key={`${label}-checkbox-${checked[label]}`}
                control={
                  <CheckboxStyled 
                    id='farm-filter'
                    value = {label}
                    checked = {checked[label]}
                    onChange = {onChange(label)}
                  />
                } 
                label={label}
              />
            </ListItemStyled>
          ))}
        </ListStyled>
      </ListContainer>
    );
  }
}

export default FarmerFilter;