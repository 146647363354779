import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import GenericModal from '../GenericModal';
import PropTypes from 'prop-types';
import { privacyPolicy } from './PrivacyPolicy';
import { termsOfService } from './ToS';

const StyledGenericModal = styled(GenericModal)`
    width: 600px !important;
    @media (max-width: 600px) {
        width: 550px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin: 50px;
    @media (max-width: 550px) {
        margin: 10px;
    }
    ${breakpoint('tablet_min', 'tablet_max')`
        margin: 25px;
    `}
`;

const Information = styled.div`
    text-align: center;
    margin-top: ${props => props.top ? "0px" : "40px"};
`;

const ScrollContent = styled.div`
    overflow-y: auto;
    white-space: pre-wrap;
    max-height: 25vh;
    @media (max-width: 550px) {
        max-height: 75vh;
    }
    background-color: ghostwhite;
    padding: 5px;
    border-radius: 5px;
`;

class LegalModal extends React.Component {
    state = {
        content: ""
    }

    constructor(props) {
        super(props);
        if (props.contentType === "Privacy Policy") {
            this.state = {
                content: privacyPolicy
            }
        }
        else {
            this.state = {
                content: termsOfService
            }
        }
    }

    render() {
        return (
            <StyledGenericModal {...this.props} heading={this.props.contentType} submitText="Got it!">
                <Content>
                    <Information top>
                        {`The following information outlines the ${this.props.contentType} governing the use of Freshspire and its products:`}
                    </Information>
                    <ScrollContent>
                        {this.state.content}
                    </ScrollContent>
                </Content>
            </StyledGenericModal>
        );
    }
}

LegalModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    contentType: PropTypes.string
}

LegalModal.defaultProps = {
    contentType: "Privacy Policy"
}

export default LegalModal;