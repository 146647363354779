import React, { Component } from 'react'
import Button from '../../atomic/buttons/Button';
import ChipInput from '../../molecules/ChipInput/';
import PendingList from '../../molecules/PendingList/';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {AlertMessage} from '../../AlertMessage';
import Typography from '@material-ui/core/Typography';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import DatabaseClient from '../../../core/DatabaseClient'
import { confirmAlert } from 'react-confirm-alert';
import { observer, inject } from 'mobx-react'
import { message } from 'antd'
import { LoadingSpinnerPage } from '../../LoadingSpinner';
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import ColumnButton from './ColumnButton';
import ColumnImage from './ColumnImage';
import ColumnInput from './ColumnInput';
import ColumnText from './ColumnText';
import ColumnDropdown from './ColumnDropdown';
import ColumnChipSelect from './ColumnChipSelect';
import ColumnQuantity from './ColumnQuantity';
import ColumnPackaging from './ColumnPackaging';
import ColumnEdit from './ColumnEdit';
import ActionToolBar from './ActionToolBar';
import CustomToolbar from './CustomToolbar';
import CustomFooter from './CustomFooter';
import { ReasonForPostOptions, FoodOptions, FoodUnits } from '../../../core/core';
import cloneDeep from 'clone-deep';
import { PackFormat } from '../../../core/model';
import { toJS } from 'mobx';

import InventoryEditModal from '../../molecules/InventoryEditModal';

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

function getCurrentDate() {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth()+1; // January is 0!
  var yyyy = today.getFullYear();

  if(dd<10) {
      dd = '0'+dd
  } 

  if(mm<10) {
      mm = '0'+mm
  } 

  today = yyyy + '-' + mm + '-' + dd;
  return today;
}

const dealFormat = {
  id: null,
  inStore: {
    value: false,
    error: null,
  },
  picture: {
    value: null,
    error: null,
  },
  name: {
    value: null,
    error: null,
  },
  logistics: {
    value: [],
    error: null,
  },
  details: {
    value: [],
    error: null,
  },
  quantity: {
    value: {},
    error: null,
  },
  packFormats: {
    value: [],
    error: null,
  },
  foodCategory: {
    value: null,
    error: null,
  },
  description: {
    value: null,
    error: null,
  },
  order: null,
};

@inject('inventoryStore', 'sheetStore')
@observer
class InventorySheet extends Component {
  constructor (props) {
    super(props)
    this.renderHash = 0;
    this.errorRowsAfter = [];
    this.state = {
      data: [],
      unsavedData: [],
      rowsInEdit: [],
      rowsToSave: [],
      editIndex: 13,
    }
    this.options = {
      filterType: 'checkbox',
      responsive: 'scroll',
      filter: false,
      print: false,
      download: false,
      selectableRows: false,
      pagination: false,
      elevation: 0,
      customToolbar: () => {
        return <CustomToolbar addItem={this.addNewDeal}/>
      },
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return <CustomFooter addItem={this.addNewDeal} />;
      }
    };
    this.columns = [
      {
        name: "ID",
        options: {
          display: 'excluded',
          filter: false,
          sort: false,
          download: false,
        }
      },
      {
        name: "In Store",
        options: {
          display: true,
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnButton
                key={`str-${indx}-${this.renderHash}`}
                name="inStore" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                onAdd={() => this.createProductListing(indx)}
                value={data['value']}
                error={data['error']}
                editMode={editMode} 
              />
            );
          }, 
        }
      },
      {
        name: "Picture",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            const picSrc = this.checkIfFile(data['value'],indx)
            return (
              <ColumnImage
                key={`img-${indx}-${this.renderHash}`}
                name='picture'
                value={picSrc}
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                editMode={editMode}
              />
            );
          },  
        }
      },
      {
        name: "Name",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnInput
                key={`name-${indx}-${this.renderHash}`}
                name="name" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                inputWidth={200}
                editMode={editMode} 
              />
            );
          }, 
        }
      },
      {
        name: "Logistics",
        options: {
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnChipSelect
                key={`logistics-${indx}-${this.renderHash}`}
                name="logistics"
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                editMode={editMode} 
                options={{
                  DELIVERY: 'Delivery',
                  PICKUP: 'Pickup',
                }}
              />
            );
          }, 
        }
      },
      {
        name: "Details",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnChipSelect
                key={`details-${indx}-${this.renderHash}`}
                name="details"
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                editMode={editMode} 
                options={{
                  ORGANIC: 'Organic',
                  LOCAL: 'Locally Grown',
                }}
              />
            );
          }, 
        }
      },
      {
        name: "Quantity",
        options: {
          hint: `Enter the total quantity of the product that you have available.  You can enter multiple quantities if your
          inventory has multiple units.  Orders will automatically deduct inventory from these values.`,
          /*`Enter the base units in which you keep track of your inventory (e.g. lbs, oz, heads, count).  For example, if you 
                have 50 heads of lettuce, enter that information here and any packaging formats you offer in the next column. 
                Orders will automatically deduct quantity from this field.
                If you do not keep track of inventory by a base unit, you can instead specify the units you sell by here 
                (e.g. bags, boxes) and have orders deduct from those units instead.  For example, you may enter
                that you have 3 bags and 4 boxes in stock rather than specifying you have 50 heads.`,*/
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            const options = Object.assign({}, FoodUnits);
            return (
              <ColumnQuantity
                key={`qty-${indx}-${this.renderHash}`}
                name="quantity" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                options={options}
                value={data['value']}
                error={data['error']}
                editMode={editMode}
              />
            );
          }, 
        }
      },
      {
        name: "Pack Formats (Optional)",
        options: {
          hint: `If you sell your product in different packaging variations, specify those here. (e.g. If you have
          50 heads of lettuce and sell them by the box, where each box has 10 heads, you would specify that here.
          When an order is placed for a box, 10 heads will automatically be deducted from your inventory.)`,
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            const options = Object.assign({}, FoodUnits);
            return (
              <ColumnPackaging
                key={`qty-${indx}-${this.renderHash}`}
                name="packFormats" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                options={options}
                value={data['value']}
                error={data['error']}
                editMode={editMode}
              />
            );
          }, 
        }
      },
      {
        name: "Category",
        options: {
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx);
            const options = Object.assign({}, FoodOptions);
            delete options['OTHER'];
            return (
              <ColumnDropdown
                key={`food-${indx}-${this.renderHash}`}
                name="foodCategory" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                editMode={editMode} 
                options={options}
                errorText={"Must be less than 26 characters"}
                allowOther={true}
                style={{width: '150px'}}
              />
            );
          }, 
        }
      },
      {
        name: "Description",
        options: {
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnText
                key={`description-${indx}-${this.renderHash}`}
                name="description"
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}  
                editMode={editMode} 
              />
            );
          }, 
        }
      },
      {
        name: "",
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const indx = value
            const ID = tableMeta['rowData'][0]
            const isNew = ID === 'null'
            const editMode = this.state.rowsInEdit.includes(indx)
            const saving = this.state.rowsToSave.includes(indx)
            return (
              <ColumnEdit
                key={`button-${indx}-${this.renderHash}`}
                editMode={editMode}
                saving={saving}
                isNew={isNew}
                onSave={()=>this.saveRow(indx)}
                onEdit={()=>this.editRow(indx)}
                onCancel={()=>this.cancelRow(indx)}
                onDelete={()=>this.quickDelete(isNew,indx)}
              />
            );
          }, 
        }
      }
    ];
  }

  formatNewDealData = (deal,index) => {
    const { sheetStore} = this.props
    let data = cloneDeep(dealFormat)

    data['name'].value = deal?deal[0]:'';
    data['inStore'].value = false;
    data['foodCategory'].value = ''
    data['description'].value = null
    data['order'] = index

    sheetStore.setImageAtIndex(index,null);

    let unitsObj = new Object();
    sheetStore.setUnitsAtIndex(index,unitsObj)

    let packUnitsObj = new Object();
    sheetStore.setPackingUnitsAtIndex(index,packUnitsObj)

    return data;
  }

  randomizeHash = () => {
    return (Math.random() * (10.00 - 1.00 + 1.00) + 1.00).toFixed(7);
  }
  
  formatDealData = (deal,index) => {
    const { sheetStore} = this.props
    let data = cloneDeep(dealFormat)

    data['id'] = deal['id']
    data['picture'].value = deal['picture'] ? deal['picture'].url : null
    data['name'].value = deal['name']
    data['quantity'].value = deal['quantity']
    data['packFormats'].value = this.object2Array(deal['packFormats'])
    data['foodCategory'].value = deal['foodCategory']
    data['inStore'].value = this.isInStore(deal['id']);
    data['description'].value = deal['description']?deal['description']:null
    data['order'] = index

    if(deal['delivery'])
      data['logistics'].value.push('Delivery')
    if(deal['pickup'])
      data['logistics'].value.push('Pickup')

    if(deal['organic'])
      data['details'].value.push('Organic')
    if(deal['local'])
      data['details'].value.push('Locally Grown')


    sheetStore.setImageAtIndex(index, deal['picture'] ? deal['picture'].url : null);
    sheetStore.setUnitBools(index);
    let unitsObj = new Object();
    sheetStore.setUnitsAtIndex(index,unitsObj);

    let packUnitsObj = new Object();
    sheetStore.setPackingUnitsAtIndex(index,packUnitsObj);
    
    return data;
  }

  async componentWillMount() {
    const { inventoryStore, sheetStore, data } = this.props
    sheetStore.reset();
    try {
      let deals = data?data:[]
      const dealData = deals.map((deal,index) => this.formatDealData(toJS(deal),index))
      
      this.setState({
        data: cloneDeep(dealData),
        unsavedData: dealData,
      })
      window.scroll(0, 0);
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  componentWillReceiveProps(props){
    if(!props.newData)
      return

    this.addNewDeals(props.newData)
  }

  async componentDidUpdate(prevProps,prevState) {
    const { rowsToSave, rowsInEdit, rowToDelete } = this.state

    let savedRows = rowsToSave.filter(item => prevState.rowsToSave.indexOf(item) < 0);
    if(rowToDelete) {
      this.setState({
        rowsToSave: [],
        rowToDelete: null
      })
      await this.deleteSingleRow(rowToDelete)
    } else {
      if(savedRows.length > 0) {
        this.errorRowsAfter = rowsInEdit.slice();
        await Promise.all(savedRows.map( async item => {
          return this.handleSave(parseInt(item))
        }))
        this.setState({
          rowsToSave: [],
          rowsInEdit: this.errorRowsAfter
        })
      }
    }
  }

  object2Array = (obj) => {
    return Object.keys(obj).map((key) => new PackFormat( key, obj[key].conversionRatio, obj[key].conversionUnit ));
  }

  isInStore = (id) => {
    const { storeFront } = this.props;
    for (const productListing of storeFront) {
      if(productListing.productID === id) {
        return true;
      }
    }
    return false;
  }

  checkIfFile = (value,indx) => {
    if(isNaN(parseInt(indx))) {
      return null;
    }

    if (!value) {
      return null;
    } else if (typeof value === 'object') {
      return this.state.unsavedData[parseInt(indx)]['pictures'];
    } else {
      return value;
    }
  }

  handleSave = async (indx) => {
    const { unsavedData, data } = this.state
    const { inventoryStore } = this.props
    const errorCheckArray = unsavedData
    let saveArray = data;
    const obj = errorCheckArray[indx];
    let foundError = false;
    let dataID = null;
    const options = Object.assign({}, FoodOptions);
    delete options['OTHER'];
    console.log(obj);
    // if(!obj['foodOption'].value) {
    //   if(obj['foodOptionOther'].value) {
    //     obj['foodOption'].value = 'Other'
    //   } else {
    //     obj['foodOption'].value = ''
    //   }
    // } else if(Object.values(options).includes(obj['foodOption'].value)) {
    //   obj['foodOptionOther'].value = null
    // } else if(obj['foodOptionOther'].value) {
    //   obj['foodOption'].value = 'Other'
    // } else if(obj['foodOption'].value === 'Other') {
    //   obj['foodOption'].value = null
    // }
    if(obj['description'].value === '') {
      obj['description'].value = null
    }

    if(_.isEmpty(obj['quantity'].value)) {
      obj['quantity'].value = null
    }

    inventoryStore.populateForm(Object.assign({},obj))
    Object.keys(obj).map(item => {
      
      let err = null;

      switch (item) {
        case 'id':
          dataID = obj[item]
          break;
        case 'order':
          break;
        case 'inStore':
          break;
        case 'details':
          break;
        // case 'foodCategory':
        //   break;
        case 'logistics':
          err = inventoryStore.validateValue('delivery')
          break;
        default:
          err = inventoryStore.validateValue(item)
          break;
      }

      if(err) {
        foundError = true;
        errorCheckArray[indx][item].error = err[Object.keys(err)[0]][0];
      } else {
        if(item !== 'id' && item !== 'order') {
          errorCheckArray[indx][item].error = null
        }
      }
    })
    if(!foundError) {
      if (dataID) {
        if(!this.dataUnchanged(unsavedData, data, indx)){
          try {
            await inventoryStore.editDeal(dataID)
          }
          catch (e) {
            message.error(e.message);
            console.error(e);
            Sentry.captureException(e);
            return;
          }
        }
      } else {
        try {
          const dealID = await inventoryStore.createDeal()
          errorCheckArray[indx]['id'] = dealID
        }
        catch (e) {
          message.error(e.message);
          console.error(e);
          Sentry.captureException(e);
          return;
        }
      }
      let indexOf = this.errorRowsAfter.indexOf(indx.toString());
      this.errorRowsAfter.splice(indexOf, 1);
      saveArray[indx] = errorCheckArray[indx];
      this.setState({
        data: cloneDeep(saveArray)
      })
    } else {
      message.error(`Please fix the highlighted fields.`);
    }
    this.setState({
      unsavedData: errorCheckArray,
    })
  }

  createProductListing = async (indx) => {
    const { unsavedData, data } = this.state
    const { inventoryStore } = this.props
    const errorCheckArray = unsavedData
    let saveArray = data;
    const obj = errorCheckArray[indx];
    let foundError = false;
    let dataID = null;
    
    const options = Object.assign({}, FoodOptions);
    delete options['OTHER'];

    // if(!obj['foodOption'].value) {
    //   if(obj['foodOptionOther'].value) {
    //     obj['foodOption'].value = 'Other'
    //   } else {
    //     obj['foodOption'].value = ''
    //   }
    // } else if(Object.values(options).includes(obj['foodOption'].value)) {
    //   obj['foodOptionOther'].value = null
    // } else if(obj['foodOptionOther'].value) {
    //   obj['foodOption'].value = 'Other'
    // } else if(obj['foodOption'].value === 'Other') {
    //   obj['foodOption'].value = null
    // }
    if(obj['description'].value === '') {
      obj['description'].value = null
    }

    if(_.isEmpty(obj['quantity'].value)) {
      obj['quantity'].value = null
    }

    inventoryStore.populateForm(Object.assign({},obj))
    Object.keys(obj).map(item => {
      
      let err = null;

      switch (item) {
        case 'id':
          dataID = obj[item]
          break;
        case 'order':
          break;
        case 'inStore':
          break;
        case 'details':
          break;
        // case 'foodCategory':
        //   break;
        case 'logistics':
          err = inventoryStore.validateValue('delivery')
          break;
        default:
          err = inventoryStore.validateValue(item)
          break;
      }

      if(err) {
        foundError = true;
      }
    })
    if(!foundError) {
      try {
        await inventoryStore.createStoreFrontItem()
        errorCheckArray[indx]['inStore'].value = true;
      }
      catch (e) {
        message.error(e.message);
        console.error(e);
        Sentry.captureException(e);
        return;
      }
      let indexOf = this.errorRowsAfter.indexOf(indx.toString());
      this.errorRowsAfter.splice(indexOf, 1);
      saveArray[indx] = errorCheckArray[indx];
      this.setState({
        data: cloneDeep(saveArray)
      })
    } else {
      message.error(`Please fix the highlighted fields.`);
    }
    this.setState({
      unsavedData: errorCheckArray,
    })
  }

  dataUnchanged = (unsavedData, data, indx) => {
    const savedDataItem =  JSON.stringify(data[indx]);
    const unsavedDataItem =  JSON.stringify(unsavedData[indx]);

    return savedDataItem === unsavedDataItem;
  }

  addNewDeals = (deals) => {
    const dealData = deals.map(deal => this.formatNewDealData(deal))
    this.setState({
      data: dealData,
      unsavedData: dealData,
    })
  }

  addNewDeal = () => {
    const { data, unsavedData, rowsInEdit } = this.state
    const dealData = []
    let newRowsInEdit = rowsInEdit.slice();
    const indx = data.length;
    dealData.push(this.formatNewDealData(null,indx))
    newRowsInEdit.push(indx.toString());
    this.setState({
      data: [...data,...cloneDeep(dealData)],
      unsavedData: [...unsavedData,...dealData],
      rowsInEdit: newRowsInEdit,
    })
  }

  onFieldChange = (index, field, value) => {
    const { unsavedData } = this.state;
    let newData = unsavedData.slice(0);
    newData[index][field].value = value;
    this.setState({
      unsavedData: newData
    });
  }

  saveRow = (indx) => {
    const { rowsToSave } = this.state
    this.setState({
      rowsToSave: [...rowsToSave,indx]
    })
  }
  
  editRow = (indx) => {
    const { rowsInEdit } = this.state;
    let newRowsInEdit = rowsInEdit.slice();
    let indexInArray = newRowsInEdit.indexOf(indx)>=0?true:false;

    if(!indexInArray){
      newRowsInEdit.push(indx);
    }

    this.setState({
      rowsInEdit: newRowsInEdit
    });
  }

  quickDelete = async (isNew, indx) => {
    if(isNew) {
      await this.deleteRow(indx);
    } else {
      AlertMessage('Delete Item','Are you sure you want to delete this inventory item?',(async () => {await this.deleteRow(indx)}))
    }
  }

  deleteRow = (indx) => {
    const { rowsInEdit } = this.state;
    let modRowsInEdit = [...rowsInEdit];
    const indexOfRow = rowsInEdit.indexOf(indx);
    modRowsInEdit.splice(indexOfRow,1);

    this.setState({
      rowToDelete: indx,
      rowsToSave: rowsInEdit
    })
  }

  deleteSingleRow = async (indx) => {
    const { rowsInEdit, unsavedData, data } = this.state;
    const { inventoryStore, sheetStore } = this.props;
    const id = unsavedData[indx]['id'];
    const dataIndex = indx;

    if(id) {
      try {
        await inventoryStore.archiveDeal(id);
      }
      catch (e) {
        message.error(e.message);
        console.error(e);
        Sentry.captureException(e);
        return;
      }
    }

    let newRowsInEdit = [...rowsInEdit];
    let unsavedDelete = [...unsavedData];
    let dataDelete = [...data];

    const indexOf = newRowsInEdit.indexOf(indx);

    unsavedDelete.splice(dataIndex,1);
    dataDelete.splice(dataIndex,1);
    newRowsInEdit.forEach((item,index) => {
      if(index > indexOf){
        const num = parseInt(item) - 1;
        newRowsInEdit[index] = num.toString();
      }
    })
    newRowsInEdit.splice(indexOf, 1);

    sheetStore.removeUnitsAtIndex(indx);
    sheetStore.removePackingUnitsAtIndex(indx);
    sheetStore.removeImageAtIndex(indx);

    unsavedDelete.forEach((item,index) => { item['order'] = index; })
    dataDelete.forEach((item,index) => { item['order'] = index; })

    ++this.renderHash;

    this.setState({
      unsavedData: unsavedDelete,
      data: dataDelete,
      rowsInEdit: newRowsInEdit,
    })
  }

  cancelRow = (indx) => { 
    const { rowsInEdit, data, unsavedData } = this.state;
    let newRowsInEdit = rowsInEdit.slice();
    let indexOf = newRowsInEdit.indexOf(indx)
    let newData = unsavedData.slice(0);
    newRowsInEdit.splice(indexOf, 1);
    newData[indx] = cloneDeep(data[indx]);
    this.setState({
      rowsInEdit: newRowsInEdit,
      unsavedData: newData
    });
  }

  isInEdit = () => {
    return this.state.rowsInEdit.length > 0;
  }

  editAll = () => {
    let newRowsInEdit = [];
    this.state.unsavedData.forEach((item,index) => newRowsInEdit.push(index.toString()))
    this.setState({
      rowsInEdit: newRowsInEdit
    });
  }

  saveAll = () => {
    this.setState({
      rowsToSave: this.state.rowsInEdit
    });
  }

  cancelAll = () => {
    const { rowsInEdit, data, unsavedData } = this.state;
    let newRowsInEdit = rowsInEdit.slice();
    let newData = unsavedData.slice(0);
    let NonIDs = [];
    newRowsInEdit.forEach((item) => {
      const indx = parseInt(item);
      const id = data[indx]['id'];
      if(!id){
        NonIDs.push(data[indx]['order'])
      }
      newData[indx] = cloneDeep(data[indx]);
    })
    this.setState({
      rowsInEdit: [],
      unsavedData: newData
    }, () => this.deleteMultiple(NonIDs))
    
  }

  deleteMultiple = (arr) => {
    const { rowsInEdit, unsavedData, data } = this.state;
    // const { inventoryStore } = this.props;
    // const id = unsavedData[indx]['id'];
    // const dataIndex = indx;

    // if(id) {
    //   try {
    //     await inventoryStore.archiveDeal(id);
    //   }
    //   catch (e) {
    //     message.error(e.message);
    //     return;
    //   }
    // }

    let newRowsInEdit = [...rowsInEdit];
    let unsavedDelete = [...unsavedData];
    let dataDelete = [...data];

    arr.reverse().forEach(dataIndex => {
      const indexOf = newRowsInEdit.indexOf(dataIndex);
  
      unsavedDelete.splice(dataIndex,1);
      dataDelete.splice(dataIndex,1);
      newRowsInEdit.forEach((item,index) => {
        if(index > indexOf){
          const num = parseInt(item) - 1;
          newRowsInEdit[index] = num.toString();
        }
      })
      newRowsInEdit.splice(indexOf, 1);
  
      unsavedDelete.forEach((item,index) => { item['order'] = index; })
      dataDelete.forEach((item,index) => { item['order'] = index; })
    })

    ++this.renderHash;

    this.setState({
      unsavedData: unsavedDelete,
      data: dataDelete,
      rowsInEdit: newRowsInEdit,
    })
  }

  render () {
    const { unsavedData } = this.state;
    const jsonData = unsavedData.map(item => {
      return Object.keys(item).map(key => {
        return JSON.stringify(item[key]);
      })
    })
    return (
      <MuiThemeProvider>
        <FormContainer>
          <ActionToolBar
            editAll={this.editAll}
            saveAll={this.saveAll}
            cancelAll={this.cancelAll}
            editMode={this.isInEdit()}
          />
          <MUIDataTable
            data={jsonData}
            columns={this.columns}
            options={this.options}
          />
          <InventoryEditModal
            renderHash={this.renderHash}
            data={unsavedData}
            foodUnits={FoodUnits}
            foodOptions={FoodOptions}
            onFieldChange={(index, field, value) => this.onFieldChange(index,field,value)}
            rowsToSave={this.state.rowsToSave}
            rowsInEdit={this.state.rowsInEdit}
            onSave={(indx) => this.saveRow(indx)}
            onCancel={(indx) => this.cancelRow(indx)}
            onDelete={(isNew, indx) => this.quickDelete(isNew, indx)}
          />
        </FormContainer>
      </MuiThemeProvider>
    )
  }
}

InventorySheet.propTypes = {
  data: PropTypes.array,
};

export default InventorySheet
