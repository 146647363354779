import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const StyleTableRow = styled(TableRow)`
  width: 100%;
  display: flex;
`

const StyleTableCell = styled(TableCell)`
  border: none;
  margin-left: auto;
  display: flex;
  align-items: center;
`

const StyleTableFooter = styled(TableFooter)`
  width: 100%;
`

const StyleButton = styled(Button)`
  min-height: 28px;
  max-height: 28px;
  padding: 0px 16px;
  margin-left: 16px;
  text-transform: none;
`

class CustomFooter extends React.Component {
  render() {
    const { addItem } = this.props

    return (
      <StyleTableFooter>
        <StyleTableRow>
          <StyleTableCell>
            <StyleButton id="sheet_footer_add_item" variant="outlined" onClick={addItem}>
              New Item +
            </StyleButton>
          </StyleTableCell>
        </StyleTableRow>
      </StyleTableFooter>
    );
  }
}

export default CustomFooter;
