import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import {LoadingSpinnerPage} from '../../../components/LoadingSpinner'
import Topbar from '../../../components/CommonTopBar'
import Menu from '../../../components/Menu';
import Typography from '@material-ui/core/Typography';
import { message } from 'antd';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import OrderContainer from '../../../components/molecules/OrderContainer'
import { StepPaginator } from 'components/molecules/Pagination';
import {OrderBundleService} from "../../../core/api";
import Button from "components/atomic/buttons/Button";
import Icon from "@material-ui/core/Icon";
import SpreadsheetDownloadModal from "./SpreadsheetDownloadModal";
import Bottombar from "../../../components/CommonBottomBar";

const DownloadSpreadsheetButton = (props) => {
    return (
        <Button variant="contained" color="secondary" className={`button`} {...props}>
            Spreadsheet
            &nbsp; <Icon className="icon">get_app</Icon>
        </Button>
    );
};

@inject('orderStore', "profileStore", "clientListStore")
@observer
class ViewOrders extends Component {

    state = {
        page: null,
        isLoadingOrders: true,
        childrenWatcher: false,
        total: 1,
        display: 4,
        number: 1,
        clients: [],
        spreadsheetModalOpen: false
    };

    async componentDidMount() {
        await this.refreshData();
    }

    async componentWillUpdate(nextProps, nextState) {
        if (nextProps !== this.props) {
            await this.refreshData(nextProps);
        }
    }

    async refreshData(props) {
        this.setState({ isLoadingOrders: true });
        const { location, clientListStore, profileStore } = props || this.props;
        await clientListStore.getAllClients();

        let page = this.getPage(location.pathname);

        switch(page) {
            case 'request':
                try {
                    const clients = [];
                    await Promise.all(
                        clientListStore.totalClientList.map(
                            async (client) => {
                                const [_, meta] = await OrderBundleService.getAll({
                                    buyerUID: profileStore.business.isBuyer() ? profileStore.business.id : client.id,
                                    sellerUID: profileStore.business.isSeller() ? profileStore.business.id : client.id,
                                    statusArr: {
                                        "array-contains": "created"
                                    },
                                    orderBy: {
                                        "timestamp": "desc"
                                    }
                                }, true, {
                                    headers: {
                                        "X-Metadata-Only": true
                                    }
                                });
                                console.log("Count is ", meta.count);
                                if (meta.count) {
                                    clients.push(client)
                                }
                            }
                        )
                    );
                    this.setState({ clients });
                }
                catch (e) {
                    message.error(e.message);
                    console.error(e);
                    Sentry.captureException(e);
                    return;
                }
                break;
            case 'active':
                try {
                    const clients = [];
                    await Promise.all(
                        clientListStore.totalClientList.map(
                            async (client) => {
                                const [_, meta] = await OrderBundleService.getAll({
                                    buyerUID: profileStore.business.isBuyer() ? profileStore.business.id : client.id,
                                    sellerUID: profileStore.business.isSeller() ? profileStore.business.id : client.id,
                                    statusArr: {
                                        "array-contains": "accepted"
                                    },
                                    orderBy: {
                                        "timestamp": "desc"
                                    }
                                }, true, {
                                    headers: {
                                        "X-Metadata-Only": true
                                    }
                                });
                                if (meta.count) {
                                    clients.push(client)
                                }
                            }
                        )
                    );
                    this.setState({ clients });
                }
                catch (e) {
                    message.error(e.message);
                    console.error(e);
                    Sentry.captureException(e);
                    return;
                }
                break;
        }
        this.setState({
            page,
            isLoadingOrders: false,
        });
        window.scroll(0, 0);
    }

    getPage(url) {
        return url.substring(url.lastIndexOf("/")+1);
    }

    getTabIndex(tabs) {
        const path = this.props.location.pathname;
        for (let i of tabs.keys()) {
            if(tabs[i].path.indexOf(path) > -1){
                return i;
            }
        }
    }


    getOrderItems() {
        const { location, clientListStore } = this.props;
        const { clients } = this.state;
        let clientField = null;
        let emptyText = "";
        let orderStatus;
        const path = this.getPage(location.pathname);
        switch(path) {
            case 'request':
                clientField = 'requests';
                emptyText = "No Requests Available";
                orderStatus = "created";
                break;
            case 'active':
                clientField = 'ordersInProgress';
                emptyText = "No orders in processing";
                orderStatus = "accepted";
                break;
            default:
                break;
        }

        if (clients.length) {
            return clients.sort((a, b) => a.name.localeCompare(b.name)).map((client) => {
                return (
                    <StepPaginator
                        key={`${client.id}-order-container-paginator`}
                        renderPaginationBar={false}
                        renderLoadingProgress={false}
                        getPage={async (page, limit) => {
                            const { business: currentBusiness } = this.props.profileStore;
                            const otherBusiness = client;
                            const query = {
                                page,
                                orderBy: {
                                    timestamp: "desc",
                                },
                                limit,
                                statusArr: {
                                    "array-contains": orderStatus
                                },
                                buyerUID: currentBusiness.isBuyer() ? currentBusiness.id : otherBusiness.id,
                                sellerUID: currentBusiness.isSeller() ? currentBusiness.id : otherBusiness.id
                            };
                            let [bundles, resp] = await OrderBundleService.getAll(query, true);
                            // Only get the orders in the order bundle that have right status, as the order bundle
                            // will be a mix of statuses
                            bundles.forEach((bundle) => bundle.orders = bundle.orders.filter((order) => order.status === orderStatus));
                            bundles = bundles.filter((bundle) => bundle.orders.length);
                            return [bundles, resp.count];
                        }}
                        limit={5}
                        renderItems={(bundles, {refresh, count, paginationBar, updateItem, removeItem, loading}) => {
                            if (!loading && !bundles.length) {
                                const currentClient = client;
                                const { clients: oldClients } = this.state;
                                const newClients = [];
                                for (const oldClient of oldClients) {
                                    if (oldClient.id !== currentClient.id) {
                                        newClients.push(oldClient);
                                    }
                                }
                                this.setState({ clients: newClients });
                                return null;
                            }
                            return (<OrderContainer
                                key={`${client.id}-order-bundles-container`}
                                business={client}
                                orderStatus={orderStatus}
                                count={count}
                                bundles={bundles}
                                path={this.state.page}
                                paginationBar={paginationBar}
                                onRefresh={refresh}
                                onEdit={updateItem}
                                onRemove={removeItem}
                                loading={loading}
                            />);
                        }}
                    />
                )
            });
        } else {
            return (
                <div className="empty-text">
                    <Typography variant="title" className="text">
                        {emptyText}
                    </Typography>
                </div>
            )
        }
    }


    handleSpreadsheetButtonClick = (e) => {
        this.setState({ spreadsheetModalOpen: true });
    };


    render() {
        const { isLoadingOrders, spreadsheetModalOpen } = this.state
        const mobileNavButtons = [
            {
                text: null
            },
            {
                text: "Spreadsheet",
                icon: (<Icon>get_app</Icon>),
                onClick: this.handleSpreadsheetButtonClick,
            },
        ];



        const pageInfo = [
            {
                title: 'Requests',
                sub: 'View the status of your requests'
            },
            {
                title: 'In Progress',
                sub: 'Track orders en route for delivery'
            },
            {
                title: 'Order Summary',
                sub: 'Overview of all current and past orders'
            },
        ]
        const tabs = [
            {
                name: `Requests`,
                child: false,
                path: '/request'
            },
            {
                name: `In Progress`,
                child: false,
                path: '/active'
            },
            {
                name: `Summary`,
                child: false,
                path: '/history'
            },
        ];
        const tabIndex = this.getTabIndex(tabs)
        return (
            <MuiThemeProvider>
                <Fragment>
                    <Menu />
                    <Topbar
                        title={pageInfo[tabIndex].title}
                        tabs={tabs} sub={pageInfo[tabIndex].sub}
                        tabIndex={tabIndex}
                        isChildPath={false}
                        mobilft={true}
                        topBarButton={
                            <DownloadSpreadsheetButton
                                onClick={this.handleSpreadsheetButtonClick}
                            />
                        }
                    />
                    <Bottombar buttons={mobileNavButtons}/>
                    <div className="request-container">
                        {
                            isLoadingOrders ?
                                <LoadingSpinnerPage />
                                :
                                <Fragment>
                                    <ul className="request-list">
                                        {this.getOrderItems()}
                                    </ul>
                                </Fragment>
                        }
                        {/* {
              this.state.total > 1 &&
                <Pagination
                  className="pagination"
                  total = { this.state.total }
                  current = { this.state.number }
                  display = { this.state.display }
                  onChange = { number => this.setState({ number }) }
                />
            } */}
                    </div>
                    {spreadsheetModalOpen && <SpreadsheetDownloadModal
                        open={spreadsheetModalOpen}
                        onClose={() => this.setState({ spreadsheetModalOpen: false })}
                    />}
                </Fragment>
            </MuiThemeProvider>
        )
    }
}

export default withRouter(ViewOrders);
