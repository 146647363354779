import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import WelcomeScreen from '../../components/organisms/WelcomeScreen'
import { UserService } from "core/api"
import DatabaseClient from '../../core/DatabaseClient'
import { message } from 'antd'
import { withRouter } from 'react-router-dom';

@inject('userStore' )
@observer
class WelcomePage extends Component {

  onCompletion = async () => {
    try {
      if (this.props.userStore.user.status !== "active") {
          const user = await UserService.update(this.props.userStore.user.id, {
              status: "active"
          });
          await this.props.userStore.setUser(user);
      }
      this.props.history.push('/login')
    }
    catch (e) {
      console.error(e);
      Sentry.captureException(e);
      message.error('Unable to initialize user, please contact customer support for assistance')
    }
  }

  render() {
      return (
          this.props.userStore.user.isVerified() ?
              <WelcomeScreen
                  data={
                    [
                      {
                        "backgroundColor": "whitesmoke",
                        "textColor": "#344115",
                        "text": "You're now ready to use Freshspire!  Click start to begin!",
                        "imageAnimation": "fadeIn",
                        "textAnimation": "fadeIn",
                        "image": require('../../assets/logo.png'),
                        size: "small",
                        showActionButton: true,
                        showLogoutButton: true
                      }
                    ]
                  }
                  onFinish={ async () => {
                    await this.onCompletion();
                    this.props.history.push('/login');
                  }}
              />
              :
			  <WelcomeScreen
				data={
					[
					  {
						"backgroundColor": "whitesmoke",
						"textColor": "#344115",
						"text": "Thank you for completing the survey.  Your account is in the process of being verified.  Check back in 24 hours to begin using Freshspire!",
						"imageAnimation": "fadeInUp",
						"textAnimation": "fadeInLeft",
						"image": require('../../assets/logo.png'),
						size: "small",
						showLogoutButton: true
					  }
					]
				}
			/>
    )
  }
}

export default withRouter(WelcomePage)
