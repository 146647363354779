import { observable, toJS } from 'mobx'
import Order from "./Order";
import { unmarshal } from "core/utilities/ModelUtils";

class OrderBundle {
    @observable buyer
    @observable buyerUID
    @observable id
    @observable orders
    @observable seller
    @observable sellerUID
    @observable timestamp
    @observable totalCost
    @observable notes

    constructor(buyer = null, buyerUID = null, id = null, orders = [], seller = null, sellerUID = null, timestamp = 0, totalCost = 0, notes = "") {
        this.buyer = buyer;
        this.buyerUID = buyerUID;
        this.id = id;
        this.orders = orders;
        this.seller = seller;
        this.sellerUID = sellerUID;
        this.timestamp = timestamp;
        this.totalCost = totalCost;
        this.notes = notes;
    }

    formatForDB() {
        let data = observable({
            buyerUID: this.buyerUID,
            orders: this.orders,
            sellerUID: this.sellerUID,
            timestamp: this.timestamp,
            totalCost: this.totalCost,
            notes: this.notes
        });
        return toJS(data);
    }
}

OrderBundle.displayName = "OrderBundle";
export default OrderBundle;
