import React, { Fragment, Component } from 'react';
import Menu from '../../components/Menu';
import Topbar from '../../components/CommonTopBar';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {LoadingSpinnerPage} from '../../components/LoadingSpinner';
import styled from 'styled-components';
import EditableLabel from '../../components/molecules/EditableLabel';
import BusinessItem from '../../components/molecules/BusinessItem/BaseComp';
import Placeholder from '../../components/molecules/Placeholder';
import { User } from '../../core/model';
import DatabaseClient from '../../core/DatabaseClient';
import AuthManager from '../../core/AuthManager';
import EditablePhoneLabel from '../../components/molecules/EditablePhoneLabel';
import { observer, inject } from 'mobx-react';
import PasswordResetButton from '../../components/atomic/buttons/PasswordResetButton';
import { withRouter } from 'react-router-dom'
import { message } from 'antd';
import PageTemplate from '../../components/templates/PageTemplate';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StoreList = styled.ul`
  flex: 1;
  // padding: 10px 30px;
  padding-bottom: 60px;
`;

const OrganizationHeader = styled.div`
  font-size: 2.2em;
  font-family: "MuseoSans700";
  padding-left: 30px;
  margin-right: 20px;
`;

const BusinessItemStyled = styled(BusinessItem)`
`;

const PlaceholderStyled = styled(Placeholder)`
  
`;

const tabs = [
  {
    name: 'Store List',
    child: false,
    path: '/management'
  },
];

@inject('userStore', 'profileStore', 'managementStore')
@observer
class Management extends Component {
  state = {
    loading: true,
    bizIDs: [],
  }

  async componentWillMount() {
    const { profileStore, managementStore, userStore, location } = this.props;
    try { 
      const orgID = this.getProfileUID(location.pathname);
      if(orgID) {
        await managementStore.getOrganizationInfo(orgID);
      } else {
        history.goBack();
      }
    }
    catch (e) {
      console.error(e);
			message.error(e.message);
			Sentry.captureException(e);
    }
    const bizIDs = Object.keys(userStore.user.businesses);
    
    bizIDs.push(profileStore.business.id);
    this.setState({loading: false, bizIDs});
  }

  getProfileUID(url) {
		return url.substring(url.lastIndexOf("organization")+13);
	}

  getButtons = (store) => {
    return [
      {
        text: 'Profile',
        onClick: (() => null),
        path: `/profile/${store.id}`,
        color: 'primary',
        mobile: false
      },
    ]
  }

  getBusinessItems = () => {
    const { managementStore } = this.props;
    const businesses = managementStore.orgBusinesses;
    if(businesses.length === 0) {
      return (
        <PlaceholderStyled
          title="No Stores Found"
          icon="store"
          subtext={[
            'To add an item to your storefront',
            'Click below'
          ]}
        />
      )
    }
    return businesses.map((item,i) => (
      <BusinessItemStyled
        key={item.id}
				// onClick={this.handleCardClick}
				picture={item.picture}
				heading={item.name}
				subheading={`${item.streetAddress} ${item.city}${item.id? ',':''} ${item.state} ${item.zipcode}`}
				checked={false}
				chips={item.foodOptions}
				uid={null}
				buttons={this.getButtons(item)}
				showActionButtons={true}
      />
    ))
  }

  render() {
    const { managementStore } = this.props;
    const { loading } = this.state;
    
    return (
      <PageTemplate 
        title="Organization"
        sub={`View ${managementStore.organization && managementStore.organization.name}`}
        // tabs={tabs}
        tabIndex={0}
        isChildPath={false}
        mobilft={true}>
        <Container>
          {
            loading ?
              <LoadingSpinnerPage />
              :
              <React.Fragment>
                <OrganizationHeader>
                  {`${managementStore.organization && managementStore.organization.name}'s Store List`}
                </OrganizationHeader>
                <StoreList className={`client-list lg-margin`}>
                  {this.getBusinessItems()}
                </StoreList>
              </React.Fragment>
          }
        </Container>
      </PageTemplate>
    );
  }
}

export default withRouter(Management);
