import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Topbar from '../../components/CommonTopBar';
import Menu from '../../components/Menu';
import InventorySheet from '../../components/organisms/InventorySheet';
import ImportBar from '../../components/molecules/DataSheet/importBar';

@inject('inventoryStore')
@observer
class ImportInventory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    async componentWillMount() {

    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    componentWillUnmount() {
        this.props.inventoryStore.resetErrors();
    }

    onImport = data => {
        this.setState({data})
    }

    render() {
        const { data } = this.state
        const tabs = [
			{
				name: 'Inventory',
				child: false,
				path: '/deals'
            },
            {
				name: 'Import Inventory',
				child: true,
				path: '/deals/import'
			},
        ];

        return (
            <React.Fragment>
                <Menu/>
                <Topbar title={'Import Inventory'} tabs={tabs} sub={'Import an existing inventory from a document'} tabIndex={1} isChildPath={true}/>
                <div className="deals-container">
                    <InventorySheet
                        newData={data}
                    />
                    <ImportBar
                        onImport={this.onImport}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ImportInventory)
