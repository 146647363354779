import React from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types';
import MessageContent from './MessageContent';
import TimeLabel from './TimeLabel';
import Conversation from '../../../core/model/Conversation';
import { inject, observer } from 'mobx-react';
import { PacmanLoader } from 'react-spinners';
import moment from 'moment';

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    overflow-y: auto;
    width: 100%;
`;

const MessageContentWrapper = styled.div`
    ${props => props.left ? css`margin-right: 100px;`:css`margin-left: 100px;`}
    max-width: calc(100% - 100px);
`;


const StyledMessageContent = styled(MessageContent)`
    float: ${props => props.left ? "left" : "right"};
    max-width: 100%;
`

const LoaderContainer = styled.div`
    display: block;
    height: 100px;
    width: 100%;
    text-align: center;
`;

const LoaderWrapper = styled.div`
    display: inline-block;
`;

@inject('profileStore', 'messagingStore')
@observer
class MessageView extends React.Component {
    state = {
        loadingMoreMessages: false
    }

    constructor(props) {
        super(props);
        this.container = React.createRef();
    }

    componentDidMount() {
        this.container.addEventListener('scroll', async (event) => {
            if (event.target.scrollTop === 0 && !this.state.loadingMoreMessages) {
                await this.retrieveMoreMessages();
            }
        }, false);
    }

    createLabels() {
        const messageLabels = this.marshalMessages();
        // Order of messageLabels is
        // [newest, ..., oldest]
        if (messageLabels.length === 0) {
            return [];
        }
        const labels = [];
        
        for (let i = 0; i < messageLabels.length - 1; i++) {
            // If time difference between messages is > 5 hours or timestamps are on different days, show a timestamp
            if (messageLabels[i].position === "left") {
                labels.push(
                    // A+ NAMING CONVENTIONS BELOW
                    <MessageContentWrapper left key={messageLabels[i].message.id}>
                        <StyledMessageContent
                            left
                            message={messageLabels[i].message}
                            picture={messageLabels[i].picture}
                            displayPicture={i === 0 || (messageLabels[i].senderUID !== messageLabels[i - 1].senderUID )}
                            businessName={messageLabels[i].businessName}
                            displayName={messageLabels[i].senderUID !== messageLabels[i + 1].senderUID}/> 
                    </MessageContentWrapper>);
            }
            else {
                labels.push(
                    <MessageContentWrapper right key={messageLabels[i].message.id}>
                            <StyledMessageContent
                                right
                                message={messageLabels[i].message}
                                colored
                                displayPicture={false}
                                businessName={messageLabels[i].businessName}
                                displayName={false}/>
                    </MessageContentWrapper>);
            }
            const timestamp_a = messageLabels[i].message.timestamp;
            const timestamp_b = messageLabels[i + 1].message.timestamp;
            if (timestamp_a - timestamp_b > 18000000 || moment(timestamp_a).toDate().getDate() !== moment(timestamp_b).toDate().getDate()) {
                labels.push(
                    <TimeLabel key={timestamp_a} timestamp={timestamp_a}/>
                )
            }
        }
        const lastMessageContent = messageLabels[messageLabels.length - 1];
        if (lastMessageContent.position === "left") {
            labels.push(
                // A+ NAMING CONVENTIONS BELOW
                <MessageContentWrapper left key={lastMessageContent.message.id}>
                    <StyledMessageContent
                        left
                        message={lastMessageContent.message}
                        picture={lastMessageContent.picture}
                        displayPicture={(messageLabels.length === 1 || messageLabels[messageLabels.length - 1].senderUID !== messageLabels[messageLabels.length - 2].senderUID)}
                        businessName={lastMessageContent.businessName}
                        displayName={true}
                        /> 
                </MessageContentWrapper>);
        }
        else {
            labels.push(
                <MessageContentWrapper right key={lastMessageContent.message.id}>
                        <StyledMessageContent
                            right
                            message={lastMessageContent.message}
                            colored
                            displayPicture={false}
                            businessName={lastMessageContent.businessName}
                            displayName={false}/>
                </MessageContentWrapper>);
        }
        labels.push(
            <TimeLabel key={lastMessageContent.message.timestamp} timestamp={lastMessageContent.message.timestamp}/>
        )
        
        return labels;
    }

    marshalMessages() {
        const messages = this.props.conversation.messages;
        const marshaled = [];
        for (let i = messages.length - 1; i > -1; i--) {
            const message = messages[i];
            const senderUID = message.senderUID;
            const business = this.props.conversation.businesses[senderUID];
            marshaled.push({message: message,
                position: this.props.profileStore.business.id === message.senderUID ? "right" : "left",
                picture: business ? business.picture : undefined,
                senderUID: message.senderUID,
                businessName: business ? business.name : undefined
            })
        }
        return marshaled;
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async retrieveMoreMessages() {
        this.setState({ loadingMoreMessages: true });
        await Promise.all([this.sleep(1000), this.props.conversation.retrieveMessages()]);
        this.setState({ loadingMoreMessages: false});
    }
    

    render() {
        return (
            <Container
                ref={ref => this.container = ref}>
                {this.createLabels()}
                {
                    this.state.loadingMoreMessages &&
                    <LoaderContainer>
                        <LoaderWrapper><PacmanLoader size={10} color={"#aceccf"} sizeUnit={"px"}/></LoaderWrapper>
                    </LoaderContainer>
                }
                
            </Container>
        );
    }
}

MessageView.propTypes = {
    conversation: PropTypes.instanceOf(Conversation).isRequired
}

MessageView.defaultProps = {
    messages: [{message: "Default", position: "right", picture: undefined}]
}

export default MessageView
