import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectDropdown from '../../molecules/SelectDropdown';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 769px) {
    margin-right: 15px;
  }
`

const TextLabel = styled.div`
  font-size: 12px;
  margin-bottom: -2px;
  opacity: 0.9;
`

const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 110px;
  @media (max-width: 768px) {
    width: 100px;
  }
`

const StyledFormHelper = styled(FormHelperText)`
  ${'' /* min-width: 88px; */}
`

const StyledItem = styled(MenuItem)`

`

class ColumnText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      option: ""
    }
  }

  componentDidMount() {
    const { value } = this.props;

    this.setState({ option: value });
  }

  // componentWillReceiveProps(nextProps) {
  //   const { editMode, name, index } = this.props
  //   const { option } = this.state
  //   if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
  //     this.props.onSave(index,name,option)
  //   }
  //   if(editMode && !nextProps.editMode) {
  //     this.setState({ option: nextProps.value });
  //   }
  // }

  update = option => {
    this.setState({ option });
  }

  createMenuItem = label => (
    <StyledItem value={label}>
        {label}
    </StyledItem>
  )

  createMenuItems = obj => (
    Object.values(obj).map(this.createMenuItem)
  )

  render () {
    const { value, editMode, options, updateField, style, error, className, ...other } = this.props;
    const { option } = this.state;

    return (
      <TextContainer className={className}>
        <TextLabel>
          Exchange
        </TextLabel>
        <StyledSelect
          value={value}
          onChange={(e) => updateField(e.target.value)}
          error={error}
          {...other}
        >
          {this.createMenuItems(options)}
        </StyledSelect>
        {
          error &&
          <StyledFormHelper error>This field is required</StyledFormHelper>
        }
      </TextContainer>
    )
  }
}

ColumnText.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  editMode: PropTypes.bool,
  errorText: PropTypes.string,
  allowOther: PropTypes.bool
};

ColumnText.defaultProps = {
  editMode: false,
  placeholder: 'Select',
  errorText: "This field is required",
  allowOther: false
};

export default ColumnText;