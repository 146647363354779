import React from 'react'
import styled from 'styled-components'
import GenericModal from '../../GenericModal';
import PropTypes from 'prop-types';
import ProductListingGroup from '../../../../core/model/ProductListingGroup';
import Content from './Content';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import MessageButton from '../../../atomic/buttons/MessageButton';
import MessagesModal from '../../MessagesModal';
import breakpoint from 'styled-components-breakpoint';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import { inject, observer } from 'mobx-react';
import SelectDropdown from '../../../molecules/SelectDropdown';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { message } from 'antd';


const StyledModal = styled(GenericModal)`
    width: 600px !important;
    @media (max-width: 600px) {
        width: 550px;
    }
`;

const StyledMessageButton = styled(MessageButton)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ProfileText = styled.div`
  margin: 7px auto;
  font-size: 1.1em;
  text-align: center;
`;

const SingleItem = styled.div`
  margin-right: auto;
  font-size: 17px;
  font-family: ${props => props.theme.fonts.ms_font700};
`;

const ModalTitle = styled(Typography)`
  margin: 7px auto;
  font-size: 1.4em;
  font-family: ${props => props.theme.fonts.ms_font500};
`;

const LinkStyled = styled(Link)`
  color: ${props => props.theme.colors.spearmint_main};
  text-decoration: none;
  text-decoration-color: ${props => props.theme.colors.spearmint_main};
  &:hover {
      text-decoration: underline;
  }
`;

const IconStyled = styled(OpenInNew)`
    font-size: 10px;
    margin-bottom: 5px;
`;

// deal-display
const Display = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  ${breakpoint('mobile_min', 'tablet_max')`
    width: 100%;
    padding-top: 0;
  `}
`

// img-crop
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 220px;
  max-width: 220px;
  height: 50vw;
  width: 50vw;
  margin: 0 auto !important;
  overflow: hidden;
`

//modal-img
const Image = styled.img`
  max-width: 10%;
  max-height: 10%;
  transform: scale(10);
  border-radius: 1.5px;
`

//info
const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-left: auto;
  ${breakpoint('mobile_min', 'tablet_max')`
    width: 100%;
    padding-top: 50px;
  `}
`

const ChipWrapper = styled.div`
    display: flex;
    justify-content: center;
    .chip {
        margin: 0 0.5rem;
    }
`;

const StyledChip = styled(Chip)`
    margin: 0 0.5rem;
`;

const DropdownContainer = styled.div`
    ${props => props.hidden && `display: none;`}
`;

@inject('basketStore', 'profileStore')
@observer
class ProductListingGroupModal extends React.Component {
    state = {
        messageModalOpen: false,
        selectedProductListingID: null
    }

    componentWillMount() {
        let listingID = null;
        if (this.props.defaultListingID) {
            // Verify it is in the array
            if (this.props.productListingGroup.productListings.map((listing) => listing.id).includes(this.props.defaultListingID)) {
                listingID = this.props.defaultListingID;
            }
        }
        this.setState({ selectedProductListingID: listingID });
    }

    onSelectListing = (id) => {
        this.setState({ selectedProductListingID: id });
    }

    /**
     * Don't reuse this method since it will throw an error
     * Throws it on purpose to be caught by GenericModal
     */
    handleAddToBasket = async () => {
        const productListing = this.getSelectedProductListing();
        if (productListing === null) return;
        if (this.props.basketStore.isInBasket(productListing.id)) {
            return;
        }
        try {
            await this.props.basketStore.addToBasket(productListing.id);
            message.success(`${productListing.product.name} has been added to your basket!`);
        }
        catch (e) {
            message.error(e.message);
            console.error(e);
            Sentry.captureException(e);
            throw e;
        }
    }

    getListingSelector = () => {
        const labelValues = {};
        this.props.productListingGroup.productListings.forEach((pl) => {
            if(pl.getBaseQuantity() > 0 || pl.getBaseQuantity() === "Unlimited") {
                labelValues[pl.id] = {
                    label: pl.getDisplayPrice(true),
                    value: pl.id
                }
            }
        })

        if (productListings.length === 1 ) {
            const key = Object.keys(labelValues)[0];
            return <SingleItem>{labelValues[key].label}</SingleItem>
          }

        return (
            <DropdownContainer hidden={this.props.hideSelector}>
                <SelectDropdown autoSelect={!this.state.selectedProductListingID} defaultValue={this.state.selectedProductListingID} showNone={false} items={labelValues} onChange={this.onSelectListing}/>
            </DropdownContainer>
        )
    }

    getSelectedProductListing = () => {
        for (let i = 0; i < this.props.productListingGroup.productListings.length; i++) {
            const pl = this.props.productListingGroup.productListings[i];
            if (pl.id === this.state.selectedProductListingID) {
                return pl;
            }
        }
        return null;
    }

    render() {
        const { profileStore, productOwner } = this.props;
        const productListingGroup = this.props.productListingGroup;
        const product = productListingGroup.product;
        const basket = this.props.basketStore.basket;
 
        //const inBasket = this.props.basketStore.isInBasket(this.state.selectedProductListingID);
        const showSubmitButton = this.props.type.match(/(basket|market|profile)(?!_restricted)/);
        let selectedPL = this.getSelectedProductListing();

        // Don't show the submit button if restricted view or if selected listing is already in the basket
        let inBasket = false;
        if (selectedPL) {
            basket.forEach((basketItem) => {
                if (basketItem.productListing.id === selectedPL.id) {
                    inBasket = true;
                    return;
                }
            });
        }
        return (
            <React.Fragment>
                <StyledModal {...this.props} showSubmitButton={showSubmitButton && !productOwner} disabled={inBasket} submitText={inBasket ? "In Basket" : "Add to basket"} submitIcon={<ShoppingBasket/>} onSubmit={this.handleAddToBasket} throwErrorOnSubmit={true}>
                    {//TODO: restricted
                    //<div className={`modal-container ${this.props.type.match(/(basket|market|profile)(?!_restricted)/)? '': 'rounded'}`}>
                    }
                    <Display>
                        <ImageContainer>
                            <Image src={product.getPictureSrc()} alt={product.name}/>
                        </ImageContainer>
                        <LinkStyled to={product.seller.getProfileLink()}>
                            <ProfileText>
                                {product.seller.name}&nbsp;<IconStyled/>
                            </ProfileText>
                        </LinkStyled>
                        <ModalTitle>
                            {product.name}
                        </ModalTitle>
                        <ChipWrapper>
                            <StyledChip label={product.foodCategory}/>
                        </ChipWrapper>
                        {/* {
                        this.state.type.match(/(basket|market|profile)(?!_restricted)/)? (
                            <ModalCartController dealInfo={dealInfo}  requestInfo={requestInfo} ref={(instance) => { this.controller = instance }}/>
                        )
                        :
                        ''
                        } */}
                        {profileStore.business.id !== product.uid && <StyledMessageButton variant={"outlined"} onClick={() => this.setState({ messageModalOpen: true })}/>}
                    </Display>
                    <Info>
                        {selectedPL && <Content
                            productListingGroup={productListingGroup}
                            productListing={selectedPL}
                            type={this.props.type}
                            onSelectListing={this.selectListing}
                            restricted={!showSubmitButton}
                            quantity={this.props.quantity}
                            hideSelector={this.props.hideSelector}
                            autoSelect={!this.state.selectedProductListingID}
                            onSelectListing={this.onSelectListing}
                            defaultValue={this.state.selectedProductListingID}
                        />}
                    </Info>
                    {//</div>
                    }
                </StyledModal>
                {this.state.messageModalOpen && <MessagesModal business={product.seller} open={this.state.messageModalOpen} onClose={() => this.setState({ messageModalOpen: false })}/>}
            </React.Fragment>
        );
    }
}

ProductListingGroupModal.propTypes = {
    defaultListingID: PropTypes.string,
    productListingGroup: PropTypes.instanceOf(ProductListingGroup).isRequired,
    hideSelector: PropTypes.bool,
    restricted: PropTypes.bool,
    quantity: PropTypes.number,
    type: PropTypes.any.isRequired
}

ProductListingGroupModal.defaultProps = {
    restricted: false,
    hideSelector: false,
    productOwner: false,
    type: 'basket'
}

export default ProductListingGroupModal;
