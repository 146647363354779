import React, { Component } from 'react'
import MUIIconButton from '@material-ui/core/IconButton';
import { LoadingSpinnerButton } from '../../LoadingSpinner'

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.state = { pending: false };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onClick = async (...args) => {
        if (this.props.onClick) {
            try {
                this.setState({ pending: true });
                //await this.sleep(1000);
                await this.props.onClick(...args);
            }
            finally {
                // This conditional is here to avoid memory leaks
                // in case the component is unmounted from the onClick
                // operation above
                if (this._isMounted) {
                    this.setState({ pending: false });
                }
            }
        }
    }

    render() {
        let {onClick, disabled = false, ...other} = this.props;
        return (
            <MUIIconButton disabled={this.state.pending || disabled} onClick={this.onClick} {...other}>
                    {(this.state.pending ? LoadingSpinnerButton() : this.props.children)}
            </MUIIconButton>
        );
        /*
        // Modify onClick
        if (onClick) {
            let newOnClick = async (...args) => {
                try {
                    console.log('Pending set')
                    this.setState({pending: true});
                    console.log('Done pending set')
                    //await this.sleep(1000);
                    await onClick(...args);
                }
                finally {
                    console.log('Mounted state: ', this.state.mounted);
                    if (this.state.mounted) {
                        console.log('Pending set to false')
                        this.setState({pending: false});
                        console.log('Done pending set to false');
                    }
                }
            }
            return (
                <MUIIconButton disabled={this.state.pending || disabled} onClick={newOnClick} {...other}>
                    {(this.state.pending ? LoadingSpinnerButton() : this.props.children)}
                </MUIIconButton>
            );
        }
        else {
            return (
                <MUIIconButton {...other}>
                    {this.props.children}
                </MUIIconButton>
            );
        }
        */
    }
}