import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import PageTemplate from '../../components/templates/PageTemplate';

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
`;


@inject('profileStore')
@observer
class DataPage extends Component {

    componentWillMount() {
        if (!this.props.profileStore.business.airtable) {
            this.props.history.push("/login")
        }
    }

    getTable = () => {
        return (<iframe class="airtable-embed" src={this.props.profileStore.business.airtable} frameborder="0" onmousewheel="" width="100%" height="100%" style={{"background": "transparent", "border": "1px solid #ccc"}}></iframe>);
    };

    render() {
		return (
            <PageTemplate 
                title="Data"
                sub="View data about your local sourcing efforts."
                tabIndex={0}
                isChildPath={false}
                mobilft={true} >
				<Container>
                    {this.getTable()}
				</Container>
            </PageTemplate>
		);
    }
}

export default withRouter(DataPage);
