import React, { Component } from 'react'
import styled, { css, withTheme } from 'styled-components'
import PropTypes from 'prop-types';
import ListItem from './ListItem';
import Header from './Header';
import List from '@material-ui/core/List';

const ListContainer = styled(List)`

`;

class CertificationList extends Component {

  createItems = () => {
    const { data, onItemClick, onItemEdit, onItemDelete, editMode, deletions = [] } = this.props

    return data.map((item)=>{
      return (
        <ListItem
          data={item}
          crossOut={deletions.includes(item.id)}
          onItemClick={onItemClick}
          onItemEdit={onItemEdit}
          onItemDelete={onItemDelete}
          editMode={editMode}
        />
      )
    })
  }

  render() {
    const { header } = this.props

    return (
      <ListContainer
        subheader={header?<Header text={header}/>:null}
      >
        {this.createItems()}
      </ListContainer>
    )
  }

}

CertificationList.propTypes = {
  data: PropTypes.array.isRequired,
  editMode: PropTypes.bool.isRequired,
  deletions: PropTypes.array.isRequired,
  header: PropTypes.string,
  onItemClick: PropTypes.func,
  onItemEdit: PropTypes.func,
  onItemDelete: PropTypes.func,
}

export default CertificationList;