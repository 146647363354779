import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import Icon from '@material-ui/core/Icon';

const StyledButton = styled(Button)`
    text-transform: none;
`;

const StyledIcon = styled(Icon)`
`;

const Text = styled.span`
    margin-right: 5px;
`

class UploadButton extends React.Component {
    render() {
        return (
            <StyledButton className={this.props.className} variant={"contained"} color={"primary"} onClick={this.props.onClick} disabled={this.props.disabled}>
                {this.props.text && (<Text>{this.props.text}</Text>)}
                <StyledIcon>attach_file</StyledIcon>
            </StyledButton>
        );
    }
}

UploadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string,
    disabled: PropTypes.bool
};

UploadButton.defaultProps = {
    text: '',
    disabled: false
}

export default UploadButton;