import { FreshspireClient, createSubscription, endSubscription } from '../clients';
import { unmarshal } from '../utilities/ModelUtils';
import { isObservableArray } from "mobx";

class Service {
	constructor(url, model=null, nestedModels, client=FreshspireClient) {
		this.model = model;
		this.client = client;
		this.url = url;
		this.nestedModels = nestedModels;
	}

	parse(res) {
		const payload = res.data;
		// console.log("Parsing ", payload);
		if (payload.error) {
			throw res.error;
		}

		const data = payload.data;
		if (!data) {
			return null;
		} else if ((Array.isArray(data) || isObservableArray(data)) && !data.length) {
			return [];
		}
		return this.deserialize(data);
	}

	deserialize(data) {
		// Cast to a model class if applicable
		let deserializedData;
		if (this.model) {
			if (Array.isArray(data) || isObservableArray(data)) {
				deserializedData = data.map((item) => item && unmarshal(item, this.model));
				deserializedData = deserializedData.filter((item) => item != null);
			}
			else {
				if (!data) {
					return null;
				}
				deserializedData = unmarshal(data, this.model);
			}
			if (this.nestedModels && Object.keys(this.nestedModels).length) {
				return this.deserializeNestedObjects(deserializedData);
			} else {
				return deserializedData;
			}
		}
		else {
			return data;
		}
	}

	async deserializeNestedObjects(data) {
		if (Array.isArray(data) || isObservableArray(data)) {
			return Promise.all(data.map(async (item) => this._deserializeNestedObjects(item)))
		} else {
			return this._deserializeNestedObjects(data);
		}
	}

	async _deserializeNestedObjects(data) {
		await Promise.all(Object.entries(this.nestedModels).map(async ([field, service]) => {
			if (data && data[field]) {
				data[field] = await service.deserialize(data[field]);
			}
		}));
		return data;
	}

	handleError(e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw e;
	}

	async create(data) {
		try {
			const resp = await this.client.post(`${this.url}`, data);
			return this.parse(resp);
		}
		catch (e) {
		    this.handleError(e)
		}
	}

	async delete(id) {
		if (!id) {
			return null;
		}
	    try {
			const resp = await this.client.delete(`${this.url}/${id}/`);
			return this.parse(resp);
		}
		catch (e) {
	        this.handleError(e);
		}
	}

	async update(id, data) {
		if (!id) {
			return null;
		}
	    try {
			const resp = await this.client.patch(`${this.url}/${id}/`, data);
			return this.parse(resp);
		}
		catch (e) {
	        this.handleError(e);
		}
	}

	async get(id) {
		if (!id) {
			return null;
		}
	    try {
			const resp = await this.client.get(`${this.url}/${id}/`)
			return this.parse(resp);
		}
		catch (e) {
			this.handleError(e);
		}
	}

	// TODO: getAll, create, etc...

	async getAll(query, returnMeta = false, axiosOptions={}) {
	    try {
			// Prefer empty arrays to null data responses
			const resp = await this.client.get(`${this.url}`, Object.assign({ params: query }, axiosOptions));
			const parsedData = (await this.parse(resp)) || [];
			if (returnMeta) {
				return [parsedData, resp.data || {}]
			} else {
				return parsedData;
			}
		}
		catch (e) {
	        this.handleError(e)
		}
	}

	subscribe(id, cb) {
		// Need to use displayName instead of name b/c class name mangling may take place during bundling
		createSubscription({
			resource: this.model.displayName,
			id
		}, async (data) => {
		    return cb(await this.deserialize(data));
		});
	}

	unsubscribe(id) {
		endSubscription({
			resource: this.model.displayName,
			id
		});
	}
}

export default Service;
