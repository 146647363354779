import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';
import SelectDropdown from '../../../molecules/SelectDropdown';

const Container = styled.div`
`;

const SingleItem = styled.div`
  margin-right: auto;
  font-size: 17px;
  font-family: ${props => props.theme.fonts.ms_font700};
`;

class UnitsController extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  getListingSelector = () => {
    const { productListings, onChange, restricted } = this.props
    const labelValues = {};
    // console.log("THE PRODUCT LISTINGS ARE", productListings)
    productListings.forEach((pl) => {
        labelValues[pl.id] = {
          label: restricted ? pl.unit : pl.getDisplayPrice(true),
          value: pl.id
        }
    });

    if ( productListings.length === 1 ) {
      const key = Object.keys(labelValues)[0];
      return <SingleItem>{labelValues[key].label}</SingleItem>
    }

    return <SelectDropdown formError={false} autoSelect fullWidth showNone={false} items={labelValues} onChange={onChange}/>
}

  render() {
    const { ...other } = this.props
    return (
      <Container {...other}>
        {this.getListingSelector()}
      </Container>
    )
  }

}

UnitsController.propTypes = {
  productListings: PropTypes.object,
  onChange: PropTypes.func,
}

export default UnitsController;
