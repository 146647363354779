import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DateInput from '../../atomic/inputs/DateInput';
import LabeledSwitch from '../../../components/LabeledSwitch';
import QuantityInput from '../../atomic/inputs/QuantityInput';
import Dropdown from '../../atomic/selectors/Dropdown';
import Button from '../../atomic/buttons/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import TotalDisplay from '../ProductList/TotalDisplay'

const ButtonStyled = styled(Button)`
  max-height: 30px;
  min-height: 30px;
  padding: 0px 15px;
  text-transform: none;
  @media (max-width: 768px) {
  }
`

const CheckoutButtonStyled = styled(Button)`
  margin-left: auto;
  max-height: 30px;
  min-height: 30px;
  padding: 0px 15px;
  text-transform: none;
  @media (max-width: 550px) {
    display: none;
  }
`

const BarContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 2px 6px 5px -1px rgba(0,0,0,0.2);

  @media (max-width: 550px) {
    border-radius: 0;
    z-index: 2; 
    position: fixed;
    margin: 0;
    margin-top: -12px;
    width: 100%;
    box-shadow: 2px 6px 5px -1px rgba(0,0,0,0.2);
  }
`

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: auto;
    justify-content: space-between; 
  }
`

const TextContainer = styled.div`
  font-size: 16px;
  @media (max-width: 550px) {  
    font-size: 14px;
  }
`

const ButtonText = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`

const DeleteStyled = styled(DeleteIcon)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const TopSection = styled(ListItem)`
  @media (max-width: 550px) {
    padding: 0;
  }
`

const BottomSection = styled(ListItem)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  border-top: 1px solid #eee;
  @media (max-width: 550px) {
    padding-left: 0px;
    padding-right: 0px;
    transform: scale(0.9);
  }
`

const DropdownStyled = styled(Dropdown)`
  margin: auto;
  @media (min-width: 769px) {
    margin-right: 15px;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
  @media (max-width: 768px) {
    flex: 1;
    ${'' /* max-width: 500px; */}
    margin-right: auto;
    justify-content: space-between; 
  }
`

class SelectionBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      date: null,
      exchange: null,
    }
  }

  updateState = (value,type) => {
    const { onValueChange } = this.props
    switch(type) {
      case 'exchange':
        this.setState({exchange: value});
        onValueChange(value,'exchange');
        break;
      case 'date':
        this.setState({date: value});
        onValueChange(value,'date');
        break;
      default:
        break;
    }
  }

  render() {
    const { 
      onSelected,
      onValueChange,
      onDeleteSelected,
      sortSeller,
      checked,
      onSwitch,
      numSelected,
      total,
      request,
      onRequest,
      indeterminate
    } = this.props;

    const {
      exchange,
      date
    } = this.state;

    return (
      <BarContainer>
        <TopSection>
          <TotalDisplay
            cost={total}
            requesting={request}
            checked={checked}
            indeterminate={indeterminate}
            onSelected={(val) => onSelected(null,val)}
          />
          <LabeledSwitch
						checked={sortSeller}
						onChange={onSwitch}
						value="sortSeller"
					/>
          <CheckoutButtonStyled variant='contained' color='primary' onClick={onRequest}>
            Request
          </CheckoutButtonStyled>
        </TopSection>
        {
          (numSelected > 0) &&
          <BottomSection>
            <ActionContainer>
              <TextContainer>{`${numSelected} item(s) selected`}</TextContainer>
            </ActionContainer>
            <InputContainer>
              <DateInput
                value={date}
                updateField={val => this.updateState(val,'date')}
              />
              <DropdownStyled
                options={['delivery','pickup']}
                value={exchange}
                updateField={val => this.updateState(val,'exchange')}
              />
            </InputContainer>
            <ButtonStyled variant='outlined' onClick={onDeleteSelected} color='secondary'>
              <DeleteStyled fontSize="small" color='secondary'/>
              <ButtonText>
                Delete Selected
              </ButtonText>
            </ButtonStyled>
          </BottomSection>
        }
      </BarContainer>
    )
  }
}

SelectionBar.propTypes = {
  onSelected: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  total: PropTypes.number,  
};

SelectionBar.defaultProps = {
  checked: false,
  total: 0,
};

export default SelectionBar;