import React, { Component } from 'react'
import Button from '../../atomic/buttons/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

const StyledButton = styled(Button)`
  min-height: 28px;
  max-height: 28px;
  padding: 0px 16px;
  margin-left: 16px;
  @media (max-width: 550px) {
    margin-left: 7px;
  }
  text-transform: none;
`

const StyledIcon = styled(Icon)`
  margin-left: 4px;
`

const Container = styled.div`
  display: flex;
  background-color: #fff;
  margin-bottom: -13px;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 10px;
`

class ActionToolBar extends Component {
  render () {
    const { editAll, saveAll, cancelAll, editMode } = this.props
    return (
      <Container>
        <StyledButton id="sheet_edit_all" variant="outlined" onClick={editAll}>
          Edit All
        </StyledButton>
        {
          editMode &&
          <React.Fragment>
            <StyledButton id="sheet_save_all" variant="outlined" onClick={saveAll}>
              Save All
            </StyledButton>
            <StyledButton id="sheet_cancel_all" variant="outlined" onClick={cancelAll}>
              Cancel All
            </StyledButton> 
          </React.Fragment>
        }
      </Container>
    )
  }
}

ActionToolBar.propTypes = {
  editAll: PropTypes.func.isRequired,
  saveAll: PropTypes.func.isRequired,
  cancelAll: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};

export default ActionToolBar