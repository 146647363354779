import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IntlCurrencyInput from "../../atomic/inputs/CurrencyInput/IntlCurrencyInput";
import FormHelperText from '@material-ui/core/FormHelperText';

const StyledTextField = styled(TextField)`
  width: 80px;
`

const StyledFormHelper = styled(FormHelperText)`
  min-width: 115px;
`

var intlInputRef;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (

    <IntlCurrencyInput
        {...other}
        ref={(ref) => intlInputRef = ref} //inputRef
        id="price"
        name="price"
        currency="USD"
        onChange={(event, value, maskedValue) => {
            onChange({
              target: {
                value: value,
              },
            });
        }}
        config={{
            locale: "US",
            formats: {
                number: {
                    USD: {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    },
                },
            },
        }}
    /> 
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class ColumnPrice extends Component {
  constructor (props) {
    super(props)
    this.state = {
      price: 5
    }
  }

  componentWillMount() {
    const { value } = this.props;

    this.setState({ price: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { price } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,price)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ price: nextProps.value });
    }
  }

  update = price => {
    this.setState({ price });
  }

  render () {
    const { editMode, value, error, ...other } = this.props;
    const { price } = this.state;
    return editMode ? (
      <React.Fragment>
        <StyledTextField
          defaultValue={parseFloat(price)}
          value={price}
          onChange={(e) => this.update(e.target.value)}
          error={error}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          {...other}
        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </React.Fragment>
    ) : (
      <React.Fragment>
        ${value.toFixed(2)}
      </React.Fragment>
    )
  }
}

ColumnPrice.propTypes = {
  value: PropTypes.number,
  editMode: PropTypes.bool,
};

ColumnPrice.defaultProps = {
  editMode: false,
  value: 0,
};

export default ColumnPrice;