import firebase from 'firebase';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';

const stagingConfig = {
    apiKey: "AIzaSyAeLr3EbmZnTFqKJ7dVeIFgehHodaHh4sg",
    authDomain: "freshspire-staging.firebaseapp.com",
    databaseURL: "https://freshspire-staging.firebaseio.com",
    projectId: "freshspire-staging",
    storageBucket: "freshspire-staging.appspot.com",
    messagingSenderId: "463174640643"
}

const testConfig = {
  apiKey: "AIzaSyB_3lvZ6NnAaIGCUmXDTlLytw0hq81IH1s",
  authDomain: "freshspire-testing.firebaseapp.com",
  databaseURL: "https://freshspire-testing.firebaseio.com",
  projectId: "freshspire-testing",
  storageBucket: "freshspire-testing.appspot.com",
  messagingSenderId: "4008422115"
}

const staging2Config = {
  apiKey: "AIzaSyDtKTxbqjVKMHqUzlb2F9ZqdDj3l8XDwUI",
  authDomain: "freshspire-mcsimps2.firebaseapp.com",
  databaseURL: "https://freshspire-mcsimps2.firebaseio.com",
  projectId: "freshspire-mcsimps2",
  storageBucket: "freshspire-mcsimps2.appspot.com",
  messagingSenderId: "4008422115"
}

const config = {apiKey: "AIzaSyCZl9dol4d6nscpSYyf62ONalvY8hGun08", authDomain: "getfreshspired.firebaseapp.com", databaseURL: "https://getfreshspired.firebaseio.com", projectId: "getfreshspired", storageBucket: "getfreshspired.appspot.com", messagingSenderId: "135668915917"}

//if (!firebase.apps.length) {
const fb =firebase.initializeApp(config);
//}
const auth = fb.auth();
//auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
const storage = fb.storage();
const db = fb.firestore();
const settings = {timestampsInSnapshots: true};
db.settings(settings);
const functions = fb.functions();

export {
  auth,
  storage,
  db,
  functions,
};
