import React from 'react'
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types';
import { Conversation } from '../../../core/model';
import MessageView from './MessageView';
import MessageSender from './MessageSender';
import ConversationTopbar from './ConversationTopbar';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

const StyledConversationTopbar = styled(ConversationTopbar)`

`;

class ConversationView extends React.Component {

    onSend = async (msg) => {
        await this.props.conversation.sendMessage(msg);
    }

    render() {
        return (
            <Container className={this.props.className}>
                <StyledConversationTopbar businesses={this.props.conversation.businesses}/>
                <MessageView conversation={this.props.conversation} messages={this.props.conversation.messages}/>
                <MessageSender onClick={this.onSend}/>
            </Container>
        );
    }
}

ConversationView.propTypes = {
    conversation: PropTypes.instanceOf(Conversation)
}

export default ConversationView