import React from 'react'
import ReactDOM from 'react-dom'
import {
  HashRouter as Router,
  Redirect,
  Route,
  BrowserRouter,
  Switch
} from 'react-router-dom'
import { Provider } from 'mobx-react'
import App from './App'
import * as stores from './stores'

import './styles/index.css'
import * as serviceWorker from './core/registerServiceWorker'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const sizes = {
  xs: 425,
  sm: 768,
  md: 960,
  lg: 1280,
  xl: 1920
}

export const theme = {
  breakpoints: { 
    mobile_min: 0,
    mobile_max: sizes.xs,
    tablet_min: sizes.sm,
    tablet_max: sizes.md,
    laptop_min: sizes.md,
    laptop_max: sizes.lg,
    desktop_min: sizes.lg,
    desktop_max: sizes.xl,
    sidebar_fixed_max: 1024,
    sidebar_fixed_min: 550,
    sidebar_off_max: 549,
    sidebar_open_width: 250,
    sidebar_closed_width: 50
  },
  colors: {
    spearmint_dark: "#3dd28f",
    spearmint_main: '#51d79a',
    spearmint_light: "#64dba5",

    opposite_dark: "#d2453d",
    opposite_main: "#d75751",
    opposide_light: "#db6a64",

    grey_dark: "#e9e9e9",
    grey_light: "#f2f3f3",

    charcoal_dark: "#2b2b2b",
    charcoal_light: "#404041"
  },
  fonts: {
    ms_font100: "MyFont100",
    ms_font300: "MuseoSans300",
    ms_font300Italic: "MuseoSans300Italic",
    ms_font500: "MuseoSans500",
    ms_font700: "MuseoSans700"
  },
  mixins: {
    flexScale: (min, max) => `
      @media (min-width: #{${min}}) and (max-width: #{${max}}) {
        @content;
      }
    `,
    sidebar: () => `
      margin-left: ${theme.breakpoints.sidebar_open_width}px;
      @media (min-width: ${theme.breakpoints.sidebar_fixed_min}px) and (max-width: ${theme.breakpoints.sidebar_fixed_max}px) {
        margin-left: ${theme.breakpoints.sidebar_closed_width}px;
      }
      @media (min-width: 0px) and (max-width: ${theme.breakpoints.sidebar_fixed_min - 1}px) {
        margin-left: 0;
      }
    `
  }
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'MyFont100';
    src: local('MyFont'), url('/fonts/MuseoSans-100.woff') format('woff');
  }

  @font-face {
    font-family: 'MuseoSans300';
    src: local('MuseoSans300'), url('/fonts/MuseoSans-300.woff') format('woff');
  }

  @font-face {
    font-family: 'MuseoSans300Italic';
    src: local('MuseoSans300Italic'), url('/fonts/MuseoSans-300Italic.woff') format('woff');
  }

  @font-face {
    font-family: 'MuseoSans500';
    src: local('MuseoSans500'), url('/fonts/MuseoSans-500.woff') format('woff');
  }

  @font-face {
    font-family: 'MuseoSans700';
    src: local('MuseoSans700'), url('/fonts/MuseoSans-700.woff') format('woff');
  }
`

ReactDOM.render(
  <Provider 
    {...stores}
  >
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
, document.getElementById('root'))

const onUpdate = () => {
  stores.notificationStore.updateAvailable = true;
}

serviceWorker.unregister(); // TODO: fix service worker
//registerServiceWorker(onUpdate)