import Service from './Service';
import Label from "core/model/Label";

const URL = 'label';

class LabelService extends Service {

}

export default new LabelService(URL, Label);
