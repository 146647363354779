import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const StyledInnerContainer = styled.div`
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;

    ${breakpoint('mobile_min', 'tablet_min')`
        padding-left: 25px;
        padding-right: 25px;
	`}
`;


const InnerContainer = (props) => (
    <StyledInnerContainer {...props} />
)

export default InnerContainer;