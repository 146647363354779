import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import GenericModal from './GenericModal';
import PropTypes from 'prop-types';
import DatabaseClient from '../../core/DatabaseClient';
import FileUploader from '../atomic/inputs/FileUploader';
import {inject} from 'mobx-react'
import { Certification, certification_status } from '../../core/model';
import { message } from 'antd';
import { FormControl, Input, InputLabel } from '@material-ui/core';

const StyledGenericModal = styled(GenericModal)`
    width: 600px !important;
    @media (max-width: 600px) {
        width: 550px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 50px;
    ${breakpoint('tablet_min', 'tablet_max')`
        margin: 25px;
    `}
`;

const Information = styled.div`
    text-align: center;
`;

const StyledInput = styled(Input)`
`;

const StyledFileUploader = styled(FileUploader)`
    margin-top: 20px;
`;

@inject("profileStore")
class UploadCertificationModal extends React.Component {
    state = {
        file: null,
        name: ""
    }

    async onSubmit() {
        if (this.state.name === "") {
            message.error("No certification name provided");
            return;
        }

        if (this.state.name.length > 50) {
            message.error("Name must be less than 50 characters");
            return;
        }

        const uid = this.props.profileStore.business.id;
        const cert = new Certification(undefined, undefined, undefined, this.state.name, certification_status.PENDING, uid, undefined);
        try {
            await DatabaseClient.createCertification(cert, this.state.file);
            message.success("Success! Please wait 24 hours for your certification to be verified.");
        }
        catch (e) {
            console.error(e);
            message.error(e.message);
            console.error(e);
            Sentry.captureException(e);
            throw e;
        }
    }

    async onFileSelect(files) {
        this.setState({ file: files[0] });
    }

    render() {
        return (
            <StyledGenericModal {...this.props} onSubmit={this.onSubmit.bind(this)} multiple={false} heading={"Upload certification documentation"}>
                <Content>
                    <Information>
                        Enter the name of the certification.  Optionally, upload a .pdf, .doc, or .docx file documenting your certification
                        (this will help us verify the given information).
                    </Information>
                    <FormControl fullWidth>
                        <InputLabel required>Certification Name</InputLabel>
                        <StyledInput 
                            value={this.state.name}
                            onChange={(e) => {
                                this.setState({ name: e.target.value });
                            }}
                        />
                    </FormControl>
                    <StyledFileUploader onSelect={this.onFileSelect.bind(this)} acceptedFileTypes={[".pdf", ".doc", ".docx"]}/>
                </Content>
            </StyledGenericModal>
        );
    }
}

UploadCertificationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
}

export default UploadCertificationModal;
