import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';
import { Message } from '../../../core/model'
import MessageLabel from './MessageLabel'
import CircularImage from '../../atomic/CircularImage'

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 10px;
    width: 40px;
`;
const StyledCircularImage = styled(CircularImage)`
    height: 40px;
    width: 40px;
    border: none;
`;
const Container = styled.div`
    display: flex;
    ${props => {
        if (props.left) {
            return css`margin-left: 10px;`;
        }
    }}
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledMessageLabel = styled(MessageLabel)`
    width: fit-content;
`;

const Text = styled.div`
    margin-top: 10px;
    margin-left: 25px;
    font-size: 0.8em;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
`;

class MessageContent extends React.Component {
    render() {
        
        return (
            <Container className={this.props.className} colored={this.props.colored} left={this.props.left} right={this.props.right}>
                {this.props.right && <StyledMessageLabel message={this.props.message} colored={this.props.colored} right displayPicture={this.props.displayPicture}/>}
                {this.props.left &&
                    <React.Fragment>
                        <ImageWrapper>
                            {this.props.displayPicture && <StyledCircularImage src={this.props.picture} />}
                        </ImageWrapper>
                        <Right>
                            {this.props.displayName &&
                                <Text>
                                    {this.props.businessName}
                                </Text>
                            }
                            <StyledMessageLabel message={this.props.message} colored={this.props.colored} left displayPicture={this.props.displayPicture}/>
                        </Right>
                        
                    </React.Fragment>
                }
                
            </Container>
        );
    }
}

MessageContent.propTypes = {
    message: PropTypes.instanceOf(Message).isRequired,
    picture: PropTypes.string,
    colored: PropTypes.bool,
    displayPicture: PropTypes.bool,
    businessName: PropTypes.string,
    displayName: PropTypes.bool
}

MessageContent.defaultProps = {
    colored: false,
    displayPicture: true,
    businessName: '',
    displayName: false
}

export default MessageContent