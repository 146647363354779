import React, { Component } from 'react'
import styled, { css, withTheme } from 'styled-components'
import PropTypes from 'prop-types';
import MUListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import { AlertMessage } from '../../AlertMessage';

const ItemContainer = styled(MUListItem)`
  
`;

const StrikeText = styled(ListItemText)`
  text-decoration: line-through; 
`;

class ListItem extends Component {

  render() {
    const { data, onItemClick, onItemEdit, onItemDelete, editMode, crossOut } = this.props
    const hasDocument = data.url !== null;
    return (
      <ItemContainer button onClick={(editMode || !hasDocument)?null:()=>onItemClick(data.url)}>
        {
          crossOut ? (            
            <StrikeText primary={data.name} />
          )
          :
          (
            <ListItemText primary={data.name} />
          )
        }
        {
          editMode ? (
            <ListItemSecondaryAction>
            {
              onItemEdit &&
              <IconButton aria-label="Create" onClick={onItemEdit}>
                <CreateIcon />
              </IconButton>
            }
            {
              onItemDelete &&
              <IconButton aria-label="Delete" onClick={() => AlertMessage('Delete Certificate','Are you sure you want to delete this certificate?',()=>onItemDelete(data.id))}>
                <DeleteIcon />
              </IconButton>
            }
            </ListItemSecondaryAction>
          )
          :
          (
            <React.Fragment>
              {
                hasDocument && 
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
              }
            </React.Fragment>
          )
        }
      </ItemContainer>
    )
  }

}

ListItem.propTypes = {
  data: PropTypes.array.isRequired,
  editMode: PropTypes.bool.isRequired,
  crossOut: PropTypes.bool,
  onItemClick: PropTypes.func,
  onItemEdit: PropTypes.func,
  onItemDelete: PropTypes.func,
}

export default ListItem;