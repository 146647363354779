import React from 'react'
import styled from 'styled-components'
import CircularImage from '../../atomic/CircularImage'
import ImageOverlap from '../ImageOverlap'
import PropTypes from 'prop-types'
import RecipientModal from './RecipientModal'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    margin-left: 60px;
    margin-right: 60px;
    width: calc(100% - 120px);
    max-width: 100%;
`;

const Text = styled.div`
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
    text-overflow: ellipsis;
    color: white;
    font-size: 1.5em;
    line-height: 50px;
    margin-right: 10px;
    max-width: calc(100% - 80px);
`;

const StyledCircularImage = styled(CircularImage)`
    width: 40px;
    height: 40px;
    margin-right: 5px;
`;

const StyledImageOverlap = styled(ImageOverlap)`
`;

const Wrapper = styled.div`
    padding: 5px;
    height: 60px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important;
    background-color: ${props => props.theme.colors.spearmint_dark};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
`;

class ConversationTopbar extends React.Component {
    state = {
        open: false
    }

    getNames() {
        const businesses = Object.values(this.props.businesses);
        const names = businesses.map((business) => business.name).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        let str = "";
        for (let i = 0; i < names.length - 1; i++) {
            str += names[i] + ", ";
        }
        str += names[names.length - 1];
        return str;
    }

    getPictures() {
        return Object.values(this.props.businesses).map((business) => (
            <React.Fragment>
                <StyledCircularImage src={business.picture} />
            </React.Fragment>
        ))
    }

    toggleOpen = bool => {
        this.setState({
            open: bool !== undefined ? bool : !this.state.open
        })
    }

    render() {
        const businesses = Object.values(this.props.businesses);
        return (
            <Wrapper>
                <Container>
                    <Text>
                        {this.getNames()}
                    </Text>
                    <StyledImageOverlap onClick={this.toggleOpen} images={Object.values(this.props.businesses).map((business) => business.picture)} />
                </Container>
                <RecipientModal open={this.state.open} recipients={businesses} onClose={() => this.toggleOpen(false)}/>
            </Wrapper>
        );
    }
}

ConversationTopbar.propTypes = {
    businesses: PropTypes.object.isRequired
}

export default ConversationTopbar