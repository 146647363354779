import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../../atomic/buttons/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import PackageSelect from "./PackageSelector";
import { PackFormat } from '../../../core/model';

const PackageSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const ButtonStyled = styled(Button)`
  margin-left: auto;
  margin-bottom: 8px;
  text-transform: none;
  min-width: 175px;
  min-height: 40px;
  max-height: 40px; 
`

const ChipStyled = styled(Chip)`

`

class PackagingInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: []
    }
  }

  // componentDidMount() {
  //   const { values } = this.props;
  //   this.setState({ values });
  // }

  // componentWillReceiveProps(nextProps) {
  //   if(this.state.values.length > nextProps.values.length) {
  //     this.setState({ values: arry });
  //   }
  // }

  getSelectors = () => {
    const { values } = this.props
    return values.map((item,index) => {
      return this.createSeletor(index, item.unit, item.conversionUnit, item.conversionRatio)
    })
  }

  createSeletor = (index,pack,unit,amount) => {
    const {options, unitOptions, hash } = this.props
    return (
      <PackageSelect
        key={`${index}-${hash}-${unit}`}
        pack={pack}
        unit={unit}
        options={options}
        unitOptions={unitOptions}
        amount={amount}
        updatePack={(val)=>this.changeVariant(index,val,'unit')}
        updateUnit={(val)=>this.changeVariant(index,val,'conversionUnit')}
        updateAmount={(val)=>this.changeVariant(index,Number(val),'conversionRatio')}
        onDelete={()=>this.removeVariant(index)}
      />
    )
  }

  // changePack = (index,val) => {
  //   const { onChange, values} = this.props
  //   // const { values } = this.state;
  //   let modVal = [...values];
  //   modVal[index][0] = val;
  //   // this.setState({modVal})
  //   onChange(this.array2Object(modVal))
  // }
  
  changeVariant = (index,val,field) => {
    const { onChange, values} = this.props
    let modVal = [...values];
    modVal[index][field] = val;
    if(field === "conversionRatio"){
      onChange(modVal, false)
    } else if (field === "unit") {
      if(val) {
        onChange(modVal, false)
      }
    } else {
      onChange(modVal)
    }
  }

  // object2Array = (obj) => {
  //   return Object.keys(obj).map(function(key) {
  //     return [key, obj[key]];
  //   });
  // }

  // array2Object = (arry) => {
  //   let obj = {}
  //   arry.map( item => {
  //     obj[item[0]] = item[1];
  //   })
  //   return obj;
  // }

  addVariant = () => {
    const { onChange, values} = this.props
    // const { values } = this.state;
    let modVal = [...values];
    const newVariant = new PackFormat();
    modVal.push(newVariant);
    // this.setState({ values: modVal })
    onChange(modVal)
  }

  removeVariant = (index) => {
    const { onChange, values} = this.props
    // const { values } = this.state;
    let modVal = [...values];
    modVal.splice(index,1);
    // this.setState({ values: modVal })
    onChange(modVal)
  }

  render () {
    const { onChange, ...other } = this.props
    return (
      <PackageSelectContainer {...other}>
        <ButtonStyled variant='contained' onClick={this.addVariant}>
          Add Pack Format<Icon fontSize='small'>add</Icon>
        </ButtonStyled>
        {this.getSelectors()}
      </PackageSelectContainer>
    )
  }
}

PackagingInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
};

PackagingInput.defaultProps = {
  values: []
};

export default PackagingInput;