import { observable, toJS } from 'mobx'
import { productSchema as schema } from '../schemas'
import joi from 'joi-browser'

/**
 * Data structure looks like 
 * {
 *      product
 *      productListings: []
 * }
 */
class ProductListingGroup {
    @observable product
    @observable productListings

    constructor(product = null, productListings = []) {
        this.product = product;
        this.productListings = productListings;
    }

    // /**
    //  * Returns a datastructure in the format
    //  * [
    //  *      unit: ProductListing (which includes price, productID, etc...)
    //  * ]
    //  * Use this in the basket view
    //  */
    // @computed get units() {
    //     const units = {};
    //     productListings.forEach((productListing) => {
    //         if (units[productListing.unit]) {
                
    //         }
    //         else {
    //             units[productListing.unit] = [productListing];
    //         }
    //     })
    //     return units;
    // }

    // Returns an array of ProductListingGroup formed by the ProductListings
    static groupListings(listings) {
        const groups = {};
        listings.forEach((listing) => {
            const productID = listing.productID;
            if (listing.getBaseQuantity() > 0 || listing.getBaseQuantity() === "Unlimited") {
                if (groups[productID]) {
                    groups[productID].productListings.push(listing);
                }
                else {
                    groups[productID] = new ProductListingGroup(listing.product, [listing]);
                }
            }
        });
        return Object.values(groups);
    }

    getAvailableUnits() {
        return this.productListings.map((listing) => listing.unit);
    }

    getPrices() {
        return this.productListings.map((pl) => {
            return {
                unit: pl.unit,
                price: pl.price
            }
        })
    }

    getPrices() {
        return this.productListings.map((pl) => {
            return {
                productListingID: pl.id,
                displayPrice: pl.getPricePerUnitString()
            }
        });
    }

    getDisplayPrices() {
        return this.getPrices().map((obj) => obj.displayPrice);
    }

    /**
     * Returns arr of format
     * {
     *  unit : string,
     *  quantity : number
     * }
     */
    getQuantities() {
        return this.productListings.map((pl) => {
            return {
                unit: pl.unit,
                quantity: pl.getBaseQuantity()
            }
        })
    }

    getDisplayQuantities() {
        return this.getQuantities().map((obj) => `${obj.unit}: ${obj.quantity}`);
    }
}

ProductListingGroup.displayName = "ProductListingGroup";
export default ProductListingGroup;
