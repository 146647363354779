import { action, observable, computed } from 'mobx'
import DatabaseClient from '../../core/DatabaseClient'
import { shoppingCartStore, profileStore, searchStore, clientListStore } from '..'
import moment from 'moment';
import validate from 'core/utilities/FormValidator'
import { ProductListing } from '../../core/model';
import ProductListingGroup from '../../core/model/ProductListingGroup';
import { BusinessService } from 'core/api';

/**
 * Store that holds data for the buyer views.  Stores the deals that are
 * available for the buyer to see.  Manages the shopping cart and the
 * checkout process
 * 
 * Usage:
 * Use @inject('shoppingStore')
 * Retrieve deals asynchronously with getDeals()
 * If you wish to observe the deals property, shoppingStore must be initialized first.
 * For example, init() can be called during componentDidMount():
 * ================================
 * state = {
 *  isLoading: true  // Turn on a loading spinner until shoppingStore has been initialized
 * }
 * 
 * async componentDidMount() {
 *  await shoppingStore.init()
 *  this.setState({isLoading: false}); // Turn off the loading spinner
 *  // Now you can access shoppingStore.deals
 * }
 * 
 * render() {
 *  return (this.state.isLoading ? <LoadingSpinner /> : <everything-else>)
 * }
 * ================================
 */
class ShoppingStore {
  /**
   * See core.js for the data format of deals
   */
  @observable deals = null
  @observable publicDeals = null

  @observable affiliated_items = []
  @observable nearby_items = []
  @observable seller_items = []
  /**
   * Each item is in the format
   * {
   *    Product
   *    ProductListings
   * }
   */

  @computed get affiliatedItems() {
    return this.affiliated_items;
  }

  @computed get nearbyItems() {
    return this.nearby_items;
  }

  @computed get sellerItems() {
    return this.seller_items;
  }

  /**
   * Form to fill in to request an item
   */
  @observable form = null
  defaultForm = {
    fields: {
      quantityRequested: {
        value: 0,
        validation: {
          numericality: {
            isInteger: true,
            greaterThan: 0,
          },
        },
        error: null,
      },
      needByDate: {
        value: '',
        validation: {
          presence: {
            message: '^Need by Date is invalid'
          },
          datetime: {
            earliest: moment().format('YYYY-MM-DD'),
            dateOnly: true,
            message: '^Need by Date is invalid'
          },
        },
        error: null,
      },
      exchange: {
        value: '',
        validation: {
          presence: true,
          inclusion: {
            within: ['delivery', 'pickup']
          },
        },
        error: null,
      },
    },
    validation: {
      error: null,
    }
  }



  constructor() {
    this.resetForm();
  }

  @action
  async addToShoppingCart(dealID) {
    if (!this.validateAll()) {
      throw new Error('The fields are invalid');
    }
    
    /*
    if (pickupAvailable && !deliveryAvailable) {
      exchange = 'pickup';
    }
    else if (deliveryAvailable && !pickupAvailable) {
      exchange = 'delivery'
    }
    */
    await shoppingCartStore.addToShoppingCart(dealID, this.form.fields.quantityRequested.value, this.form.fields.needByDate.value, this.form.fields.exchange.value);
  }

  @action
  onFieldChange = (field, value) => {
    // Go ahead and update the value
    // Treat empty strings as null
    if (value === '') {
      value = null;
    }
    this.form.fields[field].value = value;
    this.validateField(field);
  }

  @action
  validateField = (field) => {
    let data = {}; // The data to validate
    data[field] = this.form.fields[field].value;
    let validationRules = {}; // The rules to use in validation
    validationRules[field] = this.form.fields[field].validation;
    let err = validate(data, validationRules);

    // Update error message
    if (err !== undefined) {
      this.form.fields[field].error = err[field][0];
      throw new Error(err[field][0]);
    }
    else {
      this.form.fields[field].error = null;
      return true;
    }
  }

  @action
  validateAll = () => {
    // Start off assuming no errors are present
    this.form.validation.error = null;
    // let data = getFlattenedValues(this.form, 'value');
    //let validationRules = getFlattenedValues(this.form, 'validation');
    Object.keys(this.form.fields).forEach( (field) => {
      let validated = this.validateField(field);
      if (!validated) {
        this.form.validation.error = "Form failed validation.";
      }
    })
    return (this.form.validation.error === null);
    //this.form.validation.error = validate(data, validationRules);
  }

  resetForm() {
    this.form = Object.assign({}, this.defaultForm);
  }

  async init() {
    await this.refresh();
  }

  @action
  async refresh() {
    let _privatedeals = await DatabaseClient.getPrivateDealsForBuyer(profileStore.business.id);
    let _deals = _privatedeals;
    await this.getOpenDeals();

    this.deals = _deals;
  }

  async getItems() {
    await this.getAffiliatedItems();
    await this.getUnaffiliatedNearbyItems();
  }

  @action
  async getAffiliatedItems() {
    console.log('Getting items for', profileStore.business.name);
    const listings = await DatabaseClient.getAffiliatedProductListings(profileStore.business.id);
    const groups = ProductListingGroup.groupListings(listings);
    this.affiliated_items = groups;
    return groups;
  }

  @action
  async getUnaffiliatedNearbyItems() {
	const biz = profileStore.business
	if (!biz) {
		return [];
	}
    const coords = biz.coordinates;
    let unrelatedBundle = [];
    let relatedBundle = [];
    let relatedUIDs = await clientListStore.getClientUIDs();
    let completeSellerList = await BusinessService.getAll({
      center: coords,
      radius: searchStore.queryDistance,
      type: 'seller'
    });
    for( let x of completeSellerList ){
        if (!relatedUIDs.includes(x.id)){
            unrelatedBundle.push(x['id']);
        } else {
            relatedBundle.push(x);
        }
    }
    const listings = await DatabaseClient.getCollectiveProductListingsForBuyer(unrelatedBundle, profileStore.business.id); // .getPublicDealsForBuyer(user.uid,unrelatedBundle);
    const groups = ProductListingGroup.groupListings(listings);
    this.nearby_items = groups;
    return groups;
  }

  async getOpenDeals() {
	const biz = profileStore.business
	if (!biz) {
		return;
	}
    const coords = biz.coordinates;
    let unrelatedBundle = [];
    let relatedBundle = [];
    let relatedUIDs = await clientListStore.getClientUIDs();
    let completeSellerList = await BusinessService.getAll({
      center: coords,
      radius: searchStore.queryDistance,
      type: 'seller'
    });

    for( let x of completeSellerList ){
        if (!relatedUIDs.includes(x.id)){
            unrelatedBundle.push(x['id']);
        } else {
            relatedBundle.push(x);
        }
    }
    let _publicdeals = await DatabaseClient.getPublicDealsForBuyer(profileStore.business.id, unrelatedBundle);

    this.publicDeals = _publicdeals;

  }

  @action
  async refreshSellerInventory(uid) {
    const listing = await DatabaseClient.getProductListingsForBuyer(uid, profileStore.business.id);
    const groups = ProductListingGroup.groupListings(listing);
    this.seller_items = groups;
  }

  @action
  async getAll() {
    await this.refresh();
    return this.deals;
  }

  getIndexOfDeal(dealID) {
    for (let i = 0; i < this.deals.length; i++) {
      if (this.deals[i]['id'] == dealID) {
        return i;
      }
    }
    return -1;
  }

  getDeal(dealID) {
    let index = this.getIndexOfDeal(dealID);
    if (index > -1) {
      return this.deals[index];
    }
    else {
      return null;
    }
  }  
}
export default new ShoppingStore()
