import joi from 'joi-browser';
import { MIN_STRING, MAX_STRING_SHORT, productListingSchema } from '../schemas'
import { inventoryItemSchema } from '../model/InventoryItem'
import { observable, toJS, computed } from 'mobx'
import moment from 'moment';

export const shoppingCartItemSchema = joi.object().keys({
    deal: inventoryItemSchema,
    quantityRequested: joi.number().required(),
    needByDate: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    exchange: joi.string().valid(['pickup', 'delivery']).required(),
    totalCost: joi.number().allow(null).required()
});

class BasketItem {
    @observable productListing
    @observable exchange
    @observable needByDate
    @observable quantityRequested
    @observable totalCost

    constructor(productListing = null, exchange = null, needByDate = null, quantityRequested = 1, totalCost = 0) {
        Object.assign(this, {productListing, exchange, needByDate, quantityRequested, totalCost});
    }

    validate() {
        // ProductListing must not be null
        if (this.productListing == null || this.productListing.product == null) {
            throw new Error("No associated product listing and/or product");
        }
        const product = this.productListing.product;

        // Exchange must be pickup or delivery
        if (product.delivery && product.pickup) {
            if (this.exchange !== "delivery" && this.exchange !== "pickup") {
                throw new Error(`Exchange for ${product.name} must be one of delivery or pickup`);
            }
        }
        else if (product.delivery) {
            if (this.exchange !== "delivery") {
                throw new Error(`${product.name} is only available for delivery`);
            }
        }
        else if (product.pickup) {
            if (this.exchange !== "pickup") {
                throw new Error(`${product.name} is only available for pickup`);
            }
        }

        // Need by date either a timestamp or null - OK

        // Quantity requested
        if (this.quantityRequested == null || this.quantityRequested < 1) {
            throw new Error(`Quantity requested for ${product.name} must be a positive integer`);
        }
        const unit = this.productListing.unit;
        if (unit in product.packFormats && product.quantity[product.packFormats[unit].conversionUnit] !== null && product.quantity[product.packFormats[unit].conversionUnit] < this.quantityRequested) {
            throw new Error(`${this.quantityRequested} ${unit}(s) requested for ${product.name} but only ${this.productListing.getBaseQuantity()} ${unit}(s) available`);
        }
        else if (unit in product.quantity && product.quantity[unit] !== null && product.quantity[unit] < this.quantityRequested) {
            throw new Error(`${this.quantityRequested} ${unit}(s) requested for ${product.name} but only ${this.productListing.getBaseQuantity()} ${unit}(s) available`);
        }

        // Total cost - whatever
    }

    @computed get total() {
        const product = this.productListing;
        if(product) {
            const calcTotal = this.quantityRequested * product.price;
            return calcTotal;
        } else{
            return 0;
        }
    }
}

// Have to add b/c minifying will name mangle the class name
BasketItem.displayName = "BasketItem";
export default BasketItem;
