import React, { Component } from 'react'
import Button from '../atomic/buttons/Button';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

const StyledButton = styled(Button)`
  margin-left: 16px;
  text-transform: none;
`

const StyledIcon = styled(Icon)`
  margin-left: 4px;
`

class ViewToggle extends Component {

  render () {
    const { text, icon, onClick } = this.props
    return (
      <StyledButton variant="outlined" className="button" onClick={onClick}>
        {text}
        {
          icon &&
          <StyledIcon className="icon">{icon}</StyledIcon>
        }
      </StyledButton>
    )
  }
}

export default ViewToggle