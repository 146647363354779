import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import IntlCurrencyInput from "./IntlCurrencyInput"
import FormControl from '@material-ui/core/FormControl'
import TextField from "@material-ui/core/TextField"
import FormHelperText from '@material-ui/core/FormHelperText'

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
  
      <IntlCurrencyInput
          {...other}
          ref={inputRef}
          id="price"
          name="price"
          currency="USD"
          onChange={(event, value, maskedValue) => {
              onChange({
                target: {
                  value: value,
                },
              });
          }}
          config={{
              locale: "US",
              formats: {
                  number: {
                      USD: {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      },
                  },
              },
          }}
      /> 
    );
}

const StyledFormControl = styled(FormControl)`
    text-align: center !important;
`

const StyledTextField = styled(TextField)`
    text-align: center !important;
    width: 100%;
    font-family: ${props => props.theme.fonts.ms_font300} !important;
    
`

class CurrencyInput extends Component {

    constructor(props) {
        super(props)
        this.state = {
            numberformat: props.defaultValue || 0
        }
    }

    handleChange = event => {
        this.setState({ numberformat: event.target.value});
        this.props.onChange(event.target.value);
    }

    render() {
        return (
            <StyledFormControl>
                <StyledTextField
                    value={this.state.numberformat}
                    onChange={this.handleChange}
                    label={this.props.label}
                    defaultValue = {parseFloat(this.props.defaultValue)}
                    error={this.props.error !== undefined && this.props.error !== null}
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{
                        style: { textAlign: "center" }
                    }}
                />
                <FormHelperText error >{this.props.error}</FormHelperText>
            </StyledFormControl>
        )
    }
}

export default CurrencyInput