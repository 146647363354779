import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import PageTemplate from '../../components/templates/PageTemplate';

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
`;


@inject('profileStore')
@observer
class CustomPage extends Component {
	getDisplay = (url) => {
		return (<iframe class="airtable-embed" src={url} frameborder="0" onmousewheel="" width="100%" height="100%" style={{"background": "transparent", "border": "1px solid #ccc"}}></iframe>);
	};

	getPage = () => {
		const { profileStore, match } = this.props;
		const { business } = profileStore;
		if (!business.customPages) {
			return null;
		}
		const pageName = match.params.page;
		const page = business.customPages.find(({name}) => name === pageName);
		return page;
	};

	getTabIndex = () => {
		const page = this.getPage();
		if (!page || !page.tabs || !page.tabs.length) {
			return null;
		}
		const tabName = this.props.match.params.tab;
		for (const idx of page.tabs.keys()) {
			if (page.tabs[idx].name === tabName) {
				return idx;
			}
		}
		return 0;
	};


	getTab = () => {
		const page = this.getPage();
		if (!page || !page.tabs || !page.tabs.length) {
			return null;
		}
		return page.tabs[this.getTabIndex()];
	};

	getTabs = () => {
		const page = this.getPage();
		if (!page || !page.tabs || !page.tabs.length) {
			return [];
		}
		return page.tabs.map(({ name }) => ({
				name,
				path: `/custom/${page.name}/${name}`,
				child: false
			})
		);
	};

	render() {
		const tab = this.getTab();
		if (!tab) {
			this.props.history.push("/login");
			return null;
		}
		const { url, name } = tab;
		return (
			<PageTemplate
				title={name}
				sub=""
				tabIndex={this.getTabIndex()}
				tabs={this.getTabs()}
				isChildPath={false}
				mobilft={true} >
				<Container>
					{this.getDisplay(url)}
				</Container>
			</PageTemplate>
		);
	}
}

export default withRouter(CustomPage);
