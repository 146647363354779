import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Topbar from '../../components/CommonTopBar';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const TemplateContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	max-height: 100vh;
    max-width: 100vw;
`;

const ContentContainer = styled.div`
	display: flex;
	flex: 1;
	max-width: 100%;
`;

const ContentWrapper = styled.div`
	height: 100%;
	width: 100%;
	max-width: 100%;
	overflow-y: auto;
	${'' /* overflow-x: hidden; */}
`;

const Main = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 100%;
	padding-left: 250px;
	@media (max-width: 1024px) {
		padding-left: 50px;
	}
	@media (max-width: 550px) {
		padding-left: 0;
	}
`;

const TopBarContainer = styled.div`
    z-index: 2;
    .topbar {
        position: initial !important;
        padding-left: 0px !important;
        width: 100% !important;
    }
`;

class PageTemplate extends Component {

	render() {
		const { children, containerStyles, hideFab, ...other } = this.props
		return (
			<TemplateContainer>
				<Menu hideFab={hideFab}/>
				<Main>
					<TopBarContainer>
						<Topbar {...other} />
					</TopBarContainer>
					<ContentContainer style={containerStyles}>
						<ContentWrapper>
							{children}
						</ContentWrapper>
					</ContentContainer>
				</Main>
			</TemplateContainer>
		)
	}
}

PageTemplate.propTypes = {
	containerStyles: PropTypes.array,
};

export default withRouter(PageTemplate)