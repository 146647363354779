import React from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ItemImage from './ItemImage'
import Title from './Title'
import FoodChips from './FoodChips'
import ButtonFactory from './ButtonFactory'
import Checkmark from './Checkmark'

const ItemWrapper = styled.div`
	margin: 8px 10px;
`

const ItemContainer = styled.div`
	background: #fff;
	border-radius: 10px;
	overflow: hidden;
`

const ContentContainer = styled.div`
	display: flex;
	margin: 0 auto 0 auto;
	margin-left: auto;
	padding: 0;
	border-radius: 10px;
`

const Section = styled.section`
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	flex-direction: row;
	justify-content: space-between;
`

const LeftContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin: 10px 0;
	margin-right: 10px;
	${breakpoint('mobile_min', 'tablet_min')`
		max-width: 60%; 
	`}
`

const RightContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 10px;
	margin-left: 0;
	${breakpoint('mobile_min', 'tablet_min')`
		margin-bottom: auto;
	`}
`

const CheckmarkContainer = styled.div`
	display: flex;
	margin-left: auto;
	height: 0;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: auto 0;
	${breakpoint('mobile_min', 'tablet_min')`
		display: none;
	`}
`

const ActionButtonContainer = styled.div`
	display: none;
	padding: 0 8px 8px;
	padding-right: 3%;
	justify-content: flex-end;

	${breakpoint('mobile_min', 'tablet_min')`
		display: flex;
	`}
`

const BaseComp = ({
  key,
  onClick,
  picture,
  heading,
  subheading,
  checked,
  chips,
	uid,
	type,
  buttons,
  showActionButtons,
  ...other
}) => (
  <ItemWrapper key={key} {...other}>
		<ItemContainer>
			<ContentContainer onClick={onClick}>
				<ItemImage picture={picture} />

				<Section>
					<LeftContainer>
						<Title text={heading} subtext={subheading} uid={uid} type={type}/>
						{chips && <FoodChips items={chips}/>}
					</LeftContainer>

					<RightContainer>
						{
							checked && (
								<CheckmarkContainer>
									<Checkmark/>
								</CheckmarkContainer>
							)
						}
						{
							buttons &&
							<ButtonContainer>
									<ButtonFactory 
										buttons={buttons}
									/>
							</ButtonContainer>
						}
					</RightContainer>
				</Section>

			</ContentContainer>
			{
				showActionButtons && (
					<ActionButtonContainer>
						<ButtonFactory 
							mobile={true}
							buttons={buttons}
						/>
					</ActionButtonContainer>
				)
			}
		</ItemContainer>
	</ItemWrapper>
)

export default BaseComp;