import { auth } from './firebase/firebase';
import { UserService } from "core/api"

class AuthManager {
    async sendPasswordResetEmail(email) {
        await auth.sendPasswordResetEmail(email);
    }

    async login(email, password) {
        const userCredential = await auth.signInWithEmailAndPassword(email, password);
        return userCredential;
        // const user = await UserService.get(userCredential.user.uid);
        // return user;
    }

    async logout() {
        await auth.signOut();
    }

    async signUp(firstName, lastName, phoneNumber, email, password, invitationID = null) {
        // let userCredential = await auth.createUserWithEmailAndPassword(email, password);
        const user = await UserService.create({
            firstName,
            lastName,
            phoneNumber,
            email,
            invitationID,
            password
        });
        await this.login(email, password);
        await this.sendEmailVerification();
        return user;
    }

    async changeEmail(uid, email) {
        let oldEmail = await auth.currentUser.email;
        await auth.currentUser.updateEmail(email);
        try {
            const newUser = await UserService.update(uid, {
                email
            });
            await this.sendEmailVerification();
            return newUser;
        }
        catch (e) { // TODO: Change to finally
            console.error(e);
            Sentry.captureException(e);
            // Revert changes
            await auth.updateEmail(oldEmail);
            throw new Error('Could not update email');
        }
    }

    async sendEmailVerification() {
        const user = auth.currentUser;
        if (!user) {
            throw new Error("No user logged in");
        }
        await user.sendEmailVerification();
    }

    async getToken(forceRefresh = false) {
        return auth.currentUser ? auth.currentUser.getIdToken(forceRefresh) : null;
    }
}

export default new AuthManager()
