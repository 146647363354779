import { observable } from 'mobx'

class Picture {
    @observable id
    @observable name
    @observable url

    constructor(id = null, name = "", url = null) {
        this.id = id;
        this.name = name;
        this.url = url;
    }
}

Picture.displayName = "Picture";
export default Picture;
