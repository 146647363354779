import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../../atomic/buttons/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import SelectDropdown from '../../molecules/SelectDropdown';
import { cloneDeep } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Bubble from '../Bubble';

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const BubbleStyled = styled(Bubble)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const TextStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 10px;
  font-size: 1em;
  flex: 1;
  padding: 5px;
  opacity: 0.6;
`

const IconButtonStyled = styled(IconButton)`
  padding: 5px;
`

const InputStyled = styled(Input)`
  width: 90px;
`

const ChipStyled = styled(Chip)`
`

const Separate = styled.div`
  width: 10px;
`;

const BubbleStyles = {
  marginBottom: '8px',
  width: '100%',
  padding: '5px 0 5px 8px'
}

class PackageSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: []
    }
  }

  componentDidMount() {
    const { values } = this.props;
    // this.setState({ values });
  }

  // componentWillReceiveProps(nextProps) {
  //   const { editMode, name, index } = this.props
  //   const { bool } = this.state
  //   if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
  //     this.props.onSave(index,name,bool)
  //   }
  //   if(editMode && !nextProps.editMode) {
  //     this.setState({ bool: nextProps.value });
  //   }
  // }

  updateVariant = (prop,val) => {
    const { updatePack, updateUnit, updateAmount } = this.props
    switch(prop) {
      case 'pack':
        updatePack(val);
        break;
      case 'unit':
        updateUnit(val);
        break;
      default:
        break;
    }
  }

  updateAmount = (prop, val) => {
    val = parseInt(val);
    if (isNaN(val) || val < 0) {
      val = 0;
    }
    
    const { updatePack, updateUnit, updateAmount } = this.props
    updateAmount(val);
  }

  render () {
    const { updatePack, updateUnit, updateAmount, pack, unit, unitOptions, amount, onDelete, options, ...other } = this.props;
    let alteredOptions = cloneDeep(options)
    if(pack) {
      alteredOptions = Object.assign({[pack]: pack},options)
    }
    return (
      <QuantityContainer {...other}>
        <BubbleStyled enabled={true} bubbleStyles={BubbleStyles} color='#e8e8e8'>
          <TextStyled>A</TextStyled>
          <SelectDropdown
            items={alteredOptions}
            onChange={(val)=>this.updateVariant('pack',val)}
            allowOther={true}
            defaultValue={pack}
            formError={false}
            // label={"Unit of Measurement"}
          />
          <TextStyled>is</TextStyled>
          <InputStyled
            type='number'
            value={Number(amount).toString()}
            placeholder="1"
            onChange={(e)=>this.updateAmount('amount',e.target.value)}
            min={1}
            step={1}
          />
          <Separate/>
          <SelectDropdown
            items={unitOptions}
            onChange={(val)=>this.updateVariant('unit',val)}
            allowOther={false}
            defaultValue={unit}
            formError={false}
            // label={"Unit of Measurement"}
          />
          <IconButtonStyled onClick={onDelete}>
            <CloseIcon fontSize="small" />
          </IconButtonStyled>
        </BubbleStyled>
      </QuantityContainer>
    )
  }
}

PackageSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
};

PackageSelector.defaultProps = {
  values: []
};

export default PackageSelector;