import React, { useState } from 'react';
import RichTextEditor from 'react-rte';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import Button from '../../atomic/buttons/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const TextFieldStyled = styled(TextField)`
  display: flex;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  margin-left: auto;
`;

const RichTextEditorStyled = styled(RichTextEditor)`
`

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
};


const EditPanel = ({
  classname,
  initialValue,
  onChange,
 ...other 
}) => {
  const [value,setValue] = useState(RichTextEditor.createValueFromString(initialValue, 'html'))
  const onTextChange = text => {
    onChange(text.toString('html'))
    setValue(text);
  }
  return (
    <Container classname={classname} {...other}>
      <RichTextEditorStyled
        toolbarConfig={toolbarConfig}
        value={value}
        onChange={onTextChange}
      />
    </Container>
  )
}

EditPanel.propTypes = {
  initialValue: PropTypes.string
}

EditPanel.defaultProps = {
  initialValue: ''
}

export default EditPanel;