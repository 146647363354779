import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';

const Container = styled.div`

`;
const DateWrapper = styled.div`
  font-family: ${props => props.theme.fonts.ms_font300};
  color: #b9b9b9;
  font-size: 12px;
  padding-top: 3px;
  padding-left: 20px;
`;
const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors["spearmint_main"]};
`;
const ButtonWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const MentionWrapper = styled.div`
  padding: 5px 10px;
`;

const IconButtonStyled = styled(IconButton)`
  padding: 5px;
`;
const ChipStyled = styled(Chip)`
  margin-right: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const Header = ({
  onDelete,
  onEdit,
  stared,
  onStarClicked,
  date,
  updated,
  labels,
 ...other
}) => {

  const day = moment(date).format("LLL")
  const createChip = (item,index) => {
    const { value } = item
    if(!!value) {
      switch(value['type']) {
        case 'business':
          return (
            <Link to={`/profile/${value['value']}`}>
              <ChipStyled onClick={() => null} label={item.label} />
            </Link>
          )
        default: 
          return <ChipStyled onClick={() => null} label={item.label} />
      }
    } else {
      return <ChipStyled onClick={() => null} label={item.label} />
    }
  }

  return (
    <Container {...other}>
      <TopWrapper>
        <MentionWrapper>
        {labels.map((item,index) => createChip(item,index))}
        </MentionWrapper>
        <ButtonWrapper>
          <IconButtonStyled onClick={onEdit}>
            <Edit/>
          </IconButtonStyled>
          <IconButtonStyled onClick={onDelete}>
            <Delete/>
          </IconButtonStyled>
        </ButtonWrapper>
      </TopWrapper>
      <DateWrapper>{`${updated?'last modified: ': ''}${day}`}</DateWrapper>
    </Container>
  )
}

Header.propTypes = {
  labels: PropTypes.array,
}

Header.defaultProps = {
  labels: []
}

export default Header;