import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import CheckMark from '@material-ui/icons/CheckCircle';
import breakpoint from 'styled-components-breakpoint'

const StyledIcon = styled(CheckMark)`
	font-size: 1.6em;
	color: #51d79a;
    ${breakpoint('mobile_min', 'mobile_max')`
        display: none;
	`}
`

const StyledText = styled(Typography)`
    margin-right: 4px;
    font-family: ${props => props.theme.fonts.ms_font300}
    color: #51d79a;
    ${breakpoint('mobile_min', 'mobile_max')`
        font-size: 0.9em;
	`}
`

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: -25px; 
`

class Checkmark extends Component {
    render() {
        return (
            <Container>
                <StyledText>Affiliated</StyledText>
                <StyledIcon/>
            </Container>
        )
    }
}

export default Checkmark