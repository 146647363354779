import { observable } from 'mobx'

class UserSession {
    @observable notifications
    @observable pushToken
    
    constructor(notifications = [], pushToken = null) {
        this.notifications = notifications;
        this.pushToken = pushToken;
    }
}

UserSession.displayName = "UserSession";
export default UserSession;
