import React from 'react';

const TermsOfUse = props => {
    return (
        <div>
        <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Last
                    Update</b></font><font face="Times New Roman, serif">.  The Terms
                  were last updated on September 25, 2018.</font></font></font></font></p>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <p className="western" align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><u><b>A.
                      INTRODUCTION</b></u></font></font></font></font></p>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">Welcome
                    to Freshspire! At Freshspire, we are </font></font><font color="#000000"><font face="Times New Roman, serif">helping
                    to solve the inefficiencies that exist with connecting suppliers of
                    food (each, a “</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Supplier</b></font></font><font color="#000000"><font face="Times New Roman, serif">”)
                    and purchasers of food (each, a “</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Buyer</b></font></font><font color="#000000"><font face="Times New Roman, serif">”).
                  </font></font><font color="#000000"><font face="Times New Roman, serif">Freshspire
                    has created a web-based platform that facilitates communication
                    between Suppliers and Buyers (such as businesses, restaurants, other
                    distributors, and individuals) so that little, if any, food ends up
                    in our landfills, and so that all users save time and money.</font></font></font></font></font>
        </p>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Our
                  legal name is Freshspire Inc, and we are a North Carolina
                  corporation.  To make these Terms of Use easier to read:</font></font></font></font></p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">These
                      Terms of Use will be referred to herein as the “</font><font face="Times New Roman, serif"><b>Terms</b></font><font face="Times New Roman, serif">”
                      or this “</font><font face="Times New Roman, serif"><b>Agreement</b></font><font face="Times New Roman, serif">”);
                    </font></font></font></font>
            </p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Freshspire’s
                      website and any associated sites or mobile applications will be
                      referred to herein as the “</font><font face="Times New Roman, serif"><b>Platform</b></font><font face="Times New Roman, serif">”;</font></font></font></font></p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Anything
                      purchased through the Platform as a “</font><font face="Times New Roman, serif"><b>Products</b></font><font face="Times New Roman, serif">”;</font></font></font></font></p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Each
                      sale of A Product between Suppliers and Buyers is referred to herein
                      as a “</font><font face="Times New Roman, serif"><b>Transaction</b></font><font face="Times New Roman, serif">”;
                    </font></font></font></font>
            </p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Facilitating
                      Transactions between Suppliers and Buyers is referred to herein as
                      the “</font><font face="Times New Roman, serif"><b>Service</b></font><font face="Times New Roman, serif">”;</font></font></font></font></p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Freshspire,
                      including the Platform and Service, is referred to herein as
                      “</font><font face="Times New Roman, serif"><b>Freshspire</b></font><font face="Times New Roman, serif">”,
                      “</font><font face="Times New Roman, serif"><b>we</b></font><font face="Times New Roman, serif">”,
                      “</font><font face="Times New Roman, serif"><b>us</b></font><font face="Times New Roman, serif">”,
                      or “</font><font face="Times New Roman, serif"><b>our</b></font><font face="Times New Roman, serif">”;
                      and</font></font></font></font></p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">We
                      will simply refer to you (inclusive of any party or entity that you
                      represent) and other users as “</font><font face="Times New Roman, serif"><b>you</b></font><font face="Times New Roman, serif">”
                      or “</font><font face="Times New Roman, serif"><b>your</b></font><font face="Times New Roman, serif">”.</font></font></font></font></p>
          </li></ul>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">At
                  the most basic level, these Terms and our privacy policy located at
                </font> <a href="https://www.freshspire.io/privacy-policy"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>www.freshspire.io/privacy-policy</font></font></a><font face="Times New Roman, serif">
                   (“</font><font face="Times New Roman, serif"><b>Privacy Policy</b></font><font face="Times New Roman, serif">”),
                  which is incorporated herein by reference, govern your use of
                  Freshspire.  By using or accessing the Platform, you accept and agree
                  to be bound and abide by these Terms and the Privacy Policy, each as
                  amended from time to time.  In the event that we change these Terms
                  or the Privacy Policy, we will provide you notice of such changes,
                  either by email, your account, or upon your login to Freshspire.  If
                  you do not agree to either these Terms or the Privacy Policy, now or
                  as amended in the future, you must not use or access Freshspire.</font></font></font></font></p>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><u><b>B.
                        ACKNOWLEDGMENTS AND AGREEMENTS</b></u></font></font></font></font></font></p>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">While
                    much of this Section B is covered below, we find the following very
                    important and would like to call it to your attention: You, as a
                    Supplier or Buyer, hereby acknowledge and agree that Freshspire is
                    simply the provider of the Platform and Service, and not a Supplier
                    or Buyer.  As such, you hereby acknowledge and agree that Freshspire:</font></font></font></font></font></p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">Is
                        not responsible for any payments or refunds organized between a
                        Supplier and Buyer;</font></font></font></font></font></p>
          </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">Is
                        not liable or responsible for the pick-up or delivery of any Product
                        purchased through Freshspire;</font></font></font></font></font></p>
          </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">Makes
                        no representation or warranty concerning the quality of any
                        Supplier, Buyer, or Product;</font></font></font></font></font></p>
          </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">Is
                        not responsible for any party’s satisfaction with a Transaction or
                        Product purchased on the Platform;</font></font></font></font></font></p>
          </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">Is
                        not responsible for the actions, non-actions, or omissions of any
                        Supplier or Buyer;</font></font></font></font></font></p>
          </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">Is
                        not responsible for resolving, nor is it required to be involved in
                        resolving, any Supplier-Buyer disputes; and</font></font></font></font></font></p>
          </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif">May
                        seek to intervene in a Supplier-Buyer dispute, but guarantees no
                        specific result or outcome from such involvement.</font></font></font></font></font></p>
          </li></ul>
        <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p className="western" align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><u><b>C.
                      GENERAL TERMS</b></u></font></font></font></font></p>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Eligibility</b></font><font face="Times New Roman, serif">.
                      To access or use Freshspire, you must (a) be at least 18 years old,
                      (b) not have been previously suspended from accessing or using
                      Freshspire, and (c) have the full power and authority to agree to
                      these Terms and the Privacy Policy.</font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginLeft: '0.25in', marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={2}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Accounts;
                        Generally</b></font><font face="Times New Roman, serif">.  In order
                      to access or use Freshspire, you will need to register for and
                      create an account on the Platform (“</font><font face="Times New Roman, serif"><b>Account</b></font><font face="Times New Roman, serif">”)
                      by providing any information prompted on the Platform, which may
                      include, but is not limited to, the following: name, address, phone
                      number, email address, password, and payment information.  You
                      acknowledge and consent to all actions we take consistent with our
                      Privacy Policy concerning information you provide to Freshspire, or
                      concerning your access or use of Freshspire.</font></font></font></font>
            </p>
          </li></ul>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={3}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Account
                        Security</b></font><font face="Times New Roman, serif">.  You
                      acknowledge and agree that you: (a) are solely responsible for your
                      Account and any use of your Account, (b) are solely responsible for
                      any unauthorized use of your Account, and (c) will notify us in
                      writing immediately if you become aware of any unauthorized use of
                      your Account.  This means you may not provide any other party with
                      access to your Account.  You also must not use any other party’s
                      Account, unless you have express permission and are authorized under
                      these Terms to do so.</font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={4}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Platform
                          Changes</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                        We reserve the right to amend the Platform, and any feature
                        therein, in our sole discretion without notice.  We will not be
                        liable if, for any reason, all or any part of the Platform is
                        unavailable at any time or for any period.</font></font></font></font></font></p>
          </li></ul>
        <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p className="western" align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><u><b>D.
                      USE OF THE PLATFORM</b></u></font></font></font></font></p>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>How
                        the Platform Works</b></font><font face="Times New Roman, serif">. 
                      Suppliers may list Products for sale, including details concerning
                      such Products, such as price, pictures, quantity, sell by date, and
                      other relevant information (“</font><font face="Times New Roman, serif"><b>Product
                        Information</b></font><font face="Times New Roman, serif">”);
                      provided, however, that, all such Product Information is true,
                      accurate, reliable, not misleading, and generated by the Supplier
                      posting such Product Information (i.e., the Supplier only posts
                      pictures of Products that are </font><font face="Times New Roman, serif"><i><u>actually</u></i></font><font face="Times New Roman, serif">
                      for sale).  Buyers may search the Platform for available Products,
                      and if the Buyer desires to purchase any listed Products, it may
                      submit an order for the requested Products (an “</font><font face="Times New Roman, serif"><b>Order</b></font><font face="Times New Roman, serif">”).
                      Once an Order is submitted, a Supplier must review and accept or
                      decline the Order.  Once an Order is accepted (an “</font><font face="Times New Roman, serif"><b>Accepted
                        Order</b></font><font face="Times New Roman, serif">”), the
                      Supplier and Buyer will coordinate payment (if required) for, and
                      pick-up or delivery of the Products. </font></font></font></font>
            </p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={2}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Platform
                        License</b></font><font face="Times New Roman, serif">.  You are
                      hereby granted a limited, nonexclusive, nontransferable,
                      nonsublicensable license to access and use Freshspire (“</font><font face="Times New Roman, serif"><b>License</b></font><font face="Times New Roman, serif">”).
                      However, the License is subject to these Terms and any use of
                      Freshspire other than as specifically authorized herein, without the
                      prior written permission of Freshspire, is strictly prohibited and
                      will terminate your License.  You may not copy, modify, distribute,
                      sell, or lease any part of the Platform. Unless such restriction is
                      prohibited by law or you have Freshspire’s written permission, you
                      may not reverse engineer or attempt to extract the source code of
                      the Platform. Notwithstanding anything to the contrary in these
                      Terms, Freshspire may include software components provided by
                      third-parties and that are subject to separate license terms, in
                      which case those licenses will be made available to you and those
                      license terms will govern such software components. We retain the
                      right to restrict, suspend, or terminate your License at any time
                      without prior written notice for any reason or no reason.</font></font></font></font>
            </p>
          </li></ul>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={3}>
          <li><p align="JUSTIFY" style={{marginBottom: '0.11in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Complete
                          Information; License</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                        All information that you provide on the Platform will be complete
                        and accurate, including information that relates to the following:
                        your identity, your location, your contact information, your banking
                        or payment details (“</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Payment
                          Information</b></font></font><font color="#000000"><font face="Times New Roman, serif">”),
                        Product Information, and any other information you are  prompted to
                        enter (cumulatively, the “</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Information</b></font></font><font color="#000000"><font face="Times New Roman, serif">”).
                        In the event that it is determined that any Information you have
                        provided is incomplete or inaccurate, Freshspire, in its sole
                        discretion, may remove any such incorrect or inaccurate Information,
                        as well as </font></font><font face="Times New Roman, serif">restrict,
                      suspend, or terminate your License to use the Platform. While you
                      retain all right, title, and interest to Information you post on the
                      Platform, you are solely responsible for that content. Furthermore,
                      you grant Freshspire a non-exclusive, royalty-free, worldwide,
                      transferable, sub-licenseable license to use, store, publicly
                      display, reproduce, modify, create derivative works from, and
                      distribute any such Information solely for the purposes of providing
                      the Platform and Service. </font></font></font></font>
            </p>
          </li></ul>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={4}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Compliance</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                        You represent, covenant, and warrant that you will only use the
                        Platform in compliance with these Terms and all applicable laws and
                        regulations (“</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Compliance</b></font></font><font color="#000000"><font face="Times New Roman, serif">”).
                        Freshspire may </font></font><font face="Times New Roman, serif">restrict,
                      suspend, or terminate your License to use the Platform</font><font color="#000000"><font face="Times New Roman, serif">,
                        if Freshspire in its sole and reasonable discretion, determines that
                        you are not in Compliance.</font></font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={5}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Product
                        Quality</b></font><font face="Times New Roman, serif">.  While
                      Freshspire is not responsible for the quality of any Product sold
                      through the Platform, we do recommend that you inspect all Products
                      for damage, freshness, quality and any other issues immediately upon
                      receipt.  You are solely responsible for determining the freshness
                      and quality of Products purchased through the Platform.  To maintain
                      the quality and integrity of the Products, we recommend that you
                      immediately refrigerate all Products upon your receipt.  Following
                      receipt, your use and consumption of any Product is solely at your
                      own risk, and you are solely responsible for the proper and safe
                      washing, handling, preparation, storage, cooking, and use.</font></font></font></font></p>
          </li></ul>
        <p style={{marginLeft: '0.5in', marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={6}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Returns
                        and Refunds</b></font><font face="Times New Roman, serif">.  If you
                      are dissatisfied with any Product, please contact the Supplier of
                      such Product as soon as possible.  Buyers and Suppliers are solely
                      responsible for organizing and facilitating any returns and refunds
                      of purchased Products.</font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={7}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Intellectual
                        Property</b></font><font face="Times New Roman, serif">.  Unless
                      otherwise indicated in writing by us, the Freshspire logo and all
                      designs, text, graphics, pictures, information, data, software, and
                      files (that is not Information) included in the Platform, and the
                      selection and arrangement thereof, is property of Freshspire or its
                      licensors, as applicable, and is protected by United States and
                      international copyright, trademark, patent, trade secret and other
                      intellectual property or proprietary rights laws.</font></font></font></font></p>
          </li></ul>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={8}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Prohibited
                        Uses.</b></font><font face="Times New Roman, serif">You may use
                      Freshspire only for lawful purposes and in accordance with these
                      Terms.  As such, you agree not to use Freshspire:</font></font></font></font></p>
            <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
              <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">In
                          any way that violates any applicable federal, state, local, or
                          international law or regulation;</font><font face="Times New Roman, serif"><b>
                          </b></font></font></font></font>
                </p>
              </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">For
                          the purpose of exploiting, harming, harassing or attempting to
                          exploit minors in any way;</font><font face="Times New Roman, serif"><b>
                          </b></font></font></font></font>
                </p>
              </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">To
                          transmit, or procure the sending of, any advertising or promotional
                          material without our prior written consent, including any junk
                          mail, chain letter or spam or any other similar solicitation; </font></font></font></font>
                </p>
              </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">To
                          impersonate or attempt to impersonate Freshspire, a Freshspire
                          employee, another user or any other person or entity; </font></font></font></font>
                </p>
              </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">In
                          any manner that could disable, overburden, damage, or impair
                          Freshspire or interfere with any other party’s use of Freshspire,
                          including their ability to engage in real time activities through
                          Freshspire; </font></font></font></font>
                </p>
              </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Use
                          any robot, spider or other automatic device, process or means to
                          access the Platform for any purpose, including monitoring or
                          copying any of the material on the Platform; </font></font></font></font>
                </p>
              </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Use
                          any manual process to monitor or copy any of the material on the
                          Platform; </font></font></font></font>
                </p>
              </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Interfere
                          with the proper working of Freshspire in any manner, whether by
                          viruses, trojan horses, worms, logic bombs denial-of-service
                          attack, distributed denial-of-service attack, other malicious
                          technology, manual process or related conduct. </font></font></font></font>
                </p>
              </li><li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Attempt
                          to gain unauthorized access to, interfere with, damage or disrupt
                          any parts of the Platform, the server on which the Platform is
                          stored, or any server, computer or database connected to the
                          Platform; </font></font></font></font>
                </p>
              </li></ul>
          </li></ul>
        <p align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><u><b>E.
                        PAYMENTS</b></u></font></font></font></font></font></p>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Payment
                          for Orders</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                        Payment for the purchased Products will occur in one of the
                        following two methods (you will be notified of which payment method
                        is utilized at checkout):</font></font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginLeft: '0.25in', marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} type="a">
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Direct
                            Payment</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                          The Supplier will bill the Buyer for an Accepted Order (“</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Direct
                            Payment</b></font></font><font color="#000000"><font face="Times New Roman, serif">”),
                          and the Supplier and Buyer will organize the Direct Payment, and
                          the exchange of purchased Products.  At the end of each calendar
                          month, Freshspire will invoice each Supplier 15% (the “</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Freshspire
                            Fee</b></font></font><font color="#000000"><font face="Times New Roman, serif">”)
                          of the aggregate amount of all Supplier Accepted Orders facilitated
                          through Direct Payment on the Platform in that calendar month
                          (“</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Monthly
                            Invoice</b></font></font><font color="#000000"><font face="Times New Roman, serif">”).
                          Supplier must pay each Monthly Invoice within 10 business days
                          (the “</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Due
                            Date</b></font></font><font color="#000000"><font face="Times New Roman, serif">”)
                          of receiving such Monthly Invoice.  Each Monthly Invoice will be
                          sent by email to the Supplier email address listed in the
                          Supplier’s Account, and be deemed to have been received by the
                          Supplier 2 business days after the date on which such Monthly
                          Invoice was sent by Freshspire.  If a Supplier fails to pay all or
                          any portion of the amount due under a Monthly Invoice by the Due
                          Date (“</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Outstanding
                            Balance</b></font></font><font color="#000000"><font face="Times New Roman, serif">”),
                          the Supplier will be charged a monthly fee equal to the lesser of
                          (i) one and one-half percent (1.5%) of the total Outstanding
                          Balance and (ii) the maximum interest chargeable under applicable
                          law (the “</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Late
                            Fee</b></font></font><font color="#000000"><font face="Times New Roman, serif">”).
                          Freshspire also reserves the right, in its sole discretion, to
                          restrict, suspend, or terminate the Supplier’s License to use the
                          Platform until any Outstanding Balance and applicable Late Fees are
                          paid in full.</font></font></font></font></font></p>
            </li></ul>
        </ul>
        <p align="JUSTIFY" style={{marginLeft: '0.25in', marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} type="a" start={2}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Automated
                            Payment</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                          When the Platform is capable of processing payments, then Buyer’s
                          Payment Information will be used by Freshspire to process payment
                          for any Accepted Order (“</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Buyer
                            Payment</b></font></font><font color="#000000"><font face="Times New Roman, serif">”)
                          through Freshspire’s third party payment processor (“</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Payment
                            Processor</b></font></font><font color="#000000"><font face="Times New Roman, serif">”).
                          Once the Buyer Payment is received, Freshspire will deduct the
                          Freshspire Fee (15% of the total Buyer Payment), and remit the
                          balance to the Supplier using the Supplier’s Payment information.
                        </font></font></font></font></font>
              </p>
            </li></ul>
        </ul>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <p className="western" align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>*Freshspire
                      hereby reserves the right to amend its billing practices at any time.</b></font></font></font></font></font></p>
        <p style={{marginLeft: '0.5in', marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={2}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Taxes,
                          Delivery, Shipping and Related Charges</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                        Suppliers are solely responsible for charging and collecting any and
                        all amounts for fees, taxes, delivery, shipping, and any other
                        charges (cumulatively, the “</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Charges</b></font></font><font color="#000000"><font face="Times New Roman, serif">”)
                        related to the sale of Products. Suppliers may include any such
                        amounts in the price of Products, or they may bill each Buyer
                        separately for such amounts. In no event shall Freshspire be
                        responsible for collecting any such Charges, nor shall it be
                        responsible to pay any taxes concerning any amounts exchanged
                        through the Freshspire.</font></font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={3}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Subscriptions</b></font><font face="Times New Roman, serif">.
                      Freshspire does not currently offer subscriptions, but may decide
                      to offer subscriptions in the future (each, a “</font><font face="Times New Roman, serif"><b>Subscription</b></font><font face="Times New Roman, serif">”).
                      In the event that Freshspire does adopt a Subscription model,
                      Freshspire will notify all users of the implementation of a
                      Subscription model either by email, your Account, or upon your login
                      to Freshspire.  Upon adopting a Subscription model, each user will
                      be required to either select a Subscription offered by Freshspire or
                      cease using Freshspire.  If you select a paid Subscription, you will
                      be required to enter valid Payment Information that may be used by
                      Freshspire to charge the amounts due for your Subscription. By
                      registering for a paid Subscription, you expressly acknowledge and
                      agree that Freshspire or its Payment Processor is authorized to
                      charge you, using your Payment Information, in accordance with the
                      terms of your Subscription as detailed on Freshspire’s Platform. 
                      Your Subscription will remain in effect until cancellation.  In
                      order to cancel your&nbsp;Subscription, you must either email us at
                      help@freshspire.com, or cancel your Subscription through your
                      Account.  The cancellation of your Subscription will not take effect
                      until the end of the applicable Subscription cycle; in other words,
                      if your Subscription were billed monthly, your Subscription would be
                      cancelled on the monthly anniversary of your last Subscription
                      payment.  In the event that Freshspire is unable to charge you using
                      your Payment Information, Freshspire, without providing you prior
                      notice, may restrict, suspend, or terminate your License to use the
                      Platform until it receives full payment of any amounts due. </font></font></font></font>
            </p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={4}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Free
                        Trials</b></font><font face="Times New Roman, serif">.  Freshspire
                      may, occasionally and in its sole discretion, offer free trials of
                      its Subscriptions.  In the event that Freshspire offers you a free
                      trial to any of its Subscriptions, you will still be required to add
                      a Payment Information to your Account.  Upon expiration, your free
                      trial will convert to a paid Subscription for which we will begin
                      charging you, using the Payment Information you provided, in
                      accordance with the terms of the Subscription (as detailed on the
                      Platform).  Please note that you will not receive a notice from us
                      that your free trial has ended or that your paid Subscription has
                      begun. </font></font></font></font>
            </p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000">
          </font><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={5}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Refunds</b></font><font color="#000000"><font face="Times New Roman, serif">.
                        As noted in Section D.6 above, </font></font><font face="Times New Roman, serif">Buyers
                      and Suppliers are solely responsible for organizing and facilitating
                      any returns and refunds of purchased Products.</font><font face="Times New Roman, serif"><i><b>Please
                          Note</b></i></font><font face="Times New Roman, serif">, if you are
                      a Supplier and any purchased Product is retur</font><font color="#000000"><font face="Times New Roman, serif">ned
                        or refunded, we will accordingly adjust our Monthly Invoice once you
                        have provided us reasonable documentation concerning such refund.</font></font></font></font></font></p>
          </li></ul>
        <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={6}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Payment
                          Authorization</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                        By providing Freshspire with a Payment Information, you represent
                        and warrant that you are authorized to use such Payment Information,
                        and that you authorize us and our Payment Processor to charge any
                        payments (including any applicable taxes and other charges) that you
                        owe in accordance with these Terms or with respect to your use of
                        the Platform using the Payment Information you provided.  If the
                        Payment Information cannot be verified, is invalid or is otherwise
                        not acceptable, your Orders may be suspended or cancelled until such
                        issue is resolved.  You may change or update your Payment
                        Information by logging into your Account and editing the Payment
                        Information you previously provided.</font></font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={7}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>Non-Circumvention</b></font></font><font color="#000000"><font face="Times New Roman, serif">.
                        You will not, directly or indirectly, seek to circumvent the
                        Platform after discovering a Supplier or Buyer through the Platform
                        by attempting to, inducing, or actually engaging a Supplier or Buyer
                        outside of the Platform to complete a Transaction (“</font></font><font color="#000000"><font face="Times New Roman, serif"><b>Circumvention</b></font></font><font color="#000000"><font face="Times New Roman, serif">”).
                        You agree to pay Freshspire a fee equal to 15% of the amount you
                        pay or receive with respect to any Transaction that occurs outside
                        of the Platform and that would constitute Circumvention.</font></font></font></font></font></p>
          </li></ul>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <p className="western" align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><u><b>F.
                      WARRANTIES, LIABILITY LIMITATIONS, INDEMNIFICATION, WAIVERS</b></u></font></font></font></font></p>
        <p className="western" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>WARRANTY
                        AND DISCLAIMER</b></font><font face="Times New Roman, serif">. 
                      Freshspire will use commercially reasonable efforts to provide the
                      Platform and reasonable customer support concerning the Platform to
                      Suppliers and Buyers in accordance with Freshspire’s standard
                      practices.  Freshspire shall use reasonable efforts consistent with
                      prevailing industry standards to maintain the Platform in a manner
                      that minimizes errors and interruptions.  The Platform may be
                      temporarily unavailable for scheduled maintenance or for unscheduled
                      emergency maintenance by Freshspire, its third-party providers, or
                      because of other causes beyond Freshspire’s reasonable control,
                      but Freshspire shall use reasonable efforts to provide advance
                      notice in writing or by e-mail of any scheduled service disruption. 
                    </font></font></font></font>
            </p>
          </li></ul>
        <p style={{marginLeft: '0.5in', marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">FRESHSPIRE
                </font><span style={{textTransform: 'uppercase'}}><font face="Times New Roman, serif">does
                    not warrant that the Platform will be uninterrupted or error free;
                    nor does it make any warranty as to the results that may be obtained
                    from use of the Platform.</font></span><font face="Times New Roman, serif">EXCEPT
                  AS EXPRESSLY SET FORTH IN THIS SECTION, THE PLATFORM IS PROVIDED “AS
                  IS” AND FRESHSPIRE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY
                  AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. 
                  ADDITIONALLY, FRESHSPIRE MAKES NO REPRESENTATIONS OR WARRANTIES
                  CONCERNING SUPPLIERS OR PURCHASERS.  FURTHERMORE, FRESHSPIRE, TO</font><font color="#000000"><font face="Times New Roman, serif"><span style={{background: '#fcfcfc'}}>
                      THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, MAKES NO
                      REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE QUALITY,
                      SUITABILITY, OR AVAILABILITY OF THE PRODUCT PURCHASED THROUGH THE
                      PLATFORM.  YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR
                      PURCHASE, USE, OR CONSUMPTION OF PRODUCTS, REMAINS SOLELY WITH YOU.</span></font></font></font></font></font></p>
        <p style={{marginLeft: '0.5in', marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={2}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>LIMITATION
                        OF LIABILITY</b></font><font face="Times New Roman, serif">.</font><font color="#000000"><font face="Times New Roman, serif">IN
                        NO EVENT, EVEN IF FRESHSPIRE HAS BEEN ADVISED OF SUCH, SHALL
                        FRESHSPIRE, INCLUDING </font></font><font face="Times New Roman, serif">ITS
                      OFFICERS, DIRECTORS, STOCKHOLDERS, AFFILIATES, REPRESENTATIVES,
                      CONTRACTORS AND EMPLOYEES (CUMULATIVELY, THE “</font><font face="Times New Roman, serif"><b>AFFILIATES</b></font><font face="Times New Roman, serif">”)
                    </font><font color="#000000"><font face="Times New Roman, serif">BE
                        LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL,
                        OR INDIRECT DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
                        DELETION, CORRUPTION, LOSS OF DATA, LOSS OF PROGRAMS, FAILURE TO
                        STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR TRANSMITTED BY
                        THE PLATFORM, PLATFORM INTERRUPTIONS, OR FOR THE COST OF PROCUREMENT
                        OF SUBSTITUTE SERVICES) ARISING OUT OF OR IN CONNECTION WITH THE
                        PLATFORM, WHETHER BY NEGLIGENCE OR OTHERWISE.  IN NO EVENT SHALL
                        FRESHSPIRE OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL,
                        PUNITIVE, INCIDENTAL, EXEMPLARY AND/OR CONSEQUENTIAL DAMAGES
                        (INCLUDING, BUT NOT LIMITED TO PHYSICAL DAMAGES, BODILY INJURY,
                        DEATH AND/OR EMOTIONAL DISTRESS AND DISCOMFORT) ARISING OUT OF YOUR
                        USE OF THE PLATFORM (INCLUDING YOUR PURCHASE, SALE, USE, OR
                        CONSUMPTION OF PRODUCTS), EVEN IF FRESHSPIRE OR ITS AFFILIATES KNOW
                        OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  FRESHSPIRE
                        AND ITS AFFILIATES MAXIMUM AGGREGATE LIABILITY FOR ALL CLAIMS
                        RELATING TO THE PLATFORM AND ANY PRODUCTS PURCHASED THROUGH THE
                        PLATFORM SHALL NOT EXCEED THE GREATER OF $100 OR THE AGGREGATE
                        AMOUNT PAID BY OR TO YOU THROUGH PLATFORM TRANSACTIONS IN THE 12
                        MONTHS IMMEDIATELY PRECEEDING THE MOST RECENT CLAIM OR TRANSACTION
                        GIVING RISE TO SUCH LIABILITY.</font></font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={3}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font color="#000000"><font face="Times New Roman, serif"><b>INDEMNIFICATION.
                        </b></font></font><font face="Times New Roman, serif">TO THE
                      FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO INDEMNIFY,
                      DEFEND AND HOLD HARMLESS FRESHSPIRE AND ITS AFFILIATES FROM AND
                      AGAINST ANY </font><font color="#000000"><font face="Times New Roman, serif">DAMAGES,
                        LOSSES, LIABILITIES, SETTLEMENTS AND EXPENSES (INCLUDING WITHOUT
                        LIMITATION COSTS AND ATTORNEYS’ FEES) IN CONNECTION WITH ANY CLAIM
                        OR ACTION THAT ARISES FROM AN ALLEGED OR ACTUAL VIOLATION OF THESE
                        TERMS OR OTHERWISE FROM YOUR USE OF PLATFORM.</font></font></font></font></font></p>
          </li></ul>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={4}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>RELEASE</b></font><font face="Times New Roman, serif">.
                      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU (AND ON
                      BEHALF OF ANY PARTIES RELATED TO YOU) HEREBY RELEASE, WAIVE, ACQUIT
                      AND FOREVER DISCHARGE FRESHSPIRE AND ITS AFFILIATES FROM AND
                      AGAINST, AND COVENANT NOT TO SUE FRESHSPIRE OR ITS AFFILIATES FOR,
                      ALL CLAIMS YOU HAVE OR MAY HAVE ARISING OUT OF OR IN ANY WAY RELATED
                      TO THESE TERMS.  IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE
                      YOUR RIGHTS UNDER CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES
                      “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                      DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
                      EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
                      MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.” IF YOU
                      ARE A RESIDENT OF ANY STATE OTHER THAN CALIFORNIA, YOU HEREBY WAIVE
                      YOUR RIGHTS UNDER ANY STATUTE ANALOGOUS TO CALIFORNIA CIVIL CODE
                      SECTION 1542 WITH RESPECT TO GENERAL RELEASES OF CLAIMS, WHETHER
                      KNOWN OR UNKNOWN.</font></font></font></font>
            </p>
          </li></ul>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={5}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><a name="co_anchor_a1051464_51" /><a name="_30j0zll" /><a name="_gjdgxs" />
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>WAIVER
                        OF TRIAL AND CLASS ACTION; MEDIATION AND ARBITATION.</b></font><font color="#000000"><font face="Times New Roman, serif">EACH
                        OF THE PARTIES WAIVES ITS RIGHTS TO A TRIAL BY JURY OF ALL CLAIMS OR
                        CAUSES OF ACTION (INCLUDING COUNTERCLAIMS) RELATED TO OR ARISING OUT
                        OF THESE TERMS OR USE OF THE PLATFORM.  THIS WAIVER WILL APPLY TO
                        ANY SUBSEQUENT AMENDMENTS OR MODIFICATIONS TO THIS AGREEMENT.  ALL
                        CLAIMS BETWEEN THE PARTIES RELATED TO THE PLATFORM AND/OR THESE
                        TERMS WILL BE LITIGATED INDIVIDUALLY, AND YOU WILL NOT CONSOLIDATE
                        OR SEEK CLASS TREATMENT FOR ANY CLAIM RELATED TO OR ARISING FROM
                        THESE TERMS OR YOUR USE OF THE PLATFORM.  WITH RESPECT TO </font></font><font face="Times New Roman, serif">ANY
                      DISPUTES, CLAIMS, SUITS, ACTIONS, CAUSES OF ACTION, DEMANDS OR
                      PROCEEDINGS (COLLECTIVELY, “</font><font face="Times New Roman, serif"><b>DISPUTES</b></font><font face="Times New Roman, serif">”)
                      ARISING OUT OF THESE TERMS OR THE PLATFORM, YOU AGREE (A) TO WAIVE
                      ANY RIGHTS TO HAVE SUCH DISPUTES RESOLVED IN A COURT (INCLUDING BY A
                      JURY TRIAL), AND (B) TO FIRST MEDIATE, AND, IF SO REQUIRED, THEN
                      ARBITRATE, ALL SUCH DISPUTES.  IN THE CASE THAT MEDIATION IS AT AN
                      IMPASSE, AS DETERMINED BY A MEDIATOR, YOU OR FRESHSPIRE MAY DEMAND
                      AND PROCEED TO BINDING ARBITRATION.  ANY ARBITRATION SHALL BE
                      CONDUCTED IN ACCORDANCE WITH THE EXPEDITED COMMERCIAL ARBITRATION
                      RULES OF THE AMERICAN ARBITRATION ASSOCIATION, AND JUDGMENT UPON THE
                      AWARD RENDERED BY THE ARBITRATOR MAY BE ENTERED IN ANY COURT OF
                      COMPETENT JURISDICTION.  ARBITRATION SHALL BE HELD IN RALEIGH, NORTH
                      CAROLINA, AND SHALL BE CONDUCTED BEFORE A SINGLE ARBITRATOR MUTUALLY
                      AGREEABLE TO YOU AND FRESHSPIRE, OR IF NO AGREEMENT CAN BE REACHED
                      OR IF THE SELECTED ARBITRATOR IS UNAVAILABLE, BEFORE AN ARBITRATOR
                      SELECTED BY THE AMERICAN ARBITRATION ASSOCIATION.</font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={6}>
          <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Notice,
                        Information Dispute Resolution.</b></font><font face="Times New Roman, serif">You
                      and Freshspire agree that each party will notify the other party in
                      writing of any Dispute within thirty (30) days of the date such
                      Dispute arises.  Notice to Freshspire shall be sent by email to
                      Freshspire at help@freshspire.com.  Your notice must include (a)
                      your contact information, the email address listed in your Account,
                      and, any email address at which you desire to be contacted, (b) a
                      description in reasonable detail of the nature or basis of the
                      Dispute, and (c) the specific relief that you are seeking.  Notice
                      to you will be sent electronically in accordance with these Terms. 
                      Any Dispute must be commenced or filed by you or Freshspire within
                      one (1) year of the date the Dispute arose, otherwise the underlying
                      claim is permanently barred, meaning that you will no longer have
                      the right to assert such claim regarding the Dispute.</font></font></font></font>
            </p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><u><b>G.
                      COPYRIGHT AND TRADEMARK</b></u></font></font></font></font></p>
        <p align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
          <font color="#000000"><font face="Times New Roman, serif"><font size={2} style={{fontSize: '11pt'}}>All
                content that you use on the Service must comply with U.S.  copyright
                law, depending on jurisdiction.  If you are the copyright owner or an
                agent thereof and believe, in good faith, that any materials
                currently being used in connection with the Platform infringe upon
                your copyrights, you may submit a notification pursuant to the
                Digital Millennium Copyright Act (see 17 U.S.C 512) (“DMCA”) by
                sending the following information in writing to Freshspire’s 
                designated copyright agent at help@freshspire.com:</font></font></font></p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">The
                      date of your notification; </font></font></font></font>
            </p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">A
                      physical or electronic signature of a person authorized to act on
                      behalf of the owner of an exclusive right that is allegedly
                      infringed; </font></font></font></font>
            </p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">A
                      description of the copyrighted work claimed to have been infringed,
                      or, if multiple copyrighted works at a single online site are
                      covered by a single notification, a representative list of such
                      works at that site; </font></font></font></font>
            </p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">A
                      description of the material that is claimed to be infringing or to
                      be the subject of infringing activity and information sufficient to
                      enable us to locate such work; </font></font></font></font>
            </p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">Information
                      reasonably sufficient to permit</font><font color="#000000"><font face="Times New Roman, serif">
                        Freshspire </font></font><font face="Times New Roman, serif">to
                      contact you, such as an address, telephone number, and/or email
                      address; </font></font></font></font>
            </p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">A
                      statement that you have a good faith belief that use of the material
                      in the manner complained of is not authorized by the copyright
                      owner, its agent, or the law; and </font></font></font></font>
            </p>
          </li><li><p className="western" align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}>
              <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif">A
                      statement that the information in the notification is accurate, and
                      under penalty of perjury, that you are authorized to act on behalf
                      of the owner of an exclusive right that is allegedly infringed</font></font></font></font></p>
          </li></ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <p align="CENTER" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><u><b>H.
                      MISCELLANEOUS</b></u></font></font></font></font></p>
        <p style={{marginLeft: '0.5in', marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Third-Party
                          Content and Links</b></font><font face="Times New Roman, serif">. 
                        We may display content, advertisements and promotions from
                        third-parties on any Freshspire medium of communication
                        (collectively, “</font><font face="Times New Roman, serif"><b>Third-Party
                          Content</b></font><font face="Times New Roman, serif">”).  We do
                        not control, endorse or adopt any Third-Party Content, and we make
                        no representations or warranties of any kind regarding such
                        Third-Party Content.  You are solely responsible for your
                        interaction with Third-Party Content and its providers.  Freshspire
                        is not liable for the quality, content, nature or reliability of
                        any third-party websites, services or materials that are advertised
                        through Freshspire.</font></font></font></font></p>
            </li></ul>
        </ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={2}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Consent
                          to Receive Electronic Communications</b></font><font face="Times New Roman, serif">.
                        By creating an&nbsp;Account, you: (a) grant Freshspire with you
                        prior express written consent to receive electronic communications
                        (emails, text messages, etc.) from Freshspire concerning matters
                        that include, without limitation, notices about your Account,
                        payment authorizations, password changes, promotions, Product
                        specials, Freshspire newsletters, and other such information; and
                        (b) acknowledge that electronic delivery of legal communications
                        will satisfy any requirements concerning notice or communication. 
                        If you do not want to receive promotional communications from us,
                        you may opt out at any time by following the unsubscribe
                        instructions provided in any such communications.</font></font></font></font></p>
            </li></ul>
        </ul>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={3}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><a name="_GoBack" />
                <font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Severability</b></font><font face="Times New Roman, serif">.
                        If any provision of this Agreement is found to be unenforceable or
                        invalid, that provision will be limited or eliminated to the
                        minimum extent necessary so that this Agreement will otherwise
                        remain in full force and effect and enforceable</font><font face="Times New Roman, serif"><b>.</b></font></font></font></font></p>
            </li></ul>
        </ul>
        <p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={4}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Assignability</b></font><font face="Times New Roman, serif">.
                        This Agreement is not assignable, transferable or sublicensable
                        except with Freshspire’s prior written consent.  Freshspire may
                        transfer and assign any of its rights and obligations under this
                        Agreement without your consent.</font></font></font></font></p>
            </li></ul>
        </ul>
        <p style={{marginLeft: '0.25in', marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={5}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Entire
                          Understanding</b></font><font face="Times New Roman, serif">. 
                        Unless stated herein otherwise, this Agreement is the complete and
                        exclusive statement of the mutual understanding of the parties and
                        supersedes and cancels all previous written and oral agreements,
                        communications and other understandings relating to the subject
                        matter of this Agreement, and that all waivers and modifications
                        must be in a writing signed by both parties, except as otherwise
                        provided herein</font><font face="Times New Roman, serif"><b>.</b></font></font></font></font></p>
            </li></ul>
        </ul>
        <p style={{marginLeft: '0.25in', marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={6}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Independent
                          Contractors</b></font><font face="Times New Roman, serif">.  No
                        agency, partnership, joint venture, or employment is created as a
                        result of these Terms and you have no authority of any kind to bind
                        Freshspire in any respect whatsoever.</font></font></font></font></p>
            </li></ul>
        </ul>
        <p style={{marginLeft: '0.25in', marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={7}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Prevailing
                          Parties Fees</b></font><font face="Times New Roman, serif">.  In
                        any action or proceeding to enforce rights under this Agreement,
                        the prevailing party will be entitled to recover costs and
                        attorneys’ fees.</font></font></font></font></p>
            </li></ul>
        </ul>
        <p style={{marginLeft: '0.25in', marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={8}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Notices</b></font><font face="Times New Roman, serif">.
                        Unless provided for otherwise in these Terms, all notices under
                        this Agreement must be in writing and will be deemed to have been
                        duly given when received, if personally delivered; when receipt is
                        electronically confirmed, if transmitted by facsimile or e-mail;
                        the day after it is sent, if sent for next day delivery by
                        recognized overnight delivery service; and upon receipt, if sent by
                        certified or registered mail, return receipt requested.</font></font></font></font></p>
            </li></ul>
        </ul>
        <p style={{marginLeft: '0.25in', marginBottom: '0in', lineHeight: '100%'}}>
          <br />
        </p>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={9}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Governing
                          Laws</b></font><font face="Times New Roman, serif">.  This
                        Agreement shall be governed by the laws of the State of North
                        Carolina without regard to conflict of laws provisions.</font></font></font></font></p>
            </li></ul>
        </ul>
        <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}}>
          <ul style={{marginLeft: '0.3in',listStyleType: 'disc'}} start={10}>
            <li><p align="JUSTIFY" style={{marginBottom: '0in', lineHeight: '100%'}}><font color="#000000"><font face="Calibri, serif"><font size={2} style={{fontSize: '11pt'}}><font face="Times New Roman, serif"><b>Termination</b></font><font face="Times New Roman, serif">.
                        You can stop using the Platform at any time and without notice to
                        us.  Regardless of your termination of use of the Platform, 
                        Sections F through H survive and continue to apply to you. </font></font></font></font>
              </p>
            </li></ul>
        </ul>
      </div>
    )
}

export default TermsOfUse;