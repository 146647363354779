import React from 'react'
import styled, { css } from 'styled-components'
import { inject, observer } from 'mobx-react'
import ConversationView from '../molecules/ConversationView';
import ConversationPane from '../molecules/ConversationPane';
import BackButton from '../atomic/buttons/BackButton';
import MessagesModal from './MessagesModal';
import PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    border: black solid 1px;
    border-radius: 20px;
    background: white;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const StyledConversationView = styled(ConversationView)`
    width: 70%;
    @media (max-width: 700px) {
        ${props => {
            if (!props.focus) {
                return css`
                    display: none;
                `;
            }
            else {
                return css`
                    border-left: 3px solid white;
                    width: 100%;
                `;
            }
        }}
    }
`;

const StyledConversationPane = styled(ConversationPane)`
    width: 30%;
    border-right: black solid 1px;
    box-shadow: 2px 0px 4px 0 rgba(0,0,0,0.16), 2px 0px 10px 0 rgba(0,0,0,0.12) !important;
    @media (max-width: 700px) {
        ${props => {
            if (props.focus) {
                return css`
                    display: none;
                `;
            }
            else {
                return css`
                    width: 100%;
                    max-width: 100%;
                    border-right: none;
                `;
            }
        }}
    }
`;

const EmptyMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const StyledBackButton = styled(BackButton)`
    z-index: 1;
    position: absolute;
    top: 5px;
    left: 0;
    ${props => {
        if (!props.focus) {
            return css`display: none;`;
        }
    }}
    @media (min-width: 701px) {
        display: none;
    }
`;

@inject('messagingStore')
@observer
class Messages extends React.Component {
    state = {
        focus: false,
        selected: null,
        modalOpen: false
    }

    componentWillMount() {
        const conversations = this.props.messagingStore.conversations;
        if (conversations.length > 0) {
            this.onSelect(conversations[0])
        }
    }

    componentDidUpdate() {
        if (!this.state.selected) {
            const conversations = this.props.messagingStore.conversations;
            if (conversations.length > 0) {
                this.onSelect(conversations[0]);
            }
        }
    }

    onSelect(conversation) {
        this.setState({ selected: conversation, focus: true });
        this.props.onFocus && this.props.onFocus();
    }

    onBack() {
        this.setState({ focus: false });
        this.props.onDefocus && this.props.onDefocus();
    }

    onNewConversation() {
        this.setState({ modalOpen: true });
    }

    render() {
        const conversations = this.props.messagingStore.conversations; // Requires dereference in order to react. Passing as prop and never dereferencing will not trigger update
        // This is the dereference lmao. Alternatively, use slice()
        const len = conversations.length; // lol don't delete
        return (
            <Container className={this.props.className}>
                <StyledBackButton onClick={this.onBack.bind(this)} focus={this.state.focus}/>
                <StyledConversationPane
                    focus={this.state.focus}
                    conversations={conversations}
                    length={len}
                    selected={this.state.selected}
                    onSelect={this.onSelect.bind(this)}
                    onNewConversation={this.onNewConversation.bind(this)}/>
                {this.state.selected ? <StyledConversationView focus={this.state.focus} conversation={this.state.selected}/> :
                    <EmptyMessage>
                        No conversation selected
                    </EmptyMessage>
                }
                {this.state.modalOpen && <MessagesModal open={this.state.modalOpen} onClose={() => this.setState({ modalOpen: false })}/>}

            </Container>
        );
    }
}

Messages.propTypes = {
    onFocus: PropTypes.func,
    onDefocus: PropTypes.func
}

export default Messages;