import React from 'react'
import PropTypes from 'prop-types'
import { Conversation } from '../../../core/model'
import styled, { css } from 'styled-components'
import { inject, observer } from 'mobx-react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';

@observer
class NewConversationItem extends React.Component {
    render() {
        return (
            <ListItem className={this.props.className} key={this.props.key} divider button selected={this.props.selected} onClick={this.props.onClick.bind(this)}>
                <ListItemIcon>
                    <Icon>group_add</Icon>
                </ListItemIcon>
                <ListItemText primary={this.props.text} primaryTypographyProps={{noWrap: true}} />
            </ListItem>
            
        );
    }
}

NewConversationItem.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func
}

NewConversationItem.defaultProps = {
    text: "New chat",
    onClick: () => {}
}
export default NewConversationItem;