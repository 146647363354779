import React from'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Checkbox, TextField, FormControl, InputLabel, FormHelperText, FormGroup, FormControlLabel } from '@material-ui/core';

const StyledCheckbox = styled(Checkbox)`
    margin-top: 5px;
`;

const StyledTextField = styled(TextField)`

`;
/**
 * Takes an array of the following format:
 * items: [
 *      {
 *          label: string,
 *          value: any,
 *      }
 * ]
 * 
 * Default values is an array of values that are "selected" by default.
 * If allowOther is enabled and a defaultValue is passed in that is not found
 * in the passed prop "items", then the other field shows that defaultValue
 * 
 * Example:
 * <CheckboxList
        allowOther={true}
        label="Title here"
        items={[
            {
                label: "Label1",
                value: "Value1"
            },
            {
                label: "Label2",
                value: "Value2"
            }
        ]}
        defaultValues={["Value1", "SomeOtherValue"]}
        onChange={(val, checked) => {
            console.log(val, checked);
        }}
    />
 * This displays checkboxes Label1, Label2, and Other, where Label1 and Other are checked,
 * and the Other textfield shows "SomeOtherValue".
 * onChange is automatically triggered for Label1 and SomeOtherValue
 * onChange is a function that accepts a value and a boolean "checked"
 * It is only called when items are checked (not incl the other field) or the
 * OtherField is blurred
 * It is recommended you simply add and remove these values to an array for the callback
 * as such:
 * callback(value, selected) {
 *  if (selected) {
 *      state.arr.add(value);
 *  }
 * else {
 *      state.arr.remove(value) 
 * }
 * }
 */
class CheckboxList extends React.Component {
    /**
     * Items is of the format
     * Item: {
     *      {
     *          label: string,
     *          value: any,
     *          selected: bool
     *      }
     * }
     */
    state = {
        value: '',
        otherValue: '',
        showOther: false,
        items: []
    }
    
    constructor(props) {
        super(props);
        const list = [];
        this.props.items.forEach((item) => {
            list.push({
                label: item.label,
                value: item.value,
                selected: false
            })
        });
        // Auto select any default value passed in
        // Put any defaultValue not found in list into the "other" field
        // if allowed to do so
        if (props.defaultValues) {
            for (let i = 0; i < props.defaultValues.length; i++) {
                let foundMatch = false;
                for (let j = 0; j < list.length; j++) {
                    if (props.defaultValues[i] === list[j].value) {
                        list[j].selected = true;
                        foundMatch = true;
                        break;
                    }
                }
                if (!foundMatch && props.allowOther) {
                    this.state.showOther = true;
                    this.state.otherValue = props.defaultValues[i];
                }
            }
        }

        // Make initial set calls
        list.forEach((item) => {
            if (item.selected) this.props.onChange(item.value, true);
        })
        if (this.state.showOther && this.state.otherValue.length > 0) {
            this.props.onChange(this.state.otherValue, true);
        }

        this.state.items = list;
    }

    getCheckboxes = () => {
        const checkboxes = this.state.items.map((item, i) =>
            <FormControlLabel
                key={i}
                control={
                    <StyledCheckbox
                        value={item.value}
                        checked={item.selected}
                        onChange={(event, checked) => {
                            const items = this.state.items;
                            items[i].selected = checked;
                            this.setState({ items });
                            this.props.onChange(item.value, checked);
                        }}
                    />
                }
                label={item.label}
            />
        );
        if (this.props.allowOther) {
            checkboxes.push(
                <FormControlLabel
                    key={checkboxes.length}
                    control={
                        <StyledCheckbox
                            value={"Other"}
                            checked={this.state.showOther}
                            onChange={(event, checked) => {
                                this.setState({ showOther: checked });
                                if (!checked) {
                                    // Remove the value from record
                                    this.props.onChange(this.state.otherValue, false);
                                    this.setState({ otherValue: '' });
                                }
                            }}
                        />
                    }
                    label={"Other"}
                />
            )
        }
        return checkboxes;
    }

    /**
     * Whenever the textfield value changes, update the state
     */
    handleOtherChange = (event) => {
        const val = event.target.value;
        this.setState({ otherValue: val });
    }

    /**
     * When the user is done editing the Other textfield, submit
     * the changes up to the function passed in for props
     */
    submitOtherChange = () => {
        this.props.onChange(this.state.otherValue, this.state.showOther);
    }

    render() {
        return (
            <React.Fragment>
                <FormGroup row={this.props.row}>
                    <InputLabel required={this.props.required} error={this.props.error} fullWidth>
                            {this.props.label}
                    </InputLabel>
                    {this.getCheckboxes()}
                </FormGroup>
                {this.state.showOther && 
                    <FormControl fullWidth={this.props.fullWidth}>
                            <StyledTextField
                                label="Other"
                                value={this.state.otherValue}
                                onChange={this.handleOtherChange}
                                onBlur={this.submitOtherChange}
                                margin="normal"
                                error={this.props.error}
                            />
                        <FormHelperText error={this.props.error}>{this.props.error ? this.props.errorText : ''}</FormHelperText>
                    </FormControl>
                }
            </React.Fragment>
        );
    }
}

CheckboxList.propTypes = {
    allowOther: PropTypes.bool,
    defaultValues: PropTypes.array,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    fullWidth: PropTypes.bool,
    items: PropTypes.array,
    label: PropTypes.string,
    required: PropTypes.bool,
    row: PropTypes.bool,
    onChange: PropTypes.func
}

CheckboxList.defaultProps = {
    allowOther: false,
    defaultValues: [],
    error: false,
    errorText: '',
    fullWidth: false,
    items: [],
    label: '',
    required: false,
    row: false,
    onChange: () => {}
}

export default CheckboxList;