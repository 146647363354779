import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${props => props.color};
  text-align: center;
  padding: 3px;
  border-radius: 5px;
`;

class Bubble extends React.Component {

  render() {
    const { children, color, enabled, bubbleStyles, ...other } = this.props

    return enabled ? (
      <Wrapper style={bubbleStyles} color={color}>
        <div {...other}>
          {children}
        </div>
      </Wrapper>
    ) : (
      <div {...other}>
        {children}
      </div>
    )
  }
}

Bubble.propTypes = {
  enabled: PropTypes.bool,
  text: PropTypes.string,
  color: PropTypes.string,
}

Bubble.defaultProps = {
  enabled: false,
  color: 'rgba(255,0,0,0.3)'
}

export default Bubble;