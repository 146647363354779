import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment';
import Tooltip from '../../molecules/Tooltip';
import Bubble from '../../molecules/Bubble';
import { observer, inject } from 'mobx-react';
import DatePicker from "components/atomic/pickers/DatePicker";

// const TextArea = styled.div`
//   width: 90px;
// `

const StyledFormHelper = styled(FormHelperText)`
  min-width: 109px;
`

const StyledTextField = styled(TextField)`
  width: 155px;
`

const TextArea = styled(Bubble)`
  ${props => {
        if (props.small) {
            return css`
                width: 40px;
            `
        }
        else {
            return css`
                width: 130px;
            `
        }
    }}
`

@inject('sheetStore')
@observer
class ColumnText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      date: ''
    }
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ date: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { date } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,date)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ date: nextProps.value });
    }
  }

  update = date => {
    this.setState({ date });
  }

  getError = () => {
    const { sheetStore, name, index } = this.props
    if (index >= sheetStore.listingErrors.length) {
      return null;
    }
    const listingError = sheetStore.listingErrors[index];
    if(!listingError) return null;
    
    const stringArry = listingError.split(`${name}:`);

    if (stringArry && stringArry.length > 1) {
      return stringArry[1];
    } else {
      return null;
    }
  }

  render () {
    const { editMode, value, error, small, ...other } = this.props;
    const { date } = this.state;
    const tipError = this.getError();

    return editMode ? (
      <React.Fragment>
        <DatePicker
		  value={date}
		  onChange={(e) => this.update(e)}
		  {...other}
	        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </React.Fragment>
    ) : (
      <Tooltip text={tipError}>
        <TextArea enabled={tipError} small={small}>
          {date === null ? '' : moment(date).format('MM-DD-YYYY')}
        </TextArea>
      </Tooltip>
      
    )
  }
}

ColumnText.propTypes = {
  value: PropTypes.string,
  editMode: PropTypes.bool,
};

ColumnText.defaultProps = {
  editMode: false,
};

export default ColumnText;
