import { createMuiTheme } from '@material-ui/core/styles';

export const MaterialUITheme = createMuiTheme({
    palette: {
        common: {
            black: '#2b2b2b',
        },
        primary: {
            light: '#64dba5',
            main: '#51d79a',
            dark: '#3dd28f',
            contrastText: '#fff',
          },
          secondary: {
            light: '#db6a64',
            main: '#d75751',
            dark: '#d2453d',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: [
            'MuseoSans500',
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
        fontSize: 15,
    },
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                padding: '4px 12px',
                '&:last-child': {
                    position: 'sticky',
                    right: '0px',
                    maxWidth: '36px',
                    backgroundColor: '#64dba5',
                    borderLeft: 'none',
                    boxShadow: 'inset .7px 0 0 0 rgba(224, 224, 224, 1)',
                    padding: '4px 5px',
                }
            }
        },
        MUIDataTableHeadCell: {
            fixedHeader: {
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                left: 'auto',
                padding: '4px 12px',
                zIndex: '1',
                backgroundColor: 'rgb(237, 251, 244)',
                '&:last-child': {
                    position: 'sticky',
                    right: '0px',
                    borderLeft: 'none',
                    boxShadow: 'inset .7px 0 0 0 rgba(224, 224, 224, 1)',
                }
            },
            sortAction: {
                position: 'absolute',
                bottom: '11px',
                right: '0',
            }
        },
        MUIDataTableToolbar: {
            root: {
                borderBottom: '1px solid rgba(224, 224, 224, 1)'
            }
        },
        MUIDataTableViewCol: {
            formControl: {
                '&:last-child': {
                    display: 'none',
                }
            }
        }
    }
  });