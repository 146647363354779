// Modified from 
// https://github.com/utarit/react-welcome-page
import React from 'react';
import Container from './Components/Container/Container';
import OuterContainer from './Components/OuterContainer/OuterContainer';
import InnerContainer from './Components/InnerContainer/InnerContainer';
import Logo from './Components/Image/Image';
import LogoText from './Components/LogoText/LogoText';
import styled, { css } from 'styled-components';
import Icon from '@material-ui/core/Icon';
import Button from '../../atomic/buttons/Button'
import breakpoint from 'styled-components-breakpoint'
import LogoutButton from '../../atomic/buttons/LogoutButton'

const ButtonContainer = styled.div`
    position:fixed;
    width: 100%;
    height: 60px;
    bottom: 60px;
    display: flex;
    justify-content: center;
    z-index: 1;
    ${breakpoint('mobile_max', 'tablet_max')`
        bottom: 40px;
    `}
    ${breakpoint('mobile_min', 'mobile_max')`
        bottom: 20px;
    `}
`

const StyledButton = styled(Button)`
    width: 50px;
    margin: 0 48px;
    ${props => {
        if (props.hide) {
            return css`
                display: none;
            `
        }
    }}
`

const FinishButton = styled(Button)`
    width: 100px;
    margin-top: 25px;
    z-index: 3;
`

const StyledIcon = styled(Icon)`
    font-size: 2em;
`

const StartEndContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LogoutButtonContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 73px;
    bottom: 25px;
    padding-top: 25px;
    display: flex;
    justify-content: center;
    ${breakpoint('mobile_min', 'mobile_max')`
        bottom: 10px;
    `}
    z-index: 2;
`
class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            finished: false,
            index: 0
        };
        this.timer = this.timer.bind(this);
    }


    componentDidMount(){
        if(this.props.data.length === 0) {
            this.setState({finished: true});
        } else {
            this.setState({index: 0})
            //this.timer(0);
        }
    }

    timer(i) {
        const dur = this.props.loopDuration ? this.props.loopDuration : 1000;
        this.setState({index: i});
        if(i < this.props.data.length - 1) {
            setTimeout( () => this.timer(i+1), dur);
        } else {
            setTimeout( () => this.setState({finished: true}), dur);
        }
        
    }

    next = () => {
        let i = this.state.index;
        if (i >= 0 && i < this.props.data.length - 1) {
            this.setState({ index: i + 1})
        }
    }

    previous = () => {
        let i = this.state.index;
        if (i > 0 && i < this.props.data.length) {
            this.setState({ index: i - 1})
        }
    }

    finish = async () => {
        this.setState({ finished: true })
        if (this.props.onFinish) {
            await this.props.onFinish()
        }
    }

    render() {
        const {
            image,
            text = '',
            imageAnimation = 'rotateIn',
            textAnimation = 'fadeInDown',
            backgroundColor = 'whitesmoke',
            textColor = 'black',
            size = 'small',
            showActionButton = false,
            showLogoutButton = false
            } = this.props.data[this.state.index];
            
            const prevBackColor = this.state.index !== 0 ? this.props.data[this.state.index - 1 ].backgroundColor : backgroundColor;


        return(
            <Container status={this.state.finished ? 'finished' : 'moveOn'} >
                <OuterContainer 
                    prevBackColor={prevBackColor}
                    backColor={backgroundColor}
                    index={this.state.index}
                    key={this.state.index}
                    >
                        <InnerContainer>
                            <Logo className={`animated ${imageAnimation}`} image={image} size={size}/>
                            <LogoText
                                textColor={textColor}
                            className={`animated ${textAnimation}`} > {text} </LogoText>
                            {
                                this.state.index === this.props.data.length - 1 ?
                                (
                                <StartEndContainer>
                                    {
                                        showActionButton &&
                                        <FinishButton variant="contained" color="primary" onClick={ async () => await this.finish()}>
                                            Start
                                        </FinishButton>
                                    }
                                    {
                                        showLogoutButton &&
                                        <LogoutButtonContainer>
                                            <LogoutButton withText/>
                                        </LogoutButtonContainer>
                                    }
                                    
                                    
                                </StartEndContainer>
                            )
                                :
                                ''
                            }
                            
                        </InnerContainer>
                </OuterContainer>
                <ButtonContainer>
                    <StyledButton hide={this.state.index === this.props.data.length - 1} disabled={this.state.index === 0} onClick={this.previous}>
                        <StyledIcon>arrow_back</StyledIcon>
                    </StyledButton>
                    <StyledButton hide={this.state.index === this.props.data.length - 1} disabled={this.state.index === this.props.data.length - 1} onClick={this.next}>
                        <StyledIcon>arrow_forward</StyledIcon>
                    </StyledButton>
                </ButtonContainer>
            </Container>
        )
    }

}

export default Welcome;