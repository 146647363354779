import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import Popup from 'reactjs-popup';
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import BottomBar from '../../CommonBottomBar'
import Check from '@material-ui/icons/Check'
import Button from '../../atomic/buttons/Button'
import { LoadingSpinnerPage } from '../../LoadingSpinner';


const Container = styled.div`
  ${'' /* padding-bottom: 20px; */}
`
const HeaderContainer = styled.div`
  width: 100%;
  font-size: 1.3em;
  margin: 3px 0;
  color: ${props => props.theme.colors.spearmint_dark};
  font-family: ${props => props.theme.fonts.ms_font300};
  @media (max-width: 550px) {
    font-size: 0.8em;
  }
`

const Content = styled.div`
  display: flex;
  background: #fff;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 0 auto;
`

const EditWrapper = ({
  header,
  children,
  ...other
}) => {
  return (
    <Container {...other}>
    {
      header &&
      <HeaderContainer>
        {header}
      </HeaderContainer>
    }
    <Content>
      {children}
    </Content>
    </Container>
  )
}

EditWrapper.PropTypes = {

}

EditWrapper.defaultProps = {

}

export default EditWrapper; 
