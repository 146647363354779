import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Truncate from 'react-truncate';

const Container = styled.div`
  position: relative;
  padding: 10px 20px 25px 20px;
  font-family: Georgia;
  font-size: 16px;
`;

const TextBody = styled.div`
  font-size: 16px;
  font-family: Georgia;
`

const Fill = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 27%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
`

const FillText = styled.div`
  margin: auto 10px 0 auto;
  font-size: 12px;
  opacity: 0.5;
`

const Body = ({
  text,
  onTextChange,
  onClick,
  expanded,
  lines,
  ...other 
}) => {
  const [needsTrunc,setTrunc] = useState(true)
  const [truncInt,setInt] = useState(0)

  const createMarkup = () => {
    return {__html: text};
  }

  const handleTruncate = trun => {
    setInt(truncInt + 1)
    if(!trun) {
      setTrunc(trun)
    }
  }

  return (
    <Container onClick={needsTrunc && onClick} {...other}>
      {(!expanded && needsTrunc) && <Fill>
        <FillText>Click to view</FillText>
      </Fill>}
      <Truncate lines={needsTrunc ? (!expanded && lines) : -1} onTruncate={truncInt <= 0 && handleTruncate}>
        <TextBody dangerouslySetInnerHTML={createMarkup()} />
      </Truncate>
    </Container>
  )
}

Body.propTypes = {
  
}

Body.defaultProps = {
}

export default Body;