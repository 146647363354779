import { action, observable, computed } from 'mobx'
import * as searchjs from 'searchjs';
import { clientListStore } from '../../stores'
import Geography from '../../core/utilities/Geography'

class ClientSearchStore {
    @observable clientList = [];
    @observable totalClientList = [];
    @observable unrelatedList = [];
    @observable pendingList = [];
    @observable invitationList = [];
    @observable query = null;
    @observable distance = 100;
    @observable queryDistance = 100;
    @observable sortCondition = 'dist-asc';

    @computed get ClientList() {
        if (!this.clientList)
            return this.clientList;
        
        let _matches = this.clientList;

        if (this.query)
        _matches = this.matchQuery(_matches);
        
        _matches = this.applySort(_matches);

        if(_matches === undefined)
            return [];

        return _matches;
    }

    @computed get TotalClientList() {
        if (!this.totalClientList)
            return this.totalClientList;
        
        let _matches = this.totalClientList;

        if (this.query)
        _matches = this.matchQuery(_matches);
        
        _matches = this.applySort(_matches);

        if(_matches === undefined)
            return [];

        return _matches;
    }

    @computed get UnrelatedList() {
        if (!this.unrelatedList)
            return this.unrelatedList;
        
        let _matches = this.unrelatedList;

        if (this.query)
        _matches = this.matchQuery(_matches);
        
        _matches = this.applySort(_matches);

        if(_matches === undefined)
            return [];

        return _matches;
    }

    @computed get CombinedClientList() {
        if (!this.unrelatedList && !this.clientList)
            return this.unrelatedList;

        let _matches = [ ...this.unrelatedList, ...this.clientList ];
        
        if (this.query)
        _matches = this.matchQuery(_matches);
        
        _matches = this.applySort(_matches);

        if(_matches === undefined)
            return [];

        return _matches;
    }

    @computed get PendingList() {
        if (!this.pendingList)
            return this.pendingList;
        
        let _matches = this.pendingList;

        if (this.query)
        _matches = this.matchQuery(_matches);

        _matches = this.applySort(_matches);

        if(_matches === undefined)
            return [];

        return _matches;
    }

    @computed get InvitationList() {
        if (!this.invitationList)
            return this.invitationList;
        
        let _matches = this.invitationList;

        if (this.query)
        _matches = this.matchQuery(_matches);
        
        _matches = this.applySort(_matches);

        if(_matches === undefined)
            return [];

        return _matches;
    }

    isFiltered() {
        return Object.values(this.filterConditions).reduce((a,b) => {
            return  {active: a.active || b.active};
        }).active
    }

    matchQuery(list) {
        return searchjs.matchArray(list, { "name": this.query, _propertySearch: true, _text: true })
    }

    matchFilterConditions(list, strict = false) {
        let newList = strict? list : [];
        const conditionList = Object.values(this.filterConditions).filter((condition) => {return (condition.strict === strict && condition.active)})
        if(conditionList.length > 0){
            conditionList.map((condition) => {
                let morphList = strict? newList: list; 
                let { field, value } = condition;
                let arr = searchjs.matchArray(morphList, { [field]: value, _propertySearch: true});
                newList = strict? arr : [...new Set([...newList, ...arr])];
            })
            return newList;
        } else {
            return list;
        }
    }

    nameSort(list, ascending) {
        if (ascending) {
            list = list.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            })
        } else {
            list = list.sort((a, b) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
            })
        }
        return list;
    }

    stateSort(list, ascending) {
        if (ascending) {
            list = list.sort((a, b) => {
                return a.state.toLowerCase() > b.state.toLowerCase() ? 1 : -1;
            })
        } else {
            list = list.sort((a, b) => {
                return a.state.toLowerCase() > b.state.toLowerCase() ? -1 : 1;
            })
        }
        return list;
    }

    distanceSort(list, ascending) {
        if (ascending) {
            list = list.sort((a, b) => {
                if(typeof a.distance === 'string') {
                    return 1;
                }
                return a.distance > b.distance ? 1 : -1;
            })
        } else {
            list = list.sort((a, b) => {
                if(typeof a.distance === 'string') {
                    return -1;
                }
                return a.distance > b.distance ? -1 : 1;
            })
        }
        return list;
    }

    applySort(list) {
        switch (this.sortCondition) {
            case 'name-asc':
                list = this.nameSort(list, true);
                break;
            case 'name-desc':
                list = this.nameSort(list, false);
                break;
            case 'state-asc':
                list = this.stateSort(list, true);
                break;
            case 'state-desc':
                list = this.stateSort(list, false);
                break;
            case 'dist-asc':
                list = this.distanceSort(list, true);
                break;
            case 'dist-desc':
                list = this.distanceSort(list, false);
                break;
            default:
                break;
        }
        return list;
    }

    @action
    resetSearch() {
        this.clientList = [];
        this.unrelatedList = [];
        this.pendingList = [];
        this.invitationList = [];
        this.query = null
        this.sortCondition = 'dist-asc'
    }

    @action
    updateSort(sort) {
        this.sortCondition = sort;
    }

    @action
    setValidator = (validator, active) => {
        this.filterConditions[validator].active = active;
    }
    
    @action
    onRadioChange = (validator, value, active) => {
        this.filterConditions[validator].active = active;
        this.filterConditions[validator].value = value;
    }

    @action
    updateQuery(query) {
        this.query = query;
    }

    @action
    updateQueryDistance(dist) {
        this.queryDistance = dist;
    }

    @action
    updateDistance() {
        this.distance = this.queryDistance;
    }

    @action
    refresh() {
        this.clientList = clientListStore.clientList;
        this.totalClientList = clientListStore.totalClientList;
        this.unrelatedList = clientListStore.unrelatedList;
        this.pendingList = clientListStore.pendingList;
        this.invitationList = clientListStore.invitationList;
    }

}
const clientSearchStore = new ClientSearchStore();
export default clientSearchStore
