import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

class BackButton extends React.Component {
    render() {
        return (
            <IconButton className={this.props.className} color="inherit" onClick={this.props.onClick}>
                <Icon>
                    arrow_back
                </Icon>
            </IconButton>
        );
    }
}

BackButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default BackButton;