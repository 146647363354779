import { observable, toJS } from 'mobx'

class Invitation {
    @observable email
    @observable id
    @observable message
    @observable recipient
    @observable sender
    @observable status  // sent, resent, accepted
    @observable timestamp  // date of last update

    constructor(email, id, message, recipient, sender, status, timestamp) {
        Object.assign(this, { email, id, message, recipient, sender, status, timestamp });
    }
}

Invitation.displayName = "Invitation";
export default Invitation;
