import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import Popup from 'reactjs-popup'
import { SearchIcon, LocationIcon } from '../../components/Icons'
import Menu from '../../components/Menu'
import {LoadingSpinnerPage} from '../../components/LoadingSpinner'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styled, { css, withTheme } from 'styled-components';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '../../components/atomic/buttons/Button';
import LinkButton from '../../components/atomic/buttons/LinkButton';
import ToggleButton from '../../components/atomic/buttons/ToggleButton';
import ViewToggle from '../../components/molecules/ViewToggle';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import InventorySheet from '../../components/organisms/InventorySheet';
import StorefrontSheet from '../../components/organisms/StorefrontSheet';
import Topbar from '../../components/CommonTopBar'
import SearchBar from '../../components/SearchBar'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ListItem from '../../components/ListItem';
import Bottombar from '../../components/CommonBottomBar'
import Add from '@material-ui/icons/Add';
import ProductListingGroupCard from '../../components/organisms/listCards/ProductListingGroupCard'
import ProductListingGroupModal from '../../components/organisms/modals/ProductListingGroupModal'
import { message } from 'antd'
import ProfileLink from '../../components/atomic/ProfileLink'
import CircularImage from '../../components/atomic/CircularImage';
import Placeholder from '../../components/molecules/Placeholder';
import BuyerSelect from '../../components/organisms/BuyerSelect';
import { toJS } from 'mobx';

const ProductCard = styled(ProductListingGroupCard)`
  margin: 8px 10px;
  border-radius: 10px;
`;

const PlaceholderStyled = styled(Placeholder)`
  margin: 50px auto;
`

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 170px;
`

const topTabs = [
  {
    name: 'Inventory',
    child: false,
    path: '/deals'
  },
  {
    name: 'Storefront',
    child: false,
    path: '/storefront'
  },
  {
    name: 'Preview',
    child: false,
    path: '/preview'
  },
];

const SheetContainer = styled.div`
  margin-top: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sidebar_off_max}px) {
    margin-top: 65px;
  }
`;

const Information = styled.div`
  margin: 15px;
`;

const Header = (props) => <Typography variant="subtitle1">{props.children}</Typography>
const Description = (props) => <Typography variant="subtitle3">{props.children}</Typography>;

@inject('inventoryStore', 'clientListStore', 'storefrontStore', 'searchStore', 'sheetStore')
@observer
class ViewDeals extends Component {

  constructor (props) {
    super(props)
    this.state = {
      isLoadingPage: true,
      isLoadingDeals: false,
      deals: [],
      productListings: [],
      productListingGroups: [],
      currentListingGroup: null,
      dealSearchQuery: '',
      searchQueryType: 'name',
      open: false,
      tableView: false,
      viewAs: null,
    }
  }
  
  // componentWillMount() {
  //   let isTableView = sessionStorage.getItem('deal_table_view')
  //   if(isTableView === null) {
  //     sessionStorage.setItem('deal_table_view',false)
  //     this.setState({tableView: false})
  //   } else {
  //     const bool = isTableView === 'true'
  //     this.setState({tableView: bool})
  //   }
  // }

  async componentDidMount() {
		const { storefrontStore } = this.props;
		storefrontStore.setViewRestriction(null);
    await this.prepareData();
  }

  async componentWillReceiveProps(nextProps) {
    await this.prepareData(true);
  }

  prepareData = async (skip) => {
    console.log("Preparing data")
    const { inventoryStore, storefrontStore, clientListStore, searchStore } = this.props
    try {
      this.setState({isLoadingPage: true})
      await clientListStore.getAllClients();
			let deals = await inventoryStore.getDeals();
			console.log("Got deals: ", deals);
      const { productListings, productListingGroups } = await storefrontStore.getDeals();
      console.log("Got product lstings and groups", productListings, productListingGroups);
      this.setState({
				isLoadingPage: false,
        productListings,
				productListingGroups,
        deals
      })
      console.log("Skip is ", skip);
      if(!skip) {
        searchStore.setList(productListingGroups);
      }
      window.scroll(0, 0);
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  searchIfPressEnter = (e) => {
    if (e.key === 'Enter') {
      this.onDealSearchQueryChange()
    }
  }

  onDealSearchQueryChange = () => {
    this.setState({
      dealSearchQuery: this.searchQuery.value
    })
  }

  onChangeSearchQueryType = (e) => {
    this.setState({
      searchQueryType: e.target.value
    })
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  textSnipper = (text, limit) => {
    if (text.length > limit) {
      text = (text.substring(0, limit)) + "...";
    }

    return text;
  }

  filterDealsByView = (deals) => {
    const { viewAs } = this.state;
    if (!viewAs) {
      return deals;
    }

    const newDeals = [];
    for(let i = 0; i < deals.length; i++) {
      const foundOne = false;
      const deal = toJS(deals[i]);
      const listings = deal.productListings;
      const newListings = listings.filter(listing => {
        if(Object.keys(listing.visibility).includes(viewAs.value)) {
          foundOne = true;
          return true;
        }
      })
      deal.productListings = newListings;
      if(foundOne) {
        newDeals.push(deal);
      } 
    }
    return newDeals
  }

  getDealItems() {
		const { searchStore, history, storefrontStore } = this.props
    const { productListingGroups } = this.state;
    // this.viewCategory()
    let filteredDeals = this.props.searchStore.List.slice();

    if(productListingGroups.length === 0 && !storefrontStore.viewRestriction){
      return (
        <div className="empty-text">
          <PlaceholderStyled
            title="No Products Found"
            image={require('../../assets/placeholder.png')}
            icon="close"
            subtext={[
              'To add an item to your storefront',
              'Click below'
            ]}
            onClick={() => history.push('/storefront')}
            buttonIcon='add'
            buttonText='Add New Product'
          />
        </div>
      )
    } else if(filteredDeals.length === 0){
      return (
        <div className="empty-text">
          <Typography variant="title" className="text">
            No Results Found
          </Typography>
        </div>
      )
    }

    // const restrictedDeals = this.filterDealsByView(filteredDeals);

    const cards = [];
    for (let i = 0; i < filteredDeals.length; i++) {
      const group = filteredDeals[i];
      cards.push(
        <ProductCard
          key={group.product.id}
          productGroup={group}
          BasketItemsList={[]}
          onDetailsClicked={(group, productListing) => this.setState({ currentListingGroup: group, currentListing: productListing })}
          restricted={searchStore.listIsOpen}
          productOwner={true}
        />
      )
    }
    return cards;
  }

  checkout = () => {
    //route to shopping cart
    const { history } = this.props
    history.push('/shoppingcart')
  }

  getDealsData = () => {
    const { dealData } = this.state;

    return dealData;
  }

  tableRowEditToggle = (index, editBoolean) => {
    const { dealData } = this.state;
    const editFieldIndex = dealData[index].length - 1;
    let data = dealData.slice();

    data[index][editFieldIndex] = editBoolean;

    this.setState({ dealData: data });
  }

  toggleView = async () => {
    const { tableView, isLoadingPage } = this.state;
    const { inventoryStore, searchStore } = this.props
    sessionStorage.setItem('deal_table_view',!tableView)
    try {
      if(!isLoadingPage) {
        this.setState({ isLoadingPage: true })
        let deals = await inventoryStore.getDeals();
        this.setState({
          isLoadingPage: false,
          deals
        })
        searchStore.setList(deals);
        window.scroll(0, 0);
      }
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
    this.setState({tableView: !tableView})
  }

  TopBarButtons = (tableView) => {
    return (
      <React.Fragment>
        <LinkButton text='Add Item' icon='add' path='/deals/add' className='tablet-hide' tabletHide={true}/>
        <ViewToggle text={tableView?'Card View':'Bulk Edit'} icon={tableView?'view_list':'table_chart'} onClick={async () => this.toggleView()}/>
      </React.Fragment>
    )
  }

  mobileButtons = () => {
    return (
      <Icon 
        className="icon mobil-ft"
        onClick={this.toggleView}
        style={{'marginRight': '25px'}}
      >
        {this.state.tableView?'view_agenda':'table_chart'}
      </Icon>
    )
  }

  getTabIndex(tabs) {
    const path = this.props.location.pathname;
    for (let i of tabs.keys()) {
      if(tabs[i].path.indexOf(path) > -1){
        return i;
      }
    }
  }

  getSheetType = (index) => {
    const { deals, productListings } = this.state;
    
    switch(index) {
      case 0:
        return <SheetContainer><InventorySheet data={deals} storeFront={productListings}/></SheetContainer>;
      case 1:
        return <SheetContainer><StorefrontSheet data={productListings} products={deals}/></SheetContainer>;
      case 2:
        return null;
    }
  }

  getTitle = (index) => {
    switch(index) {
      case 0:
        return 'Inventory';
      case 1:
        return 'Storefront';
      case 2:
        return 'Preview';
    }
  }

  getSubtitle = (index) => {
    switch(index) {
      case 0:
        return 'Upload and keep track of your inventory.';
      case 1:
        return 'Publish an inventory item for sale.';
      case 2:
        return 'This is how your buyers see your store';
    }
  }

  getInformation = index => {
    if(index < 2) {
      return (
        <Information>
          <Header>
            Need help getting started?
          </Header>
          <Description>
            {
              index === 0 ?
              <React.Fragment>
                This page is private and only visible to you.
                Enter the products you grow here to keep track of their quantity.
                To publish an item for sale, create an inventory item on this page and visit
                the <Link id="info_link_storefront" to="/storefront">Storefront Tab</Link> to publish the item to your store, as well as to set its price and visibility.
                For more information on how to use this page, visit the <a id="sellers_info_link_guide" target="_blank" href={'https://freshspire.gitbook.io/freshspire-user-guide/sellers/inventory-and-storefront/inventory-management'}>Inventory Management User Guide.</a>
              </React.Fragment>
              :
              <React.Fragment>
                Publish an <Link id="info_link_inventoy" to="/deals">inventory item</Link> for sale here and sell it in different variants.
                You can specify multiple listings for each individual product in order to offer it for sale in multiple units or to specify prices on a buyer per buyer basis.
                You can customize who can see your listing, at what price they can see it, and what units they can purchase it in.
                For more information on how to use this page or how your store will look to buyers, visit the <a id="sellers_info_link_guide" target="_blank" href={'https://freshspire.gitbook.io/freshspire-user-guide/sellers/inventory-and-storefront/storefront'}>Storefront and Product Variants User Guide.</a>
              </React.Fragment>
            }
          </Description>
        </Information>
      )
    }
  }

  changePerspective = async (value) => {
    const { storefrontStore } = this.props;
		this.setState({ viewAs: value, isLoadingDeals: true });
    storefrontStore.setViewRestriction(value ? value.value : null)
		await storefrontStore.getDeals();
		this.setState({  isLoadingDeals: false });
  };

  getProducts = index => {
    const { clientListStore, storefrontStore } = this.props;
    const { viewAs, isLoadingDeals } = this.state;

    const clientOptions = clientListStore.totalClientList.map(item => ({ label: item.name, value: item.id }))
    if(index !== 2) {
      return null;
    }

    return (
      <React.Fragment>
        <SearchBar key={`searchbar-${index}`} hideFarmerFilter={true}/>
        <ul className="deals-list lg-margin">
          <BuyerSelect
						id="buyer-select"
						text="View as:"
            value={viewAs}
            onChange={this.changePerspective}
            options={clientOptions}
            isClearable
						isLoading={isLoadingDeals}
          />
          {
						isLoadingDeals ? (
							<SpinnerContainer>
								<LoadingSpinnerPage />
							</SpinnerContainer>
              ) :
          		this.getDealItems()
					}
        </ul>
      </React.Fragment>
    )
  }
  
  render() {
    const { isLoadingPage, tableView, deals, currentListingGroup } = this.state

    const tabIndex = this.getTabIndex(topTabs);
    const title = this.getTitle(tabIndex);
    const subtitle = this.getSubtitle(tabIndex);

    return (
      <React.Fragment>
        <Menu/>
        <Topbar
          title={title}
          searchBar={tabIndex === 2}
          tabs={topTabs} 
          sub={subtitle} 
          tabIndex={tabIndex} 
          isChildPath={false} 
          mobilft={true}
          // mobileButtons={this.mobileButtons()}
          // topBarButton={tableView?<ViewToggle text={tableView?'Card View':'Bulk Edit'} icon={tableView?'view_list':'table_chart'} onClick={this.toggleView}/>:this.TopBarButtons(tableView)}
        />
        <div className={`deals-container ${tableView?'table':''}`}>
          {
            true ?
              isLoadingPage ? (
                <LoadingSpinnerPage />
              ) :
              <React.Fragment>
                {this.getSheetType(tabIndex)}
                {this.getInformation(tabIndex)}
                {this.getProducts(tabIndex)}
              </React.Fragment>
            :
            {/* <React.Fragment>
              <Bottombar buttons={navButtons}/>
              <SearchBar visibility={true} shadow={true}/>
              {
                isLoadingPage ?
                  <LoadingSpinnerPage />
                  :
                  <ul className="deals-list">
                    {this.getDealItems()}
                  </ul>
              }
              <div className="tool-tip-container">
                <Tooltip title="Add Item" placement="bottom-end">
                  <Link to="/deals/add">
                    <Button variant="fab" color="secondary">
                      <Icon>add</Icon>
                    </Button>
                  </Link>
                </Tooltip>
              </div>
            </React.Fragment> */}
          }
        </div>
        {
          currentListingGroup &&
          <ProductListingGroupModal
            defaultListingID={this.state.currentListing.id}
            productListingGroup={currentListingGroup}
            open={currentListingGroup !== null}
            onClose={() => this.setState({currentListingGroup: null})}
            productOwner={true}
            type={`basket${this.props.searchStore.listIsOpen? '_restricted': ''}`}
          />
        }
      </React.Fragment>
    )
  }
}

export default withRouter(ViewDeals)
