import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip';
import validate from 'core/utilities/FormValidator';

const constraints = {
  field: {
    email: true
  }
};

class ChipPanel extends Component {
  constructor (props) {
    super(props)
    this.inputText =  '';
    this.hasError = false; 
    this.chipInput = React.createRef();
  }
  componentDidMount() {
    ReactDOM.findDOMNode(this).addEventListener('paste', this.handlePaste);
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this).removeEventListener('paste', this.handlePaste);
  }

  defaultParsePaste = str => {
    return str.split(/\r\n|\,\s|\n|\r|\s|\,/).filter(function (row) {
      if(!row.match(/^\s*$/)) {
        return row.split('\t');
      }
    });
  }

  handlePaste = (e) => {
    let paste = [];
    if(typeof e === 'string'){
      paste = this.defaultParsePaste(e);
    } else {
      paste = this.defaultParsePaste(e.clipboardData.getData('text/plain'));
      this.chipInput.getInputNode().blur();
    }
    this.props.addChip(paste);
  }

  validateEmail = (value) => {
    const err = validate({field: value},constraints);

    if (err !== undefined) {
      this.hasError = true;
      return 'secondary';
    }else {
      return 'default';
    }
  }

  updateInput = (inputText) => {
    this.inputText = inputText;
  }

  convertInput = () => {
    this.handlePaste(this.inputText);
    this.inputText = '';
  }

  render () {
    this.hasError = false;
    return (
      <ChipInput
        value={this.props.chips}
        onRequestAdd={(chip) => this.handlePaste(chip)}
        onRequestDelete={(chip, index) => this.props.deleteChip(chip,index)}
        fullWidth={true}
        hintText='Type emails into this area'
        errorText={this.props.error}
        newChipKeyCodes={[13, 188, 32]}
        onUpdateInput={(txt) => this.updateInput(txt)}
        onBlur={this.convertInput}
        ref={(instance) => { this.chipInput = instance }}
        chipRenderer={(
          {
            value,
            handleClick,
            handleRequestDelete,
            defaultStyle
          },
          key
        ) => (
          <Chip
            key={key}
            style={{...defaultStyle}}
            onClick={handleClick}
            onDelete={handleRequestDelete}
            label={value}
            color={this.validateEmail(value)}
          />
        )}
      />
    )
  }
}

export default ChipPanel
