import { computed, observable, toJS } from 'mobx'

class Business {
    @observable airtable
    @observable certifications
    @observable city
    @observable closing
    @observable communicationOptions
    @observable description
    @observable email
    @observable foodOptions
    @observable id
    @observable name
    @observable opening
    @observable organization
    @observable orderNotes
    @observable paymentOptions
    @observable phone
    @observable state
    @observable streetAddress
    @observable type
    @observable zipcode

    get coordinates() {
    	return this._coordinates || [0, 0];
    }

    @computed set coordinates(coordinates) {
    	// Validate
        if (Array.isArray(coordinates)) {
            this._coordinates = coordinates;
        }
        else {
            this._coordinates = [coordinates.l._latitude, coordinates.l._longitude];
        }
    }

    @computed get picture() {
        if (this._picture) {
            return this._picture.url;
        } else {
            return require('../../assets/placeholder_user.png');
        }
    }

    set picture(picture) {
        this._picture = picture;
    }

    constructor(certifications = [], city = null, closing = null, communicationOptions = [], coordinates = [0, 0], description = null,
                email = null, foodOptions = [], id = null, name = null, opening = null, organization = null, paymentOptions = [], picture = null,
                phone = null, state = null, streetAddress = null, type = null, zipcode = null, orderNotes = "") {
        this.certifications = certifications;
        this.city = city;
        this.closing = closing;
        this.communicationOptions = communicationOptions;
        this.coordinates = coordinates;
        this.description = description;
        this.email = email;
        this.foodOptions = foodOptions;
        this.id = id;
        this.name = name;
        this.opening = opening;
        this.organization = organization;
        this.paymentOptions = paymentOptions;
        this.picture = picture;
        this.phone = phone;
        this.state = state;
        this.streetAddress = streetAddress;
        this.type = type;
        this.zipcode = zipcode;
        this.orderNotes = orderNotes;
    }

    formatForDB() {
        let data = observable({
            city: this.city,
            closing: this.closing,
            communicationOptions: this.communicationOptions,
            description: this.description,
            email: this.email,
            foodOptions: this.foodOptions,
            name: this.name,
            nameInsensitive: this.name ? this.name.toLowerCase() : this.name,
            opening: this.opening,
            organization: this.organization,
            paymentOptions: this.paymentOptions,
            phone: this.phone,
            state: this.state,
            streetAddress: this.streetAddress,
            type: this.type,
            zipcode: this.zipcode,
            orderNotes: this.orderNotes
        });
        return toJS(data);
    }

    getProfileLink() {
        return `/profile/${this.id}`;
    }

    isSeller() {
        return (this.type === 'seller');
    }

    isBuyer() {
        return (this.type === 'buyer');
    }
}

Business.displayName = "Business";
export default Business;
