import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ConversationListItem from './ConversationListItem'
import NewConversationItem from './NewConversationItem'
import { inject, observer } from 'mobx-react'
import { Conversation } from '../../../core/model/'
import List from '@material-ui/core/List';

const PinnedList = styled(List)`
    width: 100%;
`;

const StyledList = styled(List)`
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

const Container = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
`;

const StyledNewConversationItem = styled(NewConversationItem)`
    box-shadow: 0px 5px 10px 0 rgba(0,0,0,0.16);
`;

const EmptyMessage = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

@inject('messagingStore')
@observer
class ConversationPane extends React.Component {
    state = {
        collapsed: false
    }

    constructor(props) {
        super(props);
        this.container = React.createRef();
    }

    getPreviews() {
        const previews = this.props.conversations.map((conversation) => <ConversationListItem key={conversation.id} conversation={conversation} selected={this.props.selected && this.props.selected.id === conversation.id} onClick={this.props.onSelect}/>);
        if (previews.length === 0) {
            return (
                <EmptyMessage>
                    Click "new chat" to start!
                </EmptyMessage>
            )
        }
        return previews;
    }
   
    render() {
        return (
            <Container className={this.props.className}>
                <PinnedList>
                    <StyledNewConversationItem onClick={this.props.onNewConversation} />
                </PinnedList>
                <StyledList>
                    {this.getPreviews()}
                </StyledList>
            </Container>
        );
    }
}

/*
<Container>
                <PinnedList>
                    <NewConversationItem onClick={this.onNewConversation} />
                </PinnedList>
                */

ConversationPane.propTypes = {
    conversations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired, // Actually MobX observable array
    selected: PropTypes.instanceOf(Conversation),
    onSelect: PropTypes.func,
    onNewConversation: PropTypes.func
}

ConversationPane.defaultProps = {
    onSelect: () => {},
    onNewConversation: () => {}
}

export default ConversationPane