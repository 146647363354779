import Service from './Service';
import Order from "../model/Order";
import DatabaseClient from "core/DatabaseClient";
import { isObservableArray } from "mobx";
import BusinessService from "./BusinessService";

const URL = 'order';

class OrderService extends Service {
    async deserialize(data) {
        let res;
        if (Array.isArray(data) || isObservableArray(data)) {
            res = await Promise.all(data.map((item) => DatabaseClient.resolveOrderFields(item)));
        } else {
            if (!res) {
                return null;
            }
            res = await DatabaseClient.resolveOrderFields(data);
        }
        res = await super.deserialize(res);
        res = await this.deserializeNestedObjects(res);

        if (Array.isArray(res) || isObservableArray(res)) {
            res.forEach((item) => {
                if (item.buyerUID) {
                    item.buyer = item.buyerUID;
                    item.buyerUID = item.buyer.id;
                }
                if (item.sellerUID) {
                    item.seller = item.sellerUID;
                    item.sellerUID = item.seller.id;
                }
            })
        } else {
            if (res.buyerUID) {
                res.buyer = res.buyerUID;
                res.buyerUID = res.buyer.id;
            }
            if (res.sellerUID) {
                res.seller = res.sellerUID;
                res.sellerUID = res.seller.id;
            }
        }
        return res;
    }
}

export default new OrderService(URL, Order, {
    buyerUID: BusinessService,
    sellerUID: BusinessService
});
