import { action, observable , computed} from 'mobx'
import AuthManager from '../../core/AuthManager'
import DatabaseClient from '../../core/DatabaseClient'
import { ReasonForPostOptions, FoodOptions, FoodUnits } from '../../core/core';
import { getFlattenedValues } from '../FormHelper';
import moment from 'moment';
import validate from 'core/utilities/FormValidator';
import firebase from 'firebase';
import { ProductListing } from '../../core/model'
import ProductListingGroup from '../../core/model/ProductListingGroup';
import { profileStore, sheetStore } from '..';

class StorefrontStore {
  @observable deals = []
  @observable listingGroups = []
  @observable currentDealID = null;
  @observable viewRestriction = null;
  @observable form = null;
  @observable priceDisabled = false;
  defaultForm = {
    fields: {
      product: {
        value: null,
        validation: {
          presence: true,
        },
        error: null,
      },
      visibility: {
        value: {},
        validation: {
          presence: true,
        },
        error: null,
      },
      endListingDate: {
        value: null,
        validation: {
          presence: false,
          datetime: {
            earliest: moment().format('YYYY-MM-DD'),
            dateOnly: true,
            message: '^Must be no earlier than ' + moment().format('MM-DD-YYYY'),
          },
        },
        error: null,
      },
      price: {
        value: 0,
        validation: (() => {
          return {
            presence: true,
            format: {
              pattern: "[0-9]+(\.[0-9]{1,2})?", //"[0-9]+(\.[0-9][0-9])",
              message: '%{value} must be in the format $X.YZ',
            },
            price: {
              priceDisabled: this.priceDisabled
            }
          };
        }),
        error: null,
      },
      unit: {
        value: null,
        validation: {
          presence: true,
          length: {
            minimum: 1,
            maximum: 10,
            message: 'is required and must be less than 10 characters',
          },
        },
        error: null,
      },
      notes: {
        value: null,
        validation: {
          presence: false,
          length: {
            maximum: 300,
            message: 'must be less than 300 characters',
          },
        },
        error: null,
      },
      product: {
        value: null,
        validation: {
          presence: true
        },
        error: null,
      }
    },
    validation: {
      error: null,
    },
  }

  @action
  resetForm() {
    this.priceDisabled = false;    
    this.form = Object.assign({}, this.defaultForm);
  }

  @action
  onFieldChangeAddDeal = (field, value) => {
    // Go ahead and update the value
    // Treat empty strings as null
    if (value === '') {
      value = null;
    }
    this.form.fields[field].value = value;
    this.validateField(field);
  }
  
  @action
  validateField = (field) => {
    let data = {}; // The data to validate
    data[field] = this.form.fields[field].value;
    let validationRules = {}; // The rules to use in validation
    validationRules[field] = this.form.fields[field].validation;
    let err = validate(data, validationRules);
    // Update error message
    if (err !== undefined) {
      this.form.fields[field].error = err[field][0];
      return false;
    }
    else {
      this.form.fields[field].error = null;
      return true;
    }
  }

  @action
  validateAll = () => {
    // Start off assuming no errors are present
    this.form.validation.error = null;
    // let data = getFlattenedValues(this.form, 'value');
    //let validationRules = getFlattenedValues(this.form, 'validation');
    Object.keys(this.form.fields).forEach( (field) => {
      let validated = this.validateField(field);
      if (!validated) {
        this.form.validation.error = "Form failed validation.";
      }
    })
    return (this.form.validation.error === null);
    //this.form.validation.error = validate(data, validationRules);
  }

  @action
  onCheckboxChange = (field, subfield) => {
      var index = this.form.fields[field].value.indexOf(subfield);
      if (index > -1) {
          this.form.fields[field].value.splice(index, 1);
      }
      else {
          this.form.fields[field].value.push(subfield);
      }
  }

  @action
  validateValue = (field) => {
    let data = {}; // The data to validate
    data[field] = this.form.fields[field].value;
    let validationRules = {}; // The rules to use in validation
    validationRules[field] = this.form.fields[field].validation;
    let err = validate(data, validationRules);

    return err;
  }

  populateForm = (deal) => {
    const dealID = deal['id'];

    if(deal['quote'].value){
      deal['price'] = {value:null};
    }
    
    delete deal['id'];
    delete deal['edit'];
    delete deal['quote'];
    
    Object.keys(this.form.fields).forEach( (field) => {
      this.form.fields[field].value = deal[field] !== undefined ? deal[field].value : this.defaultForm.fields[field].value;
    });

    if (this.form.fields['price'].value !== null) {
      this.form.fields['price'].value = this.form.fields['price'].value.toFixed(2);
      this.priceDisabled = false;
    } else {
      this.form.fields['price'].value = (+0).toFixed(2);
      this.priceDisabled = true;
    }
    this.resetErrors();
    this.currentDealID = dealID;
  }

  isVisible(obj) {
    if(!this.viewRestriction) {
      return true;
    }

    if(obj.visibilityUIDs.includes(this.viewRestriction)) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; // January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    } 

    if(mm<10) {
        mm = '0'+mm
    } 

    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  constructor() {
    this.resetForm();
  }

  /**
   * Populates the deals observable with all the deals of the currently
   * logged in user.
   */
  async init() {
    // TODO: autopopulate the deals observable
    this.resetForm();
  }

  @action
  async refreshDeals() {
    let listings = await DatabaseClient.getAllProductListings(profileStore.business.id);
    const validListings = listings.filter(listing => (this.isVisible(listing) && !listing.error))
    const groups = ProductListingGroup.groupListings(validListings)
    // Format deals properly
    // listings.forEach( (deal) => {
    //   // TODO: Allow multiple pictures
    //   /*
    //   if (!('picture' in deal)) {
    //     deal['picture'] = 'https://firebasestorage.googleapis.com/v0/b/getfreshspired.appspot.com/o/images%2Fdeals%2Fcart_empty_icon.png?alt=media&token=9a878b4f-3f11-4461-8fe7-67f4be8fef49';
    //   }
    //   */
    //   // Format price to be in format X.YZ
    //   // TODO: leave this to the view
    // });
    this.deals.replace(listings);
    this.listingGroups.replace(groups);
  }

  @action
  async getDeals() {
    // Refresh the deals
    await this.refreshDeals();
    return {
      productListings: this.deals,
      productListingGroups: this.listingGroups
    };
  }

  /**
   * Prepares a deal object from the given form
   * @param {Object} form 
   * @return an object with the contents of the form and the pictures. 
   * The format is {deal: {}, pictures: <array>}, where pictures may or
   * may not exist depending on whether the user has uploaded pictures.
   */
  prepareDeal() {
    let obj = {};
    let vals = getFlattenedValues(this.form, 'value');
    if (this.priceDisabled === true) {
      vals.price = null;
    } else {
      vals.price = Number(vals.price);
    }
    
    let id = this.currentDealID;
    let eldTimeStamp = undefined;

    if(vals.endListingDate) {
      const time = +moment(vals.endListingDate, 'YYYY-MM-DD').startOf("day");
      eldTimeStamp = Number(time)
    }

    obj['deal'] = new ProductListing(eldTimeStamp, undefined, id, vals.notes, 
                  vals.product, vals.product.id, vals.price, undefined, profileStore.business.id, vals.unit, vals.visibility);
    return obj;
  }

  prepareDuplicate() {
    let obj = {};
    let vals = getFlattenedValues(this.form, 'value');
    if (this.priceDisabled === true) {
      vals.price = null;
    } else {
      vals.price = Number(vals.price);
    }
    
    let id = this.currentDealID;
    let eldTimeStamp = undefined;

    if(vals.endListingDate) {
      const time = +moment(vals.endListingDate, 'YYYY-MM-DD').startOf("day");
      eldTimeStamp = Number(time)
    }

    obj['deal'] = new ProductListing(eldTimeStamp, undefined, id, vals.notes, 
                  vals.product, vals.product.id, undefined, undefined, profileStore.business.id, vals.unit, vals.visibility);
    return obj;
  }

  /**
   * Creates a deal using all the fields in the form observable
   * @throws an error when the form fails to be validated or there is
   *  a problem during database insertion
   */
  @action
  async createDeal(idx) {
    // Validate fields
    if (!this.validateAll()) {
      throw new Error('Form failed validation.');
    }
    let {deal} = this.prepareDeal();
    const [id, err] = await DatabaseClient.createProductListing(deal);
    this.resetForm();
    if (idx != null) {
      sheetStore.setListingError(idx, err);
    }
    return id;
  }

  @action setViewRestriction(uid) {
    this.viewRestriction = uid;
  }

  @action
  async duplicateProductListing(idx) {
    const { deal } = this.prepareDuplicate();
    const [id, err] = await DatabaseClient.createProductListing(deal, true);
    this.resetForm();
    deal['id'] = id;
    deal['error'] = err;
    return deal;
  }

  @action
  loadDeal(dealID) {
    // Get the deal of interest
    for (let i = 0; i < this.deals.length; i++) {
      if (this.deals[i]['id'] === dealID) {
        let deal = Object.assign({}, this.deals[i]);
        let reasonsObj = Object.assign({}, ReasonForPostOptions);
        delete reasonsObj.OTHER;
        let reasons = Object.values(reasonsObj);
        if (deal['reasonForPost']) {
          for (let i = 0; i < deal['reasonForPost'].length; i++) {
            if (!reasons.includes(deal['reasonForPost'][i])) {
              // We have an 'Other' reason
              deal['reasonForPostOther'] = deal['reasonForPost'][i];
              deal['reasonForPost'].splice(i, 1);
            }
          }
        }
        if (deal['picture']) {
          deal['pictures'] = deal['picture'];
          delete deal['picture'];
        }
        else {
          deal['pictures'] = null;
        }

        // Populate the form with the given deal details
        Object.keys(this.form.fields).forEach( (field) => {
          this.form.fields[field].value = deal[field] !== undefined ? deal[field] : this.defaultForm.fields[field].value;
        });
        if (this.form.fields['price'].value !== null) {
          this.form.fields['price'].value = this.form.fields['price'].value.toFixed(2);
          this.priceDisabled = false;
        }
        else {
          this.form.fields['price'].value = (+0).toFixed(2);
          this.priceDisabled = true;
        }
        this.resetErrors();
        this.currentDealID = dealID;
        return;
      }
    }
  }

  @action
  getDeal(dealID) {
    for (let i = 0; i < this.deals.length; i++) {
      if (this.deals[i]['id'] === dealID) {
        return this.deals[i];
      }
    }
  }

  @action
  async editDeal(dealID, idx) {
    // Validate fields
    if (!this.validateAll()) {
      throw new Error('Form failed validation.');
    }
    let {deal} = this.prepareDeal();
    const err = await DatabaseClient.updateProductListing(deal); // Just the OG picture. TODO: change this flaky logic
    this.resetForm();

    // Remove any previous errors
    if (idx != null) {
      sheetStore.setListingError(idx, err);
      // console.log(idx, err)
      // for (let i = 0; i < this.deals.length; i++) {
      //   console.log(i, this.deals, dealID)
      //   if (this.deals[i].id === dealID) {
      //     this.deals[i].error = err;
      //   }
      // }
    }
  }

  @action
  resetErrors() {
    Object.keys(this.form.fields).forEach( (field) => {
      this.form.fields[field].error = null;
    });
    this.form.validation.error = null;
  }

  @action
  async archiveDeal(dealID) {
    await DatabaseClient.deleteProductListing(dealID);
    for (let i = 0; i < this.deals.length; i++) {
      if (this.deals[i]['id'] === dealID) {
        this.deals.splice(i, 1);
        return;
      }
    }
  }
}

//StorefrontStore.init();
export default new StorefrontStore();
