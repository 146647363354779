import React, { Component } from 'react';
import styled, { css } from 'styled-components'

const Image = styled.img`
  ${props => {
        return css`
            max-width: ${props.scale*100}%;
            max-height: ${props.scale*100}%;
            transform: scale(${props.scale*100});
        `}}
`

export default Image