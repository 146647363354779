import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Button from '../../atomic/buttons/Button';
import { LoadingSpinnerButton } from "../../LoadingSpinner";
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div``;


const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PageNumber = styled.div`
  display: flex;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin 12px;
`

const ButtonStyled = styled(Button)`
  min-width: ${props => props.btnSize}px;
  min-height: ${props => props.btnSize}px;
  padding: 0;
  ${props => props.active && css`color: ${props => props.theme.colors.spearmint_main};`}
`

class NumberPaginator extends React.Component {
    state = {
        items: [],
        pageNumber: 1,
        initializing: true,
        count: 0
    };

    async componentDidMount() {
        this.setState({ initializing: true }, async () => {
            await this.loadPage(this.state.pageNumber);
            this.setState({ initializing: false });
        });
    }

    loadPage = async (pageNumber, loadingScreen = false) => {
        const { getPage, limit } = this.props;
        if (loadingScreen) {
            this.setState({ initializing: true });
        }
        let [newItems, count] = await getPage(pageNumber, limit);
        // Cast null/undefined
        newItems = newItems || [];
        count = count || 0;
        this.setState({ items: newItems, pageNumber, count });
        if (loadingScreen) {
            this.setState({ initializing: false });
        }
    };


    refresh = (loadingScreen = true) => {
        return this.loadPage(this.state.pageNumber, loadingScreen);
    };

    updateItem = (idx, item) => {
        const items = this.state.items;
        if (idx < items.length) {
            items[idx] = item;
            this.setState({ items });
        }
    };

    removeItem = async () => {
        const { items } = this.state;
        if (items.length <= 1) {
            const newPage = Math.max(1, this.state.pageNumber - 1);
            this.setState({ initializing: true }, async () => {
                await this.loadPage(newPage);
                this.setState({ initializing: false });
            });
        } else {
            await this.loadPage(this.state.pageNumber);
        }
    };

    handleNext = async () => {
        return this.loadPage(this.state.pageNumber + 1);
    };

    isNextEnabled = () => {
        const { limit } = this.props;
        const { pageNumber, count } = this.state;
        return pageNumber * limit < count;
    };

    handlePrev = async () => {
        return this.loadPage(this.state.pageNumber - 1);
    };

    isPrevEnabled = () => {
        const { pageNumber } = this.state;
        return pageNumber > 1;
    };

    getPageNumbers = () => {
        const { pageNumber, count } = this.state;
        const { limit } = this.props;
        const maxPage = Math.ceil(count / limit);
        if (!pageNumber || !maxPage) {
            return []
        }
        if (maxPage <= 7) {
            let range = [...Array(maxPage).keys()];
            for (let i = 0; i < range.length; i++) {
                range[i] = range[i] + 1;
            }
            return range;
        }
        const minPage = 1;
        const currentPage = pageNumber;
        const prevPage = Math.max(currentPage - 1, minPage);
        const nextPage = Math.min(currentPage + 1, maxPage);
        const allPages = new Set([minPage, maxPage, currentPage, prevPage, nextPage]);
        const sortedPages = Array.from(allPages).sort((a, b) => a - b);
        const finalPages = [];
        for (let i = 0; i < sortedPages.length; i++) {
            const currentEl = sortedPages[i];
            finalPages.push(currentEl);
            if (i !== sortedPages.length - 1) {
                const nextEl = sortedPages[i + 1];
                if (nextEl - currentEl !== 1) {
                    finalPages.push("...");
                }
            }
        }
        const width = finalPages.length;
        const numToPack = 7 - width;
        if (numToPack) {
            let i = 0;
            let lastNum;
            for (; i < finalPages.length; i++) {
                const currentEl = finalPages[i];
                const nextEl = finalPages[i + 1];
                if (currentEl + 1 !== nextEl) {
                    lastNum = currentEl;
                    break;
                }
            }
            if (lastNum !== undefined && i + 1 < finalPages.length) {
                const toPush = [...Array(numToPack).keys()];
                for (let j = 0; j < toPush.length; j++) {
                    toPush[j] = toPush[j] + lastNum + 1;
                }
                finalPages.splice(i + 1, 0, ...toPush);
            }
        }
        return finalPages;
    };

    renderPageButtons = () => {
        const { pageNumber } = this.state;
        const { buttonStyle } = this.props;
        const pages = this.getPageNumbers()
        return pages.map(page => {
            if(typeof page === "number"){
                const isActive = page === pageNumber;
                return (
                    <ButtonStyled btnSize={40} style={buttonStyle} active={isActive} onClick={() => this.loadPage(page)}>
                        {page}
                    </ButtonStyled>
                )
            } else {
                return(
                    <ButtonStyled disabled={!this.isPrevEnabled()} btnSize={40} style={buttonStyle} onClick={this.handlePrev}>
                        ...
                    </ButtonStyled>
                )
            }
        })
    }

    render() {
        const { initializing, items, pageNumber, count } = this.state;
        const { renderItems, buttonStyle, textStyle, enableNumbers, renderPaginationBar, renderLoadingProgress, ...other } = this.props;
        const paginationBar = (
            <Wrapper>
                <ButtonStyled disabled={!this.isPrevEnabled()} btnSize={40} style={buttonStyle} onClick={this.handlePrev}>
                    <FirstPage/>
                </ButtonStyled>
                {enableNumbers ? this.renderPageButtons() : <PageNumber style={textStyle}>{pageNumber}</PageNumber>}
                <ButtonStyled disabled={!this.isNextEnabled()} btnSize={40} style={buttonStyle} onClick={this.handleNext}>
                    <LastPage/>
                </ButtonStyled>
            </Wrapper>
        );
        return (
            <Container {...other}>
                {renderLoadingProgress && initializing ? (
                    <LoaderWrapper>
                        <CircularProgress size={40} thickness={5} />
                    </LoaderWrapper>
                ) : (
                    <React.Fragment>
                        {renderItems(items, { count, pageNumber, refresh: this.refresh, loadPage: this.loadPage, paginationBar, updateItem: this.updateItem, removeItem: this.removeItem, loading: initializing })}
                        {renderPaginationBar && paginationBar}
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

NumberPaginator.propTypes = {
    renderItems: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    buttonStyle: PropTypes.object,
    textStyle: PropTypes.object,
    enableNumbers: PropTypes.bool,
    renderPaginationBar: PropTypes.bool,
    renderLoadingProgress: PropTypes.bool
};

NumberPaginator.defaultProps = {
    enableNumbers: true,
    renderPaginationBar: true,
    renderLoadingProgress: true
};

export default NumberPaginator;
