import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SelectDropdown from '../../molecules/SelectDropdown';
import Tooltip from '../../molecules/Tooltip';
import Bubble from '../../molecules/Bubble';

const StyledFormHelper = styled(FormHelperText)`
  min-width: 109px;
`

const TextArea = styled(Bubble)`
  ${props => {
        if (props.small) {
            return css`
                width: 40px;
            `
        }
        else {
            return css`
                width: 130px;
            `
        }
    }}
`
@inject('sheetStore')
@observer
class ColumnInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      product: {}
    }
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ product: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index, value } = this.props
    const { product } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,product)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ product: nextProps.value });
    }
  }

  update = id => {
    const { sheetStore, index } = this.props;
    if(id) {
      const product = sheetStore.productOptions[id].product;
      this.setState({ product });
      sheetStore.setProductsAtIndex(index,id)
    } else {
      this.setState({ product: null });
      sheetStore.setProductsAtIndex(index,null)
    }
  }

  getError = () => {
    const { sheetStore, name, index } = this.props
    if (index >= sheetStore.listingErrors.length) {
      return null;
    }
    const listingError = sheetStore.listingErrors[index];
    if(!listingError) return null;
    
    const stringArry = listingError.split(`${name}:`);

    if (stringArry && stringArry.length > 1) {
      return stringArry[1];
    } else {
      return null;
    }
  }

  render () {
    const { editMode, value, index, sheetStore, inputWidth, options, small, error, ...other } = this.props;
    const { product } = this.state;

    const productRowID = index < sheetStore.unsavedProductIDs.length ? sheetStore.unsavedProductIDs[index] : null;
    const productOptions = sheetStore.productOptions;

    const tipError = this.getError();
    return editMode ? (
      <React.Fragment>
        <SelectDropdown 
          items={productOptions}
          fullWidth
          onChange={this.update}
          allowOther={false}
          error={error}
          errorText={this.props.errorText} 
          defaultValue={productRowID}
          // label={"Unit of Measurement"}
          {...other}
        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </React.Fragment>
    ) : (
      <Tooltip text={tipError}>
        <TextArea  enabled={tipError} small={small}>
          {product && product.name}
        </TextArea>
      </Tooltip>
    )
  }
}

ColumnInput.propTypes = {
  editMode: PropTypes.bool,
  small: PropTypes.bool,
  value: PropTypes.string,
};

ColumnInput.defaultProps = {
  editMode: false,
  small: false
};

export default ColumnInput;