import React, { Component } from 'react'
import styled, { css, withTheme } from 'styled-components'
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';

const SubHeader = styled(ListSubheader)`
  height: 38px;
  background-color: #f7f7f7;
  font-size: 1.3em;
  display: flex;
  align-items: center;
`;

class Header extends Component {
  render() {
    const { text } = this.props
    return (
      <SubHeader component="div">{`${text}`}</SubHeader>
    )
  }
}

Header.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Header;