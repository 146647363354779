import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

const StyledInput = styled(Input)`
  font-size: 14px;
  ${props => {
    if (props.width) {
      return css`
        width: ${props.width}px;
      `
    }
  }}
`

const StyledFormHelper = styled(FormHelperText)`
  min-width: 109px;
`

const TextArea = styled.div`
  ${props => {
        if (props.small) {
            return css`
                width: 40px;
            `
        }
        else {
            return css`
                width: 130px;
            `
        }
    }}
`

class ColumnInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      input: ''
    }
  }

  componentDidMount() {
    const { value } = this.props;

    this.setState({ input: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index, value } = this.props
    const { input } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,input)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ input: nextProps.value });
    }
  }

  update = input => {
    this.setState({ input });
  }

  render () {
    const { editMode, value, inputWidth, small, error, style, ...other } = this.props;
    const { input } = this.state;
    
    return editMode ? (
      <React.Fragment>
        <StyledInput
          value={input}
          onChange={(e) => this.update(e.target.value)}
          width={inputWidth}
          error={error}
          {...other}
        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </React.Fragment>
    ) : (
      <TextArea small={small}>
        {value}
      </TextArea>
    )
  }
}

ColumnInput.propTypes = {
  editMode: PropTypes.bool,
  inputWidth: PropTypes.number,
  small: PropTypes.bool,
  value: PropTypes.string,
};

ColumnInput.defaultProps = {
  editMode: false,
  small: false
};

export default ColumnInput;