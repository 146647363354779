import React, { Fragment, Component } from 'react';
import Menu from '../../components/Menu';
import Topbar from '../../components/CommonTopBar';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import EditableLabel from '../../components/molecules/EditableLabel';
import { User } from '../../core/model';
import DatabaseClient from '../../core/DatabaseClient';
import AuthManager from '../../core/AuthManager';
import EditablePhoneLabel from '../../components/molecules/EditablePhoneLabel';
import { observer, inject } from 'mobx-react';
import PasswordResetButton from '../../components/atomic/buttons/PasswordResetButton';
import breakpoint from 'styled-components-breakpoint';
import { UserService } from 'core/api'

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    margin-top: 112px;
    margin-bottom: 120px;
    ${props => props.theme.mixins.sidebar}
`;

const Wrapper = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: auto;
    padding-top: 30px;
    text-align: center;
`;

const StyledPaper = styled(Paper)`
    padding: 10px;
    box-shadow: none;
    border-radius: 10px;
`;

const GridItem = styled.div`
    margin: auto;
    width: 66%;
    min-width: 300px;
    max-width: 600px;
    margin-bottom: 20px;
    text-align: center;
`;

const Subheading = styled.div`
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.185);
    h2 {
        font-size: 1.6em;
    }
`;

const InformationContainer = styled.div`
    width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    padding: 5px;
    margin-bottom: 10px;
    &:first-child{
        margin-top: 10px;
    }
    &:last-child{
        margin-bottom: auto;
    }
`;


const InformationWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 350px;
    ${breakpoint('mobile_min', 'sidebar_off_max')`
        width: 90%;
    `}
    ${breakpoint('sidebar_fixed_min', 'sidebar_fixed_max')`
        width: 300px;
    `}
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    ${breakpoint('mobile_min', 'sidebar_off_max')`
        margin-left: 5px;
        margin-right: 5px;
    `
    }
`;


@inject('userStore')
@observer
class AccountSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: props.userStore.user.firstName,
            lastName: props.userStore.user.lastName,
            email: props.userStore.user.email,
            phoneNumber: props.userStore.user.phoneNumber
        };
    }

    handleFirstNameChange = async (value) => {
        if (value === this.state.firstName) {
            return;
        }
        if (value === '') {
            throw new Error("Cannot be empty.")
        }
        if (this.props.userStore.user === null) {
            throw new Error("No user is logged in.");
        }
        const user = await UserService.update(this.props.userStore.user.id, {
            firstName: value
        });
        await this.props.userStore.setUser(user);
        this.setState({ firstName: value });
    }

    handleLastNameChange = async (value) => {
        if (value === this.state.lastName) {
            return;
        }
        if (value === '') {
            throw new Error("Cannot be empty.")
        }
        if (this.props.userStore.user === null) {
            throw new Error("No user is logged in.");
        }
        const user = await UserService.update(this.props.userStore.user.id, {
            lastName: value
        });
        await this.props.userStore.setUser(user);
        this.setState({ lastName: value });
    }

    handleEmailChange = async (value) => {
        if (value === this.state.email) {
            return;
        }
        if (value === '') {
            throw new Error("Cannot be empty.")
        }
        if (this.props.userStore.user === null) {
            throw new Error("No user is logged in.");
        }
        const user = await AuthManager.changeEmail(this.props.userStore.user.id, value);
        await this.props.userStore.setUser(user);
        this.setState({ email: value });
    }

    handlePhoneNumberChange = async (value) => {
        if (value === this.state.phoneNumber) {
            return;
        }
        if (value === '') {
            throw new Error("Cannot be empty.")
        }
        if (this.props.userStore.user === null) {
            throw new Error("No user is logged in.");
        }
        const user = await UserService.update(this.props.userStore.user.id, {
            phoneNumber: value
        });
        await this.props.userStore.setUser(user);
        this.setState({ phoneNumber: value });
    }

    render() {
        const tabs = [
			{
				name: 'Account Settings',
				child: false,
				path: '/settings/account'
            },
            // {
			// 	name: 'Notifications',
			// 	child: false,
			// 	path: '/settings/notifications'
            // }
		];
		return (
			<Fragment>
				<Menu />
				<Topbar
                    title="Settings"
                    sub="Change your information and preferences here."
                    tabs={tabs}
                    tabIndex={0}
                    isChildPath={false}
                    mobilft={true} />
				<Container>
					<Wrapper>
					<Grid direction="column">
                        <GridItem item>
                            <StyledPaper>
                                <Content>
                                    <Subheading>
                                    <Typography component="h2" variant="subheading">
                                        Personal Information
                                    </Typography>
                                    </Subheading>
                                <InformationWrapper>
                                    <InformationContainer>
                                        <EditableLabel 
                                            onFinish = {async (val) => await this.handleFirstNameChange(val)}
                                            label = "First Name"
                                            defaultValue = {this.state.firstName}
                                        />
                                        <EditableLabel 
                                            onFinish = {async (val) => await this.handleLastNameChange(val)}
                                            label = "Last Name"
                                            defaultValue = {this.state.lastName}
                                        />
                                        <EditablePhoneLabel
                                            onFinish = {async (val) => await this.handlePhoneNumberChange(val)}
                                            defaultValue = {this.state.phoneNumber}
                                        />
                                    </InformationContainer>
                                </InformationWrapper>
                                </Content>
                            </StyledPaper>
                        </GridItem>
                        <GridItem item>
                            <StyledPaper>
                                <Content>
                                    <Subheading>
                                        <Typography component="h2" variant="subheading">
                                            Account Information
                                        </Typography>
                                    </Subheading>
                                    <InformationWrapper>
                                        <InformationContainer>
                                            <EditableLabel 
                                                onFinish = {async (val) => await this.handleEmailChange(val)}
                                                label = "Email"
                                                defaultValue = {this.state.email}
                                            />
                                        </InformationContainer>
                                    </InformationWrapper>
                                </Content>
                            </StyledPaper>
                        </GridItem>
                        <GridItem item>
                            <StyledPaper>
                                <Content>
                                    <Subheading>
                                        <Typography component="h2" variant="subheading">
                                            Password Reset
                                        </Typography>
                                    </Subheading>
                                    <InformationWrapper>
                                        <InformationContainer>
                                            <PasswordResetButton/>
                                        </InformationContainer>
                                    </InformationWrapper>
                                </Content>
                            </StyledPaper>
                        </GridItem>
					</Grid>
					</Wrapper>
				</Container>
			</Fragment>
		);
    }
}

export default AccountSettings;
