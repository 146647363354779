import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconButton from './IconButton';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';

const StyledIconButton = styled(IconButton)`
  
`;

const StyledCloseIcon = styled(CloseIcon)`
  
`;

const CloseButton = props => {
  const { onClick, color, fontSize, ...other } = props;
  return (
    <StyledIconButton onClick={onClick} {...other}>
      <StyledCloseIcon fontSize={fontSize} color={color}/>
    </StyledIconButton>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CloseButton;