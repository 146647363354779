import React, { Component } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Notes from '@material-ui/icons/Notes';
import Button from '../../atomic/buttons/Button';
import ListItem from '../../ListItem';
import {orderStore, profileStore} from '../../../stores';
import { message } from 'antd';
import { observer, inject } from 'mobx-react';
import { AlertMessage } from '../../AlertMessage';
import { OrderBundleService } from "../../../core/api";
import DatabaseClient from "core/DatabaseClient";

const Panel = styled(ExpansionPanel)``;
const Summary = styled(ExpansionPanelSummary)``;
const Details = styled(ExpansionPanelDetails)``;
const Actions = styled(ExpansionPanelActions)``;
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

const IdText = styled(Typography)`
  font-size: 10px; 
  color: #;
  opacity: 0.2;
`;

const ActionButton = styled(Button)`
  margin-left: 8px;
  padding-top: 5px;
  text-transform: none;
`;

const NotesButton = styled(Button)`
  text-transform: none;
  margin-right: 8px;
  @media (max-width: 550px) {
    max-width: 35px;
    min-width: 35px;
    margin-left: -10px;

    .notes-text {
      display: none;
    }
  }
`

const AcceptAllButton = (props) => {
  const action= async () => {
    try {
        await DatabaseClient.acceptOrderBundle(props.bundleID);
        props.onRemove && await props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }
  return (
    <ActionButton id="container_accept_all" size="small" variant="contained" color="primary" onClick={action}>
      Accept All
    </ActionButton>
  )
}

AcceptAllButton.propTypes = {
  bundleID: PropTypes.string
}

const MarkAllExchangedButton = (props) => {
  const action = async () => {
    try {
        await DatabaseClient.updateOrderBundleOrders(props.bundleID, {
            exchangeStatus: true,
            lastUpdatedBy: props.profileStore.business.id,
            status: "completed"
        });
        props.onRemove && await props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }
  return (
    <ActionButton id="container_mark_all_delivered" size="small" variant="contained" color="primary" onClick={action}>
      Mark All as Delivered
    </ActionButton>
  )
}

MarkAllExchangedButton.propTypes = {
  bundleID: PropTypes.string
}

const CancelAll = (props) => {
  const action = async () => {
    try {
        await DatabaseClient.updateOrderBundleOrders(props.bundleID, {
            status: "cancelled",
            lastUpdatedBy: props.profileStore.business.id
        });
        props.onRemove && await props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }
  return (
    <ActionButton id="container_cancel_all" size="small" variant="contained" color="secondary" onClick={() => AlertMessage('Cancel All orders', 'Are you sure you want to cancel ALL orders?', async () => await action())}>
      Cancel All
    </ActionButton>
  )
}

CancelAll.propTypes = {
  bundleID: PropTypes.string
}


const CancelAllRequests = (props) => {
  const action = async () => {
    try {
        await DatabaseClient.updateOrderBundleOrders(props.bundleID, {
            status: "cancelled",
            lastUpdatedBy: props.profileStore.business.id
        });
        props.onRemove && await props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }
  return (
    <ActionButton id="container_cancel_all_requests" size="small" variant="contained" color="secondary" onClick={() => AlertMessage('Cancel All Requests', 'Are you sure you want to cancel ALL requests?', async () => await action())}>
      Cancel All
    </ActionButton>
  )
}

CancelAll.propTypes = {
  bundleID: PropTypes.string
}

const DeclineAll = (props) => {
  const action = async () => {
    try {
        await DatabaseClient.updateOrderBundleOrders(props.bundleID, {
            status: "declined",
            lastUpdatedBy: props.profileStore.business.id
        });
        props.onRemove && await props.onRemove();
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }
  return (
    <ActionButton id="container_decline_all" size="small" variant="contained" color="secondary" onClick={() => AlertMessage('Declie All Requests', 'Are you sure you want to decline ALL requests?', async () => await action())}>
      Decline All
    </ActionButton>
  )
}

DeclineAll.propTypes = {
  bundleID: PropTypes.string
};


@inject('orderStore', 'profileStore')
@observer
class OrderBundle extends Component {
    constructor(props) {
      super(props);
      this.state = {
        bundles: [],
      }
    }

    injectBusinessDetails = (order) => {
      const { business , productBusiness, profileStore } = this.props;
      const isSeller = profileStore.isSeller;
      order['buyerUID'] = !isSeller ? business.id : productBusiness.id
      order['buyer'] = {name: !isSeller ? business.name : productBusiness.name}
      order['sellerUID'] = isSeller ? business.id : productBusiness.id
      order['seller'] = {name: isSeller ? business.name : productBusiness.name}
      return order
    }

    getContainerButtons = (bundleID) => {
      const isSeller = this.props.business.isSeller();
      switch (this.props.path) {
          case 'request':
              return ( isSeller ? 
                  <React.Fragment>
                    <AcceptAllButton bundleID={bundleID} {...this.props}/>
                    <DeclineAll bundleID={bundleID} {...this.props}/>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <CancelAllRequests bundleID={bundleID} {...this.props}/>
                  </React.Fragment>
              );
          case 'active':
              return (
                <React.Fragment>
                  <MarkAllExchangedButton bundleID={bundleID} {...this.props}/>
                  <CancelAll bundleID={bundleID} {...this.props}/>
                </React.Fragment>
              );

      }
  }
    render() {
        const { bundle, business, onOrderNoteClick } = this.props;
        
        const notes = bundle.notes
        return (
          <Panel {...this.props}>
            <Summary expandIcon={<ExpandMoreIcon />}>
              {notes && (
                <NotesButton size="small" variant="outlined" color={undefined} className="action-button" style={{"padding": "5px"}} onClick={(e) => {
                  e.stopPropagation();
                  onOrderNoteClick(notes)
                }}>
                    <div className="notes-text">
                      Notes
                    </div>
                    <Notes/>
                </NotesButton>
              )}
              <SummaryContainer>
                <IdText>
                  {bundle.id}
                </IdText>
                <Typography className="md">
                  <span className="colored-text">Date: </span>  {moment(bundle.timestamp).format("MM/DD/YYYY h:mma")}
                </Typography>
              </SummaryContainer>
            </Summary>
            <Details className="order-product-list colored-bg">
              {bundle.orders.map((order, i) => {
                  return (<ListItem
                      key={`${order.id}-${i}`}
                      item={this.injectBusinessDetails(order)}
                      business={business}
                      isSeller={(business.isSeller())}
                      actionButtons={true}
                      type={this.props.path}
                      onEdit={(order) => {
                          for (let i = 0; i < bundle.orders.length; i++) {
                              if (bundle.orders[i].id === order.id) {
                                  bundle.orders[i] = order;
                              }
                          }
                          this.props.onEdit(bundle);
                      }}
                      onRefresh={this.props.onRefresh}
                      onRemove={async () => {
                          const newOrders = bundle.orders.filter((bundleOrder) => bundleOrder.id !== order.id);
                          if (newOrders.length) {
                              bundle.orders = newOrders;
                              this.props.onEdit(bundle);
                          } else {
                              // Don't assign bundle.orders here b/c will re-render a blank card for a split
                              // second while onRemove runs
                              await this.props.onRemove();
                          }
                      }}
                  />)
                })
              }
            </Details>
            {
              bundle.orders.length > 1 &&
              <Actions className="colored-bg">
                {this.getContainerButtons(bundle.id)}
              </Actions>
            }
           
          </Panel>
        );
    }
}

OrderBundle.propTypes = {
  bundle: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
  open: PropTypes.bool,
}

OrderBundle.defaultProps = {
  open: false
}

export default OrderBundle;
