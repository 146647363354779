import validate from 'validate.js';
import moment from 'moment';

// Extend the validator with a way to validate datetimes
validate.extend(validate.validators.datetime, {
	parse: function(value, options) {
		return +moment.utc(value);
	},
	format: function(value, options) {
		var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
		return moment.utc(value).format(format);
	}
});

validate.validators.atLeastOneOf = function(value, options, key, attributes) {
	if (value || options['otherField'].value) {
		options['otherField'].error = null;
		return null;
	}
	else {
		return options['message'];
	}
};

validate.validators.validFormat = function(value, options, key, attributes) {

	if(value && value.length >= 1) {
		const packUnits = value.map((packFormat) => packFormat.unit);
		for (let i = 0; i < packUnits.length; i++) {
			for (let j = i + 1; j < packUnits.length; j++) {
				if (packUnits[i] === packUnits[j]) {
					return "^Duplicate pack units";
				}
			}
		}
		for (const pack of value) {
			if(!pack.verifyFields()) {

				return options['message'];
			}
		}
	}
	return null;
};

validate.validators.price = function(value, options, key, attributes) {

	if (value <= 0 && options['priceDisabled'] === true) {
		return null;
	}
	else if (value > 0 && options['priceDisabled'] == false) {
		return null;
	}
	else {
		return "must be greater than $0.00"
	}
};

export default validate;
