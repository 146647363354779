import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import Button from '../../atomic/buttons/Button';
import EditPanel from './EditPanel';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Chip from '@material-ui/core/Chip';
import ChipInput from 'material-ui-chip-input'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const EditPanelStyled = styled(EditPanel)`
  margin: 10px;
`;

const ButtonStyled = styled(Button)`
  display: flex;
  margin-left: auto;
`;

const NoteItem = ({
  onClick,
 ...other 
}) => {
  const [text,setText] = useState(text);
  return (
    <Container {...other}>
      <MuiThemeProvider>
        <ChipInput
          value={[]}
          fullWidth={true}
          hintText='This text is here to help you.'
          allowDuplicates={false}
          chipRenderer={(
            {
              value,
              handleClick,
              handleRequestDelete,
              defaultStyle
            },
            key
          ) => (
            <Chip
              key={key}
              style={{...defaultStyle}}
              onClick={handleClick}
              onDelete={handleRequestDelete}
              label={value}
            />
          )}
        />
      </MuiThemeProvider>
    </Container>
  )
}

NoteItem.propTypes = {
}

NoteItem.defaultProps = {
}

export default NoteItem;