import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Button from '../../atomic/buttons/Button';
import Image from '../../atomic/Image';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  margin-top: 15px;
  opacity: 0.5;
`
const Header = styled.div`
  font-size: 24px;
  font-family: ${props => props.theme.fonts.ms_font500};
  opacity: 0.5;
`
const Subtext = styled.div`
  font-size: 18px;
  opacity: 0.5;
`
const HeaderIcon = styled(Icon)`
  font-size: 130px;
  opacity: 0.5;
`
const IconStyled = styled(Icon)`
  margin-right: 4px;
`
const ImageStyled = styled(Image)`
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
`


const Placeholder = ({
  title,
  image,
  icon,
  subtext,
  onClick,
  buttonIcon,
  buttonText,
  ...other
}) => {

  const getSubtext = () => {
    if(subtext && subtext.length > 0) {
      return subtext.map(text => (
        <Subtext>{text}</Subtext>
      ))
    } else {
      return null;
    }
  }

  const getButton = () => {
    if(!onClick) {
      return null;
    }
    return (
      <ButtonStyled variant='outlined' onClick={onClick}>
        <IconStyled>{buttonIcon}</IconStyled> {buttonText}
      </ButtonStyled>
    )
  }

  const getImage = () => {
    return image ? (
      <ImageContainer>
        <ImageStyled scale={0.11} src={image}/>
      </ImageContainer>
    ) : (
      <HeaderIcon>{icon}</HeaderIcon>
    )
  }

  return (
    <Container {...other}>
      <Header>{title}</Header>
      {getImage()}
      {getSubtext()}
      {getButton()}
    </Container>
  );
}

Placeholder.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.string,
  subtext: PropTypes.array,
  handleClick: PropTypes.func,
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.string,
}

Placeholder.default = {
  buttonIcon: 'close',
  buttonText: 'Click'
}

export default Placeholder;
