import { message } from 'antd'
import React from 'react'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    async componentDidCatch(error, info) {
        // TODO: Display fallback UI
        this.setState({ hasError: true });
    
        message.error('An error occured. Please clear your cache and reload the page.');
        console.error(error);
        console.error(info);
        Sentry.captureException(error);
    }

    render() {
        return this.props.children;
    }
}
