import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import Popup from 'reactjs-popup';
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import BottomBar from '../../CommonBottomBar'
import Check from '@material-ui/icons/Check'
import Button from '../../atomic/buttons/Button'
import { LoadingSpinnerPage } from '../../LoadingSpinner';

// Inventory Edit Fields
import EditWrapper from '../EditWrapper';
import ColumnProduct from '../../organisms/StorefrontSheet/ColumnProduct';
import ColumnUnit from '../../organisms/StorefrontSheet/ColumnUnit';
import ColumnChipInput from '../../organisms/StorefrontSheet/ColumnChipInput';
import ColumnBoolean from '../../organisms/StorefrontSheet/ColumnBoolean';
import ColumnPrice from '../../organisms/StorefrontSheet/ColumnPrice';
import ColumnDate from '../../organisms/StorefrontSheet/ColumnDate';
import ColumnText from '../../organisms/InventorySheet/ColumnText';
import SheetEditModal from '../SheetEditModal';

const ColumnUnitStyled = styled(ColumnUnit)`

`
const ColumnChipInputStyled = styled(ColumnChipInput)`
  width: 100%;
  min-height: 0px;
`
const ColumnBooleanStyled = styled(ColumnBoolean)`
  width: 100%;
  padding: 0;
`
const ColumnPriceStyled = styled(ColumnPrice)`
  width: 100%;
`
const ColumnDateStyled = styled(ColumnDate)`
  width: 100%;
`
const ColumnProductStyled = styled(ColumnProduct)`

`
const CombineRow = styled.div`
  display: flex;
  flex-direction: row;
`
const ColumnTextStyled = styled(ColumnText)`
  width: 100%;
  min-width: 200px;
`
const SheetEditModalStyled = styled(SheetEditModal)`
  flex-direction: column;
`

const EditWrapperStyled = styled(EditWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PriceEditWrapperStyled = styled(EditWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  margin-left: 20px;
`
const RequestEditWrapperStyled = styled(EditWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonStyled = styled(Button)`
  margin: 3px 7px;
  padding: 6px 25px;
  text-transform: none;
  ${'' /* font-size: 0.9em; */}
`

class ProductListingEditModal extends Component {
  constructor(props) {
    super(props)
    // this indicates that bulk edit was clicked inistially
    this.bulkEntry = false;
    this.state = {
      data: [],
    }
  }

  firstInEdit = () => {
    const { rowsInEdit } = this.props;
    if(rowsInEdit && rowsInEdit.length > 0) {
      if(rowsInEdit.length > 1) {
        this.bulkEntry = true;
        return null;
      }
      if(!this.bulkEntry) {
        return rowsInEdit[0];
      }
    }
    this.bulkEntry = false;
    return null;
  }

  getError = name => {
    const { data } = this.props;
    const index = this.firstInEdit();
    let error = null;

    if (data && data.length > 0) {
      switch(name) {
        default:
          error = data[index][name].error;
          break;
      }
    }
    return error;
  }

  getValue = name => {
    const { data } = this.props;
    const index = this.firstInEdit();
    let value = null;

    if (data && data.length > 0) {
      // console.log(name,data[index][name].value)
      switch(name) {
        default:
          value = data[index][name].value;
          break;
      }
    }
    return value;
  }

  closeModal = () => {
    const { data, onCancel, onDelete, } = this.props;
    const index = this.firstInEdit();
    if(data && data.length > 0) {
      const isNew = data[index]['id'] === null;
      if(isNew) {
        onDelete(true, index);
      } else {
        onCancel(index);
      }
    }
  }

  render() {
    const { rowsToSave, rowsInEdit, onFieldChange, renderHash, foodUnits, foodOptions, onSave, products, ...other } = this.props;
    const index = this.firstInEdit();
    const NavButtons = [
      {
        text: 'Save',
        onClick: (() => onSave(index)),
      }
    ]
    const hasIndex = index != null; 
    const foodOptionsAlt = Object.assign({}, foodOptions);
    delete foodOptionsAlt['OTHER'];

    return !hasIndex ? null : (
      <SheetEditModalStyled
        header="Edit Product Listing"
        bottomNavigation={NavButtons}
        onClose={this.closeModal}
        open={hasIndex}
        modalButtons={[
          <ButtonStyled color="primary" variant="contained" onClick={() => onSave(index)}>Save</ButtonStyled>,
          <ButtonStyled variant="contained" onClick={this.closeModal}>Cancel</ButtonStyled>
        ]}
      >
        <EditWrapperStyled header='Product'>
          <ColumnProductStyled
            key={`product-${index}-${renderHash}`}
            name="product" 
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('product')}
            error={this.getError('product')}
            options={products}
            editMode
          />
        </EditWrapperStyled>
        <EditWrapperStyled header='Unit (Stock Remaining)'>
          <ColumnUnitStyled
            key={`uom-${index}-${renderHash}`}
            name="unit"
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('unit')}
            error={this.getError('unit')}
            isMobile={true}
            errorText={"Must be less than 10 characters"}
            editMode
          />
        </EditWrapperStyled>
        <EditWrapperStyled header='Visibility'>
          <ColumnChipInputStyled
            key={`visibility-${index}-${renderHash}`}
            name="visibility"
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('visibility')}
            error={this.getError('visibility')}
            editMode
          />
        </EditWrapperStyled>
        <CombineRow>
        <EditWrapperStyled header='Request Quote'>
          <ColumnBooleanStyled
            key={`rq-${index}-${renderHash}`}
            name="quote" 
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('quote')}
            error={this.getError('quote')}
            editMode 
          />
        </EditWrapperStyled>
        <PriceEditWrapperStyled header='Price'>
          <ColumnPriceStyled
            key={`price-${index}-${renderHash}`}
            name="price" 
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('price')}
            error={this.getError('price')}
            editMode 
          />
        </PriceEditWrapperStyled>
        </CombineRow>
        <EditWrapperStyled header='End Listing Date (Optional)'>
          <ColumnDateStyled
            key={`eld-${index}-${renderHash}`}
            name="endListingDate" 
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('endListingDate')}
            error={this.getError('endListingDate')}
            editMode
          />
        </EditWrapperStyled>
        <EditWrapperStyled header='Notes'>
          <ColumnTextStyled
            key={`notes-${index}-${renderHash}`}
            name="notes"
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('notes')}
            error={this.getError('notes')}
            editMode 
          />
        </EditWrapperStyled>
      </SheetEditModalStyled>
    )
  }
}

ProductListingEditModal.PropTypes = {
  index: PropTypes.number.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  rowsToSave: PropTypes.array.isRequired,
  rowsInEdit: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

ProductListingEditModal.defaultProps = {
  rowsToSave: [],
  rowsInEdit: [],
  data: [],
}

export default ProductListingEditModal; 