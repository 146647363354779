import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import MoreButton from '../../atomic/buttons/MoreButton';
import SaveButton from '../../atomic/buttons/SaveButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { LoadingSpinnerButton } from '../../LoadingSpinner';

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const StyleIcon = styled(Icon)`

`

const StyleCheckbox = styled(Checkbox)`

`

class ColumnEdit extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSave = () => {
    this.handleClose();
    this.props.onSave();
  }

  handleEdit = () => {
    this.handleClose();
    this.props.onEdit();
  }

  handleCancel = () => {
    this.handleClose();
    this.props.onCancel();
  }

  handleDelete = () => {
    this.handleClose();
    this.props.onDelete();
  }

  render() {
    const { editMode, saving, isNew } = this.props;
    const { anchorEl } = this.state;

    return (
      <Container>
        {
          saving ? LoadingSpinnerButton() : (
            <React.Fragment>
              <MoreButton
                id="more_button_onListing"
                onClick={this.handleClick}
              />
              {
                editMode ? (
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem id="save_edit_onListing" onClick={this.handleSave}>Save</MenuItem>
                    {
                      !isNew&&
                      <MenuItem id="cancel_edit_onListing" onClick={this.handleCancel}>Cancel</MenuItem>
                    }
                    <MenuItem id="delete_edit_onListing" onClick={this.handleDelete}>Delete</MenuItem>
                  </Menu>
                ) : (
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem id="edit_onListing" onClick={this.handleEdit}>Edit</MenuItem>
                    <MenuItem id="delete_onListing" onClick={this.handleDelete}>Delete</MenuItem>
                  </Menu>
                )
              }
            </React.Fragment>
          )
        }
      </Container>
    );
  }
}

ColumnEdit.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  saving: PropTypes.bool,
};

export default ColumnEdit;