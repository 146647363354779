import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { SearchIcon, LocationIcon } from './Icons'
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { ExpansionPanelActions } from '@material-ui/core';
import { FoodOptions } from '../core/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '../components/atomic/buttons/Button';
import FarmerFilter from '../components/molecules/FarmFilter';

const ClearButtonStyled = styled(Button)`
    fontsize: 13px;
    padding: 0;
    text-transform: none;
`

@inject('searchStore','shoppingStore')
@observer
class SearchBar extends Component {
    constructor(props) {
        super(props); 
        this.detailOptions = {
            isOrganic: 'Organic',
            isLocal: 'Local',
            delivery: 'Delivery',
            pickup: 'Pickup'
        };
      }
    
    state = Object.assign(
        {},
        {food: {}},
        {farmer: {}},
        {detail: {}},
        {expanded: false},
        {sortBy: 'name-asc'},
        {query:''},
        {distance:100},
        {visibility:'all'},
    )

    componentDidMount(){
        this.props.searchStore.resetSearch();
        const distance = this.props.searchStore.distance;
        this.setState({ distance })
    }

    createSortBy = (mobile) => {
        return (
            <div className={`sort-by-container ${mobile ? 'mobile' : 'web'}`}>
                <Typography className="sort-label">
                    Sort By
                </Typography>
                <Select
                    name="sortBy"
                    value={this.state.sortBy}
                    onChange={this.handleChange}
                    className="sort-box"
                    displayEmpty
                    disableUnderline={true}
                >
                    <MenuItem value='name-asc'>Name (A-Z)</MenuItem>
                    <MenuItem value='name-desc'>Name (Z-A)</MenuItem>
                    <MenuItem value='use-by-date-asc'>Use By Date Ascending</MenuItem>
                    <MenuItem value='use-by-date-desc'>Use By Date Descending</MenuItem>
                </Select>
            </div>
        )
    }

    checkboxChange = (name,field) => event => {
        let modState = Object.assign({}, this.state[field])
        modState[name] = event.target.checked;
        this.setState({ [field]: modState });
        this.props.searchStore.setValidator(name,event.target.checked);
    }

    clearFarmers = () => {
        this.setState({farmer: {}});
        this.props.searchStore.resetFarmerConditions();
    }

    clearFood = () => {
        this.setState({food: {}});
        this.props.searchStore.resetFoodConditions();
    }

    clearDetail = () => {
        this.setState({detail: {}});
        this.props.searchStore.resetDetailConditions();
    }

    radioChange = event => {
        switch(event.target.value){
            case 'public':
                this.props.searchStore.onRadioChange('Public',true,true);
                this.setState({ visibility: 'public' });
                break;
            case 'private':
                this.props.searchStore.onRadioChange('Public',false,true);
                this.setState({ visibility: 'private' });
                break;
            default:
                this.props.searchStore.onRadioChange('Public',true,false);
                this.setState({ visibility: 'all' });
                break;
        }
    }

    createCheckbox = (label,field) => (
        <FormControlLabel
            key={`${label}-checkbox-${this.state[field][label]}`}
            control={
                <Checkbox 
                    value = {label}
                    checked = {this.state[field][label]}
                    onChange = {this.checkboxChange(label,field)}
                />
            } 
            label={label}
            className="checkbox-label" 
        />
    )

    createFilterGrid = (distanceFilter, hideFarmerFilter) => {
        return (
            <Grid container item direction="column">
                <Grid container item justify="center">
                    <Grid container item xs={10} className="checkbox-group-label" justify="space-between" direction="row">
                        <Typography variant="subheading" className="text">Details</Typography>
                        <ClearButtonStyled onClick={this.clearDetail}>(clear)</ClearButtonStyled>
                    </Grid>
                    <Grid container item xs={10} className="checkbox-wrapper">
                    {Object.values(this.detailOptions).map((label)=>this.createCheckbox(label,'detail'))}
                    </Grid>
                </Grid>
                {
                    distanceFilter ? (
                        <React.Fragment>
                            <Grid container item justify="center">
                                <Grid container item xs={10} className="checkbox-group-label" justify="space-between" direction="row">
                                    <Typography variant="subheading" className="text">Categories</Typography>
                                    <ClearButtonStyled onClick={this.clearFood}>(clear)</ClearButtonStyled>
                                </Grid>
                                <Grid container item xs={10} className="checkbox-wrapper">
                                    {Object.values(FoodOptions).map((label)=>this.createCheckbox(label,'food'))}
                                </Grid>
                            </Grid>
                            <Grid container item justify="center">
                                <Grid item xs={10} className="checkbox-group-label">
                                    <Typography variant="subheading" className="text">Distance</Typography>
                                </Grid>
                                <Grid container direction='column' item xs={10} className="checkbox-wrapper mobil-margin">
                                    <TextField
                                        type="number"
                                        fullWidth
                                        label="Within distance (miles)"
                                        value={this.state.distance}
                                        placeholder="100"
                                        onChange={(e) => this.handleDistanceChange(e)}
                                    />
                                    <Button
                                        className="filter-button"
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => this.updateList()}
                                    >Filter</Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {
                                !hideFarmerFilter &&
                                <Grid container item justify="center">
                                    <Grid container item xs={10} className="checkbox-group-label" justify="space-between" direction="row">
                                        <Typography variant="subheading" className="text">Farms</Typography>
                                        <ClearButtonStyled onClick={this.clearFarmers}>(clear)</ClearButtonStyled>
                                    </Grid>
                                    <Grid container item xs={10} className="checkbox-wrapper">
                                        <FarmerFilter
                                            items={this.props.searchStore.clientList}
                                            checked={this.state.farmer}
                                            onChange={label => this.checkboxChange(label,'farmer')}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </React.Fragment>
                    )
                }
            </Grid>
        )
    }

    handleExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    handleChange = event => {
        this.props.searchStore.updateSort(event.target.value)
        this.setState({ [event.target.name]: event.target.value });
    };

    handleQueryChange = event => {
        this.props.searchStore.updateQuery(event.target.value);
        this.setState({query: event.target.value})
    }

    handleDistanceChange = event => {
        this.props.searchStore.updateQueryDistance(event.target.value);
        this.setState({distance: event.target.value})
    }

    updateList = async () => {
        await this.props.shoppingStore.getUnaffiliatedNearbyItems();
        this.props.searchStore.updateDistance();
        this.props.searchStore.refresh();
        this.setState({ expanded:false })
    }

    searchIfPressEnter = event => {
        if ((document.body.clientWidth <= 549) && (event.key === 'Enter')) {
            this.setState({ expanded: false });
        }
    }

    render() {
        console.log(this.state)
        const { distanceFilter, key, hideFarmerFilter } = this.props
        return (
            <div key={key} className={`search-bar row-fix ${this.state.expanded?'expanded':''}`}>
                <div className={`search-wrapper ${this.props.shadow? 'shadow':''}`}>
                    <div className={`expansion-container ${this.state.expanded?'scroll':''}`}>
                        <ExpansionPanel className="input-container" expanded={this.state.expanded}>
                            <ExpansionPanelSummary expandIcon={<Icon className="filter-dropdown" onClick={() => this.handleExpand()}>expand_more</Icon>} className="expansion-top">
                                <Icon className="search-icon">
                                    search
                                </Icon>
                                <input
                                    type="text"
                                    ref={(searchQuery) => this.searchQuery = searchQuery}
                                    onKeyPress={this.searchIfPressEnter}
                                    value={this.state.query}
                                    placeholder="Search..."
                                    onChange={(e) => this.handleQueryChange(e)}
                                    className={this.state.expanded? 'opened':''}
                                />
                                <Typography className="filter-label" onClick={() => this.handleExpand()}>
                                    Filter
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="expansion-bottom">
                                {/* {this.createSortBy(true)} */}
                                {this.createFilterGrid(distanceFilter, hideFarmerFilter)}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    {/* {this.createSortBy()} */}
                </div>
                <div className="filter-wrapper">
                    <div className="filter-container">
                        <div className="header">
                            <Typography className="text">
                                Filter
                            </Typography>
                        </div>
                        <div className="body">
                            {this.createFilterGrid(distanceFilter, hideFarmerFilter)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchBar;
