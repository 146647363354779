import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import Typography from '@material-ui/core/Typography';
import { message } from 'antd'
import BaseComp from './BaseComp';
import { InvitationService } from "../../../core/api";

const ItemWrapper = styled.div`
	margin: 8px 10px;
`

const ItemContainer = styled.div`
	background: #fff;
	border-radius: 10px;
	overflow: hidden;
`

const ContentContainer = styled.div`
	display: flex;
	margin: 0 auto 0 auto;
	margin-left: auto;
	padding: 0;
	border-radius: 10px;
`

const Section = styled.section`
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	flex-direction: row;
	justify-content: space-between;
`

const LeftContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	margin: 10px 0;
	margin-right: 10px;
	${breakpoint('mobile_min', 'tablet_min')`
		max-width: 60%; 
	`}
`

const RightContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 10px;
	margin-left: 0;
	${breakpoint('mobile_min', 'tablet_min')`
		margin-bottom: auto;
	`}
`

const CheckmarkContainer = styled.div`
	display: flex;
	margin-left: auto;
	height: 0;
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: auto 0;
	${breakpoint('mobile_min', 'tablet_min')`
		display: none;
	`}
`

const ActionButtonContainer = styled.div`
	display: none;
	padding: 0 8px 8px;
	padding-right: 3%;
	justify-content: flex-end;

	${breakpoint('mobile_min', 'tablet_min')`
		display: flex;
	`}
`

const PendingText = styled(Typography)`
	font-size: 1em;
	display: ${props => props.mobile? 'none':'block'}
	font-family: ${props => props.theme.fonts.ms_font300};
	opacity: 0.8;
	color: #51d79a;
	${breakpoint('mobile_min', 'tablet_min')`
		display: block;
	`}
`

@inject('profileStore', 'clientListStore')
@observer
class BusinessItem extends Component {

	handleCardClick = () => {
		const { business, history } = this.props;
		if ((document.body.clientWidth <= 768)) {
			if (business) {
				history.push(`/profile/${business.id}`);
			}
		}
	}

	handleInviteResponse = async (accepted) => {
		const { clientListStore, profileStore, business } = this.props
		let buyerUID, sellerUID;
		if (business){
			if (profileStore.isSeller) {
				buyerUID = business.id;
				sellerUID = profileStore.business.id;
			} else {
				buyerUID = profileStore.business.id;
				sellerUID = business.id;
			} 
		}
		try {
			if(accepted){
				await profileStore.acceptConnectRequest(buyerUID,sellerUID);
			} else {
				await profileStore.deleteClientRelationship(buyerUID,sellerUID);
			}
			await clientListStore.refresh();
		}
		catch (e) {
			message.error(e.message);
			console.error(e);
			Sentry.captureException(e);
		}
	}

	handleCancel = async () => {
		const { clientListStore, profileStore, business, invite } = this.props
		let buyerUID, sellerUID;
		if(business){
			if(profileStore.isSeller) {
				buyerUID = business.id;
				sellerUID = profileStore.business.id;
			} else {
				buyerUID = profileStore.business.id;
				sellerUID = business.id;
			} 
		}
		try {
			if (business) {
				await profileStore.deleteClientRelationship(buyerUID,sellerUID);
				await clientListStore.refresh();
			} else {
				await InvitationService.delete(invite.id);
				await clientListStore.refreshPendingInvitations(false);
			}
		}
		catch (e) {
			message.error(e.message);
			console.error(e);
			Sentry.captureException(e);
		}
	}

	chooseButtons = () => {
		const { type, business } = this.props

		switch(type) {
			case 'request':
				return [
					{
						text: 'Accept',
						onClick: ( async () => this.handleInviteResponse(true)),
						color: 'primary',
						mobile: true
					},
					{
						text: 'Decline',
						onClick: ( async () => this.handleInviteResponse(false)),
						color: 'secondary',
						mobile: true
					},
				]
				break;
			case 'pending':
				return [
					{
						text: 'Cancel',
						onClick: ( async () => this.handleCancel()),
						color: 'secondary',
						mobile: true
					},
				]
				break;
			default:
				return [
					{
						text: 'Profile',
						onClick: (() => null),
						path: `/profile/${business.id}`,
						color: 'primary',
						mobile: false
					},
				]
				break;
		}
	}

	render() {
		const { business, invite, className } = this.props;
		let info;
		if (business) {
			info = business 
		} else if (invite) {
			info = {
				picture: require('../../../assets/placeholder_user.png'),
				name: invite.email,
				city: '',
				state: '',
				uid: '',
				foodOptions: [],
				distance: '',
			}
		}
		return (
			<BaseComp
				key={this.props.key}
				onClick={this.handleCardClick}
				picture={info.picture}
				heading={info.name}
				subheading={`${info.city}${info.id? ',':''} ${info.state} ${info.distance !== ''?`(${info.distance} mi)`:''}`}
				checked={this.props.checked}
				chips={info.foodOptions}
				uid={info.id}
				buttons={this.chooseButtons()}
				showActionButtons={this.props.type.match(/(request|pending)/)}
			/>
		)	
	}
}

export default withRouter(BusinessItem);
