import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Button from '../buttons/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const ButtonContainer = styled.div`
    position: relative;
    width: 25%;
    flex-basis: auto;
    border-radius: 100%;
    border: 2px solid ${props => props.theme.colors.spearmint_main};
    :after {
        content: "";        
        padding-bottom: 100%;
        display: block;
    }
`

const StyledButton = styled.button`
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0);
    border: none;
    color: ${props => props.theme.colors.spearmint_main};
    font-family: ${props => props.theme.fonts.ms_font300} !important;
    font-size: 0.5em;    
    font-weight: lighter;
`

const StyledTypography = styled(Typography)`
    color: ${props => props.theme.colors.spearmint_main} !important;
    font-family: ${props => props.theme.fonts.ms_font300} !important;
    font-size: 1em !important;
    display: inline-block !important;
    line-height: normal !important;
    transform: scale(3) !important;
`

const StyledInput = styled.input`
    width: 50%;
    text-align: center;
    border-style: none;
    font-size: 1.3em;
    flex-basis: auto;
`

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }
    width: 150px;
    height: 50px;
`

class IntegerSelector extends Component {
    state = {
        value: 0
    }

    constructor(props) {
        super(props)
        this.state = {
            value: this.props.defaultValue !== undefined ? this.props.defaultValue : 0,
        }
    }

    setValue(val) {
        this.setState( { value: val });
    }

    setValueWithCheck(val) {
        if (this.props.min !== undefined) {
            val = Math.max(val, this.props.min);
        }

        if (this.props.max !== undefined) {
            val = Math.min(val, this.props.max);
        }

        this.setValue(val);
        this.props.onChange(val);
        
    }

    incrementValue(inc) {
        this.setValueWithCheck(this.state.value + inc);
    }

    render() {
        return (
            <Wrapper className={this.props.className}>
                <ButtonContainer>
                    <StyledButton onClick={() => this.incrementValue(-1)}> 
                        <StyledTypography variant="subheading">
                            - 
                        </StyledTypography>
                    </StyledButton>
                </ButtonContainer>

                <StyledInput
                    value={this.state.value}
                    onChange={(e) => this.setValue(e.target.value)}
                    onBlur={(e) => this.setValueWithCheck(e.target.value)}
                    type="number">
                </StyledInput>

                <ButtonContainer>
                    <StyledButton onClick={() => this.incrementValue(1)}>
                        <StyledTypography variant="subheading">
                            +
                        </StyledTypography>
                    </StyledButton>
                </ButtonContainer>
                

            </Wrapper>
        )
    }
}

export default IntegerSelector