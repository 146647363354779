import DatabaseClient from '../../core/DatabaseClient'
import { userStore } from '../index';

class ErrorStore {
    async reportError(error, info) {
        console.error(error, info);
        let user = userStore.user;
        if (user === null) {
            return;
        }
        else {
            let uid = user.id;
            await DatabaseClient.reportError(uid, error, info);
        }
    }
}

export default new ErrorStore()
