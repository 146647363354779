import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from './Header'
import Body from './Body'
import { NoteCreator } from '../NoteCreator'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 10px;
`;

const NoteCreatorStyled = styled(NoteCreator)`
  margin-bottom: 20px;
`

const HeaderStyled = styled(Header)`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
`

const NoteItem = ({
  onTextChange,
  id,
  text,
  updated,
  date,
  labels,
  options,
  editMode,
  onDeleteClicked,
  onEditClicked,
  onSaveClicked,
  onCancelClicked,
  onBodyClicked,
  expanded,
  collapseLines,
  stared,
 ...other 
}) => {

  return editMode ? (
    <NoteCreatorStyled 
      id={id}
      initialText={text}
      onSave={onSaveClicked}
      onCancel={onCancelClicked}
      options={options}
      labels={labels}/>
  ):(
    <Container {...other}>
      <HeaderStyled
        onEdit={onEditClicked}
        onDelete={onDeleteClicked}
        stared={stared}
        date={date}
        updated={updated}
        labels={labels}
      />
      <Body 
        text={text}
        onTextChange={onTextChange}
        expanded={expanded}
        lines={collapseLines}
        onClick={onBodyClicked}
      />
    </Container>
  )
}

NoteItem.propTypes = {
  collapseLines: PropTypes.number
}

NoteItem.defaultProps = {
  collapseLines: 3,
}

export default NoteItem;