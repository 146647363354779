import React from 'react';
import styled from 'styled-components';
import { BounceLoader } from 'react-spinners';

const Backdrop = styled.div`
    background-color: whitesmoke;
    width: 100%;
    height: 100%;
    position: absolute; top: 0; right: 0; bottom: 0; left: 0;
`;

const Foredrop = styled.div`
    background-color: ${props => props.theme.colors.spearmint_main}
    opacity: 0.5;
    filter: alpha(opacity=50);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

class LoadingScreen extends React.Component {
    render() {
        return (
            <Backdrop>
                <Foredrop>
                    <BounceLoader color={"whitesmoke"} size={200} sizeUnit={"px"}/>
                </Foredrop>
            </Backdrop>
        );
    }
}

export default LoadingScreen;