import moment from 'moment'

class Time {
    fromNowString = (timestamp) => {
        const now = moment().format("YYYYMMDD HH:mm:ss");
        const stamp = moment(timestamp).format("YYYYMMDD HH:mm:ss");
        const timeDiffMinutes = moment(now,"YYYYMMDD HH:mm:ss").diff(moment(stamp,"YYYYMMDD HH:mm:ss"), 'minutes');
        if(timeDiffMinutes<=0){
            return 'just now';
        } else if (timeDiffMinutes<60){
            return `${timeDiffMinutes} minute(s) ago`;
        } else if (timeDiffMinutes<1439){
            return `${Math.floor(timeDiffMinutes/60)} hour(s) ago`;
        } else {
            return moment(stamp,"YYYYMMDD HH:mm:ss").format("MMM Do, h:mma");
        }
    }

    datetimeString = (timestamp) => {
        return moment(timestamp).format("MMM Do, h:mma");
    }
}

const T = new Time();
export default T;