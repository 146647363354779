import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../../atomic/buttons/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import SelectDropdown from '../../molecules/SelectDropdown';
import Bubble from '../Bubble';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: auto;
`

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 8px;
`

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`

const ButtonStyled = styled(Button)`
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px; 
`

const TextStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1em;
  min-width: 10px;
  flex: 1;
  padding: 5px;
  opacity: 0.6;
`

const BubbleStyled = styled(Bubble)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 550px) {
    width: 100% !important;
  }
`

const IconButtonStyled = styled(IconButton)`
  padding: 5px;
`

const SelectStyled = styled(Select)`
  width: 100px;
  margin-right: 20px;
`

const InputStyled = styled(Input)`
  width: 90px;
  margin-right: 20px;
`
const BubbleInputStyled = styled(Input)`
  width: 90px;
  margin-right: auto;
`

class QuantitySelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: null,
      amount: null,
      values: {},
    }
  }

  // componentDidMount() {
  //   const { values } = this.props
  //   this.setState({values})
  // }

  // componentWillReceiveProps(nextProps) {
  //   const { editMode, name, index } = this.props
  //   const { bool } = this.state
  //   if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
  //     this.props.onSave(index,name,bool)
  //   }
  //   if(editMode && !nextProps.editMode) {
  //     this.setState({ bool: nextProps.value });
  //   }
  // }

  update = (prop,val) => {
    this.setState({ [prop]: val });
  }

  updateAmount = (unit,value) => {
    if (value == null || value === '') {
      value = null;
    }
    else {
      value = parseInt(value);
      if (isNaN(value) || value < 0) {
        value = 0;
      }
      // }
      // const num = value.match(/^\d+$/);
      // if (num === null) {
      //     console.log(value);
      //     // If we have no match, value will be empty.
          
      // }
      // value = parseInt(value);
    }
    
    const { onUpdate, values } = this.props
    let modValues = Object.assign({},values);
    modValues[unit] = value
    onUpdate(modValues)
  }

  createQuantityOption = () => {
    const { onChange, onAdd, values } = this.props
    const { selected, amount } = this.state
    if(!selected)
      return;

    let modValues = Object.assign({},values);
    modValues[selected] = amount;
    onAdd();
    onChange(modValues)
  }

  deleteQuantityOption = selected => {
    const { onChange, onDelete, values } = this.props
    let modValues = Object.assign({},values);
    delete modValues[selected]
    onDelete();
    onChange(modValues)
  }

  getChips = () => {
	const { values } = this.props
	if (!values) {
		return;
	}
    const units = Object.keys(values)
    return units.map(unit => {
      return this.createChip(unit, values[unit])
    })
  }

  createChip = (unit, value) => {
    const { BubbleStyles } = this.props;
    return (
      <BubbleStyled enabled={true} bubbleStyles={BubbleStyles} color='#e8e8e8'>
        <BubbleInputStyled
          type='number'
          value={value == null ? null : Number(value).toString()}
          placeholder="unlimited"
          onChange={(e)=>this.updateAmount(unit,e.target.value)}
          min="0"
          step="1"
        />
        <TextStyled>{unit}</TextStyled>
        <IconButtonStyled onClick={()=>this.deleteQuantityOption(unit)}>
          <CloseIcon fontSize="small" />
        </IconButtonStyled>
      </BubbleStyled>
    )
  }

  render () {
    const { editMode, options, error, onChange, ...other } = this.props;
    const { selected, amount } = this.state;
    
    return (
      <QuantityContainer {...other}>
        <Selector>
          <SelectDropdown
            fullWidth={true}
            items={options}
            onChange={(val)=>this.update('selected',val)}
            allowOther={true}
            defaultValue={selected}
            formError={false}
            // label={"Unit of Measurement"}
          />
          <ButtonStyled size='small' onClick={this.createQuantityOption}>
            <Icon>add</Icon>
          </ButtonStyled>
        </Selector>
        <ChipContainer>
          {this.getChips()}
        </ChipContainer>
      </QuantityContainer>
    )
  }
}

QuantitySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  BubbleStyles: PropTypes.object,
  values: PropTypes.object,
};

QuantitySelector.defaultProps = {
  values: {
    bag: 90,
  },
  BubbleStyles: {
    marginBottom: '8px',
    width: '100%',
    padding: '5px 0 5px 13px'
  },
};

export default QuantitySelector;