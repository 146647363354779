import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Header from './Header';
import AuthManager from '../../../core/AuthManager';

class EmailVerificationHeader extends React.Component {
    state = {
        text: 'Your email has not been verified.  Click to resend link.',
        hidden: false
    }

    componentWillMount() {
        if (this.props.verified) {
            this.setState({ hidden: true });
        }
    }

    onClick = async () => {
        await AuthManager.sendEmailVerification();
        this.setState({ text: "A link has been sent to your email!"});
        setTimeout(() => {
            this.setState({ hidden: true });
        }, 3000);
    }

    render() {
        return (
            !this.state.hidden &&
            <Header onClick={this.onClick}>
                {this.state.text}
            </Header>
        );
    }
}

EmailVerificationHeader.propTypes = {
    verified: PropTypes.bool
}

EmailVerificationHeader.defaultProps = {
    verified: false
}

export default EmailVerificationHeader;