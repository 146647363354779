import Service from './Service';
import Invitation from "../model/Invitation"
import BusinessService from "./BusinessService";

const URL = 'invitation';

class InvitationService extends Service {

}

export default new InvitationService(URL, Invitation, {
    sender: BusinessService,
    recipient: BusinessService
});
