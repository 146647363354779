import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Button from '../../atomic/buttons/Button';
import ListItem from '../../ListItem';
import { orderStore } from '../../../stores/';
import { message } from 'antd';
import { observer, inject } from 'mobx-react';
import BusinessOption from './BusinessOption';
import BaseComp from '../BusinessItem/BaseComp';

const Panel = styled(ExpansionPanel)`
`;
const Summary = styled(ExpansionPanelSummary)`
  padding: 0;
`;
const Details = styled(ExpansionPanelDetails)`
  background-color: rgba(61, 210, 143, 0.123);
`;
const Actions = styled(ExpansionPanelActions)``;
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

const IdText = styled(Typography)`
  font-size: 10px; 
  color: #;
  opacity: 0.2;
`;

const ActionButton = styled(Button)`
  margin-left: 8px;
  padding-top: 5px;
  text-transform: none;
`;

const BaseCompStyled = styled(BaseComp)`
  margin: 0px;
  flex: 1;
`; 

const BusinessOptionStyled = styled(BusinessOption)`
  margin: 8px 10px 8px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
`;

@observer
class OrganizationBundleListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  renderStore = (store) => {
    return (
      <BusinessOptionStyled
        text={store.name}
        subText={`(${store.distance} mi)`}
        buttons={[
					{
						text: 'Profile',
						onClick: (() => null),
						path: `/profile/${store.id}`,
						color: 'primary',
						mobile: false
					},
				]}
      />
    )
  }

  render() {
    const { organization } = this.props;

    return (
      <Panel {...this.props}>
        <Summary expandIcon={<ExpandMoreIcon />}>
          <BaseCompStyled
            key={`organization-bundle-${organization.id}`}
            heading={organization.name}
            subheading={`${organization.stores.length} result(s) found`}
            uid={organization.id}
            type='organization'
            // onClick={this.handleCardClick}
            picture={organization.picture}
            // checked={this.props.checked}
            // chips={info.foodOptions}
            // buttons={this.chooseButtons()}
            // showActionButtons={this.props.type.match(/(request|pending)/)}
          />
        </Summary>
        <Details className="order-product-list colored-bg">
          {organization.stores.map((store, i) => this.renderStore(store))}
        </Details>
      </Panel>
    );
  }
}

OrganizationBundleListItem.propTypes = {
  organization: PropTypes.object.isRequired,
}

OrganizationBundleListItem.defaultProps = {
  organization: {
    "id": "f0NRZeCV108fWR4Y6Y6e",
    "name": "Matt co",
    "stores": [
      {
        "certifications": [],
        "city": "Raleigh",
        "closing": "17:00",
        "communicationOptions": [
          "Text"
        ],
        "coordinates": [
          35.7418297,
          -78.713608
        ],
        "description": "I'm buyer 3",
        "email": "buyer3@freshspire.io",
        "foodOptions": [
          "Fruit",
          "frui"
        ],
        "name": "Buyer 3",
        "opening": "08:00",
        "organization": {
          "id": "f0NRZeCV108fWR4Y6Y6e",
          "name": "Matt co"
        },
        "paymentOptions": [
          "Money Order"
        ],
        "picture": "https://firebasestorage.googleapis.com/v0/b/freshspire-mcsimps2.appspot.com/o/profile_pictures%2F2nKDT54t84DMJHTfpvIv?alt=media&token=528ddad8-e547-4ed9-98e3-1954783af750",
        "phone": "(999) 999-9999",
        "state": "NC",
        "streetAddress": "123 Buyer 3 St",
        "type": "buyer",
        "uid": "fYkSLtFFkaWj0mcGvTrIbwONGsF3",
        "zipcode": "27606",
        "distance": 0
      }
    ]
  }
}

export default OrganizationBundleListItem;
