import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject, renderReporter } from 'mobx-react'
import breakpoint from 'styled-components-breakpoint';
import PropTypes from 'prop-types';
import OrderBundle from './OrderBundle';
import moment from 'moment';

const Container = styled.div``;

@inject('orderStore', 'profileStore', 'messagingStore')
@observer
class OrderBundleList extends Component {
    constructor(props) {
      super(props);
      this.state = {
        bundles: [],
      }
    }

    componentWillMount() {
      this.prepareState(this.props);
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps !== this.props) {
        this.prepareState(nextProps);
      }
    }

    prepareState(props) {
      const { orders } = props;
      const bundles = this.separateOrders(orders);
      this.setState({ bundles: orders });
    }

    separateOrders = items => {
      const containedBundleIds = [];
      const sortedBundles = [];
      items.map((item,index) => {
        const { bundleID, timestamp } = item;
        if(!containedBundleIds.includes(bundleID)) {
          containedBundleIds.push(bundleID)
        }
        const bundleIndex = containedBundleIds.indexOf(bundleID);
        if(!sortedBundles[bundleIndex]) {
          sortedBundles[bundleIndex] = {id: bundleID, timestamp: timestamp, orders: []}
        }
        sortedBundles[bundleIndex].orders.push(item);
      })
      sortedBundles.sort((a,b) => {
        if(a.timestamp < b.timestamp) {
          return 1;
        } else {
          return -1;
        }
      })
      return sortedBundles;
    }

    renderBundles = () => {
      const { productBusiness } = this.props;
      const { business } = this.props.profileStore;
      const { bundles } = this.state;

      if(bundles && bundles.length > 0) {
        return bundles.map((bundle, idx) => (
          <OrderBundle
              bundle={bundle}
              business={business}
              productBusiness={productBusiness}
              path={this.props.path}
              key={`${bundle.id}-${idx}`}
              onEdit={(item) => this.props.onEdit(idx, item)}
              onRefresh={this.props.onRefresh}
              onRemove={this.props.onRemove}
              onOrderNoteClick={this.props.onOrderNoteClick}
          />
        ))
      }

      return null;
    }

    render() {
        return (
            <Container {...this.props}>
              {this.renderBundles()}
            </Container>
        );
    }
}

OrderBundleList.propTypes = {
  orders: PropTypes.array.isRequired,
}

OrderBundleList.defaultProps = {

}

export default OrderBundleList;
