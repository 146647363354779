import React from 'react'
import ReactDOM from 'react-dom';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import SendButton from '../../atomic/buttons/SendButton';
import MessageInput from '../../atomic/inputs/MessageInput';
import FileUploader from '../../atomic/inputs/FileUploader';
import UploadButton from '../../atomic/buttons/UploadButton';

// TODO: find alternative to fit-content
const Container = styled.div`
    display: flex;
    width: 100%;
    min-height: 55.14px;
    height: fit-content;
    align-items: stretch;
    border: 1px solid black;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.16),0 -2px 10px 0 rgba(0,0,0,0.12) !important;
`;

const StyledSendButton = styled(SendButton)`
    min-width: 50px;
    width: 50px;
    float: right;
`;

const StyledMessageInput = styled(MessageInput)`
    margin: 10px;
`;

const StyledFileUploader = styled(UploadButton)`
    min-width: 15px;
    width: 15px;
    height: 100%;
    border-radius: 0px;
    border-top-left-radius: 5px;
`;

class MessageSender extends React.Component {
    state = {
        value: '',
        disabled: false
    }

    constructor(props) {
        super(props);
    }

    componentWillUpdate() {
        //console.log(this.sendButton);
        //console.log(this.fileUpload);
        
    }

    onChange = (value) => {
        this.setState({ value });
    }

    onClick = async () => {
        if (this.state.value === '' || this.state.value.length > 500) {
            return;
        }
        const val = this.state.value;
        this.setState({ disabled: true });
        await this.props.onClick(val);
        this.setState({ value: '', disabled: false });
    }

    onKeyPress = async (evt) => {
        if (evt.key === "Enter" && !evt.shiftKey) {
            evt.preventDefault(); //TODO: fix enter going to newline
            //TODO add capabilities for newline in message
            await this.onClick();
        }
    }

    render() {
        const {onClick, ...messageProps} = this.props;
        
        return (
            <Container>
                <StyledFileUploader
                    withText={false}
                    withInstructions={false}
                    disabled={true} //TODO remove
                    onClick={() => {}} //TODO fill
                    />
                <StyledMessageInput
                    {...messageProps}
                    value={this.state.value}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    disabled={this.state.disabled}
                    rowsMax="4"
                    autoFocus
                />
                <StyledSendButton onClick={this.onClick} disabled={this.state.disabled}/>
            </Container>
        );
    }
}

MessageSender.propTypes = {
    onClick: PropTypes.func.isRequired
}

MessageSender.defaultProps = {
    placeholder: "Enter your message here",
    multiline: true,
    margin: "normal",
    label: undefined
}

export default MessageSender