import React from 'react'
import styled from 'styled-components'
import Time from '../../../core/utilities/Time'
import PropTypes from 'prop-types'

const Container = styled.div`
    display: block;
    text-align: center;
`;

class TimeLabel extends React.Component {
    render() {
        return (
            <Container>
                {Time.datetimeString(this.props.timestamp)}
            </Container>
        );
    }
}

TimeLabel.propTypes = {
    timestamp: PropTypes.number.isRequired
}

export default TimeLabel