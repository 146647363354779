import Service from './Service';
import OrderService from "./OrderService";
import BusinessService from "./BusinessService";
import OrderBundle from "../model/OrderBundle";
import { isObservableArray } from "mobx";

const URL = 'orderbundle';

class OrderBundleService extends Service {
    async deserialize(data) {
        console.log(data)
        const res = await super.deserialize(data);
        if (Array.isArray(res) || isObservableArray(res)) {
            res.forEach((item) => {
                if (item.buyerUID) {
                    item.buyer = item.buyerUID;
                    item.buyerUID = item.buyer.id;
                }
                if (item.sellerUID) {
                    item.seller = item.sellerUID;
                    item.sellerUID = item.seller.id;
                }
            })
        } else {
            if (!res) {
                return null;
            }
            if (res.buyerUID) {
                res.buyer = res.buyerUID;
                res.buyerUID = res.buyer.id;
            }
            if (res.sellerUID) {
                res.seller = res.sellerUID;
                res.sellerUID = res.seller.id;
            }
        }
        return res;
    }
}

export default new OrderBundleService(URL, OrderBundle, {
    orders: OrderService,
    buyerUID: BusinessService,
    sellerUID: BusinessService
});
