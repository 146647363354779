import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectDropdown from '../../molecules/SelectDropdown';
import Tooltip from '../../molecules/Tooltip';
import Bubble from '../../molecules/Bubble';

const TextContainer = styled.div`

`

const TextArea = styled(Bubble)`
  width: 150px;
`

const StyledSelect = styled(Select)`

`

const StyledFormHelper = styled(FormHelperText)`
  min-width: 88px;
`

const StyledItem = styled(MenuItem)`

`
@inject('sheetStore')
@observer
class ColumnUnit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      option: '',
    }
    this.disposer = observe(props.sheetStore.unsavedProductIDs, (change) => this.setState({option: this.state.option}));
  }

  componentWillMount() {
    const { value } = this.props;
    this.setState({ option: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { option } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,option)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ option: nextProps.value });
    }
  }

  update = option => {
    this.setState({ option });
  }

  createMenuItem = label => (
    <StyledItem value={label}>
      {label}
    </StyledItem>
  )

  createMenuItems = obj => (
    Object.values(obj).map(this.createMenuItem)
  )

  getError = () => {
    const { sheetStore, name, index } = this.props
    if (index >= sheetStore.listingErrors.length) {
      return null;
    }
    const listingError = sheetStore.listingErrors[index];
    if(!listingError) return null;
    
    const stringArry = listingError.split(`${name}:`);

    if (stringArry && stringArry.length > 1) {
      return stringArry[1];
    } else {
      return null;
    }
  }

  getLabel = (option, obj) => {
    if(!option) {
      return null;
    }
    
    if(obj && obj.hasOwnProperty(option)) {
      if(obj[option].hasOwnProperty('label')) {
        return obj[option].label;
      }
    }
    return null;
  }

  render () {
    const { editMode, index, sheetStore, placeholder, value, style, error, ...other } = this.props;
    const { option } = this.state;
    
    const productRowID = index < sheetStore.unsavedProductIDs.length ? sheetStore.unsavedProductIDs[index] : null;
    const unitOptions = sheetStore.unitOptions[productRowID];
    
    const tipError = this.getError();
    const label = this.getLabel(option, unitOptions);

    return editMode ? (
      <React.Fragment>
        <SelectDropdown
          key={`unit-${productRowID}-${index}`}
          items={unitOptions}
          fullWidth
          onChange={this.update}
          allowOther={false}
          error={error}
          errorText={this.props.errorText} 
          defaultValue={option}
          {...other}
        />
      </React.Fragment>
    ) : (
      <Tooltip text={tipError}>
        <TextArea enabled={tipError}>
          {label}
        </TextArea>
      </Tooltip>
    )
  }
}

ColumnUnit.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  editMode: PropTypes.bool,
  errorText: PropTypes.string,
  allowOther: PropTypes.bool
};

ColumnUnit.defaultProps = {
  editMode: false,
  placeholder: 'Select',
  errorText: "This field is required",
  allowOther: false
};

export default ColumnUnit;