import React, { Component, Fragment } from 'react'
import {observable, autorun} from "mobx";
import { observer, inject, renderReporter } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Button from '../atomic/buttons/Button';
import Modal from '../Modal';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions } from '@material-ui/core';
import { AlertMessage } from '../AlertMessage'
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import { message } from 'antd';
import { StepPaginator } from '../molecules/Pagination';
import MessagesModal from '../organisms/MessagesModal';
import OrderNotesModal from '../organisms/OrderNoteModal';
import OrderBundlesList from '../molecules/OrderBundlesList';
import MessageButton from '../atomic/buttons/MessageButton';
import { OrderBundleService } from "../../core/api"
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LoadingProgress = (props) => (
    <LoaderWrapper {...props}>
        <CircularProgress size={40} thickness={5} />
    </LoaderWrapper>
);

@inject('orderStore', 'profileStore', 'messagingStore')
@observer
class OrderContainer extends Component {

    state = {
        pageItems: [],
        total: 1,
        display: 3,
        number: 1,
        currentPage: 1,
        fetchPage: 1,
        messageModalOpen: false,
        orderNote: null,
        messageModal: undefined,
        noteModal: false,
    }

    componentDidMount() {
        this.setState({
          currentPage: 1,
        })
        window.scroll(0, 0);
    }

    onNextPage = async () => {
        const { fetchPage, currentPage } = this.state;
        const newPageNum = currentPage + 1;
        if(fetchPage === currentPage) {
            await this.props.onNextPage()
            this.setState({
                currentPage: newPageNum,
                fetchPage: newPageNum
            })
        } else {
            this.setState({
                currentPage: newPageNum,
            })
        }
    }

    setOrderNote = note => this.setState({orderNote: note})
    

    checkPicture = (object) => {
        if(!object.picture){
            object.picture = require('../../assets/placeholder_user.png');
        }
    }


    render() {
        const { loading } = this.props;
        const requests_queued = this.props.count;
        const seller = this.props.business.name
        this.checkPicture(this.props.business);

        
        return (
            <ExpansionPanel className="panel" defaultExpanded={true} expanded={true}>
                {this.state.messageModalOpen && <MessagesModal business={this.props.business} open={this.state.messageModalOpen} onClose={() => this.setState({ messageModalOpen: false})} />}
                {this.state.orderNote && <OrderNotesModal heading="Order Notes" showSubmitButton={false} orderNotes={this.state.orderNote} open={Boolean(this.state.orderNote)} onClose={() => this.setState({ orderNote: null})} />}
                <ExpansionPanelSummary className="card-author" >
                    <section className="text">
                        <div className="contact-container">
                            <div className="image-container">
                                <div className="image-floater">
                                    <img src={this.props.business.picture} className="card-image" />
                                </div>
                            </div>
                        </div>
                        <section className="info-section">
                            <section className="major-text">
                                <div className="card-name-container">
                                    <Link to={`/profile/${this.props.business.id}`}>
                                        <Typography variant="title" className="name lg profile-link">
                                            {seller}&nbsp;<Icon className="text_icon">open_in_new</Icon>
                                        </Typography>
                                    </Link>
                                </div>
                                <div className="card-info-container">
                                    <section className="subinfo">
                                        <Typography variant="subheading" className="label sm">
                                            Number of Items:
                    						</Typography>
                                        <Typography variant="subheading" className="value sm ">
                                            {requests_queued}
                                        </Typography>
                                    </section>
                                    {/* <div className="subinfo">
                                        <Typography variant="subheading" className="label sm">
                                            Total:
                    	                </Typography>
                                        <Typography variant="subheading" className="value sm">
                                            {!totalCost ? "Request Quote" : `$${totalCost.toFixed(2)}`}
                                        </Typography>
                                    </div> */}
                                </div>
                                <div className="detail-button-container contact-button">
                                    <MessageButton size="small" variant="outlined" color={undefined} className="action-button" style={{"padding": "5px"}} onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ messageModalOpen: true })}
                                    }/>
                                </div>
                            </section>
                        </section>
                    </section>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="order-product-list">
                    {
                        loading ?
                            <LoadingProgress/>
                            :
                            <>
                                <OrderBundlesList
                                    orders={this.props.bundles}
                                    productBusiness={this.props.business}
                                    path={this.props.path}
                                    onRefresh={this.props.onRefresh}
                                    onEdit={this.props.onEdit}
                                    onRemove={this.props.onRemove}
                                    onOrderNoteClick={this.setOrderNote}
                                />
                                {this.props.paginationBar}
                            </>
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}

export default OrderContainer;
