import Service from './Service';
import Business from "../model/Business"
import OrganizationService from "./OrganizationService";

const URL = 'business';

class BusinessService extends Service {

}

// TODO: nested OrganizationService
export default new BusinessService(URL, Business, {
    organization: OrganizationService
});
