import React, { Component } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import { inject } from 'mobx-react'
import { message } from 'antd'
import breakpoint from 'styled-components-breakpoint'
import logoTitle from '../../assets/FreshSpire-Brandmark_Combination-White.png';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '../../components/atomic/buttons/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
import { LoadingSpinnerPage } from '../../components/LoadingSpinner';
import styled, { css } from 'styled-components';
import PhoneNumberInput from '../../components/atomic/inputs/PhoneNumberInput'
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LegalModal from '../../components/organisms/LegalModal';
import {InvitationService} from "../../core/api";

const INITIAL_STATE = {
	firstName: '',
	lastName: '',
	phoneNumber: '',
	email: '',
	password: '',
	passwordRepeat: '',
	error: null,
	invitation: null,
	senderName: null,
	loading: false,
	expired: false,
	TOSAgreement: false,
	modalOpen: false
};

const ImageContainer = styled.div`
	justify-content: center;
	display: flex;
	padding-bottom: 15px;
`;

const StyledImage = styled.img`
	border: 14px solid #fff;
	border-radius: 100%;
	width: 150px;
	height: 150px;
	object-fit: cover;
	${breakpoint('mobile_min', 'mobile_max')`
		border: 7px solid #fff;
		width: 110px;
		height: 110px;
	`}
`;

const ImageAndSubtitle = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 15px;
	margin-top: -30px; 
`;

const SubtitleContainer = styled.div`
	text-align: center !important;
`;

@inject('userStore')
class SignUpForm extends Component {
	constructor(props) {
		super(props);
		this.state = {...INITIAL_STATE};
	}

	async componentWillMount() {
		this.setState({ loading: true })
		if (this.props.invitationID) {
			try {
				const invitation = await InvitationService.get(this.props.invitationID);
				if (invitation && invitation.status === 'sent') {
				    const { sender } = invitation;
					if (this.props.userStore.isAuthenticated) {
						this.props.history.push('/profile/' + sender.id);
					}
					else {
						this.setState( {invitation, senderName: sender.name, senderPicture: sender.picture, email: invitation.email });
					}
					this.setState({ loading: false });
					return;
				}
				else {
					this.setState( {expired: true});
				}
			} catch (e) {
				console.error(e);
				Sentry.captureException(e);
				this.setState( {expired: true});
			}
		}
		if (this.props.userStore.isAuthenticated) {
			this.props.history.push('/login');
		}
		this.setState({ loading: false })
	}

	onSubmit = async (event) => {
		if (!this.validate()) {
			return;
		}
		
		const {
			firstName,
			lastName,
			phoneNumber,
			email,
			password,
		} = this.state;
		const { history } = this.props;
		
		try {
			await this.props.userStore.signUp(firstName, lastName, phoneNumber, email, password, this.state.invitation ? this.state.invitation.id : null);
			message.success('Sign up successful!');
		}
		catch(e) {
			message.error(e.message);
			console.error(e);
			Sentry.captureException(e);
		}
	}

	validate = () => {
		// Firebase can validate email and password strength
		// Here, we check for only password = confirmpassword
		if (this.state.password !== this.state.passwordRepeat) {
			message.error("The passwords do not match");
			return false;
		}

		if (this.state.email.length === 0) {
			message.error("Missing email");
			return false;
		}

		if (this.state.password.length === 0) {
			message.error("Missing password");
			return false;
		}

		if (this.state.passwordRepeat.length === 0) {
			message.error("Missing confirm password");
			return false;
		}
		
		if (this.state.firstName.length === 0) {
			message.error("Missing first name");
			return false;
		}

		if (this.state.firstName.length > 25) {
			message.error("First name must be less than 25 characters");
			return false;
		}

		if (this.state.lastName.length === 0) {
			message.error("Missing last name");
			return false;
		}

		if (this.state.lastName.length > 25) {
			message.error("Last name must be less than 25 characters");
			return false;
		}

		if (this.state.phoneNumber.length === 0) {
			message.error("Missing phone number");
			return false;
		}

		let pattern = new RegExp("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$");
		if (!pattern.test(this.state.phoneNumber)) {
			message.error("Phone number is formatted incorrectly");
			return false;
		}
		
		if (!this.state.TOSAgreement) {
			message.error("You must accept the Terms of Service and Privacy Policy");
			return false;
		}

		return true;
	}

	onEmailChange = (e) => {
		this.setState({ email: e.target.value});
	}

	onPasswordChange = (e) => {
		this.setState({ password: e.target.value })
	}

	onConfirmPasswordChange = (e) => {
		this.setState({ passwordRepeat: e.target.value })
	}

	onFirstNameChange = (e) => {
		this.setState({ firstName: e.target.value })
	}

	onLastNameChange = (e) => {
		this.setState({ lastName: e.target.value })
	}

	onPhoneNumberChange = (value) => {
		this.setState({ phoneNumber: value })
	}

	handleAgreement = (e) => {
		this.setState({ TOSAgreement: e.target.checked })
	}

	openModal = (contentType) => {
		this.setState({ modalOpen: true, modalContent: contentType });
	}

	render() {
			return (
				<div className="login-container scroll">
					<div className="login-wrapper">
						<div className="img-wrapper">
							<div className="img-container">
								<img src={logoTitle} alt="FreshSpire" className="img-format" />
							</div>
						</div>
						<div className="credentials-wrapper">
							{
								this.state.loading ? 
								LoadingSpinnerPage("white")
								:
								<React.Fragment>
									{ this.state.invitation && 
										<div className="login-credentials">
											<ImageAndSubtitle>
												<ImageContainer>
													<StyledImage
														src={this.state.senderPicture || require('../../assets/placeholder_user.png')}
														alt="profilePhoto"
														style={{ backgroundColor: '#fff' }}
														/>
												</ImageContainer>
												<SubtitleContainer>
													<InputLabel className="label">{`${this.state.senderName} has invited you to join Freshspire as a ${this.state.invitation.sender === "seller" ? "buyer" : "seller"}!`}</InputLabel>
												</SubtitleContainer>
											</ImageAndSubtitle>
										</div>
									}
									{ this.state.expired && 
										<div className="login-credentials">
											<ImageAndSubtitle>
												<SubtitleContainer>
													<InputLabel className="label">{`This invitation has expired`}</InputLabel>
												</SubtitleContainer>
											</ImageAndSubtitle>
										</div>
									}
									<div className="login-credentials">
										<InputLabel className="label">First Name</InputLabel>
										<input
											className="input"
											placeholder="First Name"
											onChange={this.onFirstNameChange}
										/>
									</div>
									<div className="login-credentials">
										<InputLabel className="label">Last Name</InputLabel>
										<input
											className="input"
											placeholder="Last Name"
											onChange={this.onLastNameChange}
										/>
									</div>
									<div className="login-credentials">
											<InputLabel className="label">Contact Phone Number</InputLabel>
											<PhoneNumberInput className="phoneInput" value={this.state.phoneNumber} onChange={this.onPhoneNumberChange}/>
									</div>
									<div className="login-credentials">
										<InputLabel className="label">Email</InputLabel>
										<input
											className="input"
											placeholder="Email"
											defaultValue={this.state.invitation ? this.state.invitation.email : undefined}
											onChange={this.onEmailChange}
										/>
									</div>
									<div className="login-credentials">
											<InputLabel className="label">Password</InputLabel>
											<input
												className="input"
												placeholder="Password"
												type="password"
												onChange={this.onPasswordChange}
											/>
									</div>
									<div className="login-credentials">
											<InputLabel className="label">Confirm Password</InputLabel>
											<input
												className="input"
												placeholder="Confirm Password"
												type="password"
												onChange={this.onConfirmPasswordChange}
											/>
									</div>

									<div className="login-credentials">
										<Grid container="true" justify="center" item xs={12}>
											<FormControl component="fieldset">
												<FormGroup>
													<Grid container justify="center" xs={12}>
														<Grid container item direction="row" justify="space-between" className="terms-container" xs={12}>
															<Grid item xs={2} sm={1} justify="center">
																<FormControlLabel
																	control={
																		<Checkbox
																			value="TOSAgreement"
																			checked={this.state["TOSAgreement"]}
																			onChange={this.handleAgreement}
																			style={{color: "#fff"}}
																		/>
																	}
																	style={{marginTop: '-10px',marginLeft: '0'}}
																/>
															</Grid>
															<Grid item xs={9} sm={10}>
																<Typography variant="subheading" className="terms-text" style={{fontSize: '0.9em', color: "#fff"}}>
																	{'I agree to Freshspire\'s '}
																	&nbsp;{<span style={{cursor: "pointer", color: "blue", textDecoration: "underline"}} onClick={() => this.openModal("Terms of Use")}>Terms of Use</span>}
																	{' &'}
																	&nbsp;{<span style={{cursor: "pointer", color: "blue", textDecoration: "underline"}} onClick={() => this.openModal("Privacy Policy")}>Privacy Policy</span>}
																	{', and I give my express written consent for Freshspire to email, text, or call me concerning the platform and services.'}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</FormGroup>
											</FormControl>
										</Grid>
									</div>
									<div className="button-container">
										<div className="btn-submit">
											<Button variant="outlined" onClick={this.onSubmit}>Sign up</Button>
										</div>
									</div>
								
								</React.Fragment>
							}
							
							
							<div className="text-wrapper center">
								Already have an account? &nbsp;
								<Link to={this.props.invitationID ? `/login?inv=${this.props.invitationID}` : '/login'}>Login</Link>
							</div>
						</div>
					</div>
					{this.state.modalOpen && <LegalModal contentType={this.state.modalContent} open={this.state.modalOpen} onClose={() => this.setState({ modalOpen: false })}/>}
				</div>
			)
	}	
}
export default withRouter(SignUpForm);
