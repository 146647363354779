import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import GenericModal from './GenericModal';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react'
import ConversationView from '../molecules/ConversationView';
import { Business } from '../../core/model';
import { message } from 'antd';
import MessageInput from '../atomic/inputs/MessageInput';
import BusinessChipInput from '../molecules/BusinessChipInput';


const StyledGenericModal = styled(GenericModal)`
    width: 600px !important;
    @media (max-width: 600px) {
        width: 550px;
    }
`;

const StyledConversationView = styled(ConversationView)`
    height: 50vh;
    margin-bottom: 20px;
    @media (max-width: ${props => props.theme.breakpoints.sidebar_off_max}px) {
        height: calc(100vh - 100px);
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin: 50px;
    @media (max-width: 550px) {
        margin: 10px;
    }
    ${breakpoint('tablet_min', 'tablet_max')`
        margin: 25px;
    `}
`;

const Information = styled.div`
    text-align: center;
    margin-top: ${props => props.top ? "0px" : "40px"};
`;

const StyledMessageInput = styled(MessageInput)``;


@inject("profileStore", "messagingStore")
@observer
class MessagesModal extends React.Component {
    state = {
        loading: true,
        existingConversation: false,
        conversation: null,
        value: '',
        disabled: false,
        businesses: []
    }
    
    constructor(props) {
        super(props);
        if (props.business) {
            this.state.value = '';
            this.state.disabled = false;
            this.state.businesses = [props.business];
        }
    }

    async componentDidMount() {
        if (!this.props.business) {
            this.setState({ loading: false, existingConversation: false });
        }
        else {
            const conv = await this.props.messagingStore.getDirectConversation(this.props.business.id);
            if (conv) {
                this.setState({ loading: false, existingConversation: true, conversation: conv})
            }
            else {
                this.setState({ loading: false, existingConversation: false, conversation: null });
            }
        }
    }

    onSubmit = async () => {
        if (this.state.value.length === 0 || this.state.value.length > 500) {
            message.error("Message must be less than 500 characters");
            throw new Error("Message must be less than 500 characters")
        }
        
        if (this.state.businesses.length === 0) {
            message.error("No businesses selected");
            throw new Error("No businesses selected");
        }
        const uids = this.state.businesses.map((business) => business.id);
        try {
            await this.props.messagingStore.sendMessage(uids, this.state.value);
        }
        catch (e) {
            message.error(e.message);
            console.error(e);
            Sentry.captureException(e);
            return;
        }
        message.success("Message sent!");
    }

    onChange = (value) => {
        this.setState({ value });
    }

    onAddBusiness = (business) => {
        let { businesses } = this.state;
        const ids = businesses.map((i) => i.id);
        if (ids.indexOf(business.id) === -1) {
            businesses = [...businesses, business];
        }

        this.setState({
            businesses,
        });
    }

    onRemoveBusiness = (business) => {
        const ids = this.state.businesses.map((i) => i.id);
        const newState = [...this.state.businesses];
        newState.splice(ids.indexOf(business.id), 1);
        this.setState({ businesses: newState });
    }
    
    render() {
        return (
            <StyledGenericModal {...this.props} loading={this.state.loading} heading={this.state.existingConversation ? "Messages" : "Start new conversation"} showSubmitButton={!this.state.existingConversation} onSubmit={this.onSubmit} submitText={"Send"}>
            {
                this.state.existingConversation ?
                    <StyledConversationView conversation={this.state.conversation}/>
                    :
                    <Content>
                        <Information top>
                            Add people to the conversation:
                        </Information>
                        <BusinessChipInput onAdd={this.onAddBusiness} onRemove={this.onRemoveBusiness} initialValue={this.props.business} blacklist={[this.props.profileStore.business.id]}/>
                        <Information>
                            Enter a message below to start your chat!
                        </Information>
                        <StyledMessageInput
                            value={this.state.value}
                            onChange={this.onChange}
                            onKeyPress={this.onKeyPress}
                            disabled={this.state.disabled}
                            rowsMax="12"
                        />
                    </Content>
            }
            </StyledGenericModal>
        );
    }
}

MessagesModal.propTypes = {
    business: PropTypes.instanceOf(Business),
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func
}

export default MessagesModal;
