
export const UserKeys = ['uid', 'firstName', 'lastName', 'email'];

export const CommunicationOptions = Object.freeze({
    TEXT: 'Text',
    EMAIL: 'Email',
    CALL: 'Call',
    OTHER: 'Other',
});

export const PaymentOptions = Object.freeze({
    CREDIT_CARD:  'Credit Card',
    CHECK: 'Check',
    CASH: 'Cash',
    PAYPAL: 'PayPal',
    MONEY_ORDER: 'Money Order',
    DIRECT_DEPOSIT: 'Direct Deposit',
    OTHER: 'Other',
});

export const FoodOptions = Object.freeze({
    FRUIT: 'Fruit',
    VEGETABLES: 'Vegetables',
    DAIRY: 'Dairy',
    MEATS: 'Meat',
    FUNGI: 'Fungi',
    BAKERY: 'Bakery',
    FLOWERS: 'Flowers',
    OTHER: 'Other',
});

export const ReasonForPostOptions = Object.freeze({
    EXTRA: 'Extra from an order',
    REJECT: 'Rejected Shipment',
    IMPERFECTION: 'Imperfections',
    OTHER: 'Other',
});

export const BusinessKeys = ['name', 'streetAddress', 'city', 'state', 'zipcode', 'phone', 'description', 'communicationOptions', 'opening', 'closing'];

export const SellerKeys = ['paymentOptions', 'foodOptions'];

export const DealKeys = ['name', 'quantity', 'quantityUnit', 'useByDate', 'price', 'priceUnit', 'isOrganic', 'isLocallyGrown', 'delivery', 'pickup', 'reasonForPost', 'notes', 'public', 'foodOption'];

export const FoodUnits = Object.freeze({
    BAG: 'bag',
    BARREL: 'barrel',
    BASKET: 'basket',
    BOTTLE: 'bottle',
    BOX: 'box',
    BUNCH: 'bunch',
    BUSHEL: 'bushel',
    CARTON: 'carton',
    CASE: 'case',
    CAN: 'can',
    COUNT: 'count',
    CRATE: 'crate',
    CUP: 'cup',
    EAR: 'ear',
    HEAD: 'head',
    GALLON: 'gallon',
    GRAM: 'gram',
    LB: 'lb',
    LUG: 'lug',
    OZ: 'oz',
    PACK: 'pack',
    PALLET: 'pallet',
    PECK: 'peck',
    PINT: 'pint',
    SACK: 'sack',
    QUART: 'quart',
    TRAY: 'tray'
});

export const States = ["AK","AL","AR","AZ","CA","CO","CT","DC","DE","FL","GA","GU","HI","IA","ID", "IL","IN","KS","KY","LA","MA","MD","ME","MH","MI","MN","MO","MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY", "OH","OK","OR","PA","PR","PW","RI","SC","SD","TN","TX","UT","VA","VI","VT","WA","WI","WV","WY"];