import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import Icon from '@material-ui/core/Icon';

const StyledIcon = styled(Icon)`
`

const StyledButton = styled(Button)`
    min-width: 100%;
    min-height: 100%;
    padding: 0px;
    border-radius: 100%;
`

const Wrapper = styled.div`
    display: inline-block;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 25px;
`;

class EditButton extends React.Component {
    render() {
        return (
            <Wrapper>
                <StyledButton onClick={this.props.onClick}>
                    <StyledIcon>edit</StyledIcon>
                </StyledButton>
            </Wrapper>
        );
    }
}

EditButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default EditButton;