import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '../../atomic/buttons/Button';
import Typography from '@material-ui/core/Typography';

class ChipPanel extends Component {
  render () {
    return (
      <div>
        <Card className='alert-box'>
          <CardHeader title={this.props.title} className='alert-header'/>
          <CardContent>
            <Typography component="p" variant="subheading">
              {this.props.message}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary" onClick={ () => this.props.closePopup()}>Continue</Button>
          </CardActions>
        </Card>
      </div>
    )
  }
}

export default ChipPanel