import React from "react";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
/*
* {
*      label: <Bags>
*      value: productID
* }
*/
/**
 * Can either take an array of values to show OR
 * an object of format
 * {
 *  key1: {
 *      label,
 *      value
 * },
 * key2: {
 *  label,
 *  value
 * }
 * }
 */
class SelectDropdown extends React.Component {
    constructor(props) {
        super(props);
        let list;
        let isStructuredObject = true;
        if (Array.isArray(props.items)) {
            list = props.items;
            list = list.sort();
            isStructuredObject = false;
        }
        else {
            Object.keys(props.items).forEach((key) => {
                if (props.items[key].value === undefined || props.items[key].label === undefined) {
                    isStructuredObject = false;
                }
            });
            if (isStructuredObject) {
                //list = Object.keys(props.items).map((key) => props.items[key].value);
                const keys = Object.keys(props.items).sort((a, b) => {
                    return props.items[a].label.localeCompare(props.items[b].label);
                })
                // Sort by key
                list = keys.map((key) => props.items[key].value);
            }
            else {
                list = Object.values(props.items);
                list = list.sort();
            }
        }
        if (props.autoSelect && list.length > 0) {
            const defaultValue = list[0];

            this.state = {
                isStructuredObject,
                list,
                value: defaultValue,
                otherValue: '',
                showOther: false
            }
            this.props.onChange(defaultValue);
        }
        else if (props.defaultValue && props.defaultValue.length > 0) {
            const defaultValue = props.defaultValue;
            if (list.includes(defaultValue)) {
                this.state = {
                    isStructuredObject,
                    list,
                    value: defaultValue,
                    otherValue: '',
                    showOther: false
                }
                // props.onChange(defaultValue);
            }
            else if (props.allowOther) {
                this.state = {
                    isStructuredObject,
                    list,
                    value: 'Other',
                    otherValue: defaultValue,
                    showOther: true
                }
                // props.onChange(defaultValue);
            }
            else {
                this.state = {
                    isStructuredObject,
                    list,
                    value: '',
                    otherValue: '',
                    showOther: false
                }
            }
        }
        else {
            this.state = {
                isStructuredObject,
                list,
                value: '',
                otherValue: '',
                showOther: false
            }
        }
    }

    componentWillMount() {
        const list = this.state.list;
        if (this.props.autoSelect && list.length > 0) {
            const defaultValue = list[0];
            this.setState({
                value: defaultValue,
                otherValue: '',
                showOther: false
            })
            this.props.onChange(defaultValue);
        }
        else if (this.props.defaultValue && this.props.defaultValue.length > 0) {
            const defaultValue = this.props.defaultValue;
            if (list.includes(defaultValue)) {
                this.setState({
                    value: defaultValue,
                    otherValue: '',
                    showOther: false
                });
                // props.onChange(defaultValue);
            }
            else if (this.props.allowOther) {
                this.setState({
                    value: 'Other',
                    otherValue: defaultValue,
                    showOther: true
                });
                // props.onChange(defaultValue);
            }
            else {
                this.setState({
                    value: '',
                    otherValue: '',
                    showOther: false
                });
            }
        }
        else {
            this.setState({
                value: '',
                otherValue: '',
                showOther: false
            });
        }
    }

    handleChange = (event) => {
        const val = event.target.value;
        if (this.props.allowOther && val === "Other") {
            this.setState({ value: val, showOther: true, otherValue: '' });
            this.props.onChange('');
        }
        else {
            this.setState({ value: val, showOther: false });
            this.props.onChange(val);
        }
    }

    handleOtherChange = (event) => {
        const val = event.target.value;
        this.setState({ otherValue: val });
        this.props.onChange(val);
    }

    getMenuItems = () => {
        if (!this.state.isStructuredObject) {
            return this.state.list.map((item) => <MenuItem value={item}>{item}</MenuItem>);
        }
        else {
            // Sort
            //list = Object.keys(props.items).map((key) => props.items[key].value);
            const keys = Object.keys(this.props.items).sort((a, b) => {
                return this.props.items[a].label.localeCompare(this.props.items[b].label);
            })
            // Sort by key
            return keys.map((key) => <MenuItem value={this.props.items[key].value}>{this.props.items[key].label}</MenuItem>);
            //return Object.keys(this.props.items).map((key) => <MenuItem value={this.props.items[key].value}>{this.props.items[key].label}</MenuItem>)
        }
    }

    render() {
        return (
            <React.Fragment>
                <FormControl fullWidth={this.props.fullWidth}>
                    {
                        this.props.label &&
                        <InputLabel required={this.props.required} error={this.props.error}>{this.props.label}</InputLabel>
                    }
                    <Select
                        value={this.state.value}
                        onChange={this.handleChange}
                        required={this.props.required}
                        error={this.props.error}
                    >
                        {this.props.showNone && 
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>}
                        {this.getMenuItems()}
                        {this.props.allowOther && <MenuItem value={"Other"}><em>Other</em></MenuItem>}
                    </Select>
                    {(!this.state.showOther && this.props.formError) && <FormHelperText error={this.props.error}>{this.props.error ? this.props.errorText : ''}</FormHelperText>}

                </FormControl>
                {this.state.showOther &&
                    <FormControl fullWidth={this.props.fullWidth}>
                        {
                            this.state.showOther && 
                                <TextField
                                    label="Other"
                                    value={this.state.otherValue}
                                    onChange={this.handleOtherChange}
                                    margin="normal"
                                    error={this.props.error}
                                />
                        }
                        <FormHelperText error={this.props.error}>{this.props.error ? this.props.errorText : ''}</FormHelperText>
                    </FormControl>
                }
            </React.Fragment>
            
        );
    }
}

SelectDropdown.propTypes = {
    allowOther: PropTypes.bool,
    autoSelect: PropTypes.bool, // Auto select the first item on the dropdown
    error: PropTypes.bool,
    formError: PropTypes.bool,
    errorText: PropTypes.string,
    fullWidth: PropTypes.bool,
    defaultValue: PropTypes.string,
    items: PropTypes.array, // Either simple array or array with objects that have keys value and label
    label: PropTypes.string,
    required: PropTypes.bool,
    showNone: PropTypes.bool,
    onChange: PropTypes.func,
}

SelectDropdown.defaultProps = {
    allowOther: false,
    formError: true,
    autoSelect: false,
    error: false,
    errorText: '',
    fullWidth: false,
    defaultValue: '',
    items: [],
    label: '',
    required: false,
    showNone: true,
    onChange: () => {},
}

export default SelectDropdown;