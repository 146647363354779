import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectDropdown from '../../molecules/SelectDropdown';

const TextArea = styled.div`
  ${'' /* width: 50px; */}
`

const StyledSelect = styled(Select)`

`

const StyledFormHelper = styled(FormHelperText)`
  min-width: 88px;
`

const StyledItem = styled(MenuItem)`

`

class ColumnText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      option: ''
    }
  }

  componentWillMount() {
    const { value } = this.props;

    this.setState({ option: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { option } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,option)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ option: nextProps.value });
    }
  }

  update = option => {
    this.setState({ option });
  }

  createMenuItem = label => (
    <StyledItem value={label}>
        {label}
    </StyledItem>
  )

  createMenuItems = obj => (
    Object.values(obj).map(this.createMenuItem)
  )

  render () {
    const { editMode, options, placeholder, value, style, error, ...other } = this.props;
    const { option } = this.state;
    return editMode ? (
      <React.Fragment>
        {/* <StyledSelect
          value={option}
          onChange={(e) => this.update(e.target.value)}
          error={error}
          {...other}
        >
          <StyledItem value="" disabled>
              <em>{placeholder}</em>
          </StyledItem>
          {this.createMenuItems(options)}
        </StyledSelect>
        {
          error &&
          <StyledFormHelper error >This field is required</StyledFormHelper>
        } */}
        <SelectDropdown 
          items={options}
          fullWidth
          onChange={this.update}
          allowOther={this.props.allowOther}
          error={error}
          errorText={this.props.errorText} 
          defaultValue={option}
          {...other}
          // label={"Unit of Measurement"}
        />
      </React.Fragment>
    ) : (
      <TextArea>
        {value}
      </TextArea>
    )
  }
}

ColumnText.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.obj, 
  value: PropTypes.string,
  editMode: PropTypes.bool,
  errorText: PropTypes.string,
  allowOther: PropTypes.bool
};

ColumnText.defaultProps = {
  editMode: false,
  placeholder: 'Select',
  errorText: "This field is required",
  allowOther: false
};

export default ColumnText;