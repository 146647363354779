import Service from './Service';
import Organization from "../model/Organization";

const URL = 'organization';

class OrganizationService extends Service {

}

// TODO: nested OrganizationService inside of BusinessService
export default new OrganizationService(URL, Organization);
