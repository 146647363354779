import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip';
import validate from 'core/utilities/FormValidator';
import Button from '../../atomic/buttons/Button';
import styled, { css, withTheme } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import breakpoint from 'styled-components-breakpoint';

const Text = styled(Typography)`
  font-size: 1em;

  ${breakpoint('mobile_max', 'tablet_min')`
		font-size: 0.9em;
	`}
`

const StyledButton = styled(Button)`
  font-size: 0.9em;
  text-transform: none;
  min-height: 23px;

  ${breakpoint('mobile_min', 'mobile_max')`
		font-size: 0.8em;
	`}
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  padding: 6px 8px;
`

class PendingItem extends Component {

  handleClick = () => {
    this.props.reinvite();
  }

  render () {
    const activation = this.props.defaultState;
    return (
      <ItemContainer>
        <Text variant="body1">
          {this.props.title}
        </Text>
        <StyledButton
          size='small'
          variant='outlined'
          onClick={this.handleClick}
          disabled={activation}
        >{activation? 'Added':'Resend Invite'}</StyledButton>
      </ItemContainer>
    )
  }
}

export default PendingItem
