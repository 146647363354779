import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import OpenInNew from '@material-ui/icons/OpenInNew';

const Container = styled.div`
  position: relative;
  &:hover {
    text-decoration: underline;
  }
`;

const LinkStyled = styled(Link)`
  color: ${props => props.theme.colors.spearmint_main};
  text-decoration: none;
  text-decoration-color: ${props => props.theme.colors.spearmint_main};
  &:hover {
      text-decoration: underline;
  }
`;

const IconStyled = styled(OpenInNew)`
  font-size: 10px;
  position: absolute;
`;

class OpenInNewLink extends Component {
  constructor(props) {
    super(props);
  }

  handleClick = event => {
    if (document.body.clientWidth <= 768) {
      event.preventDefault();
    }
    this.props.onClick();
  }

  render() {
    const { text, onClick, ...other } = this.props
    
    if (this.props.link) {
      return (
        <LinkStyled to={`/profile/${uid}`} onClick={this.handleClick}>
          <Container {...other}>
            {text}<IconStyled/>
          </Container>
        </LinkStyled>
      )
    }
    else {
      return (
        <Container {...other} onClick={this.handleClick}>
          {text}<IconStyled/>
        </Container>
      )
    }
    
  }
}

OpenInNewLink.propTypes = {
  onClick: PropTypes.func,
  link: PropTypes.string
}

OpenInNewLink.defaultProps = {
  onClick: () => {},
}

export default OpenInNewLink;