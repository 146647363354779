import React from "react";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
	DatePicker as MUDatePicker,
	MuiPickersUtilsProvider,
} from "material-ui-pickers";
import PropTypes from 'prop-types';

MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth;

class DatePicker extends React.Component {

	onChange = (date) => {
		this.props.onChange(this.props.useDate ? date : date ? moment(date).format(this.props.parseFormat) : null);
	};

	render() {
		const { value, onChange, ...other } = this.props;
		return (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<MUDatePicker
					// Treat blank strings as null
					value={this.props.value
						? this.props.useDate
							? this.props.value
							: moment(this.props.value, this.props.parseFormat).toDate()
						: null}
					onChange={this.onChange}
					{...other}
				/>
			</MuiPickersUtilsProvider>
		);
	}
}

DatePicker.propTypes = {
	useDate: PropTypes.bool, // Use value typeof DateObject instead of string in format YYYY-MM-DD
	parseFormat: PropTypes.string
};

DatePicker.defaultProps = {
	clearable: true,
	disablePast: true,
	parseFormat: 'YYYY-MM-DD',
	useDate: false,
	format: 'MM-DD-YYYY' // display format
}


export default DatePicker;
