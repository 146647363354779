import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { withRouter, Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import Popup from 'reactjs-popup'
import { SearchIcon, LocationIcon } from '../../components/Icons'
import Menu from '../../components/Menu'
import {LoadingSpinnerPage} from '../../components/LoadingSpinner'
import Typography from '@material-ui/core/Typography';
import ListItem from '../../components/ListItem';
import Topbar from '../../components/CommonTopBar'
import SearchBar from '../../components/SearchBar'
import Bottombar from '../../components/CommonBottomBar'
import ProductListingGroupCard from '../../components/organisms/listCards/ProductListingGroupCard'
import ProductListingGroupModal from '../../components/organisms/modals/ProductListingGroupModal'
import { message } from 'antd'
import ProfileLink from '../../components/atomic/ProfileLink'
import CircularImage from '../../components/atomic/CircularImage';

const ProductCard = styled(ProductListingGroupCard)`
  margin: 8px 10px;
  border-radius: 10px;
`;

const SellerTitle = styled(ProfileLink)`
  font-size: 1.5em !important;
  font-weight: bold;
  margin-left: 15px;
  color: black;
`;

const StyledCircularImage = styled(CircularImage)`
  width: 50px;
  height: 50px;
`;

const SellerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => !props.top && css`margin-top: 20px`}
`;

@inject('shoppingStore', 'shoppingCartStore', 'basketStore', 'searchStore', 'clientListStore')
@observer
class ViewShelf extends Component {
  state = {
    isLoadingDeals: true,
    deals: [],
    openDeals: [],
    filterDistance: false,
    searchQueryType: 'name',
    category: 'public',
    currentListingGroup: null,
    currentListing: null
  }

  async componentWillMount() {
    const { shoppingStore, searchStore, clientListStore } = this.props;
    try {
      await shoppingStore.getItems();
      let deals = shoppingStore.affiliatedItems;
      let openDeals = shoppingStore.nearbyItems;
      let clients = clientListStore.totalClientList;
      searchStore.setList(deals,openDeals,clients);
      this.setState({
        isLoadingDeals: false,
        deals,
        openDeals,
      });
      if(deals.length === 0){
        this.exploreProducts();
      }
      window.scroll(0, 0);
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.props.searchStore.resetConditions();
    }
  }

  componentWillUpdate(nextProps, nextState){
    if(this.state.deals !== this.props.searchStore.List && !this.state.isLoadingDeals){
      this.setState({deals: this.props.searchStore.List})
    }
  }

  switchToOpenList(bool) {
    this.props.searchStore.switchToOpenList(bool);
    if(this.state.filterDistance !== bool) {
      this.setState({ filterDistance: bool });
    }
  }

  viewCategory = () => {
    const path = this.getPage(this.props.location.pathname);
    switch(path){
      case 'explore':
        this.props.searchStore.onRadioChange('Public',true,false);
        this.switchToOpenList(true);
        break;
      default:
        this.props.searchStore.onRadioChange('Public',true,false);
        this.switchToOpenList(false);
        break;
    }
  }

  getPage(url) {
		return url.substring(url.lastIndexOf("/")+1);
  }
  
  getTabIndex(tabs) {
    const path = this.props.location.pathname;
    for (let i of tabs.keys()) {
      if(tabs[i].path.indexOf(path) > -1){
        return i;
      }
    }
  }

  selectGroupListing = ProductListingGroup => {
    this.setState({currentListingGroup: ProductListingGroup })
  }

  getDealItems() {
    const { basketStore, searchStore } = this.props
    this.viewCategory()
    let filteredDeals = this.props.searchStore.List.slice();

    if(filteredDeals.length === 0){
      return (
        <div className="empty-text">
          <Typography variant="title" className="text">
            No Results Found
          </Typography>
        </div>
      )
    }

    // let inCart = [];
    // inCart = this.props.shoppingCartStore.shoppingCart.map(item => {
    //   return item.deal.id;
    // })
    // Sort filtered deals by the seller name, then by product name
    const sortedDeals = filteredDeals.sort((a, b) => {
      const sellerCompare = a.product.seller.name.localeCompare(b.product.seller.name);
      return sellerCompare || a.product.name.localeCompare(b.product.name);
    });

    const cards = [];
    let fresh = true;
    for (let i = 0; i < sortedDeals.length; i++) {
      const group = sortedDeals[i];
      if (fresh) {
        cards.push(
          <SellerContainer top={i === 0}>
            <StyledCircularImage src={sortedDeals[i].product.seller.picture}/>
            <SellerTitle id="seller_header_link" size="lg" showIcon={false} uid={sortedDeals[i].product.seller.id} text={sortedDeals[i].product.seller.name}/>
          </SellerContainer>
        )
        fresh = false;
      }
      cards.push(
        <ProductCard
          id="product_card"  
          key={group.product.id}
          productGroup={group}
          BasketItemsList={basketStore.basket}
          onDetailsClicked={(group, productListing) => this.setState({ currentListingGroup: group, currentListing: productListing })}
          restricted={searchStore.listIsOpen}
        />
      )
      // Check if the next seller is different from the current one
      if (i < sortedDeals.length - 1 && group.product.seller.id !== sortedDeals[i + 1].product.seller.id) {
        fresh = true;
      }
    }
    return cards;
    // return sortedDeals.map((group, productListing) => {
    //   console.log(group);
    //   console.log(productListing);
    //   return (
    //     <ProductCard
    //       key={group.product.id}
    //       productGroup={group}
    //       BasketItemsList={basketStore.basket}
    //       onDetailsClicked={(group, productListing) => this.setState({ currentListingGroup: group, currentListing: productListing })}
    //       restricted={searchStore.listIsOpen}
    //     />
    //   )
    // })
  }

  checkout = () => {
    //route to shopping cart
    const { history } = this.props
    history.push('/shoppingcart')
  }

  exploreProducts = () => {
    //route to shopping cart
    const { history } = this.props
    history.push('/shelf/explore')
  }

  render() {
    const { isLoadingDeals, isEmpty, currentListingGroup, filterDistance } = this.state
    const tabs = [
      {
        name: 'My Sellers',
				child: false,
				path: '/shelf'
      },
      {
        name: 'Explore Nearby',
        child: false,
        path: '/shelf/explore'
      },
    ];
    const tabIndex = this.getTabIndex(tabs)
    return (
      <React.Fragment>
        <Menu />
        <Topbar title="View Products" tabs={tabs} searchBar={true} sub={`${this.props.searchStore.List.length} Result(s)`} tabIndex={tabIndex} isChildPath={false} mobilft={true}/>
        <div className="deals-container">
          <SearchBar key={`searchbar-${tabIndex}`} distanceFilter={filterDistance}/>
          {
            isLoadingDeals ?
              <LoadingSpinnerPage />
              :
              <React.Fragment>
                <ul className="deals-list lg-margin">
                  {
                    filterDistance &&
                    <React.Fragment>
                      <Typography className="result-label">{`Products within ${this.props.searchStore.distance?this.props.searchStore.distance:0} mile(s)`}</Typography>
                      <Typography className="result-label sm">{`Change your search settings to show more results.`}</Typography>
                      <Typography className="result-label sm">Click on a profile to request the ability to buy from a seller.</Typography>
                    </React.Fragment>
                  }
                  {this.getDealItems()}
                </ul>
              </React.Fragment>
          }
        </div>
        {
          currentListingGroup &&
          <ProductListingGroupModal
            defaultListingID={this.state.currentListing.id}
            productListingGroup={currentListingGroup}
            open={currentListingGroup !== null}
            onClose={() => this.setState({currentListingGroup: null})}
            type={`basket${this.props.searchStore.listIsOpen? '_restricted': ''}`}
          />
        }
      </React.Fragment>
    )
  }
}

export default withRouter(ViewShelf)
