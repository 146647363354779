import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const TextArea = styled.div`
  width: 300px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledTextField = styled(TextField)`
  min-width: 300px;
  flex: 1;
`

const StyledFormHelper = styled(FormHelperText)`
  min-width: 109px;
`

class ColumnText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      text: ''
    }
  }

  componentDidMount() {
    const { value } = this.props;

    this.setState({ text: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { text } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,text)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ text: nextProps.value });
    }
  }

  update = text => {
    this.setState({ text });
  }

  render () {
    const { editMode, value, error, index, ...other } = this.props;
    const { text } = this.state;
    return editMode ? (
      <React.Fragment>
        <StyledTextField
          value={text}
          multiline
          margin="normal"
          fullWidth="true"
          onChange={(e) => this.update(e.target.value)}
          {...other}
        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </React.Fragment>
    ) : (
      <TextArea>
        {value}
      </TextArea>
    )
  }
}

ColumnText.propTypes = {
  value: PropTypes.string,
  editMode: PropTypes.bool,
};

ColumnText.defaultProps = {
  editMode: false,
};

export default ColumnText;