import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { configure, mobx } from 'mobx';
import {
  HashRouter as Router,
  Redirect,
  withRouter,
  Route,
  BrowserRouter,
  Switch
} from 'react-router-dom'
import { compose } from 'recompose';
import { auth } from './core/firebase/firebase'
import DatabaseClient from './core/DatabaseClient'
import Login from './pages/common/Login'
import ViewDeals from './pages/sellers/ViewDeals';
import InventoryDeal from './pages/sellers/InventoryDeal';
import ImportInventory from './pages/sellers/ImportInventory';
import History from './pages/common/History';
import ForgotPass from './pages/common/ForgotPass';
import SignUp from './pages/common/SignUp';
import ShoppingCart from './pages/buyers/ShoppingCart';
import ViewShelf from './pages/buyers/ViewShelf';
import AddClients from './pages/common/AddClients';
import FirstTimeSurvey from './pages/common/FirstTimeSurvey';
import Profile from './pages/common/Profile';
import ClientList from './pages/common/ViewClientList';
import ViewLegal from './pages/common/ViewLegal';
import Help from './pages/common/Help';
import ViewOrders from './pages/common/ViewOrders';
import PrivateBuyerRoute from './routes/PrivateBuyerRoute'
import PrivateSellerRoute from './routes/PrivateSellerRoute'
import GenericPrivateRoute from './routes/GenericPrivateRoute'
import { MaterialUITheme as theme} from './core/MaterialUITheme';
import { MuiThemeProvider, createMuiTheme, createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { message } from 'antd';
import ErrorBoundary from './components/ErrorBoundary'
import queryString from 'query-string';
import WelcomePage from './pages/common/WelcomePage';
import AccountSettings from './pages/common/AccountSettings';
import NotificationSettings from './pages/common/NotificationSettings';
import ReloadPageModal from './components/organisms/ReloadPageModal';
import LoadingScreen from './pages/common/LoadingScreen';
import MessagesPage from './pages/common/MessagesPage';
import { hot } from 'react-hot-loader'
import OfflineModal from './components/organisms/OfflineModal';
import styled from 'styled-components';
import Header from './components/organisms/headers/Header';
import EmailVerificationHeader from './components/organisms/headers/EmailVerificationHeader';
import DataPage from './pages/common/DataPage';
import OrganizationProfile from './pages/common/OrganizationProfile';
import { UserService } from "core/api";
import NotesPage from 'pages/buyers/Notes';
import CustomPage from "./pages/common/CustomPage";


const styleNode = document.createComment("jss-insertion-point");
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: false,
  productionPrefix: 'c',
});

const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point',
});

// configure({ enforceActions: true });

const Page404 = ({ location }) => (
  <div>
    <h2>404: Uh-oh! We weren't able to find the page <code>{location.pathname}</code></h2>
  </div>
);

// // Botched css workarounds
// const NotchBlocker = styled.div`
//   @supports (padding-top: env(safe-area-inset-top)) {
//     --safe-area-inset-top: env(safe-area-inset-top);
//     height: calc(0% + var(--safe-area-inset-top));
//   }
//   @supports (padding-top: constant(safe-area-inset-top)) {
//     --safe-area-inset-top: constant(safe-area-inset-top);
//     height: calc(0% + var(--safe-area-inset-top));
//   }
//   width: 100%;
//   background-color: ${props => props.theme.colors["spearmint_light"]};
//   position: fixed;
//   top: 0;
//   z-index: 2;
// `;

const NotchBlocker = styled.div`
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  height: calc(0% + var(--safe-area-inset-top));
  width: 100%;
  background-color: ${props => props.theme.colors["spearmint_light"]};
  position: fixed;
  top: 0;
  z-index: 1000;
`;

@inject('userStore', 'profileStore', 'globalUIStore', 'notificationStore')
@observer
class App extends Component {
  state = {
    loadingApplication: true,
    online: true
  }
  oneSignalID = ''

  setOneSignalID = (id) => {
    this.oneSignalID = id;
    if (this.props.userStore.isAuthenticated && id && id !== '') {
      DatabaseClient.setOneSignalID(this.props.userStore.user.id, id);
    }
  }

  async componentWillMount() {
    // Event listeners to detect if client is ever online or offline
    window.addEventListener('online',  () => this.setState({ online: true }));
    window.addEventListener('offline', () => this.setState({ online: false }));

    this.props.globalUIStore.isLoading = true;

    // Error reporting services - don't change any of these lines b/c they are recognized by sed
    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: 'https://277bc68ac4944224a5a7779ce3b7da0b@sentry.io/1480862',
        release: 'prod@4ff09fc94e255f1fecb68e150eee7ab4bd408e45',
        environment: 'prod'
      });
    }

    // hotjar.initialize(1017666, 6);
    // TODO: move to AuthManager
    auth.onAuthStateChanged(async (FirebaseUser) => {
      if (FirebaseUser) {
          const uid = FirebaseUser.uid;
        let user = await UserService.get(uid);
        // User may be null if this was a sign up
        this.props.globalUIStore.isLoading = true;
        await this.props.userStore.setUser(user);
        this.props.userStore.emailVerified = FirebaseUser.emailVerified;
        if (this.oneSignalID && this.oneSignalID !== '') {
          await DatabaseClient.setOneSignalID(uid, this.oneSignalID);
        }
        this.props.globalUIStore.isLoading = false;

        // Initialize pendo with user info
        // in your authentication promise handler or callback
        try {
          if (process.env.NODE_ENV !== 'development') {
            pendo.initialize({
              visitor: {
                id: uid,   // Required if user is logged in
                email: user ? user.email : FirebaseUser.email,
                role: user && user.type,
                name: user && (user.firstName + user.lastName)
                // email:        // Optional
                // role:         // Optional

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
              },
              account: {
                // id: user.uid
                // name:
                // id:           'ACCOUNT-UNIQUE-ID' // Highly recommended
                // name:         // Optional
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
              }
            });
          }
        } catch(e) {
          console.error("Failed to initialize Pendo", e.message);
        }

        // Initialize sentry with user info
        // Set user information, as well as tags and further extras
        try {
          Sentry.configureScope(scope => {
            if (process.env.NODE_ENV !== 'development') {
              scope.setUser({
                id: uid,
                email: user ? user.email : FirebaseUser.email,
                role: user && user.type,
                name: user && (user.firstName + user.lastName)
              });
              // scope.clear();
            }
          });
        } catch (e) {
          console.error("Failed to initialize Sentry", e.message);
        }
      } else {
        this.props.globalUIStore.isLoading = false;
        if (this.props.userStore.isAuthenticated) {
          // Log out user
          if (this.oneSignalID && this.oneSignalID !== '') {
            await DatabaseClient.removeOneSignalID(this.oneSignalID);
          }
          const redirectURL = "/login" + (this.oneSignalID && this.oneSignalID !== '' ? `?oneSignalID=${this.oneSignalID}` : '');
          this.oneSignalID = '';
          window.location.href = redirectURL;
        }
      }
    });
  }
  render() {
    return (
      <React.Fragment>
        <NotchBlocker/>
        <ErrorBoundary>
        <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          {
            !this.state.online && <OfflineModal/>
          }
          {
            this.props.globalUIStore.isLoading ?
            <LoadingScreen/>
            :
            <React.Fragment>
              {this.props.notificationStore.updateAvailable && <ReloadPageModal/>}
                <div className="container">
                  <Switch>
                    <Route exact path={'/'}
                      render={() => {
                        //default go to homepage
                        return <Redirect to={'/login'} />
                      }}
                      />

                    <Route exact path={'/index.html'}
                      render={() => {
                        //default go to homepage
                        return <Redirect to={'/login'} />
                      }}
                      />


                    <Route exact path={'/(privacy-policy|terms-of-use)'}
                      render={() => {
                        return (<ViewLegal/>)
                      }}
                    />
                      
                    {/***** AUTH ****/}
                    <Route exact    path={'/login'}
                      render={(props) => {
                        if (this.props.profileStore.isBuyer) {
                          return (<Redirect to={'/shelf'} />)
                        }
                        else if (this.props.profileStore.isSeller) {
                          return (<Redirect to={'/deals'} />)
                        }
                        else if (this.props.userStore.isAuthenticated) {
                          return (<Redirect to={'/firsttimesurvey'} />)
                        }
                        else {
                          let params = queryString.parse(props.location.search)
                          return (<Login invitationID={params.inv}/>)
                        }
                      }}/>
                    <Route exact    path={'/forgotpass'}        component={ForgotPass}/>
                    <Route exact    path={'/signup'}
                      render={(props) => {
                        let params = queryString.parse(props.location.search)
                        return (<SignUp invitationID={params.inv}/>)
                      }}/>
                    <Route exact path={'/admin'}
                    render={(props) => {
                      let params = queryString.parse(props.location.search)
                      const token = params.token;
                      if (token) {
                        auth.signInWithCustomToken(token).catch(function(error) {
                          console.error(error);
                        });
                      }
                      return <Redirect to={'/login'} />
                    }}
                    />
                    
                    {/***** COMMON ****/}
                    <GenericPrivateRoute exact    path={'/profile'}           component={Profile} />
                    <GenericPrivateRoute exact    path={'/profile/:uid'}      component={Profile} key={this.props.location.pathname}/>
                    <GenericPrivateRoute exact    path={'/help'}              component={Help} />
                    <GenericPrivateRoute exact    path={'/(clientlist|affiliated|pending|invitations)'}        component={ClientList} />
                    <GenericPrivateRoute exact    path={'/welcome'}           component={WelcomePage} />
                    <GenericPrivateRoute exact    path={'/custom/:page/:tab?'}           component={CustomPage} />

                    <GenericPrivateRoute exact    path={'/settings/account'}   component={AccountSettings} />
                    <GenericPrivateRoute exact    path={'/settings/notifications'}   component={NotificationSettings} />
                    <GenericPrivateRoute exact    path={'/messages'}           component={MessagesPage} />
                    <GenericPrivateRoute exact    path={'/data'}           component={DataPage} />
                    {/* <GenericPrivateRoute exact    path={'/management'}           component={Management} /> */}
                    <GenericPrivateRoute exact    path={'/organization/:uid'}           component={OrganizationProfile} />
                    
                    
                    {/***** ORDERS ****/}
                    <GenericPrivateRoute exact    path={'/(request|active)'}           component={ViewOrders} />
                    {/* <GenericPrivateRoute exact    path={'/(request|active|tracking|completed)'}           component={ViewOrders} /> */}
                    <GenericPrivateRoute exact    path={'/history'}          component={History}/>

                    {/***** DEALS ****/}
                    <PrivateSellerRoute exact    path={'/(deals|storefront|preview)'}             component={ViewDeals}/>
                    <PrivateSellerRoute exact    path={'/deals/add'}         component={InventoryDeal}/>
                    <PrivateSellerRoute exact    path={'/deals/edit/:uid'}   component={InventoryDeal}/>
                    <PrivateSellerRoute exact    path={'/deals/import'}      component={ImportInventory}/>

                    {/**** OTHERS *****/}
                    <GenericPrivateRoute exact    path={'/notes'}             component={NotesPage} />
                    <GenericPrivateRoute exact    path={'/clientlist/add'}        component={AddClients}/>
                    <GenericPrivateRoute exact   path={'/firsttimesurvey'}   component={FirstTimeSurvey} />

                    {/***** SHELF ****/}
                    <PrivateBuyerRoute exact    path={'/shoppingcart'}       component={ShoppingCart}/>
                    <PrivateBuyerRoute exact    path={'/(shelf|shelf/private|shelf/sales|shelf/explore)'}              component={ViewShelf} />
                    <Route component={Page404} />
                  </Switch>
                  <Route render={(props) => {
                    let params = queryString.parse(props.location.search)
                    if (params.oneSignalID) {
                      this.setOneSignalID(params.oneSignalID);
                    }
                    return null;
                  }}/>
                </div>
            </React.Fragment>
              
          }
          
        </MuiThemeProvider>
        </JssProvider>
      </ErrorBoundary>
      </React.Fragment>
      
    )
  }
}

export default compose(withRouter)(App);
