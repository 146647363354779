import React from 'react';
import MUITooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }

  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const { children, text, color, disabled, ...other } = this.props

    return text ? (
      <ClickAwayListener onClickAway={this.handleTooltipClose}>
        <MUITooltip
          onClose={this.handleTooltipClose}
          onOpen={this.handleTooltipOpen}
          open={ !disabled && this.state.open}
          title={text}
          {...other}
        >
          <div onClick={this.handleTooltipOpen}>
            {children}
          </div>
        </MUITooltip>
      </ClickAwayListener>
    ) : children;
  }
}

Tooltip.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
}

Tooltip.defaultProps = {
  disabled: false,
  color: 'red'
}

export default Tooltip;