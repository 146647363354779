import { observable, action, toJS } from 'mobx'
import Message from './Message'
import DatabaseClient from '../DatabaseClient';
import joi from 'joi-browser';
import { profileStore, messagingStore } from '../../stores';
import { BusinessService } from "core/api";

const schema = joi.object().keys({
    unread: joi.array().items(joi.string()).required(),
    uids: joi.array().items(joi.string()).required(),
    timestamp: joi.number().required()
});

class Conversation {
    @observable businesses
    @observable id
    @observable messages
    @observable unread
    @observable uids
    @observable timestamp

    constructor(id = null, messages = [], unread = [], uids = [], timestamp = 0) {
        this.id = id;
        this.messages = messages;
        this.unread = unread;
        this.uids = uids;
        this.timestamp = timestamp
    }

    formatForDB() {
        // Convert array to set
        const uids_set = {};
        const unread_set = {};
        const reminderSent = {};
        this.uids.forEach((uid) => {
            uids_set[uid] = true;
            reminderSent[uid] = false;
            unread_set[uid] = this.unread.includes(uid);
        });
        let data = observable({
            reminderSent,
            unread: unread_set,
            uids: uids_set,
            uidsArr: this.uids,
            numUIDs: this.uids.length,
            timestamp: this.timestamp
        });
        return toJS(data);
    }

    validate() {  // DON'T USE
        const result = joi.validate(this.formatForDB(), schema);
        if (result.error) {
            console.error(result.error);
        }
        return !result.error;
    }

    @action
    async init() {
        const promises = [];
        for (let i = 0; i < this.uids.length; i++) {
            if (this.uids[i] !== profileStore.business.id) {
                promises.push(BusinessService.get(this.uids[i]));
            }
        }
        const results = await Promise.all(promises);
        const businesses = {};
        results.forEach((business) => {
            businesses[business.id] = business;
        });
        this.businesses = businesses;
        await this.retrieveMessages();
    }

    @action
    async retrieveMessages(count) {
        if (!this.id) {
            throw new Error("No id assigned to conversation");
        }
        const retrievedMessages = await DatabaseClient.getMessages(this.id, count, this.messages.length > 0 ? this.messages[0].snapshot : null);
        // Filter out any bad messages
        const messages = retrievedMessages.filter((message) => {
            const len = this.messages.length;
            for (let i = 0; i < len; i++) {
                if (this.messages[i].id == message.id) {
                    return false;
                }
            }
            return true;
        });
        if (messages.length > 0) {
            this.messages.replace(messages.concat(this.messages.slice()));
        }
    }

    @action
    async retrieveAllMessages() {
        if (!this.id) {
            throw new Error("No id assigned to conversation");
        }
        const messages = await DatabaseClient.getAllMessages(this.id);
        if (messages.length > 0) {
            this.messages.replace(messages);
        }
    }

    @action
    async retrieveNewMessages() {
        if (!this.id) {
            throw new Error("No id assigned to conversation");
        }
        if (this.messages.length  === 0) {
            return this.retrieveMessages();
        }
        const retrievedMessages = await DatabaseClient.getMessagesUntil(this.id, this.messages[this.messages.length - 1].snapshot);
        // Filter out any bad messages
        const messages = retrievedMessages.filter((message) => {
            const len = this.messages.length;
            for (let i = 0; i < len; i++) {
                if (this.messages[i].id == message.id) {
                    return false;
                }
            }
            return true;
        });
        if (messages.length > 0) {
            this.messages.replace(this.messages.concat(messages));
        }
    }

    @action
    async sendMessage(msgText, attachment) {
        const newMsg = await DatabaseClient.sendMessage(this.id, profileStore.business.id, msgText, attachment);
        //this.messages.push(newMsg);
    }

    @action
    async markAsRead() {
        if (this.unread.includes(profileStore.business.id)) {
            await DatabaseClient.markAsRead(this.id, profileStore.business.id);
        }
    }

    @action
    updateConversation(conv) {
        this.unread.replace(conv.unread);
        this.timestamp = conv.timestamp;
    }
}

Conversation.displayName = "Conversation";
export default Conversation;
