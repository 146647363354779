import { action, observable, toJS } from 'mobx'
import DatabaseClient from '../../core/DatabaseClient';
import { profileStore } from '..'
import { BusinessService, OrganizationService } from 'core/api'

class ManagementStore {
  @observable organization = null;
  @observable orgBusinesses = [];

  async init() {
    const biz = profileStore.business
		if (!biz || !biz.organization) {
			return;
    }
    const orgID = biz.organization.id;
    await this.getOrganizationInfo(orgID);
  }

  @action
  async refresh() {
    const biz = profileStore.business
		if (!biz || !biz.organization) {
			return;
    }
    const orgID = biz.organization.id;
    await this.getOrganizationInfo(orgID);
  }

  @action
  async getOrganizationInfo(orgID) {
    const org = await OrganizationService.get(orgID);
    const bizes = await BusinessService.getAll({
      organization: orgID
    });
    bizes.sort((a,b) => {
      return a.name > b.name ? 1 : -1;
    });
    this.organization = org;
    this.orgBusinesses = bizes;
  }

}
const managementStore = new ManagementStore();
export default managementStore
