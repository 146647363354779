class Geography {
    getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2-lat1);  // this.deg2rad below
        var dLon = this.deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
    }

    getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
        return this.kmToMiles(this.getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2));
    }
      
    deg2rad(deg) {
        return deg * (Math.PI/180);
    }

    kmToMiles(km) {
        return km*0.621371;
    }

    milesToKm(mi) {
        return mi*1.60934;
    }
}

export default new Geography()