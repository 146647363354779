import joi from 'joi-browser';
export const MIN_STRING = 1;
export const MAX_STRING_SHORT = 50;
export const MAX_STRING_MED = 100;
export const MAX_STRING_LONG = 200;
export const MAX_STRING_EXTRA_LONG = 300;

export const businessSchema = joi.object().keys({
    city: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    closing: joi.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/).required(),
    communicationOptions: joi.array().items(joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).optional()).required(), 
    coordinates: joi.array().items(joi.number()),
    description: joi.string().min(MIN_STRING).max(MAX_STRING_EXTRA_LONG).required(),
    email: joi.string().email().required(),
    foodOptions: joi.array().items(joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).optional()),
    name: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    nameInsensitive: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    opening: joi.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/).required(),
    orderNotes: joi.string().allow(""),
    paymentOptions: joi.array().items(joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).optional()).required(),
    phone: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(), // TODO: add regex
    state: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    streetAddress: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    type: joi.string().valid(['buyer', 'seller']).required(), // TODO: enums
    zipcode: joi.string().regex(/^[0-9]{5}$/).required()
})

export const inventoryItemSchema = joi.object().keys({
    delivery: joi.boolean().required(),
    foodOption: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    isLocallyGrown: joi.boolean().required(),
    isOrganic: joi.boolean().required(),
    name: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    notes: joi.string().min(MIN_STRING).max(MAX_STRING_EXTRA_LONG).allow(null).required(), // Can be null
    pickup: joi.boolean().required(),
    price: joi.number().allow(null).required(), // TODO: remove required
    priceUnit: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    public: joi.boolean().required(),
    quantity: joi.number().required(),
    quantityUnit: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    reasonForPost: joi.array().items(joi.string().min(MIN_STRING).max(MAX_STRING_SHORT)).required(), // Can be empty
    uid: joi.string().required(),
    useByDate: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required() // TODO: date validator
});

export const preferencesSchema = joi.object().keys({
    notifications: joi.object().keys({
        clientRequests: joi.boolean().required(),
        orderRequests: joi.boolean().required(),
        orderUpdates: joi.boolean().required(),
        messages: joi.boolean().required()
    }).required()
});

export const userSchema = joi.object().keys({
    businesses: joi.object().required(),
    email: joi.string().email().required(),
    invitationID: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).allow(null).required(),
    firstName: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    lastName: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    phoneNumber: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    preferences: preferencesSchema.required(),
    status: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required()
});

export const orderSchema = joi.object().keys({
    bundleID: joi.string().required(),
    buyerUID: joi.string().required(),
    exchange: joi.string().valid(['pickup', 'delivery']).required(),
    exchangeStatus: joi.boolean().required(),
    needByDate: joi.number().allow(null).required(),
    paymentStatus: joi.boolean().required(),
    price: joi.number().allow(null).required(),
    quantityRequested: joi.number().min(1).required(),
    sellerUID: joi.string().required(),
    status: joi.string().required(),
    timestamp: joi.number().required(),
    totalCost: joi.number().allow(null).required(), // TODO: positive floats, ints, etc...
    unit: joi.string().required()
});

export const orderBundleSchema = joi.object().keys({
    buyerUID: joi.string().required(),
    orders: joi.array().items(joi.any()).required(), // TODO: figure out how to put Orders object in here
    sellerUID: joi.string().required(),
    timestamp: joi.number().required(),
    totalCost: joi.number().required(),
    notes: joi.string().allow("")
});

export const productSchema = joi.object().keys({
    delivery: joi.boolean().required(),
    description: joi.string().allow('').required(),
    foodCategory: joi.string().required(),
    local: joi.boolean().required(),
    organic: joi.boolean().required(),
    name: joi.string().required(),
    packFormats: joi.object().required(),
    pickup: joi.boolean().required(),
    quantity: joi.object().min(0).required(),
    timestamp: joi.number().required(),
    uid: joi.string().required()
});

export const productListingSchema = joi.object().keys({
    defaultVisibility: joi.boolean().required(),
    endListingDate: joi.number().allow(null).required(),
    error: joi.string().allow(null).required(),
    notes: joi.string().allow('').required(),
    productID: joi.string().required(),
    price: joi.number().allow(null).required(),
    timestamp: joi.number().required(),
    uid: joi.string().required(),
    unit: joi.string().required(),
    visibility: joi.object().required()
});
