import React, { Component } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ProductListItem from './ProductListItem';
import SellerHeader from './SellerHeader';
import TotalDisplay from './TotalDisplay';
import ListItem from '@material-ui/core/ListItem';
import Button from '../../atomic/buttons/Button';
import { some } from 'lodash';
import OrderNote from '../OrderNoteDisplay';

const ListContainer = styled(List)`
  padding: 0px;
  margin: 10px;
  margin-top: 0;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 550px) {
    border-radius: 0;
    margin: 0;
    padding-top: ${props => props.extraPadding ? 75 : 0}px;
    margin-top: 40px;
  }
`;

const ListItemStyle = styled(ListItem)`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  background-color: #fff;
`;

const ButtonContainer = styled.div`
  margin-left: auto;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  @media (max-width: 550px) {
    display: none;
  }
`;

const ButtonStyled = styled(Button)`
  max-height: 30px;
  margin: 0 8px;
  min-height: 30px;
  padding: 0px 15px;
  text-transform: none;
`;
const OrderNoteStyled = styled(OrderNote)`
  padding-left: 48px;
  width: 100%;
  margin: 8px 0 24px 0;
  @media (max-width: 550px) {
    padding: 0;
  }
`


class ProductList extends Component {
  constructor (props) {
    super(props)
    this.singleRender = null;
    this.inputs = [];
    this.state = {}
  }

  singleUpdate = (itemIndex,qty,date,exch) => {
    const { onUpdateItem } = this.props;
    this.singleRender = itemIndex;
    onUpdateItem(itemIndex,qty,date,exch);
  }

  createProduct = (item,index,sellerIndex) => {
    const {
      products,
      sellers,
      selectedItems,
      errorItems,
      onSelected,
      onDelete,
      onUpdateItem,
      request,
    } = this.props;
    const identifier = { id: item.productListing.id, seller: item.productListing.product.seller.name }
    return (
      <ProductListItem
        product={item}
        onDelete={async () => onDelete(identifier)}
        checked={some(selectedItems,identifier)}
        error={some(errorItems,identifier)}
        onChecked={() => onSelected(identifier)} 
        onUpdateItem={(qty,date,exch) => this.singleUpdate(item.listIndex,qty,date,exch)}
        placeInput={this.placeInput}
        singleRender={this.singleRender}
        request={request}
        productIndex={index}
        inputShift={this.shiftFocus}
      />
    )
  }

  checkItemPresence = (selected,sellerItems) => {
    for (let index = 0; index < sellerItems.length; index++) {
      const item = sellerItems[index];
      const identifier = { id: item.productListing.id, seller: item.productListing.product.seller.name }
      if(!some(selected,identifier)){
        return false;
      }
    }
    return true;
  }

  checkIndet = (selected, sellerItems) => {
    if (this.checkItemPresence(selected, sellerItems)) return false;
    for (let index = 0; index < sellerItems.length; index++) {
      const item = sellerItems[index];
      const identifier = { id: item.productListing.id, seller: item.productListing.product.seller.name }
      if(some(selected,identifier)){
        return true;
      }
    }
    return false;
  }


  placeInput = (inputRef) => {
    if(this.singleRender === null) {
      this.inputs.push(inputRef)
    }
  }

  shiftFocus = (index) => {
    if(++index < this.inputs.length) {
      this.inputs[index].focus();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(this.singleRender === null) {
      this.inputs = [];
    }
    return true;
  }

  componentDidUpdate() {
    this.singleRender = null;
  }

  sortItems = () => {
    const {
      products,
      sellers,
      selectedItems,
      errorItems,
      sortSeller,
      sellerSelect,
      onSelected,
      onDelete,
      onValueChange,
      onDeleteSeller,
      total,
    } = this.props;
    if(sortSeller) {
      let sellerMap = {};
      let rollingIndex = 0;
      sellers.forEach(seller => {
        sellerMap[seller] = [];
        products.forEach((item,index) => {
          if(item.productListing.product.seller.name === seller) {
            sellerMap[seller].push(item)
          }
        });
      });
      return sellers.map((seller,sellerIndex) => {
        let sellerID = null;
        let sellerTotal = 0;
        const sellerItems = sellerMap[seller].map((item,index) => {
          if(!sellerID){
            sellerID = item.productListing.product.uid;
          }
          sellerTotal += item.total ? item.total : 0;
          return this.createProduct(item,rollingIndex++,sellerIndex)
        })
        return (
          <React.Fragment>
            <SellerHeader
              cost={sellerTotal}
              seller={seller}
              sellerID={sellerID}
              checked={this.checkItemPresence(selectedItems,sellerMap[seller])}
              indeterminate={this.checkIndet(selectedItems, sellerMap[seller])}
              onSelected={(val) => sellerSelect(seller,val)}
              onDelete={async () => onDeleteSeller(seller)}
            />
            {sellerItems}
          </React.Fragment>
        )
      })
    } else {
      return products.map((item,index) => {
        return this.createProduct(item,index)
      })
    }
  }

  render() {
    const { total, request, onRequest, editOrderNote, addOrderNote, removeOrderNote, extraPadding, orderNotes } = this.props
    return (
      <ListContainer extraPadding={extraPadding}>
        {this.sortItems()}
        <ListItemStyle>
          {orderNotes && (
            <OrderNoteStyled
              orderNotes={orderNotes}
              onEdit={editOrderNote}
            />
          )}
          <InfoContainer>
            <TotalDisplay
              cost={total}
              hideCheckbox={true}
              requesting={request}
            />
            <ButtonContainer>
              {orderNotes ? (
                <ButtonStyled variant='contained' color="secondary" onClick={removeOrderNote}>
                  Delete Notes 
                </ButtonStyled>
              ):(
                <ButtonStyled variant='contained' onClick={addOrderNote}>
                  Add Notes
                </ButtonStyled>
              )}
            <ButtonStyled variant='contained' color='primary' onClick={onRequest}>
              Request
            </ButtonStyled>
            </ButtonContainer>
          </InfoContainer>
        </ListItemStyle>
      </ListContainer>
    )
  }
}

ProductList.propTypes = {
  sellers: PropTypes.array,
  products: PropTypes.array,
  selectedItems: PropTypes.array,
  sortSeller: PropTypes.bool,
  request: PropTypes.bool,
  selectItem: PropTypes.func.isRequired,
  total: PropTypes.number,
};

ProductList.defaultProps = {
  sellers: [],
  products: [],
  selectedItems: [],
  sortSeller: false,
  request: false,
  total: 0,
};

export default ProductList;