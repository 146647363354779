import { observable } from 'mobx';

class GlobalUI {
  @observable loadingScreen = false;

  get isLoading() {
    return this.loadingScreen;
  }
  
  set isLoading(value) {
    this.loadingScreen = value;
  }
}

export default new GlobalUI();
