import { action, observable, computed, toJS } from 'mobx'
import { profileStore } from '..'
import DatabaseClient from '../../core/DatabaseClient'
import { Order, ShoppingCartItem } from '../../core/model'
import BasketItem from '../../core/model/BasketItem';
import moment from 'moment';

class BasketStore {
    /**
     * Array of objects in the format
     * BasketItem: {
     *      productListing: ProductListing (incl Product)
     *      exchange: "pickup" or "delivery" (null if not yet selected)
     *      needByDate: timestamp or null (null if not yet selected or user has opted not for a needByDate)
     *      quantityRequested: number (1 if not yet selected)
     *      totalCost: number (always a number, not null - treat RQ as 0)
     * }
     * Each BasketItem has a validate method to check that it is sound
     * before creating an order out of it
     * You can catch this error and use it as an error message on the
     * FormHelperText if necessary
    */ 
    @observable basket = []

    /* The total running cost of all items in the basket */
    @observable totalCost = 0

    /* Items ready to be checked out */
    @observable checkoutLine = []

    async init() {
        await this.getBasket();
    }

    @action
    async getBasket() {
        const basketListings = await DatabaseClient.getBasket(profileStore.business.id);
        const newBasket = []
        for (let i = 0; i < basketListings.length; i++) {
            const pl = basketListings[i];
            if (pl != null) {
                // Set exchange to default value
                let exchange;
                if (pl.product.delivery && pl.product.pickup) {
                    exchange = "delivery";
                }
                else if (pl.product.delivery) {
                    exchange = "delivery";
                }
                else {
                    exchange = "pickup";
                }
                newBasket.push(new BasketItem(pl, exchange, null, 1, 0));
            }
        }
        this.basket.replace(newBasket);
        return newBasket;
    }

    /**
     * Does not allow repeated adds of the same listing to the basket - auto
     * caught and thrown by DBClient
     * @param {ProductListing} productListing 
     */
    @action
    async addToBasket(productListingID) {
        const pl = await DatabaseClient.addToBasket(profileStore.business.id, productListingID);
        // Automatically set the exchange to a default value
        let exchange;
        if (pl.product.delivery && pl.product.pickup) {
            exchange = "delivery";
        }
        else if (pl.product.delivery) {
            exchange = "delivery";
        }
        else {
            exchange = "pickup";
        }
        this.basket.push(new BasketItem(pl, exchange, null, 1, 0));
    }

    @action
    async removeFromBasket(productListingID) {
        for (let i = 0; i < this.basket.length; i++) {
            if (this.basket[i].productListing.id === productListingID) {
                await DatabaseClient.removeFromBasket(profileStore.business.id, productListingID);
                this.basket.splice(i, 1);
                this.calculateTotalCost();
                return;
            }
        }
    }

    @action
    async removeMultipleFromBasket(productListingIDs) {
        const newBasket= [];
        for (let i = 0; i < this.basket.length; i++) {
            if (!productListingIDs.includes(this.basket[i].productListing.id)) {
                newBasket.push(this.basket[i]);
            }
        }
        await Promise.all(productListingIDs.map((id) => DatabaseClient.removeFromBasket(profileStore.business.id, id)));
        this.basket.replace(newBasket);
        this.calculateTotalCost();
    }

    @action
    async clearBasket() {
        await DatabaseClient.clearBasket(profileStore.business.id);
        this.basket.replace([]);
        this.totalCost = 0;
    }

    /**
     * Updates the given basket item corresponding to a productListingID
     * This only updates the item in the store
     * No updates are needed for the database
     * @param {string} productListingID 
     * @param {BasketItem} basketItem 
     */
    @action
    editShoppingCart(productListingID, basketItem) {
        for (let i = 0; i < this.basket.length; i++) {
            if (this.basket[i].productListing.id === productListingID) {
                this.basket[i] = basketItem;
            }
        }
    }

    @action
    async checkout(notes) {
        if (this.basket.length === 0) {
            throw new Error("No items to checkout");
        }

        // Validate cart
        this.basket.forEach((item) => {
            item.validate(); // Throws an error
        });

        const buyerUID = profileStore.business.id;
       
        const orders = this.basket.map( (basketItem) => {
            let needByDateTS = null;
            if (basketItem.needByDate) {
                needByDateTS = +moment(basketItem.needByDate).startOf("day");
            }
            const order = new Order(undefined, undefined, buyerUID, basketItem.productListing, basketItem.exchange, false,
                            undefined, needByDateTS, false, basketItem.productListing.price, basketItem.quantityRequested, undefined,
                            basketItem.productListing.product.uid, "created", + new Date(), basketItem.productListing.unit, basketItem.totalCost);
            order.validate(); // May throw an error
            return order;
            // new Order(undefined, undefined, buyerUID, basketItem.productListing, basketItem.exchange,
            //                                                         false, undefined, needByDateTS, false, basketItem.productListing.price, basketItem.quantityRequested,
            //                                                         undefined, basketItem.productListing.uid, 'created', + new Date(), basketItem.productListing.unit,
            //                                                         basketItem.totalCost)
            
        });
        await DatabaseClient.createOrderBundles(orders, notes);

        // Clear the basket
        await this.clearBasket();
    }

    /**
     * Validates the basket.
     * Returns the latest error message from the basket
     * Otherwise, returns null if no error message
     */
    // @action
    // validateCart() {
    //     let errorMessage = null;
    //     let newCart = [];
    //     let validCart = this.basket.filter( (basketItem) => {
    //         try {
    //             basketItem.validate();
    //             return true;
    //         }
    //         catch (e) {
    //             errorMessage = e.message;
    //             newCart.push(basketItem);
    //             return false;
    //         }
    //     });
    //     this.checkoutLine.replace(validCart);
    //     this.basket.replace(newCart);
    //     return errorMessage;
    // }

    // @action
    // async checkout() {
    //     if (this.basket.length === 0) {
    //         throw new Error("No items to checkout");
    //     }
    //     const errMessage = this.validateCart();
    //     const buyerUID = profileStore.business.id;
       
    //     let orders = this.checkoutLine.map( (basketItem) => {
    //         let needByDateTS = null;
    //         if (basketItem.needByDate) {
    //             needByDateTS = +moment(basketItem.needByDate).startOf("day");
    //             console.log("NeedByDateTS", needByDateTS);
    //         }
    //         return new Order(undefined, undefined, buyerUID, basketItem.productListing, basketItem.exchange, false,
    //                         undefined, needByDateTS, false, basketItem.productListing.price, basketItem.quantityRequested, undefined,
    //                         basketItem.productListing.product.uid, "created", + new Date(), basketItem.productListing.unit, basketItem.totalCost);
    //         // new Order(undefined, undefined, buyerUID, basketItem.productListing, basketItem.exchange,
    //         //                                                         false, undefined, needByDateTS, false, basketItem.productListing.price, basketItem.quantityRequested,
    //         //                                                         undefined, basketItem.productListing.uid, 'created', + new Date(), basketItem.productListing.unit,
    //         //                                                         basketItem.totalCost)
            
    //     });
    //     await DatabaseClient.createOrderBundles(orders);
    //     if (errMessage !== null) {
    //         throw new Error(errMessage);
    //     }
    // }

    @action
    calculateTotalCost() {
        let total = 0;
        this.basket.forEach( (item) => {
            total += item.total;
        })
        this.totalCost = total;
        return this.totalCost;
    }

    isInBasket(productListingID) {
        if (!productListingID) return false;
        this.basket.forEach((basketItem) => {
            if (basketItem.productListing.id === productListingID) return true;
        });
        return false;
    }
}
export default new BasketStore()
