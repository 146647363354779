import Service from './Service';
import Note from "core/model/Note";

const URL = 'note';

class NoteService extends Service {

}

export default new NoteService(URL, Note);
