import React, { Fragment, Component } from 'react';
import Menu from '../../components/Menu';
import Topbar from '../../components/CommonTopBar';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import Messages from '../../components/organisms/Messages';

const MessagesContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
`;


const StyledMessages = styled(Messages)`
    border: none;
    border-radius: 0;
    width: 100%;
`;

const StyledContainer = styled.div`
    width: 100%;
    @media (max-width: 550px) {
        margin-left: 0px;
        margin-top: 56px;
        height: calc(100vh - 56px - env(safe-area-inset-top));
        ${props => {
            if (props.focus) {
                return css`
                    z-index: 12;
                `;
            }
        }}
    }
    @media (min-width: 550px) and (max-width: 1024px) {
        margin-left: 50px;
        margin-top: 112px;
        height: calc(100vh - 112px - env(safe-area-inset-top));
        width: calc(100vw - 50px);
    }
    @media(min-width: 1025px) {
        margin-top: 112px;
        margin-left: 250px;
        height: calc(100vh - 112px - env(safe-area-inset-top));
        width: calc(100vw - 250px);
    }
    border-top: 2px solid black;
`;

@inject('userStore', 'messagingStore')
@observer
class MessagesPage extends Component {
    state = {
        focus: false
    }

    constructor(props) {
        super(props);
        this.messages = React.createRef();
    }

    onFocus = () => {
        this.setState({ focus: true });
    }

    onDefocus = () => {
        this.setState({ focus: false });
    }

    render() {
        
        const focus = this.props.messagingStore.conversations.length > 0 ? this.props.messagingStore.conversations[0] : null
        const tabs = [
			{
				name: 'Messages',
				child: false,
				path: '/messages'
			},
        ];
        const conversations = this.props.messagingStore.conversations;
        var conv;
        if (conversations.length > 0) {
            conv = this.props.messagingStore.conversations[0];
            const messages = this.props.messagingStore.conversations[0].messages;
        }
		return (
			<Fragment>
				<Menu />
				<Topbar title="Messages" sub="Message users here." tabs={tabs} tabIndex={0} isChildPath={false} />
                <StyledContainer focus={this.state.focus}>
                    <MessagesContainer>
                        <StyledMessages onFocus={this.onFocus} onDefocus={this.onDefocus} />
                    </MessagesContainer>
                </StyledContainer>
			</Fragment>
		);
    }
}

export default MessagesPage;