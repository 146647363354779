import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@material-ui/core';
import EditButton from '../atomic/buttons/EditButton';
import SaveButton from '../atomic/buttons/SaveButton';
import CancelButton from '../atomic/buttons/CancelButton';
import PhoneNumberInput from '../atomic/inputs/PhoneNumberInput';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Top = styled.div`
    display: flex;
    width: 100%;
`;

const Bottom = styled.div`
    width: 100%;
`;


const StyledFormControl = styled(FormControl)`
    width: 100%;
`

const StyledPhoneNumberInput = styled(PhoneNumberInput)`
    width: 100%;
    &.disabled {
        color: black !important;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    width: 60px;
    ${props => {
        if (props.wide) {
            return css`
                width: 125px;
            `;
        }
    }}
`;

/**
 * InputComponent can be a component that adheres to
 * the MaterialUI props for an Input
 */
class EditablePhoneLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            value: this.props.defaultValue || '',
            error: null
        }
    }

    componentDidUpdate() {
        if (this.state.editing) {
            this.input.inputElement.focus()
        }
    }

    handleChange = (value) => {
        this.setState( {value});
    }

    onFinish = async () => {
        let pattern = new RegExp("^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$");
		if (!pattern.test(this.state.value)) {
            this.setState({ error: "Must be in the format (XXX) XXX-XXXX" });
        }
        else {
            try {
                await this.props.onFinish(this.state.value);
                this.endEditing();
            }
            catch (e) {
                this.setState({ error: e.message });
            }
        }
    }

    startEditing = () => {
        this.setState({ editing: true })
    }

    endEditing = () => {
        this.setState({ editing: false, error: null })
    }

    cancelEditing = () => {
        this.setState({ value: this.props.defaultValue || '' });
        this.endEditing();
    }

    render() {
        const InputComponent = this.props.inputComponent;
        return (
            <Container>
                <Top>
                    <StyledFormControl >
                        <StyledPhoneNumberInput
                            inputRef={(input) => { 
                                this.input = input; 
                            }}
                            classes={{
                                disabled: 'disabled'
                            }}
                            disabled = {!this.state.editing}
                            displaylabel
                            onChange={this.handleChange}
                            value = {this.state.value}
                            error = {!!this.state.error}
                        />
                    </StyledFormControl>
                    {
                            this.state.editing ?
                                <ButtonWrapper wide>
                                    <SaveButton onClick={this.onFinish} />
                                    <CancelButton onClick={this.cancelEditing} />
                                </ButtonWrapper>
                                :
                                <ButtonWrapper>
                                    <EditButton onClick={this.startEditing} />
                                </ButtonWrapper>
                        }
                </Top>
                <Bottom>
                    <FormHelperText error>{this.state.error}</FormHelperText>
                </Bottom>
            </Container>
        );
    }
}

EditablePhoneLabel.propTypes = {
    defaultValue: PropTypes.string,
    onFinish: PropTypes.func.isRequired
};

EditablePhoneLabel.defaultProps = {
    defaultValue: "(555) 555-5555",
};

export default EditablePhoneLabel;