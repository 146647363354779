import React, { Component } from 'react'
import styled from 'styled-components'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import breakpoint from 'styled-components-breakpoint'

const FoodChipsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	width: auto;
`

const StyledGrid = styled(Grid)`
    display: flex;
    justify-content: flex-start;
    align-content: center;
    color: $spearmint-main;
    flex-wrap: wrap;
`

const StyledChip = styled(Chip)`
    margin-right: 5px;
    margin-top: 5px; 
    height: 25px;
    border-radius: 5px;
    ${breakpoint('mobile_min', 'tablet_max')`
        font-size: 0.8em;
        height: 22px;
    `}
`

class FoodChips extends Component {

    createChips() {
        return this.props.items.map(item => (
			<StyledChip key={item} label={item} />
        ))
    }

    render() {
        return (
            <FoodChipsContainer>
                <StyledGrid>
                    {this.createChips()}
                </StyledGrid>
            </FoodChipsContainer>
        )
    }
}

export default FoodChips