import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import ReloadButton from '../atomic/buttons/ReloadButton';

const StyledPopup = styled(Popup)`
    width: 90% !important;
    max-width: 500px !important;
    background: rbga(0,0,0,0);
    border-style: none;
    font-family: ${props => props.theme.fonts.ms_font300};
    border-radius: 20px;
`

const Container = styled.div`
    padding: 1.4rem 1rem;
`;

const Top = styled.div`
    display: inline-block;
    text-align: center;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const StyledReloadButton = styled(ReloadButton)`
`;

class OfflineModal extends React.Component {

    reloadPage = () => {
        window.location.reload(true);
    }

    render() {
        return (
            <StyledPopup
                modal
                closeOnDocumentClick={false}
                closeOnEscape={false}
                defaultOpen
            >
                <Container>
                    <Top>
                        Uh oh! Looks like you're offline.  Freshspire will automatically attempt to reconnect when you go back online, or you can press the button below to try to reconnect to Freshspire.
                    </Top>
                    <Bottom>
                        <StyledReloadButton withText onClick={this.reloadPage}/>
                    </Bottom>
                </Container>
            </StyledPopup>
        );
    }
}

export default OfflineModal;