import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import Menu from '../../components/Menu'
import Topbar from '../../components/CommonTopBar'
import ClientInvites from '../../components/organisms/ClientInvites'
import { LoadingSpinnerPage } from '../../components/LoadingSpinner';
import DatabaseClient from '../../core/DatabaseClient'
import { message } from 'antd'

const sellerTabs = [
  {
    name: `View Buyers`,
    child: false,
    path: '/clientlist'
  },
  {
    name: `Invite Buyers`,
    child: true,
    path: '/addclients'
  },
];

const buyerTabs = [
  {
    name: `View Sellers`,
    child: false,
    path: '/clientlist'
  },
  {
    name: `Invite Sellers`,
    child: true,
    path: '/addclients'
  },
];

@inject('inventoryStore', 'searchStore', 'profileStore', 'clientListStore')
@observer
class AddClients extends Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    const { clientListStore } = this.props;
    try {
      await clientListStore.refreshPendingInvitations();
			window.scroll(0, 0);
		}
		catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
    this.setState({ loading: false });

  }

  render() {
    const { profileStore } = this.props
    return (
      <React.Fragment>
      <Menu />
      <Topbar
        title={profileStore.isSeller?"Invite Buyers":"Invite Sellers"}
        sub={`Invite your current ${profileStore.isSeller?"buyers":"sellers"} to start ${profileStore.isSeller?"buying":"selling"} to you on Freshspire`}
        tabs={profileStore.isSeller? sellerTabs:buyerTabs}
        tabIndex={1}
        mobilft={false}
        isChildPath={true}
      />
      <div className="Profile-container">
        {
          this.state.loading ? 
          <LoadingSpinnerPage />
          :
          <ClientInvites
            pendingInvites={this.props.clientListStore.pendingInvites}
            pendingEmails={this.props.clientListStore.pendingEmails}
          />
        }
      </div>
      </React.Fragment>
    )
  }
}

export default withRouter(AddClients)
