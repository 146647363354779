import React, { Component, Fragment } from 'react'
import { LoadingSpinnerPage } from '../../LoadingSpinner';
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import { toJS } from 'mobx'
import styled, { css } from 'styled-components';
import { message } from 'antd'
import { withRouter} from 'react-router-dom';
import NoteList from './NoteList'
import { NoteCreator } from '../../molecules/NoteCreator';
import { Label } from '../../../core/model'
import LabelSelect from '../LabelSelect';

const Container  = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const Scroller = styled.div`
  display: flex;
	flex: 1;
	flex-direction: column;
	margin: 10px;
	z-index: 0;
`;

const LabelSelectStyled = styled(LabelSelect)`
	position: absolute;
`

const NoteCreatorStyled = styled(NoteCreator)`
  margin-bottom: 20px;
`;

const NoteListStyled = styled(NoteList)`
	${props => {
		if(props.blocked){
			return css`
						pointer-events: none;
						opacity: ${props => props.blocked ? 0.7 : 1};
					`
			}
	}}
`

@inject('errorStore', 'noteStore', 'clientListStore')
@observer
class Notes extends Component {
	state = {
		defaultLabels: []
	}
	 
  async componentDidMount() {
    const { noteStore, clientListStore, defaultValues } = this.props;
		await noteStore.init();
		const filteredLabels = this.filterDefaultLabels(defaultValues);
		noteStore.setLabelFilters(filteredLabels)
		this.setState({
			defaultLabels: filteredLabels 
		})
		if(clientListStore.totalClientList <= 0) {
			await clientListStore.init();
		}
	}

	filterDefaultLabels = values => {
		const options = this.createOptions();
		const filteredLabels = values.map(value => {
				for(let i = 0; i < options.length; i++) {
						const label = options[i];
						if(label.value.value === value) {
								return label;
						}
				}
		}).filter((label) => label != null);
		return filteredLabels;
	}
	
	createOptions = () => {
		const currentLabels = this.props.noteStore.Labels;
		let labelTexts = []
		let otherOptions = []
		
		for(let i = 0; i<currentLabels.length; i++) {
			labelTexts.push(currentLabels[i].label.toLowerCase())
		}

		const clientOptions = this.props.clientListStore.totalClientList.map(client => new Label(undefined,client['name'],{
			value: client['id'],
			type: 'business'
		}))

		otherOptions = [...clientOptions]
		otherOptions = otherOptions.filter(option => labelTexts.indexOf(option.label.toLowerCase()) <= -1)
		const options = [...currentLabels, ...otherOptions]

		return options
	}

	removeDups = (array) => {
		let unique = {};
		array.forEach(i => {
			if(!unique[i]) {
				unique[i] = true;
			}
		});
		return Object.keys(unique);
	}

  saveNote = data => {
		const { noteStore } = this.props;
		if(data && data.id) {
			noteStore.updateNote(data)
		} else {
			noteStore.createNote(data);
		}
	}

  updateLabelFilter = async (value) => {
    const { noteStore } = this.props;
    noteStore.setLabelFilters(value ? value : [])
	};

	deleteNote = (noteID) => {
		this.props.noteStore.deleteNote(noteID)
	}

	render() {
		const { noteStore } = this.props;
		const { defaultLabels } = this.state;
		const options = this.createOptions()

		const tabs = [
			{
				name: 'Notes',
				child: false,
				path: '/notes'
			},
		];

		const noteData = toJS(noteStore.getFilteredNotes())
		const filters = toJS(noteStore.FilteredLabels)

		return (
      <Container>
        <LabelSelectStyled
          id="label-select"
          text="Filter by label"
          value={filters}
          onChange={this.updateLabelFilter}
          options={options}
          isClearable
          isMulti
        />
        <Scroller>
          <NoteListStyled
            data={noteData}
            onDelete={this.deleteNote}
            options={options}
						onSave={this.saveNote}
						defaultLabels={filters}
						blocked={noteStore.processing} />
						{noteStore.processing && <LoadingSpinnerPage/>}
        </Scroller>
      </Container>
		)
	}
}

Notes.propTypes = {
	defaultValues: PropTypes.array
};

Notes.defaultProps = {
	defaultValues: []
};


export default withRouter(Notes)
