import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';

const Container = styled.div`
`;

class Quantity extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { value, ...other } = this.props
    return (
      <Container {...other}>
        {`Quantity: ${value}`}
      </Container>
    )
  }

}

Quantity.propTypes = {
  value: PropTypes.number,
}

export default Quantity;