import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { LoadingSpinnerPage } from '../../LoadingSpinner';
import MenuItem from './BusinessMenuItem';

const MenuItemStyled = styled(MenuItem)`
  min-width: 300px;
  miax-width: 300px;
`

class BusinessMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getBusinesses = (data) => {
    const newData = data.sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    })
    return newData.map(item => <MenuItemStyled data={item} onClick={this.props.onClick}/>)
  }

  render() {
    const { onClick, data, isLoading, ...other } = this.props;

    return (
      <Menu {...other}>
        {this.getBusinesses(data)}
      </Menu> 
    )
  }
}

BusinessMenu.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

BusinessMenu.defaultProps = {

};

export default BusinessMenu;