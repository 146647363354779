import React, { Component } from 'react'
import {
    Redirect,
    Route
  } from 'react-router-dom'
import { userStore, profileStore } from '../stores'
import PrivateSellerRoute from './PrivateSellerRoute'
import PrivateBuyerRoute from './PrivateBuyerRoute'
import FirstTimeSurvey from '../pages/common/FirstTimeSurvey';

export default function GenericPrivateRoute({component: Component, ...rest}) {
    if (!userStore.isAuthenticated) {
        return (
            <Route {...rest}
                   render={(props) => (<Redirect to={{pathname: '/login', state: {from: props.location}}}/>)}
            />
        )
    }
    else if(!userStore.userHasBusinesses) {
    	if (Component !== FirstTimeSurvey) {
            return <Route {...rest}
                   render={(props) => (<Redirect to={{pathname: '/firsttimesurvey', state: {from: props.location}}}/>)}
            />
        } else {
            return <Route {...rest}
                   render={(props) => (<Component {...props} />)}
            />
        }
    } else if (profileStore.isSeller) {
        return (<PrivateSellerRoute component={Component} {...rest} />)
    } else {
        return (<PrivateBuyerRoute component={Component} {...rest} />)
    }
}
