import React, { Component } from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import _Dropzone from 'react-dropzone'
import Button from '../../atomic/buttons/Button'
import Input from '@material-ui/core/Input'
import XLSX from 'xlsx'
import validate from 'core/utilities/FormValidator';

const constraints = {
  field: {
    email: true
  }
};

const InputContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: row;
`

const StyledInput = styled(Input)`
	font-size: 16px;
	font-family: ${props => props.theme.fonts.ms_font500};
`

const ImportButton = styled(Button)`
    text-transform: none;
    min-width: 35px;
    margin-left: 13px; 
    ${breakpoint('mobile_min', 'mobile_max')`
		font-size: 0.9em;
	`}
`

class ImportBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filename: ''
        }
        this.zone = React.createRef();
        this.rABS = true;
    }

    formatSheet = (sheet) => {
        const keys = Object.keys(sheet)
        const values = Object.values(sheet);
        let arr = [];
        for(let i = 0; i < keys.length - 1; i++) {
            arr[i] = values[i]['v'] + "";
        }
        return arr;
    }

    validateEmail = (value) => {
        const err = validate({field: value},constraints);

        if (err !== undefined) {
            this.hasError = true;
            return false;
        }else {
            return true;
        }
    }

    filterValidEmails = (arr) => {
        return arr.filter(item => {
            return this.validateEmail(item);
        })
    }

    render() {
        let {onImport, onClearDrop, source, ...other } = this.props;
        let newOnDrop = (e) => {
            this.setState({filename: e[0].name})
            var f = e[0];
            var reader = new FileReader();
            reader.onload = (e) => {
                var data = e.target.result;
                if(!this.rABS) {
                    data = new Uint8Array(data);
                }  
                    
                var workbook = XLSX.read(data, {type: this.rABS ? 'binary' : 'array'})['Sheets'];
                var sheet = workbook[Object.keys(workbook)[0]];
                var json = this.formatSheet(sheet);
                json = this.filterValidEmails(json)
                onImport(json)
            };
            if(this.rABS) {
                reader.readAsBinaryString(f); 
            } else  {
                reader.readAsArrayBuffer(f);
            }
        }
        return (
            <InputContainer>
                <_Dropzone ref={(node) => { this.zone = node; }} onDrop={newOnDrop} style={{flex: '1'}} className="drop-box" multiple={false} {...other}>
                    <StyledInput 
                        fullWidth 
                        value={this.state.filename}
                        placeholder="Click here to upload file"
                    />
                </_Dropzone>
                <ImportButton variant="outlined" color="primary" onClick={() => { this.zone.open() }}>
                    Import Sheet
                </ImportButton>
            </InputContainer>
        )
    }
}

export default ImportBar
