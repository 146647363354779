import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import AutoComplete from '../../molecules/AutoComplete';

const Container = styled.div`
	background: #fff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 10px 8px 10px 8px;
	padding: 5px 10px 0 10px;
	border-radius: 0.5rem;
`

const TextWrapper = styled(Typography) `
	color: #999;
	margin-right: 10px;
`

const AutoCompleteStyled = styled(AutoComplete)`
  flex: 1;
`
 
 const BuyerSelect = ({
   text,
   classname,
  ...other
 }) => (
  <Container classname={classname}>
    <TextWrapper>{text}</TextWrapper>
    <AutoCompleteStyled {...other}/>
  </Container>
)

export default BuyerSelect;
 