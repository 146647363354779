import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';

const Container = styled.div`
`;

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  getDetails = () => {
    const { group } = this.props
    let details = '';
    details += group.organic ? 'Organic' : '';
    details += group.local ? ' Local' : '';
    return details.trim();
  }

  render() {
    const { ...other } = this.props

    return (
      <Container {...other}>
        {this.getDetails()}
      </Container>
    )
  }

}

Details.propTypes = {
  group: PropTypes.object
}

Details.defaultProps = {
  group: {
    local: true,
    organic: false,
  }
}

export default Details;