import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

const Container = styled.div`
  display: flex;
  margin-left: auto;
  padding-right: 24px;
  z-index: 2;
  margin-top: -125px;
  right: 0;
  position: fixed;
  @media (max-width: 550px) {
    position: relative;
    margin-top: 0px;
    padding-right: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto; 
`;

const SwitchLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-family: ${props => props.theme.fonts.ms_font700};
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchStyled = styled(Switch)`

`

const LabeledSwitch = (props) => {
  const { 
    checked, 
    onChange, 
    value,
    label,
    leftLabel,
    rightLabel,
  } = props;

  return (
    <Container>
      <Wrapper>
        <SwitchLabel>{label}</SwitchLabel>
        <SwitchContainer>
          {leftLabel}
          <SwitchStyled
            id="basket_sort_switch"
            checked={checked}
            onChange={onChange(value)}
            value={value}
          />
        </SwitchContainer>
      </Wrapper>
    </Container>
  )
}

LabeledSwitch.propTypes = {
  value: PropTypes.string,
};

LabeledSwitch.defaultProps = {
  label: 'Sort By:',
  leftLabel: 'Seller',
};

export default LabeledSwitch;