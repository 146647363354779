import React, { Component } from 'react'
import EditableLabel from 'react-inline-editing'
import { observer, inject } from 'mobx-react'
import Menu from '../../components/Menu'
import logo from '../../assets/logo.png'
import pencil from '../../assets/Edit-Profile-Pencil-Green.png'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MaskedInput from 'react-text-mask';
import TextField from "@material-ui/core/TextField";
import {LoadingSpinnerPage} from '../../components/LoadingSpinner'
import { FoodOptions, PaymentOptions, States, CommunicationOptions } from '../../core/core'
import Topbar from '../../components/CommonTopBar'
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ProductListingGroupCard from '../../components/organisms/listCards/ProductListingGroupCard';
import ProductListingGroupModal from '../../components/organisms/modals/ProductListingGroupModal';
import Chip from '@material-ui/core/Chip';
import Button from '../../components/atomic/buttons/Button';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import { AlertMessage } from '../../components/AlertMessage';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import Dropzone from '../../components/molecules/Dropzone';
import DropdownMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Select from '@material-ui/core/Select';
import InputMask from 'react-input-mask';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Input from '@material-ui/core/Input';
import ChipInput from 'material-ui-chip-input'
import InputLabel from '@material-ui/core/InputLabel';
import PhoneNumberInput from '../../components/atomic/inputs/PhoneNumberInput';
import ProfilePanelCheckBox from '../../components/molecules/ProfilePanelCheckBox';
import UploadCertificationModal from '../../components/organisms/UploadCertificationModal';
import CertificationList from '../../components/organisms/CertificationList';
import { certification_status } from '../../core/model';
import MessageButton from '../../components/atomic/buttons/MessageButton';
import styled from 'styled-components';
import MessagesModal from '../../components/organisms/MessagesModal';
import NotesPage from '../../components/organisms/NotesPage'

function TextMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
			<MaskedInput
					{...other}
					ref={inputRef}
					mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
					placeholderChar={'\u2000'}
			// showMask
			/>
	);
}

const StyledButtonContainer = styled.div`
	display: flex;
	@media (max-width: 767px) {
		flex-direction: column;
	}
`;

const ProductCard = styled(ProductListingGroupCard)`
  margin: 8px 10px;
  border-radius: 10px;
`;
const NoteContainer = styled.div`
  margin: 8px 10px;
`;

const StyledMessageButton = styled(MessageButton)`
	margin-top: auto;
	margin-left: auto;
	margin-bottom: 10px;
	margin-right: 20px;
	min-height: 35px;
	max-height: 35px;
	padding: 0 15px;
	text-transform: none;
	width: 130px;
	min-width: 130px;
	@media (max-width: 767px) {
		margin: 5px auto 8px;
		min-height: 33px;
		max-height: 33px;
		padding: 0 30px;
		text-transform: none;
	}
`;

@inject('profileStore', 'shoppingStore', 'basketStore', 'clientListStore', 'searchStore')
@observer
class Profile extends Component {
	constructor(props){
		super(props);
		this.tabIndexValues = {
			info: 0,
			products: -1,
			certifications: -1,
			notes: -1
		}
	}

	state = {
		isLoadingProfile: true,
		businessInfo: null,
		profileUID: null,
		deals: [],
		tabIndex: 0,
		anchorEl: null,
		open: false,
		editMode: false,
		modalOpen: false,
		queuedCertificateDeletions: [],
		messageModalOpen: false,
    currentListingGroup: null,
    currentListing: null
	}
	
	async componentDidMount() {
		const { profileStore, location, shoppingStore, clientListStore, searchStore } = this.props
		let businessInfo, profileUID, deals;
		try {
			 profileUID = this.getProfileUID(location.pathname);
			 if(profileUID) {
				businessInfo = await profileStore.getBusiness(profileUID);
				if (businessInfo.type === profileStore.business.type && businessInfo.id !== profileStore.business.id) {
				    // Not allowed to view own type
					return this.props.history.push("/login");
				}
				if(businessInfo.type === 'seller') {
					await shoppingStore.refreshSellerInventory(profileUID);
					deals = shoppingStore.sellerItems;
					searchStore.setList(deals);
				}
			} else {
				profileUID = profileStore.business.id;
				businessInfo = profileStore.business;
			}
			this.props.searchStore.switchToOpenList(false);

			if (businessInfo.id === this.props.profileStore.business.id) {
				this.props.profileStore.resetForm();
				this.props.profileStore.loadBusiness();
			}
			const opening = moment(businessInfo.opening+'','hh:mm').format('hh:mm a')
			const closing = moment(businessInfo.closing+'','hh:mm').format('hh:mm a')

			this.setState({
				isLoadingProfile: false,
				state: businessInfo.state,
				opening,
				closing,
				businessInfo,
				profileUID,
				deals
			})
			window.scroll(0, 0);
		}
		catch (e) {
			console.error(e);
			message.error(e.message);
			console.error(e);
			Sentry.captureException(e);
		}
	}

	// componentWillUpdate(nextProps, nextState) {
	// 	console.log(nextState.profileUID, this.state.profileUID)
	// }

	formatPhoneNumber = (s) => {
		var s2 = (""+s).replace(/\D/g, '');
		var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
		return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
	}

	formatTime = (name,value) => {
		this.setState({[name]:value})
		let formatted24HrTime = (value.includes("-") || (!value.toLowerCase().includes('am') && !value.toLowerCase().includes('pm'))) ? '25:00' : moment(value, "h:mm A").format("HH:mm");
		this.props.profileStore.onFieldChange(name, formatted24HrTime)
	}

	createFoodOptionLabels = (array, isChip = true) => {
		if(isChip){
			return array.map(item => (
				<Chip key={item} className="chip" label={item} />
			))
		} else {
			return array.map(item => (
				<div key={item} className="text-chip chip">
					<Typography className="text">
						{item}
					</Typography>
				</div>
			))
		}
	}

	getProfileUID(url) {
		return url.substring(url.lastIndexOf("profile")+8);
	}

	handleChange = (event, tabIndex) => {
		this.setState({ tabIndex });
	}

	handleaDropdownClick = event => {
		this.setState({ 
			anchorEl: event.currentTarget,
			open: !this.state.open,
		 });
	};

	handleaDropdownClose = () => {
		this.setState({ open: false });
	};

	editProfile = () => this.setState({ editMode: true });

	saveProfileChanges = async () => {
		const { profileStore }= this.props;
		const { queuedCertificateDeletions } = this.state;
		try {
			await profileStore.deleteCertifications(queuedCertificateDeletions);
			await profileStore.updateBusiness();
			await profileStore.refreshBusiness();
			const businessInfo = profileStore.business;
			this.setState({ editMode: false, businessInfo, queuedCertificateDeletions: [] });
		}
		catch (e) {
			message.error(`${e} Please fix the highlighted fields.`)
		}
	}

	openCertification = (url) => {
		window.open(url);
	}

	removeCertification = (id) => {
		this.setState({ queuedCertificateDeletions: [...new Set([...this.state.queuedCertificateDeletions, id])]})
	}

	isStranger = () => {
		let relationship = this.props.profileStore.clientRelationship;
		return this.state.businessInfo.id !== this.props.profileStore.business.id && relationship === null;
	}

	getCerificationLists = () => {
		const { editMode, queuedCertificateDeletions } = this.state
		const { profileStore } = this.props
		let businessInfo = this.state.businessInfo;
		let activeArry = [], pendingArry = [], expiredArry = [], rejectedArry = [];

		if (businessInfo.id === this.props.profileStore.business.id) {
			businessInfo = profileStore.business;
		}

		businessInfo.certifications.forEach(cert => {
			switch(cert.status) {
				case certification_status.ACTIVE:
					activeArry.push(cert);
					break;
				case certification_status.PENDING:
					pendingArry.push(cert);
					break;
				case certification_status.EXPIRED:
					expiredArry.push(cert);
					break;
				case certification_status.REJECTED:
					rejectedArry.push(cert);
					break;
			}
		})

		if (businessInfo.id === profileStore.business.id) {
			let lists = [];
			if(activeArry.length > 0){
				lists.push(
					<CertificationList
						data={activeArry}
						header='Active'
						deletions={queuedCertificateDeletions}
						onItemClick={this.openCertification}
						onItemDelete={this.removeCertification}
						editMode={editMode}
					/>
				)
			}

			if(pendingArry.length > 0){
				lists.push(
					<CertificationList
						data={pendingArry}
						header='Pending'
						deletions={queuedCertificateDeletions}
						onItemClick={this.openCertification}
						onItemDelete={this.removeCertification}
						editMode={editMode}
					/>
				)
			}

			if(expiredArry.length > 0){
				lists.push(
					<CertificationList
						data={expiredArry}
						header='Expired'
						deletions={queuedCertificateDeletions}
						onItemClick={this.openCertification}
						onItemDelete={this.removeCertification}
						editMode={editMode}
					/>
				)
			}

			if(rejectedArry.length > 0){
				lists.push(
					<CertificationList
						data={rejectedArry}
						header='Rejected'
						deletions={queuedCertificateDeletions}
						onItemClick={this.openCertification}
						onItemDelete={this.removeCertification}
						editMode={editMode}
					/>
				)
			}

			return lists;
		} else {
			if(activeArry) {
				return (
					<CertificationList
						data={activeArry}
						onItemClick={this.openCertification}
						onItemDelete={()=>console.log('clicked item delete')}
						editMode={false}
					/>
				)
			}
		}
	}

	cancelProfileChanges = () => {
		this.setState({ editMode: false, queuedCertificateDeletions: [] });
		this.props.profileStore.loadBusiness();
	}

	restrictProducts = () => {
		const relationship = this.props.profileStore.clientRelationship;
		if (relationship === null) {
			return true;
		}
		switch (relationship.status) {
			case 'requested':
				return true;
			default:
				return false;
		}
	}
	
	getRequestButton = (contained) => {
		// There is currently no flow where a seller should be able to find a buyer and request to connect with them
		let Btnvariant = contained ? 'contained' : 'outlined';
		let relationship = this.props.profileStore.clientRelationship;
		if (this.state.businessInfo.id === this.props.profileStore.business.id) {
			return (
				<React.Fragment>
				{
					this.state.editMode?
						<div className="request-button-container">
							<Button variant={Btnvariant} className="request-button sm-md-button mobil-fixed-width" onClick={async () => await this.saveProfileChanges()}>
								Save Changes
							</Button>
							<Button variant={Btnvariant} className="request-button mobil-fixed-width" onClick={this.cancelProfileChanges}>
								Cancel
							</Button>
						</div>
						:
						<Button id="edit_profile" variant={Btnvariant} className="request-button" onClick={this.editProfile}>
							Edit Profile
						</Button>
				}
				</React.Fragment>
			);
		}
		
		if (relationship === null) {
			if (this.props.profileStore.isBuyer) {
				return (
				<StyledButtonContainer>
					<StyledMessageButton variant={Btnvariant} uid={this.state.businessInfo.id} onClick={() => this.setState({messageModalOpen: true})}/>
					<Button variant={Btnvariant} color="primary" className="request-button md-button" onClick={async () => {await this.sendRequest()}}>
							Request to be a buyer
					</Button>
				</StyledButtonContainer>
				)
			}
			else {
				return (
					<StyledButtonContainer>
						<StyledMessageButton variant={Btnvariant} uid={this.state.businessInfo.id} onClick={() => this.setState({messageModalOpen: true})}/>
					</StyledButtonContainer>
					)
			}
		}
		switch (relationship.status) {
			case 'active':
				return (
					<StyledButtonContainer>
						<StyledMessageButton variant={Btnvariant} uid={this.state.businessInfo.id} onClick={() => this.setState({messageModalOpen: true})}/>
						<Button variant={Btnvariant} color="primary" className="request-button sm-button" onClick={this.handleaDropdownClick}>
							Affiliated
							<Icon className="drop-icon">{this.state.open? 'keyboard_arrow_up':'keyboard_arrow_down'}</Icon>
						</Button>
						<Popper open={this.state.open} anchorEl={this.state.anchorEl} className="profile-dropdown sm-popper">
							<Paper>
								<MenuList>
									<MenuItem onClick={() => AlertMessage('Unaffiliate',`Are you sure you want to be unaffiliated with ${this.state.businessInfo.name}?`, async () => await this.handleDeclineConnectRequest(relationship.buyerUID, relationship.sellerUID))}>Unaffiliate</MenuItem>
								</MenuList>
							</Paper>
						</Popper>
					</StyledButtonContainer>
				)
			case 'requested':
				if (relationship[relationship.sender + "UID"] === this.props.profileStore.business.id) {
					return (
						<StyledButtonContainer>
							<StyledMessageButton variant={Btnvariant} uid={this.state.businessInfo.id} onClick={() => this.setState({messageModalOpen: true})}/>
							<Button variant={Btnvariant} className="request-button md-button" onClick={this.handleaDropdownClick}>
								Request sent
								<Icon className="drop-icon">{this.state.open? 'keyboard_arrow_up':'keyboard_arrow_down'}</Icon>
							</Button>
							<Popper open={this.state.open} anchorEl={this.state.anchorEl} className="profile-dropdown md-popper">
								<Paper>
									<MenuList>
										<MenuItem onClick={() => AlertMessage('Cancel Request',`Are you sure you want to cancel this connection request?`, async () => await this.handleDeclineConnectRequest(relationship.buyerUID, relationship.sellerUID))}>Cancel</MenuItem>
									</MenuList>
								</Paper>
							</Popper>
						</StyledButtonContainer>
					)
				}
				else {
					return (
						<StyledButtonContainer>
							<StyledMessageButton variant={Btnvariant} uid={this.state.businessInfo.id} onClick={() => this.setState({messageModalOpen: true})}/>
							<Button variant={Btnvariant} color="primary" className="request-button md-button" onClick={this.handleaDropdownClick}>
								Respond to request
								<Icon className="drop-icon">{this.state.open? 'keyboard_arrow_up':'keyboard_arrow_down'}</Icon>
							</Button>
							<Popper open={this.state.open} anchorEl={this.state.anchorEl} style={{zIndex: '1'}} className="profile-dropdown md-popper">
								<Paper>
									<MenuList>
										<MenuItem onClick={async () => await this.handleAcceptConnectRequest(relationship.buyerUID, relationship.sellerUID)}>Accept</MenuItem>
										<MenuItem onClick={async () => await this.handleDeclineConnectRequest(relationship.buyerUID, relationship.sellerUID)}>Decline</MenuItem>
									</MenuList>
								</Paper>
							</Popper>
							<div className="accept-container">
								<div className="top">
									<Typography className="accept-text">
										{`Requested to ${this.state.businessInfo.type === 'seller' ? "sell to" : "buy from"} you`}
									</Typography>
								</div>
								<div className="bot">
									<Button color="primary" variant={Btnvariant} className="response-button" onClick={async () => await this.handleAcceptConnectRequest(relationship.buyerUID, relationship.sellerUID)}>
										Accept
									</Button>
									<Button color="secondary" variant={Btnvariant} className="response-button" onClick={async () => await this.handleDeclineConnectRequest(relationship.buyerUID, relationship.sellerUID)}>
										Decline
									</Button>
								</div>
							</div>
						</StyledButtonContainer>
					)
				}
			default:
				return;
		}
	}



	handleAcceptConnectRequest = async (buyerUID, sellerUID) => {
		try {
			await this.props.profileStore.acceptConnectRequest(buyerUID, sellerUID);
			this.handleaDropdownClose();
			message.success('Request accepted!')
		}
		catch (e) {
			console.error(e);
			console.error(e);
			Sentry.captureException(e);
			message.error(e.message);
		}
	}

	createMenuItem = label => (
		<MenuItem key={`${label}-menu`} value={label}>
			{label}
		</MenuItem>
	)

	createMenuItems = (arr) => (
		arr.map(this.createMenuItem)
	)

	noOther = () => {
		const optionsMock = Object.assign({},FoodOptions);
		delete optionsMock.OTHER;
		return optionsMock
	}

	handleDeclineConnectRequest = async (buyerUID, sellerUID) => {
		try {
			await this.props.profileStore.deleteClientRelationship(buyerUID, sellerUID);
			this.handleaDropdownClose();
			message.success('Request declined')
		}
		catch (e) {
			console.error(e);
			console.error(e);
			Sentry.captureException(e);
			message.error(e.message);
		}
	}

	async sendRequest() {
		try {
			await this.props.profileStore.sendRequest(this.state.profileUID);
			this.handleaDropdownClose();
			message.success('Request successfully sent!');
		}
		catch (e) {
			console.error(e);
			console.error(e);
			Sentry.captureException(e);
			message.error('Unable to send request.');
		}
	}

	onDrop = file  => {
		// Currenlty returns BLOBs
		console.log('upload store set ', file[0]);
		this.props.profileStore.onFieldChange('picture', file[0]);
	}

	onClearDrop = event  => {
		// Currenlty returns BLOBs
		event.stopPropagation();
		this.props.profileStore.onFieldChange('picture', null);
	}

	changeStateValue = event => {
		const { name, value } = event.target
		this.setState({ [name]: value });
		this.props.profileStore.onFieldChange(name, value);
	}
	 
	getNotes = () => {
		return (
			<NotesPage/>
		)
	}

	addChip = arr => {
		const chips = [...new Set([...this.props.profileStore.form.fields.foodOptions.value, ...arr])];
		this.props.profileStore.onFieldChange('foodOptions', chips);
  }

  deleteChip = (chip,index) => {
    var chips = [...this.props.profileStore.form.fields.foodOptions.value]; // make a separate copy of the array
		chips.splice(index, 1);
		this.props.profileStore.onFieldChange('foodOptions', chips);
  }

	defaultParsePaste = str => {
    return str.split(/\r\n|\,|\n|\r|\,/).filter(function (row) {
      if(!row.match(/^\s*$/)) {
        return row.split('\t');
      }
    });
  }

	handlePaste = (e) => {
		let paste = [];
		if(typeof e === 'string'){
			paste = this.defaultParsePaste(e);
		} else if (e && e.clipboardData) {
			paste = this.defaultParsePaste(e.clipboardData.getData('text/plain'));
		}
		console.log(paste)
		this.addChip(paste);
	}

	updateInput = (inputText) => {
    this.inputText = inputText;
  }

	convertInput = () => {
    this.handlePaste(this.inputText);
  }
	
	getDealItems() {
		const { basketStore } = this.props
		const restricted = this.restrictProducts();
    let filteredDeals = this.props.searchStore.List;

    if(filteredDeals.length === 0){
      return (
        <div className="empty-text">
          <Typography variant="title" className="text">
            No Results Found
          </Typography>
        </div>
      )
    }

    // let inCart = [];
    // inCart = this.proshoppingCart.map(item => {
    //   return item.deal.id;
    // })

    return filteredDeals.map((group, productListing) => {
      return (
        <ProductCard
          productGroup={group}
          BasketItemsList={basketStore.basket}
          onDetailsClicked={(group, productListing) => this.setState({ currentListingGroup: group, currentListing: productListing })}
					restricted={restricted}
        />
      )
    })
	}

	profileTabs(businessInfo, tabIndex) {
		let indexnum = 0;
		let elems = [(
			<Tab id="profile_active_tab" className={`tab ${tabIndex === indexnum? 'active':''}`} label="Info" />
		)]

		if(businessInfo.type === 'seller' && businessInfo.id !== this.props.profileStore.business.id){
			elems.push(<Tab id="profile_products_tab" className={`tab ${tabIndex === ++indexnum? 'active':''}`} label="View Products" />)
			this.tabIndexValues.products = indexnum;
		}
		elems.push(<Tab id="profile_certifications_tab" className={`tab ${tabIndex === ++indexnum? 'active':''}`} label="Certifications" />)
		this.tabIndexValues.certifications = indexnum;


		// if(businessInfo.type === 'buyer' && businessInfo.uid === this.props.userStore.user.uid){
		// 	elems.push(<Tab id="profile_notes_tab" className={`tab ${tabIndex === ++indexnum? 'active':''}`} label="Notes" />)
		// 	this.tabIndexValues.notes = indexnum;
		// }
		return elems;						
	}

	render() {
		let title = '', sub = '', tabs = null, hasEmail = false, hasPhone = false, phonePrefer = '', isSeller = false;
		const { isLoadingProfile, businessInfo, profileUID, tabIndex, currentListingGroup, editMode } = this.state;
		const { profileStore } = this.props;
		if(profileUID && (profileUID !== this.props.profileStore.business.id)){
			title = 'Profile';
			sub = `View ${businessInfo.name}'s profile`;
		} else if (profileUID && (profileUID === this.props.profileStore.business.id)) {
			title = "Profile";
			sub = "View your information"
			tabs = [
				{
					name: 'Profile',
					child: false,
					path: '/profile'
				},
			];
		}
		if(businessInfo){
			let options = businessInfo.communicationOptions;
			hasEmail = options.includes("Email");
			hasPhone = (options.includes("Text") || options.includes("Call"));
			phonePrefer;
			if(hasPhone){
				if (options.includes("Text") && !options.includes("Call")){
					phonePrefer = " (Text Only)";
				} else if (!options.includes("Text") && options.includes("Call")){
					phonePrefer = " (Call Only)";
				} else {
					phonePrefer = "";
				}
			}
		}

		if(businessInfo && businessInfo.type === 'seller') {
			isSeller = true
		}


		return (
			<React.Fragment>
				<Menu />
				<Topbar title={title} sub={sub} tabs={tabs} tabIndex={0} isChildPath={false} />
				<div className="Profile-container">
					{
						isLoadingProfile ? 
						<LoadingSpinnerPage />
						:
						(
							<React.Fragment>
							<div className="top-container">
								<div className="backdrop">
									<div className="info-container">
										{
											editMode?
												<Dropzone avatar={true} onDrop={this.onDrop} onClearDrop={this.onClearDrop} source={profileStore.form.fields.picture.value ? (profileStore.form.fields.picture.value.preview ? profileStore.form.fields.picture.value.preview : profileStore.form.fields.picture.value) : null}/>
												:
												<div className="avatar">
													<img
														src={businessInfo.picture? businessInfo.picture:require('../../assets/placeholder_user.png')}
														alt="profilePhoto"
														style={{ backgroundColor: '#fff' }}
													/>
												</div>
										}
										<div className="text-container web-ft">
											<Typography variant="headline" className="business-name">{businessInfo.name}</Typography>
											{this.getRequestButton(true)}
										</div>
									</div>
								</div>
								<div className={`whitebar ${editMode?'extra-padding':''}`}>
									<div className="text-container mobil-ft">
										<Typography variant="headline" className="business-name">{businessInfo.name}</Typography>
										{this.getRequestButton()}
									</div>
									<div className="tabs">
										<div className="tabs-container">
												<Tabs
													value={tabIndex}
													onChange={this.handleChange}
													indicatorColor={'none'}
													className="tabs-wrapper"
													textColor="primary"
													fullWidth
												>
													{this.profileTabs(businessInfo,tabIndex)}
												</Tabs>
										</div>
									</div>
								</div>
							</div>
							<div className="profile-wrapper">
								{tabIndex === this.tabIndexValues['info'] && 
								<div className="info-wrapper">
									<div className="side">
										<div className="contact">
											<div className="contact-header">
												<Icon className="icon">person</Icon>
												<Typography variant="headline">Contact</Typography>
											</div>
											<div className="contact-body">
												{
													hasPhone && !this.isStranger() ?
													<div className="contact-item">
														<div className="phone-wrapper">
															<Icon className="icon">call</Icon>
															{editMode? '':phonePrefer}
														</div>
														{
															editMode?
																<div className="contact-text">
																	<FormControl fullWidth='true'>
																		<PhoneNumberInput
																			name="phone"
																			label="Phone Number"
																			placeholder="(555) 555-5555"
																			onChange={(val) => profileStore.onFieldChange('phone', val)}
																			inputComponent={TextMaskCustom}
																			fullWidth
																			displaylabel
																			value={profileStore.form.fields.phone.value}
																			error = {profileStore.form.fields.phone.error !== null}
																		/>
																		<FormHelperText error >{profileStore.form.fields.phone.error}</FormHelperText>
																	</FormControl>
																</div>
																:
																<Typography variant="subheading" className="contact-text">{this.formatPhoneNumber(businessInfo.phone)}</Typography>
														}
													</div>
													:
													''
												}
												{
													hasEmail && !this.isStranger()?
													<div className="contact-item">
														<Icon className="icon">email</Icon>
														{
															editMode?
																<div className="contact-text">
																	<FormControl fullWidth='true'>
																		<TextField
																			placeholder="Email"
																			label="Business Email"
																			fullWidth
																			name="email"
																			value={profileStore.form.fields.email.value}
																			onChange={(e) => profileStore.onFieldChange(e.target.getAttribute('name'), e.target.value)}
																			error = {profileStore.form.fields.email.error !== null}
																		/>
																		<FormHelperText >This is the email that will be publicly shown on your profile page and can be different from your login email.</FormHelperText>
																		<FormHelperText error >{profileStore.form.fields.email.error}</FormHelperText>
																	</FormControl>
																</div>
																:
																<Typography variant="subheading" className="contact-text">{businessInfo.email}</Typography>
														}
													</div>
													:
													''
													
												}
												<div className="contact-item">
													<Icon className="icon">location_on</Icon>
													{
														editMode?
															(
																<div className="contact-text">
																	<FormControl fullWidth='true'>
																		<TextField
																			name="streetAddress"
																			placeholder="Street Address"
																			label="Street Address"
																			fullWidth
																			value={profileStore.form.fields.streetAddress.value}
																			onChange={(e) => profileStore.onFieldChange(e.target.getAttribute('name'), e.target.value)}
																			error={profileStore.form.fields.streetAddress.error !== false && profileStore.form.fields.streetAddress.error != null}
																		/>
																		<FormHelperText error >{profileStore.form.fields.streetAddress.error}</FormHelperText>
																	</FormControl>
																	<FormControl fullWidth='true'>
																		<TextField
																			placeholder="City"
																			label="City"
																			fullWidth
																			name="city"
																			value={profileStore.form.fields.city.value}
																			onChange={(e) => profileStore.onFieldChange(e.target.getAttribute('name'), e.target.value)}
																			error={profileStore.form.fields.city.error !== false && profileStore.form.fields.city.error != null}
																		/>
																		<FormHelperText error >{profileStore.form.fields.city.error}</FormHelperText>
																	</FormControl>
																	<FormControl fullWidth>
																		<InputLabel shrink>state</InputLabel>
																		<Select
																			placeholder="State"
																			fullWidth
																			name="state"
																			value={this.state.state}
																			onChange={(e) => this.changeStateValue(e)}
																			error={profileStore.form.fields.state.error !== null}
																		>
																			<MenuItem value="" disabled>
																					<em>State</em>
																			</MenuItem>
																			{this.createMenuItems(States)}
																		</Select>
																		<FormHelperText error >{profileStore.form.fields.state.error}</FormHelperText>
																	</FormControl>
																	<FormControl fullWidth='true'>
																		<TextField
																			placeholder="Zipcode"
																			fullWidth
																			name="zipcode"
																			label="Zipcode"
																			value={profileStore.form.fields.zipcode.value}
																			onChange={(e) => profileStore.onFieldChange(e.target.getAttribute('name'), e.target.value)}
																			error={profileStore.form.fields.zipcode.error !== false && profileStore.form.fields.zipcode.error != null}
																		/>
																		<FormHelperText error >{profileStore.form.fields.zipcode.error}</FormHelperText>
																	</FormControl>
																	
																</div>
															)
															:
															<Typography variant="subheading" className="contact-text">{`${!this.isStranger() ? (businessInfo.streetAddress + ",") : ''} ${businessInfo.city}, ${businessInfo.state} ${businessInfo.zipcode}`}</Typography>
													}
												</div>
												<div className="contact-item">
													<Icon className="icon">access_time</Icon>
													{
														editMode?
															(
																<div className="contact-text">
																	<FormControl fullWidth={true}>
																		<InputMask
																			mask="99:99 am" 
																			maskChar="-"
																			value={this.state.opening}
																			onChange={(e) => this.formatTime('opening', e.target.value)}
																		>
																				{() => <TextField
																						fullWidth
																						label="Opening"
																						type="text"
																						defaultValue="07:30"
																						className={"time"}
																						error={profileStore.form.fields.opening.error !== null}
																						InputLabelProps={{
																								shrink: true
																						}}
																						inputProps={{
																								step: 300 // 5 min
																						}}
																				/>}
																		</InputMask>
																		<FormHelperText error >{profileStore.form.fields.opening.error}</FormHelperText>
																	</FormControl>
																	<FormControl fullWidth={true}>
																		<InputMask
																				mask="99:99 am" 
																				maskChar="-"
																				value={this.state.closing}
																				onChange={(e) => this.formatTime('closing', e.target.value)}
																		>
																				{() => <TextField
																						fullWidth
																						label="Closing"
																						type="text"
																						defaultValue="07:30"
																						className={"time"}
																						error={profileStore.form.fields.closing.error !== null}
																						InputLabelProps={{
																								shrink: true
																						}}
																						inputProps={{
																								step: 300 // 5 min
																						}}
																				/>}
																		</InputMask>
																		<FormHelperText error >{profileStore.form.fields.closing.error}</FormHelperText>
																	</FormControl>
																</div>
															)
															:
															<Typography variant="subheading" className="contact-text">{`${moment(businessInfo.opening+'','hh:mm').format('hh:mm A')} - ${moment(businessInfo.closing+'','hh:mm').format('hh:mm A')}`}</Typography>
													}
												</div>
											</div>
										</div>
										<div className="contact">
											<div className="contact-header">
												<Icon className="icon">forum</Icon>
												<Typography variant="headline">Communications</Typography>
											</div>
											<div className="contact-body">
												{
													editMode?
													<ProfilePanelCheckBox
														bottomLabel='Select call/text if you want make your phone number available to buyers who have purchased an item from you. Select email if you also want to share your business email with them.'
														coreValues={CommunicationOptions}
														formOtherValue={profileStore.form.fields.communicationOptionsOther.value}
														formArraySting='communicationOptions'
														formOtherSting='communicationOptionsOther'
														formArrayError={profileStore.form.fields.communicationOptions.error}
														formOtherError={profileStore.form.fields.communicationOptionsOther.error}
														updateArrayError={(val) => profileStore.form.fields.communicationOptions.error = val}
														updateOtherError={(val) => profileStore.form.fields.communicationOptions.error = val}
													/>
													:
													<Grid container={true} className="food-chips">
														{this.createFoodOptionLabels(businessInfo.communicationOptions, false)}
													</Grid>
												}
											</div>
										</div>
										<div className="contact">
											<div className="contact-header">
												<Icon className="icon">credit_card</Icon>
												<Typography variant="headline">Payment Methods</Typography>
											</div>
											<div className="contact-body">
												{
													editMode?
													<ProfilePanelCheckBox
														bottomLabel='Select which payment methods you accept for orders.'
														coreValues={PaymentOptions}
														formOtherValue={profileStore.form.fields.paymentOptionsOther.value}
														formArraySting='paymentOptions'
														formOtherSting='paymentOptionsOther'
														formArrayError={profileStore.form.fields.paymentOptions.error}
														formOtherError={profileStore.form.fields.paymentOptionsOther.error}
														updateArrayError={(val) => profileStore.form.fields.paymentOptions.error = val}
														updateOtherError={(val) => profileStore.form.fields.paymentOptionsOther.error = val}
													/>
													:
													<Grid container={true} className="food-chips">
														{this.createFoodOptionLabels(businessInfo.paymentOptions, false)}
													</Grid>
												}
											</div>
										</div>
										{
											(!this.restrictProducts() && (profileUID && (profileUID !== this.props.profileStore.business.id))) &&
											<React.Fragment>
										<div className="delivery">
											<div className="delivery-header">
												<Icon className="icon">local_shipping</Icon>
												<Typography variant="headline">Food Options</Typography>
											</div>
											{
												editMode?
												<MuiThemeProvider>
													<ChipInput
														value={profileStore.form.fields.foodOptions.value}
														onRequestAdd={(chip) => this.handlePaste(chip)}
														onRequestDelete={(chip, index) => this.deleteChip(chip,index)}
														dataSource={Object.values(this.noOther())}
														fullWidth={true}
														hintText='This text is here to help you.'
														errorText={profileStore.form.fields.foodOptions.error !== null}
														newChipKeyCodes={[13, 188]}
														allowDuplicates={false}
														onUpdateInput={(txt) => this.updateInput(txt)}
														// onBlur={this.convertInput}
														chipRenderer={(
															{
																value,
																handleClick,
																handleRequestDelete,
																defaultStyle
															},
															key
														) => (
															<Chip
																key={key}
																style={{...defaultStyle}}
																onClick={handleClick}
																onDelete={handleRequestDelete}
																label={value}
															/>
														)}
													/>
												</MuiThemeProvider>
												:
												<Grid container={true} className="food-chips">
													{this.createFoodOptionLabels(businessInfo.foodOptions)}
												</Grid>
											}
										</div>
										<div className="description">
											<div className="description-header">
												<Icon className="icon">description</Icon>
												<Typography variant="headline">Description</Typography>
											</div>
											{
												editMode?
													<FormControl fullWidth={true}>
														<TextField
															name="description"
															fullWidth
															multiline
															rows="5"
															placeholder="Write a description of your business here."
															value={profileStore.form.fields.description.value}
															onChange={(e) => profileStore.onFieldChange(e.target.getAttribute('name'), e.target.value)}
															error={profileStore.form.fields.description.error !== false && profileStore.form.fields.description.error != null}
														/>
														<FormHelperText error >{profileStore.form.fields.description.error}</FormHelperText>
													</FormControl>	
													:
													<Typography variant="subheading">{businessInfo.description}</Typography>
											}
										</div>
										</React.Fragment>
										}
									</div>
									<div className="main">
										{
											(this.restrictProducts() || !(profileUID && (profileUID !== this.props.profileStore.business.id))) &&
											<React.Fragment>
										<div className="delivery">
											<div className="delivery-header">
												<Icon className="icon">local_shipping</Icon>
												<Typography variant="headline">Food Options</Typography>
											</div>
											{
												editMode?
												<MuiThemeProvider>
													<ChipInput
														value={profileStore.form.fields.foodOptions.value}
														onRequestAdd={(chip) => this.handlePaste(chip)}
														onRequestDelete={(chip, index) => this.deleteChip(chip,index)}
														dataSource={Object.values(this.noOther())}
														fullWidth={true}
														hintText='This text is here to help you.'
														errorText={profileStore.form.fields.foodOptions.error !== null}
														newChipKeyCodes={[13, 188]}
														allowDuplicates={false}
														onUpdateInput={(txt) => this.updateInput(txt)}
														// onBlur={this.convertInput}
														chipRenderer={(
															{
																value,
																handleClick,
																handleRequestDelete,
																defaultStyle
															},
															key
														) => (
															<Chip
																key={key}
																style={{...defaultStyle}}
																onClick={handleClick}
																onDelete={handleRequestDelete}
																label={value}
															/>
														)}
													/>
												</MuiThemeProvider>
												:
												<Grid container={true} className="food-chips">
													{this.createFoodOptionLabels(businessInfo.foodOptions)}
												</Grid>
											}
										</div>
										<div className="description">
											<div className="description-header">
												<Icon className="icon">description</Icon>
												<Typography variant="headline">Description</Typography>
											</div>
											{
												editMode?
													<FormControl fullWidth={true}>
														<TextField
															name="description"
															fullWidth
															multiline
															rows="5"
															placeholder="Write a description of your business here."
															value={profileStore.form.fields.description.value}
															onChange={(e) => profileStore.onFieldChange(e.target.getAttribute('name'), e.target.value)}
															error={profileStore.form.fields.description.error !== false && profileStore.form.fields.description.error != null}
														/>
														<FormHelperText error >{profileStore.form.fields.description.error}</FormHelperText>
													</FormControl>	
													:
													<Typography variant="subheading">{businessInfo.description}</Typography>
											}
										</div>
										{!isSeller && (
											<div className="description orderNotes">
												<div className="description-header orderNotes-header">
													<Icon className="icon">notes</Icon>
													<Typography variant="headline">Order Notes</Typography>
												</div>
												{editMode ?
														<FormControl fullWidth={true}>
															<TextField
																name="orderNotes"
																fullWidth
																multiline
																rows="5"
																placeholder="Add notes to your orders"
																value={profileStore.form.fields.orderNotes.value}
																onChange={(e) => profileStore.onFieldChange(e.target.getAttribute('name'), e.target.value)}
																error={profileStore.form.fields.orderNotes.error !== false && profileStore.form.fields.orderNotes.error != null}
															/>
															<FormHelperText error >{profileStore.form.fields.orderNotes.error}</FormHelperText>
														</FormControl>	
														:
															<Typography variant="subheading">{businessInfo.orderNotes}</Typography>
												}
											</div>
										)}
										</React.Fragment>
										}
										{
											(!this.restrictProducts() && (profileUID && (profileUID !== this.props.profileStore.business.id))) &&
											<NotesPage
												defaultValues={[profileUID]}
											/>
										}
									</div>
								</div>}
								{tabIndex === this.tabIndexValues['products'] && 
								<ul className="deals-list"> 
									{this.getDealItems()}
								</ul>
								}
								{tabIndex === this.tabIndexValues['certifications'] &&
									<div className="info-wrapper">
										<div className="main full">
											<div className="certification">
												<div className="certification-header">
													<Icon className="icon">star_rate</Icon>
													<Typography variant="headline">Certifications</Typography>
													{
														editMode &&
															<Button variant="outlined" className="add-cert-button" onClick={()=>this.setState({modalOpen: true})} ><Icon>add</Icon>Add</Button>
													}
												</div>
												{this.getCerificationLists()}
											</div>
											{this.state.modalOpen && <UploadCertificationModal open={this.state.modalOpen} onClose={()=>this.setState({modalOpen: false})}/>}
										</div>
									</div>
								}
								{tabIndex === this.tabIndexValues['notes'] &&
								<div className="info-wrapper">
									<div className="main full">
										<NoteContainer>
											{this.getNotes()}
										</NoteContainer>
									</div>
								</div>
								}
							</div>
							{this.state.messageModalOpen && <MessagesModal business={businessInfo} open={this.state.messageModalOpen} onClose={() => this.setState({ messageModalOpen: false })}/>}
							</React.Fragment>
						)
					}
				</div>
				{
          currentListingGroup &&
          <ProductListingGroupModal
            defaultListingID={this.state.currentListing.id}
            productListingGroup={currentListingGroup}
            open={currentListingGroup !== null}
            onClose={() => this.setState({currentListingGroup: null})}
            type={`basket${ this.restrictProducts() ? '_restricted' : ''}`}
          />
        }
			</React.Fragment>
		)
	}
}

export default withRouter(Profile)
