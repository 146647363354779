import React, { Component, Fragment } from 'react';
import Dropzone from 'react-dropzone';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Button from '../atomic/buttons/Button';
import Typography from '@material-ui/core/Typography';
var findOrientation = require('exif-orientation');

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const DropArea = styled(Dropzone)`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
`;

const DropImg = styled.img`
    max-width: 10%;
    max-height: 10%;
    ${props => {
        return css`
            transform: rotate(${props.rotation}deg) scale(10)
        `
    }}
`;

const DeleteButton = styled(Button)`
    ${props => {
        if(props.visible){
            return css`
                display: flex;
            `
        } else {
            return css`
                display: none;
            `
        }
    }}
    position: absolute;
    color: #fff;
    bottom: 0;
    right: 0;
    max-width: 40px;
    min-width: 40px;
    max-height: 30px;
    min-height: 30px;
    padding: 0;
    box-shadow: none;
`;

const UploadButton = styled(Button)`
    ${props => {
        if(props.visible){
            return css`
                display: block;
            `
        } else {
            return css`
                display: none;
            `
        }
    }}
    max-height: 30px;
    min-height: 30px;
    padding: 0 8px;
    margin: auto;
    text-transform: none;
`;

const DeleteIcon = styled(Icon)`
    font-size: 20px;
`;

const PlaceholderContainer = styled.div`
    ${props => {
        if(props.visible){
            return css`
                display: none;
            `
        } else {
            return css`
                display: block;
            `
        }
    }}
    position: absolute;
    opacity: 0.3;
    text-align: center;
`;

const SplashIcon = styled(Icon)`
    font-size: 70px;
`;

const SplashText = styled(Typography)`
    font-size: 1em;
`;

class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {rotation: 0}
        this.zone = React.createRef();
    }

    render() {
        let { onDrop, onClearDrop, source, uploadButton, setRotation, srcRotation, placeholderIcon, ...other } = this.props;
        let newOnDrop = (file) => {
            findOrientation(file[0],(err,orientation) => {
                if (err) {
                    setRotation(0)
                    console.log(err);
                    return;
                }
                setRotation(orientation.rotate)
            });
            onDrop(file);
        }
        return (
            <Container>
                <DropArea ref={(node) => { this.zone = node; }} onDrop={newOnDrop} multiple={false} {...other}>
                    <PlaceholderContainer visible={source}>
                        {
                            placeholderIcon &&
                            <SplashIcon>photo</SplashIcon>
                        }
                        <SplashText variant="subheading">
                            click to upload photo
                        </SplashText>
                    </PlaceholderContainer>
                    <DropImg rotation={srcRotation} src={source}/>
                    <DeleteButton variant="contained" color="secondary" onClick={(e) => onClearDrop(e) } visible={source}>
                        <DeleteIcon>delete</DeleteIcon>
                    </DeleteButton>
                </DropArea>
                <UploadButton variant="outlined" color="primary" onClick={() => { this.zone.open() }} visible={uploadButton}>
                    Upload Photo
                </UploadButton>
            </Container>
        )
    }
}

ImageUpload.propTypes = {
    onDrop: PropTypes.func.isRequired,
    onClearDrop: PropTypes.func.isRequired,
    source: PropTypes.string,
    uploadButton: PropTypes.bool,
    placeholderIcon: PropTypes.bool
};

ImageUpload.defaultProps = {
    uploadButton: false,
    placeholderIcon: true,
};

export default ImageUpload;