import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import logoTitle from '../../assets/FreshSpire-Brandmark_Combination-Green.png';
import { FTSF1 } from '../../components/FirstTimeSurveyForm';
import { observer, inject } from 'mobx-react';
import { userStore, surveyStore} from '../../stores';
import { message } from 'antd';
import Button from '@material-ui/core/Button';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import {LoadingSpinnerButton} from '../../components/LoadingSpinner'
import DatabaseClient from '../../core/DatabaseClient';
import WelcomeScreen from '../../components/organisms/WelcomeScreen';
import LogoutButton from '../../components/atomic/buttons/LogoutButton';
import { UserService } from "core/api"

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
});

export const firstTimeSurveyStore = surveyStore;

@inject(() => ({
    store: firstTimeSurveyStore
}))
@observer
class FirstTimeSurvey extends React.Component {
  state = {
    value: 0,
    buttonValue: "Next",
    pending: false
  };

  componentWillMount() {
    this.props.store.resetForm();
  }

  async componentDidMount() {
    if (!userStore.isAuthenticated) {
      message.error('No user is logged in!');
      this.props.history.push('/login');
      return;
    }

    const user = await UserService.get(userStore.user.id);

    if (user.isActive()) {
      this.props.history.push('/login');
      return;
    }
  }

  handleChange = (event, value) => {
    this.setState({ value: value });
    if (value == 2) {
      this.state.buttonValue = "Submit"
    } else {
      this.state.buttonValue = "Next"
    }
  };

  async logout() {
    userStore.removeUser();
    await userStore.logout();
  }

  handleChangeIndex = index => {
    this.setState({ value: index });
    if (index == 2) {
       this.state.buttonValue = "Submit"
    } else {
      this.state.buttonValue = "Next"
    }
  };

  handleButtonClick = event => {
    this.setState({pending: true});
    this.props.store.createEntity()
      .then(() => {
        this.setState({pending: false});
        message.success('Form submitted successfully!');
        this.props.history.push('/welcome');
      })
      .catch( (e) => {
        this.setState({pending: false});
        message.error(`${e} Please fix the highlighted fields.`)
      })
    window.scroll(0, 0);
  }
  
  render() {
    const { classes, theme } = this.props;

    return (
      <div className="survey-container">
        <WelcomeScreen
            data={
                [
                  {
                    "backgroundColor": "whitesmoke",
                    "textColor": "#344115",
                    "text": "Welcome to Freshspire!  Thank you for signing up.",
                    "imageAnimation": "fadeInUp",
                    "textAnimation": "fadeInLeft",
                    "image": require('../../assets/logo.png'),
                    size: "small"
                  },
                  {
                    "backgroundColor": "whitesmoke",
                    "textColor": "#344115",
                    "text": "These next few steps will help us create your profile and will take a moment to complete.  If you wish to start now, click the button below.  Otherwise, you can return to this page at any time simply by logging back into Freshspire.",
                    "imageAnimation": "tada",
                    "textAnimation": "fadeIn",
                    "image": require('../../assets/logo.png'),
                    size: "small",
                    showActionButton: true,
                    showLogoutButton: true
                  }
                ]
            }
          />

      <div className="form-container">
        {/* <img src={logoTitle} alt="FreshSpire" className="img-format-sign"/> */}
        <div className="title-wrapper">
          <Typography variant="title" color="primary" className="survey-head">
            {`Tell Us About Your Company`}
          </Typography>
        </div>
        <div className="sign-up-wrapper">
          <AppBar position="static" color="green">
            <Tabs
              value={0}
              indicatorColor={"secondary"}
              textColor={"secondary"}
              fullWidth
            >
              <Tab label="Business Info"/>
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={0}
            action={actions => {
              this.swipeableActions = actions;
            }}
            style={{overflow:'hidden'}}
            animateHeight
          >
            <TabContainer dir={theme.direction}><FTSF1/></TabContainer>
          </SwipeableViews>
        </div>
        <div className="btn-submit-sign ">
          <Button className="action" variant="contained" color="primary" disabled={this.state.pending} onClick={this.handleButtonClick}> {this.state.pending ? LoadingSpinnerButton() : 'Submit'} </Button>
          <LogoutButton withText/>
        </div>
      </div>
      </div>
    );
  }
}

FirstTimeSurvey.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(withRouter,withStyles(styles, { withTheme: true }))(FirstTimeSurvey);
