import { observable, action, toJS, computed } from 'mobx'
import { productSchema as schema } from '../schemas'
import joi from 'joi-browser'
import { PackFormat } from '.';

class Product {
    @observable delivery
    @observable description
    @observable id
    @observable foodCategory
    @observable local
    @observable organic
    @observable name
    @observable packFormats
    @observable pickup
    @observable picture
    @observable seller
    @observable quantity
    @observable timestamp
    @observable uid

    /**
     * May thrown an error if pack formats doesn't match up with quantity base units
     * @param {*} delivery 
     * @param {*} description 
     * @param {*} id 
     * @param {*} foodCategory 
     * @param {*} local 
     * @param {*} organic 
     * @param {*} name 
     * @param {*} packFormats 
     * @param {*} pickup 
     * @param {*} picture 
     * @param {*} quantity 
     * @param {*} timestamp 
     * @param {*} uid 
     */
    constructor(delivery = false, description = "", id = null, foodCategory = null, local = false, organic = false, 
                name = "", packFormats = [], pickup = false, picture = null, seller = null, quantity = {}, timestamp = 0, uid = null) {
        this.delivery = delivery;
        this.description = description;
        this.id = id;
        this.foodCategory = foodCategory;
        this.local = local;
        this.organic = organic;
        this.name = name;
        this.pickup = pickup;
        this.seller = seller;
        this.picture = picture;
        this.quantity = quantity;
        this.timestamp = timestamp;
        this.uid = uid;
        this.packFormats = {};
        if (packFormats) packFormats.forEach((format) => this.addPackFormat(format));
    }

    /**
     * May thrown an error if pack format has no corresponding base unit in the product
     * @param {*} packFormat 
     */
    @action
    addPackFormat(packFormat) {
        if (!Object.keys(this.quantity).includes(packFormat.conversionUnit)) {
            throw new Error(`Pack format ${packFormat.unit} for ${this.name} has not been converted to a valid base unit set in quantity: ${Object.keys(this.quantity).toString()}`);
        }
        this.packFormats[packFormat.unit] = {
            conversionRatio: packFormat.conversionRatio,
            conversionUnit: packFormat.conversionUnit
        }
    }

    @action
    removePackFormat(unit) {
        if (unit in this.packFormats) {
            delete this.packFormats[unit];
        }
        // const newPackFormats = {};
        // for (let key in this.packFormats) {
        //     if (key !== unit) {
        //         newPackFormats[key] = this.packFormats[key];
        //     }
        // }
        // this.packFormats = newPackFormats;
    }

    @computed get packFormatsArr() {
        return Object.keys(this.packFormats).map((key) => new PackFormat(key, this.packFormats[key].conversionRatio, this.packFormats[key].conversionUnit));
    }

    @computed set packFormatsArr(packFormats) {
        this.packFormats = {};
        if (packFormats) packFormats.forEach((format) => this.addPackFormat(format));
    }

    @action
    addBaseUnit(unit, quantity) {
        this.quantity[unit] = quantity;
    }

    /**
     * Will remove any associated pack formats that used the base unit
     */

     @action
    removeBaseUnit(unit) {
        // const newQuantity = {};
        // for (let key in this.quantity) {
        //     if (key !== unit) {
        //         newQuantity[key] = this.quantity[key];
        //     }
        // }
        // this.quantity = newQuantity;
        // const newPackFormats = {};
        // for (let key in this.packFormats) {
        //     if (unit !== this.packFormats[key].conversionUnit) {
        //         newPackFormats[key] = this.packFormats[key];
        //     }
        // }
        // this.packFormats = newPackFormats;
        if (unit in this.quantity) {
            delete this.quantity[unit];
            for (let key in this.packFormats) {
                if (unit === this.packFormats[key].conversionUnit) {
                    delete this.packFormats[key];
                }
            }
        }
    }

    formatForDB() {
        if (!this.uid || this.uid.length === 0) {
            throw new Error("No associated user");
        }
        if (!(this.delivery || this.pickup)) {
            throw new Error("Item must be available for either pickup or delivery or both");
        }
        this.verifyUnits();
        let data = observable({
            delivery: this.delivery,
            description: this.description,
            foodCategory: this.foodCategory,
            local: this.local,
            organic: this.organic,
            name: this.name,
            packFormats: toJS(this.packFormats),
            pickup: this.pickup,
            quantity: this.quantity,
            timestamp: this.timestamp,
            uid: this.uid
        });
        const jsData = toJS(data);
        const result = joi.validate(jsData, schema);
        if (result.error) {
            throw new Error(result.error.details[0].message)
        }
        return jsData;
    }

    verifyUnits() {
        // Rules for pack formats and quantity units
        // availableBaseUnits = foodUnits.remove([...usedBaseUnits, ...usedPackUnits])
        // availablePackUnits = foodUnits.remove([...usedBaseUnits, ...usedPackUnits])
        // conversionUnits = baseUnits

        const baseUnits = this.getBaseUnits();
        const packUnits = this.getPackUnits();

        if (baseUnits.length === 0) {
            throw new Error("Must specify a quantity");
        }

        // Make sure no two base units repeat
        for (let i = 0; i < baseUnits.length; i++) {
            for (let j = i + 1; j < baseUnits.length; j++) {
                if (baseUnits[i] === baseUnits[j]) {
                    throw new Error(`Error: Duplicate unit ${baseUnits[j]} exists in quantity for ${this.name}`);
                }
            }
        }

        // Make sure no two pack units repeat
        for (let i = 0; i < packUnits.length; i++) {
            for (let j = i + 1; j < packUnits.length; j++) {
                if (packUnits[i] === packUnits[j]) {
                    throw new Error(`Error: Duplicate packing unit ${packUnits[i]} found in pack formats for ${this.name}`);
                }
            }
        }

        // Make sure no pack unit is found in base unit and vice versa
        for (let i = 0; i < baseUnits.length; i++) {
            for (let j = 0; j < packUnits.length; j++) {
                if (baseUnits[i] === packUnits[j]) {
                    throw new Error(`Error: ${packUnits[j]} found as both a base unit and a packing unit for ${this.name}`);
                }
            }
        }

        // Verify packFormats conversion units (must be a base unit)
        // Verify packFormats conversion ratios
        Object.keys(this.packFormats).forEach((unit) => {
            if (!Object.keys(this.quantity).includes(this.packFormats[unit].conversionUnit)) {
                throw new Error(`Pack format ${unit} for ${this.name} has not been converted to a valid base unit set in quantity: ${Object.keys(this.quantity).toString()}`);
            }
            if (!Number.isInteger(this.packFormats[unit].conversionRatio)) {
                throw new Error(`Pack format ${unit} for ${this.name} must have an integer conversion ratio but found ${this.packFormats[unit].conversionRatio}`);
            }
            if (this.packFormats[unit].conversionRatio < 1) {
                throw new Error(`Invalid conversion ratio for the pack format ${unit} in ${this.name}`);
            }
        });

        // Quantity should be in format string: number
        Object.keys(this.quantity).forEach((key) => {
            if (this.quantity[key] !== null) {
                if (!Number.isInteger(this.quantity[key])) {
                    throw new Error(`Quantity ${this.quantity[key]} for ${key} in ${this.name} must be an integer`);
                }
                else if (this.quantity[key] < 0) {
                    throw new Error(`Quantity ${this.quantity[key]} for ${key} in ${this.name} must be 0 or greater`);
                }
            }
        })
    }

    validate() {
        const result = joi.validate(this.formatForDB(), schema);
        return !result.error;
    }

    getUnitsArr() {
        // Available units are those in quantity + those in pack formats
        return [...this.getBaseUnits(), ...this.getPackUnits()];
    }

    getBaseUnits() {
        return Object.keys(this.quantity);
    }

    getPackUnits() {
        return Object.keys(this.packFormats);
    }
    
    getPriceArr() {

    }

    getDisplayProps() {
        const display = {};

        // Price
        
    }

    getPictureSrc() {
        if (this.picture) {
            return this.picture.url;
        }
        else {
            return require('../../assets/placeholder.png');
        }
    }

    getBaseQuantity(searchUnit) {
        const unit = searchUnit;
        if (unit in this.packFormats) {
            if (this.quantity[this.packFormats[unit].conversionUnit] === null) {
                return "Unlimited";
            }
            return Math.floor(this.quantity[this.packFormats[unit].conversionUnit] / this.packFormats[unit].conversionRatio);
        }
        else if (unit in this.quantity) {
            if (this.quantity[unit] === null) {
                return "Unlimited";
            }
            return this.quantity[unit];
        }
        else {
            return 0;
        }
    }
}

Product.displayName = "Product";
export default Product;
