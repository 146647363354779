import { action, observable, computed} from 'mobx'
import DatabaseClient from '../../core/DatabaseClient'
import Geography from '../../core/utilities/Geography'
import { profileStore, clientSearchStore } from '../index';
import { BusinessService, InvitationService } from "core/api";

class ClientListStore {
    @observable loading = false;
    @observable clientList = [];  // Limited by distance
    @observable totalClientList = []; // Not limited by distance
    @observable pendingList = [];
    @observable pendingInvites = [];
    @observable invitationList = [];
    @observable unrelatedList = [];
    @observable organizationList = [];
    @observable affOrganizationList = [];

    @computed get pendingEmails() {
        if (!this.pendingInvites)
            return this.pendingInvites;

        let _emails = this.pendingInvites.map( item => item.email );
        return _emails;
    }

    @action
    async init() {
        await this.getAllClients();
    }

    getClientFromList(clientID, list) {
        for (const client of list) {
            if (client.id === clientID) {
                return client;
            }
        }
        return null;
    }

    @action
    async refresh() {
		const biz = profileStore.business;
		// New business
		if (!biz) {
			return;
		}
		const coords = biz.coordinates;
        this.clientList.replace([]);
        this.unrelatedList.replace([]);
        this.organizationList.replace([]);
        let pendingBundle = [];
        let invitationBundle = [];
        let pendingObjects = [];
        let invitationObjects = [];

        await Promise.all([this.filterList(), this.getAllClients()]);
        [pendingBundle, invitationBundle] = await Promise.all([this.getPotentialClientUIDs(biz.isBuyer()?'buyer':'seller'), this.getPotentialClientUIDs(biz.isBuyer()?'seller':'buyer')]);
        
        [pendingObjects, invitationObjects] = await Promise.all([this.getBusinessesInfo(pendingBundle), this.getBusinessesInfo(invitationBundle)]);
        this.pendingList.replace(pendingObjects.map(x => {
            x['distance'] = Number(Geography.getDistanceFromLatLonInMi(coords[0],coords[1],x.coordinates[0],x.coordinates[1]).toFixed(1))
            return x;
        }));
        this.invitationList.replace(invitationObjects.map(x => {
            x['distance'] = Number(Geography.getDistanceFromLatLonInMi(coords[0],coords[1],x.coordinates[0],x.coordinates[1]).toFixed(1))
            return x;
        }));
        await this.refreshPendingInvitations(false);
    }

    @action
    async getClientUIDs() {
        let relatedBundle = [];
        let biz = profileStore.business;

        if (!biz) {
			return;
		}

        if(biz.isBuyer()){
            relatedBundle = await DatabaseClient.getRelatedUIDs(biz.id,'buyerUID','sellerUID');
        } else {
            relatedBundle = await DatabaseClient.getRelatedUIDs(biz.id,'sellerUID','buyerUID');
        }

        return relatedBundle;
    }

    @action
    async refreshPendingInvitations(clearObservable = true) {
        if(clearObservable){
            this.pendingInvites = [];
        }
        let biz = profileStore.business;
        const res = await InvitationService.getAll({
            sender: biz.id,
            status: {
                in: ["sent", "created"]
            }
        })
        console.log(res);
        this.pendingInvites.replace(res);
    }

    @action
    async getPotentialClientUIDs(sender) {
        let potentialBundle = [];
        let biz = profileStore.business;
        if (!biz) {
			return;
		}

        if(biz.isBuyer()){
            potentialBundle = await DatabaseClient.getPotentialClientUIDs(biz.id,'buyerUID','sellerUID',sender);
        } else {
            potentialBundle = await DatabaseClient.getPotentialClientUIDs(biz.id,'sellerUID','buyerUID',sender);
        }
        return potentialBundle;
    }

    @action
    async filterList(){
		const biz = profileStore.business
		if (!biz) {
			return;
		}
        const coords = biz.coordinates;
        let unrelatedBundle = [];
        let relatedBundle = [];
        let relatedUIDs = await this.getClientUIDs();
        if(biz.isBuyer()){
            let completeSellerList = await BusinessService.getAll({
                center: coords,
                radius: clientSearchStore.queryDistance,
                type: 'seller'
            });
            for( let x of completeSellerList ){
                x['distance'] = Number(Geography.getDistanceFromLatLonInMi(coords[0],coords[1],x.coordinates[0],x.coordinates[1]).toFixed(1))
                if (!relatedUIDs.includes(x.id)){
                    unrelatedBundle.push(x);
                } else {
                    x['affiliated'] = true;
                    relatedBundle.push(x);
                }
            }
        } else {
            let completeBuyerList = await BusinessService.getAll({
                center: coords,
                radius: clientSearchStore.queryDistance,
                type: 'buyer'
            });
            for( let x of completeBuyerList ){
                x['distance'] = Number(Geography.getDistanceFromLatLonInMi(coords[0],coords[1],x.coordinates[0],x.coordinates[1]).toFixed(1))
                if (!relatedUIDs.includes(x.id)){
                    unrelatedBundle.push(x);
                } else {
                    x['affiliated'] = true;
                    relatedBundle.push(x);
                }
            }
        }

        const unrelatedListOfOrgs = this.coupleStores(unrelatedBundle);
        
        
        this.organizationList.replace(unrelatedListOfOrgs)
        this.unrelatedList.replace(unrelatedBundle);
        
        const listOfOrgs = this.coupleStores(relatedBundle);
        this.clientList.replace(listOfOrgs);
    }

    @action
    async getAllClients() {
		const biz = profileStore.business
		if (!biz) {
			return;
		}
        const coords = biz.coordinates;
        let relatedUIDs = await this.getClientUIDs();
        const bizPromises = [];
        for (let i = 0; i < relatedUIDs.length; i++) {
            bizPromises.push(BusinessService.get(relatedUIDs[i]));
        }
        const businesses = await Promise.all(bizPromises);
        const filtered = businesses.filter((biz) => !!biz);
        const withDist = filtered.map(x => {
            x['distance'] = Number(Geography.getDistanceFromLatLonInMi(coords[0],coords[1],x.coordinates[0],x.coordinates[1]).toFixed(1))
            return x;
        })
        const sorted = withDist.sort((a, b) => a.name.localeCompare(b.name));
        this.totalClientList.replace(sorted);
        
        const listOfOrgs = this.coupleStores(sorted);
        
        this.affOrganizationList.replace(listOfOrgs);
    }

    coupleStores = (sorted) => {
        const listOfOrgs = [];
        const orgIDs = [];
        for(let i = 0; i < sorted.length; i++) {
            const item = sorted[i];
            if(item.organization) {
                const itemOrgID = item.organization.id;
                if(!orgIDs.includes(itemOrgID)) {
                    orgIDs.push(itemOrgID);
                }
                const index = orgIDs.indexOf(itemOrgID);
                if(!listOfOrgs[index]) {
                    listOfOrgs[index] = {
                        id:  itemOrgID,
                        name: item.organization.name,
                        picture: item.organization.picture,
                        stores: [item.id]
                    };
                } else {
                    listOfOrgs[index].stores.push(item.id);
                }
            }
        }
        
        return listOfOrgs;
    }

    async getBusinessesInfo(Bundle) {
        let Businesses = await Promise.all(Bundle.map( async (businessUID) => {
            let info = await BusinessService.get(businessUID);
            return info;
        }))
        return Businesses.filter(x => x !== null).sort( (a, b) => { return a.name.localeCompare(b.name)});
    }
}

const ClientlistStore = new ClientListStore();
export default ClientlistStore;
