import React, { Component } from 'react'
import styled, { css, withTheme } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IntegerSelector from '../../atomic/selectors/IntegerSelector'
import TextField from '@material-ui/core/TextField'
import Controller from './Controller'
import Popup from 'reactjs-popup'
import Content from '../modals/ProductListingGroupModal/Content';
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import ProfileLink from '../../atomic/ProfileLink'
import breakpoint from 'styled-components-breakpoint'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import BottomBar from '../../CommonBottomBar'
import ShoppingBasket from '@material-ui/icons/ShoppingBasket'
import Button from '../../atomic/buttons/Button'
import {inject, observer} from 'mobx-react'
import { message } from 'antd'
import DatabaseClient from '../../../core/DatabaseClient'
import moment from 'moment'
import { ShoppingCartItem, Product } from '../../../core/model';
import GenericModal from '../GenericModal';
import { unmarshal } from '../../../core/utilities/ModelUtils'

const StyledModal = styled(GenericModal)`
    width: 750px !important;
    @media (max-width: 750px) {
        width: 550px;
    }
`;

// modal-generic
const StyledPopup = styled(Popup)`
    width: 90% !important;
    max-width: 800px !important;
    padding: 0 !important;
    background: rgba(0,0,0,0) !important;
    border-style: none !important;
    font-family: ${props => props.theme.fonts.ms_font300};
    ${breakpoint('mobile_min', 'mobile_max')`
      width: 100vw !important;
    `}

    ${breakpoint('tablet_min', 'tablet_max')`
      max-width: 500px !important;
    `}
`

// modal-scroll
const ScrollContainer = styled.div`
  overflow-y: scroll;‚
  background: rgba(0,0,0,0);
  display: flex;    
  flex-direction: column;
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpoint('mobile_min', 'mobile_max')`
    padding-bottom: 0;
  `}
`

// popup
const Container = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  margin-bottom: 0;
  ${breakpoint('mobile_min', 'mobile_max')`
    padding-bottom: 0;
    margin: 0;
  `}
`


// modal-container
const ModalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 48px 1rem 1.4rem;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-bottom: auto;
  ${breakpoint('mobile_min', 'mobile_max')`
    flex-direction: column;
    min-height: calc(100vh - 40px);
    margin: 40px 0 0;
    padding-bottom: 112px;
    padding-top: 56px;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-grow: 1;
  `}

  ${breakpoint('tablet_min', 'tablet_max')`
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
  `}
`

// deal-display
const Display = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  ${breakpoint('mobile_min', 'tablet_max')`
    width: 100%;
    padding-top: 0;
  `}
`

// img-crop
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 220px;
  max-width: 220px;
  height: 50vw;
  width: 50vw;
  margin: 0 auto !important;
  overflow: hidden;
`

//modal-img
const Image = styled.img`
  max-width: 10%;
  max-height: 10%;
  transform: scale(10);
  border-radius: 1.5px;
`

//info
const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-left: auto;
  ${breakpoint('mobile_min', 'tablet_max')`
    width: 100%;
    padding-top: 50px;
  `}
`

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0 !important;
  ${breakpoint('mobile_min', 'mobile_max')`
    display: none !important;
  `}
`

const Title = styled(Typography)`
  max-width: 300px !important;
  margin: 7px auto !important;
  font-size: 1.4em !important;
`

const TopBarContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  top: env(safe-area-inset-top);
  width: 100%;
  z-index: 300;
  ${breakpoint('mobile_min', 'mobile_max')`
    display: block;
  `}
`

const ProfileText = styled.div`
  margin: 7px auto;
  font-size: 1.1em;
  text-align: center;
`;

const ModalTitle = styled.div`
  margin: 7px auto;
  font-size: 1.3em;
  font-family: ${props => props.theme.fonts.ms_font500};
`;

const StyledAppBar = styled(AppBar)`
  background-color: #fff !important;
`

const StyledToolbar = styled(Toolbar)`
  padding-left: 0 !important;
`

const StyledBottomBar = styled(BottomBar)`
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;

  &.highest-stack {
      z-index: 3;
  }
  ${breakpoint('mobile_min', 'mobile_max')`
    display: block;
  `}
`

const SubmitButtonContainer = styled.div`
  width: 100%;
  margin: 0;
  margin-bottom: auto;
  padding: 0;

  ${breakpoint('mobile_min', 'mobile_max')`
    display: none;
    position: fixed;
    bottom: 0;
  `}
`

const SubmitButton = styled(Button)`
  width: 100% !important;
  height: 50px !important;
  font-size: 1.5em !important;
  font-family: ${props => props.theme.fonts.ms_font700} !important;
  text-transform: none !important;
  text-align: center !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  background: ${props => props.theme.colors.spearmint_main} !important;  

  ${breakpoint('mobile_min', 'mobile_max')`
    border-radius: 0 !important;
    font-size: 1.3em !important;
  `}
`

@inject('profileStore', 'orderStore')

class RequestModal extends Component {

  constructor(props) {
    super(props)
    const request = props.request
    this.state = {
      quantityRequested: request.quantityRequested,
      price: request.price,
      needByDate: request.needByDate,
      exchange: request.exchange
    }
  }

  onSubmit = async () => {
    let { price, quantityRequested, needByDate, exchange } = this.state;
    let totalCost = price * quantityRequested; // Might be 0 if price is null

    // Scenarios
    // RQ is turned on, price is 0 or null -> price should be set to null and so should totalCost
    // RQ is turned off, price is 0-> price should be allowed to be set to 0 and so should totalCost
    if (this.props.request.price === null && !price) { // Price is either 0 or undefined/null
      price = null;
      totalCost = null;
    }
    else if (this.props.request.price !== null && !price) {
      price = 0;
      totalCost = 0;
    }
    else if (price < 0) {
      throw new Error("Price must be non-negative");
    }

    try {
      await DatabaseClient.editOrder(this.props.request.id, needByDate, quantityRequested, exchange, price, totalCost);
      this.props.onEdit && this.props.onEdit(await DatabaseClient.getOrder(this.props.request.id));
      message.success('Your request has been successfully updated')
      
      this.props.onClose()
    }
    catch (e) {
      message.error(e.message)
      console.error(e);
      Sentry.captureException(e);
      throw e;
    }
  }

  render() {
      const request = this.props.request;
      const productListing = request.productListing;
      const product = unmarshal(productListing.product,Product);
      return (
        <StyledModal {...this.props} showSubmitButton={!this.props.hideController} submitText={"Edit Request"} onSubmit={this.onSubmit} throwErrorOnSubmit={true}>
          <Display>
            <ImageContainer>
              <Image src={product.getPictureSrc()}/>
            </ImageContainer>
            <ProfileLink {...(this.props.profileStore.isSeller ? {uid: request.buyerUID, text: request.buyer.name} : {uid: request.sellerUID, text: request.seller.name})}/>
            <Title variant="title">
              {product.name}
            </Title>
            {
              !this.props.hideController &&
              <Controller
                quantityRequested={request.quantityRequested}
                onQuantityRequestedChange={(val) => {
                  this.setState({ quantityRequested: val})
                }}
                maxQuantity={request.quantityAvailable === "Unlimited" || request.isAccepted() ? 99999 : request.quantityAvailable}
                needByDate={request.needByDate ? moment(request.needByDate).format('YYYY-MM-DD') : null}
                onNeedByDateChange={(val) => {
                  this.setState({ needByDate: val})
                }}
                exchange={request.exchange}
                onExchangeChange={(val) => {
                  this.setState({ exchange: val})
                }}
                delivery={product.delivery}
                pickup={product.pickup}
                price={(request.totalCost / request.quantityRequested).toFixed(2)}
                onPriceChange={(val) => {
                  this.setState({ price: val})
                }}
                priceUnit={request.unit}
                displayPrice={this.props.profileStore.isSeller}
                />
            }
          </Display>
          <Info>
            <Content productListing={productListing} hideSelector={true} quantity={request.quantityAvailable}/>    
          </Info>    
        </StyledModal>          
      )
  }
}

export default RequestModal
