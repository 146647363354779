import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import SelectionBar from '../../../components/molecules/SelectionBar';
import ProductList from '../../../components/molecules/ProductList';
import { some, isEqual, values, findIndex } from 'lodash';
import Bottombar from '../../../components/CommonBottomBar';
import Send from '@material-ui/icons/Send';
import Notes from '@material-ui/icons/Notes';
import Delete from '@material-ui/icons/Delete';
import { message } from 'antd';
import OrderNoteModal from '../OrderNoteModal'

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
`

@inject('basketStore', 'profileStore')
@observer
class BasketPanel extends Component {
  constructor (props) {
    super(props)
    this.list = React.createRef();
    this.state = {
      selectedItems: [],
      errorItems: [],
      sellerArry: [],
      itemArry: [],
      isRequesting: false,
      sortSeller: true,
      orderNotes: null,
      orderNoteModal: false,
    }
  }

  async componentWillMount() {
    this.prepareBasket();
  }

  prepareBasket = async () => {
    const { cartItems, redirect, profileStore } = this.props
    const { isRequesting } = this.state
    let sortedSellers = [];

    //move to orders if cart is empty
    if((cartItems && cartItems.length === 0) && isRequesting) {
      redirect();
    }

    let sortedItems = cartItems.map((item,index)=> {
      if(!sortedSellers.includes(item.productListing.product.seller.name)){
        sortedSellers.push(item.productListing.product.seller.name)
      }
      item['staticExchange'] = (!item.productListing.product.delivery || !item.productListing.product.pickup);
      return item;
    })
    sortedItems.sort((a,b) => {
      if(a.productListing.product.name.toLowerCase() > b.productListing.product.name.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    })
    sortedSellers.sort((a,b) => {
      if(a.toLowerCase() > b.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    })
    const sortedOrder = sortedItems.map((item,index) => {
      item['listIndex'] = index;
      return item;
    })

    this.setState({
      itemArry: sortedOrder,
      sellerArry: sortedSellers,
      isRequesting: false,
      selectedItems: [],
      orderNotes: profileStore && profileStore.business && profileStore.business.orderNotes
    })
  }

  clickItem = itemIdentity => {
    const { selectedItems } = this.state;
    let modifedSelectedItems = [...selectedItems];
    if (some(modifedSelectedItems,itemIdentity)) {
      modifedSelectedItems = modifedSelectedItems.filter(id => id.id !== itemIdentity.id);
    } else {
      modifedSelectedItems.push(itemIdentity);
    }
    this.setState({ selectedItems: modifedSelectedItems });
  }

  deleteItem = async (itemIdentity) => {
    const { basketStore } = this.props;
    const { itemArry, selectedItems } = this.state;
    let sortedSellers = [];
    let modSelectedItems = selectedItems.filter(id => itemIdentity.id !== id.id)
    let listIndex = 0;
    let modItemArry = itemArry.reduce(function(result, item) {
      if(itemIdentity.id !== item.productListing.id){
        if(!sortedSellers.includes(item.productListing.product.seller.name)){
          sortedSellers.push(item.productListing.product.seller.name)
        }
        item.listIndex = listIndex++;
        result.push(item);
      }
      return result;
    }, []);
    sortedSellers.sort((a,b) => {
      if(a.toLowerCase() > b.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    })

    this.setState({
      itemArry: modItemArry,
      selectedItems: modSelectedItems,
      sellerArry: sortedSellers,
    })

    await basketStore.removeFromBasket(itemIdentity.id);
  }

  updateItem = (index, qty, date, exch) => {
    const { basketStore } = this.props
    const { itemArry } = this.state;
    let modItemArry = [...itemArry];
    let max = modItemArry[index].productListing.getBaseQuantity();
    if (max === "Unlimited") max = 99999;
    const limitQty = qty ? Math.min(max, Math.max(0, qty)): qty;
    const amount = (limitQty * modItemArry[index].productListing.price);
    modItemArry[index].quantityRequested = limitQty;
    modItemArry[index].needByDate = date;
    modItemArry[index].exchange = exch;
    modItemArry[index].totalCost = amount;
    basketStore.editShoppingCart(modItemArry[index].productListing.id, modItemArry[index]);
    this.setState({ itemArry: modItemArry })
  }

  deleteSelected = async () => {
    const { basketStore } = this.props;
    const { itemArry, selectedItems } = this.state;
    const selectedIDs = selectedItems.map((item) => item.id);
    await basketStore.removeMultipleFromBasket(selectedIDs);
    let sortedSellers = [];
    let listIndex = 0;
    let modItemArry = itemArry.reduce(function(result, item) {
      if(!selectedIDs.includes(item.productListing.id)){
        if(!sortedSellers.includes(item.productListing.product.seller.name)){
          sortedSellers.push(item.productListing.product.seller.name)
        }
        item.listIndex = listIndex++;
        result.push(item);
      }
      return result;
    }, []);
    sortedSellers.sort((a,b) => {
      if(a.toLowerCase() > b.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    })

    this.setState({
      itemArry: modItemArry,
      selectedItems: [],
      sellerArry: sortedSellers,
    })
  }

  deleteSeller = async (seller) => {
    const { basketStore } = this.props;
    const { itemArry, selectedItems } = this.state;
    let modSelectedItems = selectedItems.filter(id => {
      return seller !== id.seller;
    })
    let sortedSellers = [];
    let listIndex = 0;
    const modItemArry = [];
    const removeIDs = [];
    for (let i = 0; i < itemArry.length; i++) {
      const item = itemArry[i];
      if(item.productListing.product.seller.name !== seller){
        if(!sortedSellers.includes(item.productListing.product.seller.name)){
          sortedSellers.push(item.productListing.product.seller.name)
        }
        item.listIndex = listIndex++;
        modItemArry.push(item);
      } else {
        removeIDs.push(item.productListing.id);
      }
    }
    await basketStore.removeMultipleFromBasket(removeIDs);

    sortedSellers.sort((a,b) => {
      if(a.toLowerCase() > b.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    })
    this.setState({
      itemArry: modItemArry,
      selectedItems: modSelectedItems,
      sellerArry: sortedSellers,
    })
  }

  updateMass = (value,type) => {
    const { basketStore } = this.props;
    const { itemArry, selectedItems } = this.state;
    const selectedIDs = selectedItems.map(item => item.id);
    let modItemArry = itemArry.map((item,index)=> {
      if(selectedIDs.includes(item.productListing.id)){
        switch(type) {
          case 'exchange':
            item.exchange = item.staticExchange ? item.exchange : value;
            break;
          case 'date':
            item.needByDate = value;
            break;
          default:
            break;
        }
      }
      basketStore.editShoppingCart(item.productListing.id, item);
      return item;
    })

    this.setState({
      itemArry: modItemArry,
    })
  }

  selectMass = (sellerName, checked) => {
    const { itemArry, selectedItems } = this.state;
    let modifedSelectedItems = [...selectedItems];
    if(sellerName) {
      if(checked) {
        let newlySelected = [];
        itemArry.forEach(item => {
          if(item.productListing.product.seller.name === sellerName) {
            const identifier = { id: item.productListing.id, seller: item.productListing.product.seller.name }
            newlySelected.push(identifier);
          }
        });
        modifedSelectedItems = [...new Set([...modifedSelectedItems, ...newlySelected])];
      } else {
        modifedSelectedItems = modifedSelectedItems.filter(id => id.seller !== sellerName);
      }
    } else {
      if(checked) {
        let newlySelected = [];
        itemArry.forEach(item => {
          const identifier = { id: item.productListing.id, seller: item.productListing.product.seller.name }
          newlySelected.push(identifier);
        });
        modifedSelectedItems = newlySelected;
      } else {
        modifedSelectedItems = [];
      }
    }
    this.setState({ selectedItems: modifedSelectedItems });
  }

  requestBasket = async () => {
    const { basketStore } = this.props;
    const { orderNotes } = this.state;
    this.setState({ isRequesting: true })
    try {
      const notes = orderNotes ? orderNotes : '';
      await basketStore.checkout(notes);
      message.success("Success!");
    } catch (e) {
      message.error(`${e.message}.  If this is an error regarding quantity, please refresh your web page.`)
      console.error(e);
      Sentry.captureException(e);
    }
    this.prepareBasket();
    this.setState({ isRequesting: false });
  }

  getTotal = () => {
    const { basketStore } = this.props;
    const total = basketStore.calculateTotalCost();
    return total;
  }

  handleSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  toggleOrderNoteModal = open => this.setState({orderNoteModal: open !== undefined ? open : !this.state.orderNoteModal})

  addOrderNote = () => {
    this.setState({orderNotes: ''})
    this.toggleOrderNoteModal(true)
  }
  removeOrderNote = () => {
    this.setState({orderNotes: null})
  }
  saveOrderNote = orderNotes => {
    this.setState({orderNotes})
    this.toggleOrderNoteModal(false)
  }
  closeOrderNote = () => {
    if(!this.state.orderNotes) this.setState({orderNotes: null})
    this.toggleOrderNoteModal(false)
  }

  render() {
    const { selectedItems, sellerArry, errorItems, isRequesting, itemArry, sortSeller, orderNotes, orderNoteModal } = this.state

    const total = this.getTotal();
    const orderNoteBtn = orderNotes ? {
        text: 'Delete Notes',
        icon: (<Delete/>),
        onClick: this.removeOrderNote,
      } : {
        text: 'Add Notes',
        icon: (<Notes/>),
        onClick: this.addOrderNote,
      }
    const buyerNavButtons = [
      orderNoteBtn,
      {
        text: null
      },
      {
        text: 'Request',
        icon: (<Send/>),
        onClick: (() => this.requestBasket()),
      },
    ]
    return (
      <PanelContainer>
        <Bottombar buttons={buyerNavButtons}/>
        <SelectionBar
          checked={selectedItems.length === itemArry.length}
          indeterminate={selectedItems.length > 0 && selectedItems.length !== itemArry.length}
          numSelected={selectedItems.length}
          onSelected={this.selectMass}
          onValueChange={this.updateMass}
          onDeleteSelected={this.deleteSelected}
          onSwitch={this.handleSwitch}
          sortSeller={sortSeller}
          total={total}
          onRequest={this.requestBasket}
          request={isRequesting}
        />
        <ProductList
          products={itemArry}
          sellers={sellerArry}
          sortSeller={sortSeller}
          total={total}
          extraPadding={selectedItems.length>0}
          selectedItems={selectedItems}
          sellerSelect={this.selectMass}
          errorItems={errorItems}
          onSelected={this.clickItem}
          onDelete={this.deleteItem}
          onDeleteSeller={this.deleteSeller}
          onUpdateItem={this.updateItem}
          onRequest={this.requestBasket}
          addOrderNote={this.addOrderNote}
          editOrderNote={() => this.toggleOrderNoteModal(true)}
          removeOrderNote={this.removeOrderNote}
          orderNotes={orderNotes}
          request={isRequesting}
          ref={this.list}
        />
        {orderNotes !== undefined && orderNotes !== null && (
          <OrderNoteModal
            editMode
            orderNotes={orderNotes}
            open={orderNoteModal}
            heading="Edit Notes"
            submit={this.saveOrderNote}
            onClose={this.closeOrderNote}
          />
        )}
      </PanelContainer>
    )
  }
}

BasketPanel.propTypes = {
  sortSeller: PropTypes.bool.isRequired,
  cartItems: PropTypes.array.isRequired,
};

BasketPanel.defaultProps = {
  sortSeller: false,
  cartItems: [],
};

export default BasketPanel;
