import React, { Component } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types';
import OpenInNew from '@material-ui/icons/OpenInNew';
import breakpoint from 'styled-components-breakpoint'
import { Link } from 'react-router-dom'

const TitleContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 3px 0;
    max-width: 310px;
    margin-right: 10px;
`

const Heading = styled(Typography)`
    font-family: ${props => props.theme.fonts.ms_font700}
    font-size: 1.2em;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    ${breakpoint('mobile_max', 'tablet_min')`
		font-size: 1.1em;
	`}
    ${breakpoint('mobile_min', 'mobile_max')`
		font-size: 1em;
	`}
`

const Subheading = styled(Typography)`
    opacity: 0.4;
    font-size: 0.9em;
    ${breakpoint('mobile_min', 'tablet_min')`
		font-size: 0.8em;
	`}
`

const StyledIcon = styled(OpenInNew)`
    font-size: 0.8em;
    margin-bottom: 4px;
    margin-left: 3px; 
    color: #51d79a;
`

class Title extends Component {
    render() {
        const { uid, type, text, subtext } = this.props;
        return (
            <TitleContainer>
                {
                    uid ? (
                        <Heading
                            variant="title"
                            component={Link}
                            to={`/${type}/${uid}`}
                        >
                            {text}
                            <StyledIcon/>
                        </Heading>
                    )
                    :
                    (
                        <Heading
                            variant="title"
                        >
                            {text}
                        </Heading>
                    )
                }
                <Subheading variant="subheading">
                    {subtext}
                </Subheading>
            </TitleContainer>
        )
    }
}

Title.propTypes = {
    uid: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    subtext: PropTypes.string,
  }
  
Title.defaultProps = {
    uid: 'Hello',
    type: 'profile',
}

export default Title