import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { observer, inject } from 'mobx-react'
import breakpoint from 'styled-components-breakpoint'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

@inject('profileStore')
@observer
class ProfilePanelCheckBox extends Component {
  state = Object.values(this.props.coreValues).reduce((o, key) => Object.assign(o, { [key]: false }), {});

  componentWillMount() {
    const { profileStore, coreValues, formArraySting, formOtherSting } = this.props

    if (profileStore.form.fields[formOtherSting].value) {
      this.setState({ Other: true });
    }
    // Figure out the reasonForPost
    Object.keys(coreValues).forEach( (option) => {
      if (profileStore.form.fields[formArraySting].value.includes(coreValues[option])) {
        const op = coreValues[option]
        this.setState({ [op]: true });
      }
    })
  }


  handleCheckboxChange = name => event => {
    const { profileStore, formArraySting, formOtherSting } = this.props

      this.setState({ [name]: event.target.checked });
      if (name != 'Other') {
          profileStore.onCheckboxChange(formArraySting, name);
      } else {
          profileStore.onFieldChange(formOtherSting, '');
          // Reset the error
          this.props.updateArrayError(null);
      }
  }

  createCheckbox = label => (
      <Grid key={`${label}-checkbox`} item container justify="center" xs={6}>
          <FormControlLabel
              control={
                  <Checkbox
                      value={label}
                      checked={this.state[label]? true:false}
                      onChange={this.handleCheckboxChange(label)}
                  />
              }
              className="label"
              label={label}
          />
      </Grid>
  )

  createCheckboxes = () => (
    Object.values(this.props.coreValues).map(this.createCheckbox)
  )

  render() {
    const { profileStore, formOtherValue, formOtherSting, formArrayError, formOtherError, bottomLabel } = this.props
    return (
        <div className="form__group">
            <div className="form__control">
                <Grid container justify="center" direction="column">
                    <Grid container item>
                        <FormControl component="fieldset">
                            <FormGroup>
                                <Grid container justify="center" xs={12}>
                                    <Grid container item className="checkbox-grid-container">
                                        {this.createCheckboxes()}
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            <FormHelperText>
                                {bottomLabel}
                            </FormHelperText>
                            <FormHelperText error >{formArrayError}</FormHelperText>
                        </FormControl>
                    </Grid>
                    {(() => {
                        if (this.state['Other']) {
                          return (
                            <Grid item xs={12} otherOption>
                              <FormControl fullWidth={true}>
                                  <InputLabel error={formOtherError !== null}>Other</InputLabel>
                                  <Input
                                      name={formOtherSting}
                                      value={formOtherValue? formOtherValue:''}
                                      onChange={(e) => profileStore.onFieldChange(e.target.getAttribute('name'), e.target.value)}
                                      placeholder="Please Specify"
                                      error={formOtherError !== null}
                                  />
                                  <FormHelperText error >{formOtherError}</FormHelperText>
                              </FormControl>
                          </Grid>
                          ); 
                        }
                        else {
                            if (formOtherValue != '') {
                                profileStore.onFieldChange(formOtherSting, '');
                            }
                        }
                    })()}
                </Grid>
            </div>
        </div>
    )
  }
}

export default ProfilePanelCheckBox;