import React, { Component } from 'react'
import Button from '../../atomic/buttons/Button';
import PendingItem from './pendingItem';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { confirmAlert } from 'react-confirm-alert';

const SubmitButton = styled(Button)`
  margin-left: auto;
  text-transform: none;
  min-height: 25px;

  ${breakpoint('mobile_min', 'mobile_max')`
    font-size: 0.9em;
  `}
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  ${breakpoint('mobile_min', 'mobile_max')`
  `}
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
`

const ListContainer = styled.div`
  background-color: #fff; 
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-bottom: 10px;
  border-radius: 10px; 
`

const Title = styled(Typography)`
  font-size: 1.2em;
`

class PendingList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pendingEmails: [],
    }
  }

  componentDidMount() {
    const { emails } = this.props
    this.setState({
      pendingEmails: emails,
    });
  }

  resendAll = () => {
    let list = [...this.state.pendingEmails];
    this.props.transferEmails(list);
  }

  resendInvite = (index) => {
    let list = [...this.state.pendingEmails];
    let email = list.splice(index,1);
    this.props.transferEmails(email);
  };

  getEmailItems = () => {
    return this.state.pendingEmails.map((item,index) => {
      let state = this.props.chips.includes(item);
      return (
        <PendingItem
          key={item}
          reinvite={() => this.resendInvite(index)}
          title={item}
          defaultState={state}
        />
      )
    })
  }

  render () {
    return (
      <MuiThemeProvider>
          <FormContainer>
            <HeaderContainer>
              <Title variant="subheading">
                Pending Invitations
              </Title>
              <SubmitButton 
                onClick={this.resendAll}
                color="primary"
                variant="contained"
              >Resend To All</SubmitButton>
            </HeaderContainer>
            <ListContainer>
              {this.getEmailItems()}
            </ListContainer>
          </FormContainer>
      </MuiThemeProvider>
    )
  }
}

export default PendingList