import { action, observable, computed} from 'mobx';
import note_data from '../../demo_fixtures/notes.json'
import moment from 'moment';
import { profileStore } from '..'
import { NoteService, LabelService } from "core/api";
import { unmarshal } from '../../core/utilities/ModelUtils'
import Note from '../../core/model/Note'

class NoteStore {
    @observable notes = [];
    @observable labels = [];
    @observable labelSelection = [];
    @observable processing = false;

    @computed
    get Labels() {
        return this.labels;
    }

    @computed
    get FilteredLabels() {
        return this.labelSelection;
    }

    @computed
    get Notes() {
        return this.notes;
    }

    isVisible(obj) {
        if(this.labelSelection.length <= 0) {
            return true;
        }

        const labelValues = this.labelSelection.map(label => label.value.value)
        const noteLabelValues = obj.labels.map(label => label.value.value)

        if(noteLabelValues.filter(x => labelValues.includes(x)).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    @action
    async init() {
        const biz = profileStore.business
        if (!biz) return;

        this.processing = true;
        await this.getUserNotes();
        await this.getUserLabels();
        this.processing = false;
    }

    @action
    async setInitalFilter(values) {
        const filteredLabels = values.map(value => {
            for(let i = 0; i < this.labels.length; i++) {
                const label = this.labels[i];
                if(label.value.value === value) {
                    return label;
                }
            }
        })

        this.setLabelFilters(filteredLabels);
    }

    @action
    async createNote(data) {
        const business = profileStore.business.id;
        this.processing = true;
        await NoteService.create({business,...data});
        await this.getUserNotes();
        await this.getUserLabels();
        this.processing = false;
    }
    
    @action
    async updateNote(data) {
        const business = profileStore.business.id;
        const note = unmarshal(data, Note);
        this.processing = true;
        await NoteService.update(note.id, {business, ...note.formatForDB()})
        await this.getUserNotes();
        await this.getUserLabels();
        this.processing = false;
    }

    @action
    async setLabelFilters(filter) {
        this.labelSelection = filter
    }

    @action
    async deleteNote(id) {
        this.processing = true;
        await NoteService.delete(id)
        await this.getUserNotes();
        await this.getUserLabels();
        this.processing = false;
    }

    @action
    getFilteredNotes() {
        return this.notes.filter(note => this.isVisible(note))
    }

    @action
    async getUserNotes() {
        const biz = profileStore.business
        if(!biz) return;

        this.notes = await NoteService.getAll({
            "business": biz.id,
            "orderBy": {
                "lastModified": "desc"
            }
        })
    }

    @action
    async getUserLabels() {
        const biz = profileStore.business
        if(!biz) return;

        this.labels = await LabelService.getAll({ "business": biz.id, })
    }

}

const notesStore = new NoteStore();
export default notesStore;
