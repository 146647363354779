import React, { useState } from 'react';
import { AlertMessage } from '../../AlertMessage';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NoteItem } from '../../molecules/NoteItem';
import Button from '../../atomic/buttons/Button'

const Container = styled.div`
  display: flex;
  padding: 3px;
  flex-direction: column; 
  flex: 1;
`;

const NoteItemStyled = styled(NoteItem)`
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 12px;
`

const ButtonStyled = styled(Button)`
  text-transform: none;
`

const NoteList = ({
  data,
  onDelete,
  onSave,
  defaultLabels,
  options,
  ...other
}) => {

  const [editID,setEditID] = useState(null)
  const [createNew,setCreate] = useState(false)
  const [selectedNote,setSelectedNote] = useState(null)
  const saveNote = (note, newData = {}) => {
    setEditID(null)
    setCreate(false)
    const newNote = Object.assign(note,newData);
    onSave(newNote)
  }
  
  const cancelAction = () => {
    setEditID(null)
    setCreate(false)
  }

  const openNote = id => {
    if(id) {
      setEditID(id);
      setCreate(false)
    } else {
      setEditID(null)
      setCreate(true)
    }
  }

  const renderNote = item => {
    const {id, text, lastModified, timestamp, labels} = item;
    return (
      <NoteItemStyled
        key={`${id}-note-item`}
        editMode={editID === id || !id}
        id={id}
        text={text}
        updated={lastModified > timestamp}
        date={lastModified}
        labels={labels}
        options={options}
        onSaveClicked={(data) => saveNote(item, data)}
        onEditClicked={() => openNote(id)}
        onDeleteClicked={() => AlertMessage('Delete Note', 'Are you sure you want to delete this note?', () => onDelete(id))}
        onBodyClicked={() => setSelectedNote(id)}
        expanded={selectedNote === id}
        collapseLines={3}
        onCancelClicked={cancelAction}/>
    )
  }

  return (
    <Container {...other}>
      {createNew ? (
          renderNote({labels: defaultLabels})
        ):(
        <ButtonWrapper>
          <ButtonStyled variant="contained" onClick={() => openNote()}>New Note</ButtonStyled>
        </ButtonWrapper>
      )}
      {data.map(item => renderNote(item))}
    </Container>
  )
}

NoteList.propTypes = {
  options: PropTypes.array,
  defaultLabels: PropTypes.array,
}

NoteList.defaultProps = {
  options: [],
  defaultLabels: []
}

export default NoteList;