import React, { Component } from 'react'
import Button from '../../atomic/buttons/Button';
import ChipPanel from './ChipPanel';
import RejectedPanel from './RejectedPanel';
import ErrorMessage from './ErrorMessage';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { confirmAlert } from 'react-confirm-alert';
import TextField from '@material-ui/core/TextField';
import EmailFileInput from '../../molecules/EmailFileInput'

const SubmitButton = styled(Button)`
  margin-left: auto;
  text-transform: none;
  min-height: 25px;

  ${breakpoint('mobile_min', 'mobile_max')`
    font-size: 0.9em;
  `}
`

const FormContainer = styled.div`
  background-color: #fff;
  overflow-x: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 8px 0 30px;

  ${breakpoint('mobile_min', 'mobile_max')`
    padding: 10px;
  `}
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`

const Title = styled(Typography)`
  font-size: 1.2em;
  margin-bottom: 8px;
`

const Subtext = styled(Typography)`
  font-size: 0.9em;
  margin-top: -6px;
`

const MethodBreaker = styled(Typography)`
  margin:  15px auto 2px;
  opacity: 0.5;
`

class ChipInput extends Component {
  constructor (props) {
    super(props)
    this.panelRef = React.createRef();
  }

  submit = async () => {
    if(this.panelRef.hasError){
      const options = {
        customUI: ({ onClose }) => {
          return (
            <ErrorMessage
              title={'Error'}
              message={'Some of the addresses were invalid. Please make sure all addresses are in the correct format.'}
              closePopup={onClose}
            />
          )
        },
      }
      confirmAlert(options);
    } else {
      await this.props.handleSubmit();
      this.props.clearChips();
    }
  }

  render () {
    return (
      <MuiThemeProvider>
          <FormContainer>
            <InputContainer>
              <Title variant="title">
                {`Enter the emails of the ${this.props.isSeller?"buyers":"sellers"} you would like to invite to join you on Freshspire:`}
              </Title>
              <EmailFileInput
                onImport={this.props.onImport}
              />
              <MethodBreaker>--- OR ---</MethodBreaker>
              <ChipPanel
                chips={this.props.chips}
                addChip={this.props.addChip}
                deleteChip={this.props.deleteChip}
                setError={this.setError}
                ref={(instance) => { this.panelRef = instance }}
              />
              <Subtext variant="subheading">
              {`Enter or paste a list of addresses separated by commas or spaces.  Emails will be sent to the ${this.props.isSeller?"buyers":"sellers"} inviting them to join you on Freshspire.  If they already have an account, a request to connect will be sent to them.`}
              </Subtext> 
            </InputContainer>
            <TextField
              multiline
              fullWidth
              value={this.props.message || ''}
              onChange={this.props.customMessage}
              label="Message"
              margin="normal"
              helperText="This message will be sent to all emails"
            />
            <SubmitButton 
              onClick={this.submit}
              color="primary"
              variant="contained" 
            >{this.props.chips.length>1 ? 'Send Invites':'Send Invite'}</SubmitButton>
          </FormContainer>
      </MuiThemeProvider>
    )
  }
}

export default ChipInput