import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { SearchIcon, LocationIcon } from './Icons'
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { ExpansionPanelActions } from '@material-ui/core';
import { FoodOptions } from '../core/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '@material-ui/core/Input';
import Button from '../components/atomic/buttons/Button';
import FarmerFilter from '../components/molecules/FarmFilter';

const ClearButtonStyled = styled(Button)`
    fontsize: 13px;
    padding: 0;
    text-transform: none;
`

const styles = theme => ({
    root: {
        background: '#3dd28f',
        color: 'white'
    },
})

@inject('clientSearchStore','clientListStore')
@observer
class SearchBar extends Component {
    constructor(props) {
        super(props); 
      }
    
    state = Object.assign(
        {},
        {expanded: false},
        {sortBy: 'dist-asc'},
        {query:''},
        {distance:100},
        {farmer: {}},
        {visibility:'all'}
    )

    componentDidMount(){
        this.props.clientSearchStore.resetSearch();
        const distance = this.props.clientSearchStore.distance;
        this.setState({ distance })
    }

    createSortBy = (mobile) => {
        return (
            <div className={`sort-by-container ${mobile ? 'mobile' : 'web'}`}>
                <Typography className="sort-label">
                    Sort By
                </Typography>
                <Select
                    name="sortBy"
                    value={this.state.sortBy}
                    onChange={this.handleChange}
                    className="sort-box"
                    displayEmpty
                    disableUnderline={true}
                >
                    <MenuItem value='name-asc'>Name (A-Z)</MenuItem>
                    <MenuItem value='name-desc'>Name (Z-A)</MenuItem>
                    <MenuItem value='dist-asc'>Distance Ascending</MenuItem>
                    <MenuItem value='dist-desc'>Distance Descending</MenuItem>
                    <MenuItem value='state-asc'>State Ascending</MenuItem>
                    <MenuItem value='state-desc'>State Descending</MenuItem>
                </Select>
            </div>
        )
    }

    checkboxChange = (name,field) => event => {
        const { groups } = this.props; 
        let modState = Object.assign({}, this.state[field])
        modState[name] = event.target.checked;
        this.setState({ [field]: modState });
        const groupNames = Object.keys(modState);
        const newList = groupNames.filter(item => modState[item]);
        
        const listOfOrgs = [];
        for(let i = 0; i < groups.length; i++){
            const item = groups[i];
            if(newList.includes(item.name)){
                listOfOrgs.push(item);
            }
        }
        this.props.updateGroupped(listOfOrgs);
    }

    handleExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    handleChange = event => {
        this.props.clientSearchStore.updateSort(event.target.value)
        this.setState({ [event.target.name]: event.target.value });
    };

    handleQueryChange = event => {
        this.props.clientSearchStore.updateQuery(event.target.value);
        this.setState({query: event.target.value})
    }

    handleDistanceChange = event => {
        this.props.clientSearchStore.updateQueryDistance(event.target.value);
        this.setState({distance: event.target.value})
    }

    updateList = async () => {
        await this.props.clientListStore.filterList();
        this.props.clientSearchStore.updateDistance();
        this.props.clientSearchStore.refresh();
        this.setState({ expanded:false })
    }

    clearFarmers = () => {
        this.setState({farmer: {}});
        this.props.updateGroupped([]);
    }

    createFilterGrid = (visibility) => {
        return (
            <Grid container item direction="column">
                <Grid container item justify="center">
                    <Grid container direction='column' item xs={10} className="checkbox-wrapper mobil-margin">
                        <TextField
                            type="number"
                            fullWidth
                            label="Within distance (miles)"
                            value={this.state.distance}
                            placeholder="100"
                            onChange={(e) => this.handleDistanceChange(e)}
                        />
                        <Button
                            className="filter-button"
                            variant="contained"
                            color="primary"
                            onClick={async () => this.updateList()}
                        >Filter</Button>
                    </Grid>
                    {/* <Grid container item justify="center">
                        <Grid container item xs={10} className="checkbox-group-label" justify="space-between" direction="row">
                            <Typography variant="subheading" className="text">Organizations</Typography>
                            <ClearButtonStyled onClick={this.clearFarmers}>(clear)</ClearButtonStyled>
                        </Grid>
                        <Grid container item xs={10} className="checkbox-wrapper">
                            <FarmerFilter
                                items={this.props.groups ? this.props.groups.map(item => item.name) : []}
                                checked={this.state.farmer}
                                onChange={label => this.checkboxChange(label,'farmer')}
                            />
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        )
    }

    searchIfPressEnter = event => {
        if ((document.body.clientWidth <= 549) && (event.key === 'Enter')) {
            this.setState({ expanded: false });
        }
    }

    render() {
        const { enableFilter } = this.props
        return (
            <div className="search-bar row-fix">
                <div className={`search-wrapper ${this.props.shadow? 'shadow':''}`}>
                    <div className="expansion-container">
                        <ExpansionPanel className="input-container" expanded={this.state.expanded}>
                            <ExpansionPanelSummary expandIcon={<Icon className={`filter-dropdown ${enableFilter?'':'short'}`} onClick={() => this.handleExpand()}>expand_more</Icon>} className="expansion-top">
                                <Icon className="search-icon">
                                    search
                                </Icon>
                                <input
                                    type="text"
                                    ref={(searchQuery) => this.searchQuery = searchQuery}
                                    onKeyPress={this.searchIfPressEnter}
                                    value={this.state.query}
                                    placeholder="Search..."
                                    onChange={(e) => this.handleQueryChange(e)}
                                    className={this.state.expanded? 'opened':''}
                                />
                                {
                                    enableFilter &&
                                    <Typography className="filter-label" onClick={() => this.handleExpand()}>
                                        Filter
                                    </Typography>
                                }
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="expansion-bottom">
                                {this.createSortBy(true)}
                                {enableFilter ? this.createFilterGrid(this.props.visibility):[]}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    {this.createSortBy()}
                </div>
                {
                    enableFilter &&
                    <div className="filter-wrapper">
                        <div className="filter-container">
                            <div className="header">
                                <Typography className="text">
                                    Filter
                                </Typography>
                            </div>
                            <div className="body">
                                {this.createFilterGrid(this.props.visibility)}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default withStyles(styles)(SearchBar);
