import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import Icon from '@material-ui/core/Icon';

const StyledIcon = styled(Icon)`
`

const StyledButton = styled.button`
    padding: 0px;
    border: none;
    background-color: Transparent;
    color: black;
`

class SendButton extends React.Component {
    render() {
        return (
            <StyledButton className={this.props.className} onClick={this.props.onClick}>
                <StyledIcon>send</StyledIcon>
            </StyledButton>
        );
    }
}

SendButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default SendButton;