import io from "socket.io-client";
import { BASE_URL } from "./conf";

// const client = io("http://localhost:8080/v1");
const client = io(BASE_URL);

const subscriptions = {};

const subscriptionKey = (args) => JSON.stringify(args, Object.keys(args).sort());

const registerSubscription = (args, cb) => {
    subscriptions[subscriptionKey(args)] = cb;
};

const unregisterSubscription = (args) => {
    const key = subscriptionKey(args);
    if (key in subscriptions) {
        delete subscriptions[key];
        return true;
    }
    return false;
};

export const createSubscription = (args, cb) => {
    client.emit("subscription", args);
    registerSubscription(args, cb);
};

export const endSubscription = (args) => {
    client.emit("unsubscription", args);
    unregisterSubscription(args);
};

client.on("data", (args, data) => {
    const key = subscriptionKey(args);
    if (key in subscriptions) {
        subscriptions[key](data);
    }
});

export default client;
