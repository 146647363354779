import React, { Component } from 'react'
import styled, { css, withTheme } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import { ProductListing, Product } from '../../../../core/model';
import SelectDropdown from '../../../molecules/SelectDropdown';
import Bubble from '../../../molecules/Bubble';
import Tooltip from '../../../molecules/Tooltip';
import { unmarshal } from '../../../../core/utilities/ModelUtils'

const TopGrid = styled(Grid)`
    display: flex;
    flex-flow: column nowrap;
`

const Label = styled(Typography)`
    font-size: 1.1em !important;
    font-family: ${props => props.theme.fonts.ms_font300} !important;
    color: ${props => props.theme.colors.spearmint_main} !important;
`

const Value = styled(Typography)`
    font-size: 1.1em !important;
    font-family: ${props => props.theme.fonts.ms_font300} !important;
`

const DetailsContainer = styled.div`
    border-bottom: 1px solid #ddd;
    padding-bottom: 13px;
    margin-bottom: 10px;
`

const GridItem = styled(Grid)`
    display: flex;
    flex-direction: column;
    flex-grow: 1 !important;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
`

const SingleItem = styled.div`
  margin-right: auto;
  font-size: 17px;
  font-family: ${props => props.theme.fonts.ms_font700};
`;

const DetailsItem = styled(Grid)`
    display: flex !important;
    flex-wrap: nowrap !important;
    padding: 6px 0 !important;
`

const DropdownContainer = styled.div`
    ${props => props.hidden && `display: none;`}
`;


const Detail = styled(Typography)`
    font-size: 1.1em !important;
    font-family: ${props => props.theme.fonts.ms_font300} !important;
    margin-left: 16px !important;
`
const BottomContainer = styled.div`
    margin-bottom: 10px;
`

const Note = styled(Typography)`
    font-family: ${props => props.theme.fonts.ms_font300} !important;    
    font-size: 1.1em !important;
`

class Content extends Component {
    getPrices = () => {
        return this.props.productListingGroup.getDisplayPrices().map((price, i) => (
            <Typography variant="subheading" className="value sm" key={i}>
                {price}
            </Typography>
        ));
    }

    getQuantities = () => {
        return this.props.productListingGroup.getDisplayQuantities().map((qty, i) => (
            <Typography variant="subheading" className="value sm" key={i}>
                {qty}
            </Typography>
        ));
    }

    getListingSelector = () => {
        const labelValues = {};
        this.props.productListingGroup.productListings.forEach((pl) => {
            if(pl.getBaseQuantity() > 0 || pl.getBaseQuantity() === "Unlimited") {
                labelValues[pl.id] = {
                    label: pl.getPricePerUnitString(),
                    value: pl.id
                }
            }
        })

        if (this.props.productListingGroup.productListings.length === 1 ) {
            const key = Object.keys(labelValues)[0];
            return <SingleItem>{labelValues[key].label}</SingleItem>
          }

        return (
            <DropdownContainer id="product_modal_unit_dropdown" hidden={this.props.hideSelector}>
                <SelectDropdown formError={false} autoSelect={this.props.autoSelect} fullWidth={true} defaultValue={this.props.defaultValue} showNone={false} items={labelValues} onChange={this.props.onSelectListing}/>
            </DropdownContainer>
        )
    }

    render() {
        const pl = unmarshal(this.props.productListing, ProductListing);
        const product = unmarshal(pl.product, Product);
        return (
            <React.Fragment>
                <TopGrid container spacing={0} justify="space-between">
                    {
                        !this.props.restricted &&
                        <GridItem>
                            <Label variant="subheading">
                                Price:
                            </Label>
                            {
                                !this.props.hideSelector ? this.getListingSelector() : 
                                <Value variant="subheading">
                                    {pl.getPricePerUnitString()}
                                </Value>
                            }
                        </GridItem>
                    }
                    <GridItem>
                        <Label variant="subheading">
                            Qty Available:
                        </Label>
                        <Value variant="subheading">
                            {this.props.quantity != null ? 
                                <Tooltip text='Product or selected unit may have been removed or expired' disabled={this.props.quantity !== 0}>
                                    <Bubble enabled={this.props.quantity === 0}>
                                        {`${this.props.quantity} ${pl.unit}(s)`}
                                    </Bubble>
                                </Tooltip>
                                :
                                `${pl.getBaseQuantity()} ${pl.unit}(s)`}
                        </Value>
                    </GridItem>
                </TopGrid>

                <DetailsContainer>
                    <Label variant="subheading">
                        Details:
                    </Label>

                    <Grid container spacing={24} justify="space-around">
                        <DetailsItem item xs={3}>
                            <Icon color={product.organic ? "primary" : "secondary"}>{product.organic ? "check" : "close"}</Icon>
                            <Detail>Organic</Detail>
                        </DetailsItem>
                        <DetailsItem item xs={3}>
                        <Icon color={product.local ? "primary" : "secondary"}>{product.local ? "check" : "close"}</Icon>
                            <Detail>Local</Detail>
                        </DetailsItem>
                    </Grid>

                    <Grid container spacing={24} justify="space-around">
                        <DetailsItem item xs={3}>
                            <Icon color={product.delivery ? "primary" : "secondary"}>{product.delivery? "check" : "close"}</Icon>
                            <Detail>Delivery</Detail>
                        </DetailsItem>
                        <DetailsItem item xs={3}>
                            <Icon color={product.pickup ? "primary" : "secondary"}>{product.pickup ? "check" : "close"}</Icon>
                            <Detail>Pickup</Detail>
                        </DetailsItem>
                    </Grid>
                </DetailsContainer>
                <BottomContainer>
                    {(product.description && product.description.length !== 0) && 
                        <React.Fragment>
                            <Label>
                                Description:
                            </Label>
                            <Note variant="subheading" className="note-body sm">
                                    {product.description}
                            </Note>
                        </React.Fragment>
                    }
                    {(pl.notes && pl.notes.length !== 0) && 
                        <React.Fragment>
                            <Label>
                            Additional notes:
                            </Label>
                            <Note variant="subheading" className="note-body sm">
                                    {pl.notes}
                            </Note>
                        </React.Fragment>
                    }
                </BottomContainer>
            </React.Fragment>
        )
    }
}

Content.propTypes = {
    productListing: PropTypes.instanceOf(ProductListing).isRequired,
    quantity: PropTypes.number
}

Content.defaultProps = {
}

export default Content