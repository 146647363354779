import React, { Component } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import PropTypes from 'prop-types';
import ButtonFactory from '../BusinessItem/ButtonFactory';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const HeaderText = styled.div`
  flex: 2;
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${props => props.theme.fonts.ms_font500} !important;
`
const SubHeaderText = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
  margin-left: 0;
`


const BusinessOption = ({
  text,
  subText,
  buttons,
  ...other
}) => {
  return (
    <Container {...other}>
      <HeaderText>{text}</HeaderText>
      <SubHeaderText>{subText}</SubHeaderText>
      <ButtonContainer>
        <ButtonFactory buttons={buttons}/>
      </ButtonContainer>
    </Container>
  );
}

BusinessOption.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  buttons: PropTypes.array,
}

BusinessOption.defaultProps = {
  text: 'Hello',
  subText: '(1 miles away)',
  buttons: [],
}

export default BusinessOption;