import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom'

const StyledIcon = styled(Icon)`
`

const StyledButton = styled(Button)`
    min-width: 100%;
    min-height: 100%;
    padding: 0px;
    border-radius: 100%;
`

const Wrapper = styled.div`
    display: inline-block;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 25px;
`;

class LinkButton extends React.Component {
    render() {
        const { text, icon, path, tabletHide } = this.props
        return (
          <Button variant="contained" color="secondary" className={`button ${tabletHide?'tablet-hide':''}`} to={path} component={Link}>
              {text}
              {
                icon &&
                <Icon className="icon">{icon}</Icon>
              }
          </Button>
        );
    }
}

LinkButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default LinkButton;