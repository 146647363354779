import React, { Component } from 'react'
import styled from 'styled-components'
import _Dropzone from 'react-dropzone'
import Button from '../../atomic/buttons/Button'
import Input from '@material-ui/core/Input'
import XLSX from 'xlsx'

class ImportBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filename: ''
        }
        this.zone = React.createRef();
        this.rABS = true;
    }

    formatSheet = (sheet) => {
        const keys = Object.keys(sheet)
        const firstColumnKey = keys[0].slice(0,1);
        const secondColumnKey = keys[1].slice(0,1);
        const thirdColumnKey = keys[2].slice(0,1);
        const values = Object.values(sheet);
        let total = [];
        let arr = ['','',''];
        for(let i = 0; i < keys.length - 1; i++) {
            const val = values[i]['v'];
            if (keys[i].slice(0,1) === firstColumnKey){
                arr[0] = val
            } else if(keys[i].slice(0,1) === secondColumnKey) {
                arr[1] = val
            } else if(keys[i].slice(0,1) === thirdColumnKey) {
                arr[2] = val
                total.push([...arr]);
            }
        }
        return total;
    }

    render() {
        let {onImport, onClearDrop, source, ...other } = this.props;
        let newOnDrop = (e) => {
            this.setState({filename: e[0].name})
            var f = e[0];
            var reader = new FileReader();
            reader.onload = (e) => {
                var data = e.target.result;
                if(!this.rABS) {
                    data = new Uint8Array(data);
                }  
                    
                var workbook = XLSX.read(data, {type: this.rABS ? 'binary' : 'array'})['Sheets'];
                var sheet = workbook[Object.keys(workbook)[0]];
                var json = this.formatSheet(sheet);
                onImport(json)
            };
            if(this.rABS) {
                reader.readAsBinaryString(f); 
            } else  {
                reader.readAsArrayBuffer(f);
            }
        }
        return (
            <div className="dropzone">
                <_Dropzone ref={(node) => { this.zone = node; }} onDrop={newOnDrop} className="drop-box" multiple={false} {...other}>
                    <Input value={this.state.filename}/>
                </_Dropzone>
                <Button variant="outlined" color="primary" onClick={() => { this.zone.open() }} className="drop-open-button">
                    import sheet
                </Button>
            </div>
        )
    }
}

export default ImportBar