import React, { Component } from 'react'
import styled, { css } from 'styled-components';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Image from '../../../atomic/Image';
import SellerName from './SellerName';
import ProductTitle from './ProductTitle';
import { Link } from 'react-router-dom';
import Details from './Details';
import UnitsController from './UnitsController';
import ListItemActions from './ListItemActions';
import Quantity from './Quantity';
import { message } from 'antd';

const Container = styled.div`
  background-color: #fff;
  width: auto;
  padding: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-flow: row nowrap;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 3;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
`;

const SubContent = styled.div`
  display: flex;
  flex: 2;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: none;
    flex: 1;
    padding-top: 5px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  margin-right: 8px;
  border-radius: 14px; 
  overflow: hidden;
  cursor: pointer;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
  }
`;

const Info = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin-right: 10px;
  margin-top: 5px;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    margin-top: 3px;
    margin-right: 0;
  }
`;

const SubInfo = styled.div`
  display: flex;
  flex: 6;
  flex-flow: column nowrap;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    max-width: none;
    flex: 3;
  }
`;

const DetailStyled = styled.div`
  flex: 2;
  flex-flow: column nowrap;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  display: ${props => props.webft ? 'none' : 'flex'};
  @media (max-width: 768px) {
    flex: 0;
    flex-flow: row nowrap;
    display: ${props => props.webft ? 'flex' : 'none'};
  }
`;

const LinkStyled = styled(Link)`
  cursor: pointer;
  @media (max-width: 768px) {
    
  }
`;

const lineHeight = 1.1;

const SellerNameStyled = styled(SellerName)`
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1em;
  max-height: ${lineHeight * 1}em;
  line-height: ${lineHeight}em;
  font-family: ${props => props.theme.fonts.ms_font300};
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    font-size: 0.9em;
    margin-top: 3px;
  }
`;

const ProductTitleStyled = styled(ProductTitle)`
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.2em;
  max-height: ${lineHeight * 2}em;
  line-height: ${lineHeight}em;
  font-family: ${props => props.theme.fonts.ms_font700};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    flex: 1;
    font-size: 1.2em;
  }
`;

const ExtraInfo = styled(Details)`
  display: flex;
  flex: 1;
  align-items: center;
  color: ${props => props.theme.colors.opposite_main};
  font-family: ${props => props.theme.fonts.ms_font500};
  font-size: 1em;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    font-size: 0.96em;
    justify-content: flex-end;
  }
`;

const QuantityStyled = styled(Quantity)`
  display: flex;
  flex: 1;
  align-items: center;
  font-family: ${props => props.theme.fonts.ms_font500};
  font-size: 1em;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    font-size: 0.96em;
  }
`;

const UnitsControllerStyled = styled(UnitsController)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    transform: scale(0.96);
  }
`;

const ListItemActionsStyled = styled(ListItemActions)`
  flex: 4;
  max-width: 115px;
  min-width: 115px;
  ${'' /* background-color: rgba(0,0,0,0.3); */}
  @media (max-width: 768px) {
    flex: 2;
    max-width: auto;
    min-width: auto;
  }
`;

const ImageStyled = styled(Image)`
`;

@inject('basketStore')
@observer
class ProductListingGroupCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentListing: null,
    }
  }

  componentWillMount() {
    const { productGroup } = this.props;
    const currentListing = productGroup.productListings[0];
    this.setState({
      currentListing
    })
  }

  changeDisplayedListing = (id) => {
    const { productGroup } = this.props
    let currentListing = null;
    for (const listing of productGroup.productListings) {
      if( listing.id === id ) {
        currentListing = listing;
        break;
      }
    }
    this.setState({currentListing})
  }

  handleAddToBasket = async () => {
    const { currentListing } = this.state;

    if (currentListing === null) return;
    if (this.props.basketStore.isInBasket(currentListing.id)) {
        return;
    }
    try {
        await this.props.basketStore.addToBasket(currentListing.id);
        message.success(`${currentListing.product.name} has been added to your basket!`);
    }
    catch (e) {
        message.error(e.message);
        console.error(e);
        Sentry.captureException(e);
    }
  }

  handleCardClick = unblock => {
    if (document.body.clientWidth <= 768 || unblock) {
      const { productGroup, onDetailsClicked } = this.props
      const currentListing = this.state.currentListing;
      onDetailsClicked(productGroup, currentListing);
    }
  } 

  isInBasket = listing => {
    const { BasketItemsList } = this.props;
    for (const basketItem of BasketItemsList) {
      const id = basketItem.productListing.id;
      if(id === listing.id)
        return true;
    }
    return false;
  }

  render() {
    const { productGroup, BasketItemsList, restricted, onDetailsClicked, productOwner, onAddToBasketClicked, ...other } = this.props;
    const { currentListing } = this.state;
    const listingInBasket = this.isInBasket(currentListing)

    return (
      <Container {...other}>
        <Wrapper>
          <MainContent onClick={this.handleCardClick}>
            <ImageContainer onClick={() => this.handleCardClick(true)}>
              <ImageStyled scale={0.115} src={productGroup.product.getPictureSrc()}/>
            </ImageContainer>
            <Info>
              <SellerNameStyled text={productGroup.product.seller.name} uid={productGroup.product.seller.id}/>
              <ProductTitleStyled text={productGroup.product.name}/>
              <DetailStyled webft={true}>
                <QuantityStyled value={currentListing.getBaseQuantity()}/>
                <ExtraInfo group={productGroup.product} webft={true}/>
              </DetailStyled>
            </Info>
          </MainContent>
          <SubContent>
            <SubInfo>
              <UnitsControllerStyled restricted={restricted} productListings={productGroup.productListings} onChange={this.changeDisplayedListing}/>
              <DetailStyled>
                <QuantityStyled value={currentListing.getBaseQuantity()}/>
                <ExtraInfo group={productGroup.product}/>
              </DetailStyled>
            </SubInfo>
            <ListItemActionsStyled
              onDetailsClicked={() => onDetailsClicked(productGroup, this.state.currentListing)}
              inBasket={listingInBasket}
              onAddToBasketClicked={async () => this.handleAddToBasket()}
              restricted={restricted || productOwner}
            />
          </SubContent>
        </Wrapper>
      </Container>
    )
  }

}

ProductListingGroupCard.propTypes = {
  productGroup: PropTypes.object,
  onDetailsClicked: PropTypes.func,
  onAddToBasketClicked: PropTypes.func,
  BasketItemsList: PropTypes.array,
  restricted: PropTypes.bool,
  productOwner: PropTypes.bool
}

ProductListingGroupCard.defaultProps = {
  restricted: false,
  productOwner: false,
}

export default ProductListingGroupCard;
