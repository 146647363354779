import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const BusinessMenuItem = ({
  data,
  onClick,
  ...other
}) => {
  const image = data.picture ? data.picture : require("../../../assets/placeholder_user.png");

  return (
    <MenuItem onClick={() => onClick(data.id)} {...other}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={data.name} src={image} />
        </ListItemAvatar>
        <ListItemText
          primary={data.name}
          secondary={`${data.streetAddress}, ${data.city} ${data.state} ${data.zipcode}`}
        />
      </ListItem>
    </MenuItem>
  )
}

BusinessMenuItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

BusinessMenuItem.defaultProps = {

};

export default BusinessMenuItem;
