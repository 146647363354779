import { computed, observable, toJS } from 'mobx'

class Organization {
    @observable id
    @observable name

    set picture(picture) {
        this._picture = picture;
    }
    @computed get picture() {
        if (this._picture) {
            return this._picture.url;
        } else {
            return require('../../assets/placeholder_user.png');
        }
    }
}

Organization.displayName = "Organization";
export default Organization;
