import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import DatePicker from 'components/atomic/pickers/DatePicker';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 769px) {
    margin-right: 15px;
  }
`

const TextLabel = styled.div`
  font-size: 12px;
  margin-bottom: -2px;
  opacity: 0.9;
`

const StyledFormHelper = styled(FormHelperText)`
  ${'' /* min-width: 109px; */}
`

const StyledTextField = styled(DatePicker)`
  width: 150px;
  @media (max-width: 768px) {
    width: 120px;
  }
  font-size: 14px;
`

class DateInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      date: ''
    }
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ date: value });
  }

  // componentWillReceiveProps(nextProps) {
  //   const { editMode, name, index } = this.props
  //   const { date } = this.state
  //   if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
  //     this.props.onSave(index,name,date)
  //   }
  //   if(editMode && !nextProps.editMode) {
  //     this.setState({ date: nextProps.value });
  //   }
  // }

  update = date => {
    this.setState({ date });
  }

  render () {
    const { value, error, updateField, ...other } = this.props;

    return (
      <TextContainer>
        <TextLabel>
          Need By Date:
        </TextLabel>
        <StyledTextField
          value={value}
          onChange={(e) => updateField(e)}
          {...other}
        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </TextContainer>
    )
  }
}

DateInput.propTypes = {
  value: PropTypes.string,

};

DateInput.defaultProps = {

};

export default DateInput;
