import { action, observable, toJS } from 'mobx'
import DatabaseClient from '../../core/DatabaseClient'
import { profileStore } from '../index';

class MessagingStore {
    @observable conversations = []
    @observable numUnread = 0;

    init() {
        this.conversations = [];
        this.numUnread = [];
        this.setListener();
    }

    indexOf(conversationID) {
        for (let i = 0; i < this.conversations.length; i++) {
            if (this.conversations[i].id === conversationID) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Retrieves a conversation with the same uids as the array passed in
     * @param uids an array of uids
     */
    getConversation(uids) {
        // TODO: make this async when we no longer store every single conversation
        const containsAll = (arr1, arr2) => 
                arr2.every(arr2Item => arr1.includes(arr2Item))

        const sameMembers = (arr1, arr2) => 
                        containsAll(arr1, arr2) && containsAll(arr2, arr1);

        for (let i = 0; i < this.conversations.length; i++) {
            if (sameMembers(this.conversations[i].uids, uids)) {
                return this.conversations[i];
            }
        }
        return null;
    }

    /**
     * Async version of getConversation
     * @param {*} uid 
     */
    async getDirectConversation(uid) {
        // First try locally ("cache" hit)
        let conv;
        conv = this.getConversation([profileStore.business.id, uid]);
        // If no hit, try the database
        if (!conv) {
            conv = await DatabaseClient.getDirectConversation(profileStore.business.id, uid);
        }
        // If still no hit, return null
        if (!conv) {
            return null;
        }
        // Otherwise, add to cache
        else {
            await this.onCreateUpdate(conv);
            return conv
        }
    }

    @action
    calculateNumUnread() {
        let count = 0;
        for (let i = 0; i < this.conversations.length; i++) {
            if (this.conversations[i].unread.includes(profileStore.business.id)) {
                count++;
            }
        }
        this.numUnread = count;
    }

    @action
    async sendMessage(uids, msg) {
        if (uids.includes(profileStore.business.id)) {
            throw new Error("Cannot send message to self");
        }
        else if (!uids || uids.length === 0) {
            throw new Error("No recipients selected");
        }
        const conv = await DatabaseClient.createConversation([profileStore.business.id, ...uids]);
        await conv.sendMessage(msg);
    }

    @action
    async onCreateUpdate(conv) {
        const index = this.indexOf(conv.id);
        let _conv = conv;
        if (index > -1) {
            const _conv = this.conversations[index]; 
            const prevTimestamp = _conv.timestamp;
            
            if (prevTimestamp !== conv.timestamp) {
                await _conv.retrieveNewMessages();
                _conv.updateConversation(conv);

                // TODO: Make this implementation more efficient. An array probably wasn't the best DS to use
                this.conversations.replace(this.conversations.sort((a, b) => b.timestamp - a.timestamp));
            }
            else {
                _conv.updateConversation(conv);

            }
            // const _conv = this.conversations[index]; 
            // console.log("Specifically", toJS(_conv));
            
            // await _conv.retrieveNewMessages();
            // _conv.updateConversation(conv);
            // console.log("Updated conv", toJS(_conv));
            // // TODO: Make this implementation more efficient. An array probably wasn't the best DS to use
            // this.conversations.replace(this.conversations.sort((a, b) => b.timestamp - a.timestamp));
            // console.log("this.conversations is", toJS(this.conversations));
        }
        else {
            await _conv.init();
            if (this.conversations.length === 0) {
                this.conversations.push(_conv);
            }
            else {
                for (let i = 0; i < this.conversations.length; i++) {
                    if (_conv.timestamp > this.conversations[i].timestamp) {
                        this.conversations.splice(i, 0, _conv);
                        break;
                    }
                    else if (i === this.conversations.length - 1) {
                        this.conversations.push(_conv);
                        break;
                    }
                }
            }
        }
        this.calculateNumUnread();
    }

    @action
    onRemove(conv) {
        const index = this.indexOf(conv.id);
        if (index > -1) {
            this.conversations.splice(index, 1);
        }
        this.calculateNumUnread();
    }

    setListener() {
        DatabaseClient.setConversationsListener(profileStore.business.id, this.onCreateUpdate.bind(this), this.onCreateUpdate.bind(this), this.onRemove.bind(this));
    }
}

export default new MessagingStore()
