import React from 'react';
import { Icon, Typography } from '@material-ui/core/';
import Button from './Button';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled.span`
    margin-right: 5px;
`;
class ReloadButton extends React.Component {
    render() {
        return (
            <Button variant="contained" color="primary" onClick = {this.props.onClick}>
                { this.props.withText && 
                    <Text>
                        {this.props.withText && "Reload"}
                    </Text>
                }
                <Icon>refresh</Icon>
            </Button>
        );
    }
}

ReloadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    withText: PropTypes.bool
};

ReloadButton.defaultProps = {
    withText: false
}

export default ReloadButton;