import Service from './Service';
import User from "core/model/User";

const URL = 'user';

const DEFAULT_PERMISSIONS = {
    email: true,
    push: true
};

const DEFAULT_ROLE = "admin";

class UserService extends Service {
    async addBusiness(userID, businessID) {
        const data = {
            permissions: DEFAULT_PERMISSIONS,
            role: DEFAULT_ROLE
        };
        const resp = await this.client.put(`${this.url}/${encodeURIComponent(userID)}/business/${encodeURIComponent(businessID)}`, data);
        return this.parse(resp);
    }
}

export default new UserService(URL, User);
