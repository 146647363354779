import React, { Component } from 'react'
import {
	Redirect,
	Route
} from 'react-router-dom'
import { userStore } from '../stores'
import FirstTimeSurvey from '../pages/common/FirstTimeSurvey';
import WelcomePage from '../pages/common/WelcomePage';

export default function PrivateAccountRoute({component: Component, ...rest}, isAllowed) {
	if(!userStore.isAuthenticated) { // Not authenticated -> Go to login
		return (
			<Route {...rest}
			       render={(props) => (<Redirect to={{pathname: '/login', state: {from: props.location}}}/>)}
			/>
		)
	} else if (isAllowed) {
		switch (userStore.user.status) {
			// Active user
			case 'active':
				return (
					<Route {...rest}
					       render={(props) => (<Component {...props} />)}
					/>
				);
			// User that needs to make their first business
			case 'created':
				// Why did we need this if/else statement instead of just redirect?
				if (Component !== FirstTimeSurvey) {
					return (
						<Route {...rest}
						       render={(props) => (<Redirect to={{pathname: '/firsttimesurvey', state: {from: props.location}}}/>)}
						/>
					)
				}
				else {
					return (
						<Route {...rest}
						       render={(props) => (<Component {...props} />)}
						/>
					)
				}
			default:
				if (Component !== WelcomePage) {
					return (
						<Route {...rest}
						       render={(props) => (<Redirect to={{pathname: '/welcome', state: {from: props.location}}}/>)}
						/>
					)
				}
				else {
					return (
						<Route {...rest}
						       render={(props) => (<Component {...props} />)}
						/>
					)
				}

		}
	} else { // Not a buyer - not allowed to access this route, redirect to login
		return (
			<Route {...rest}
			       render={(props) => (<Redirect to={{pathname: '/login', state: {from: props.location}}}/>)}
			/>
		)
	}
}
