import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';

const ListStyled = styled(List)`
  width: 100%;
`
const ListItemStyled = styled(ListItem)`
  padding: 0;
`
const ListItemIconStyled = styled(ListItemIcon)`
  margin: 0;
`
const ListItemTextStyled = styled(ListItemText)`
  padding: 0;
`

const FarmerFilter = ({
  items,
  handleToggle,
  checked,
  ...other
}) => {

  return (
    <ListStyled {...other}>
      {items.map(value => (
        <ListItemStyled id="multiselect_list_item" key={value} dense button onClick={handleToggle(value)}>
          <ListItemIconStyled>
            <Checkbox
              id="multiselect_checkbox"
              edge="start"
              checked={checked.indexOf(value) !== -1}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIconStyled>
          <ListItemTextStyled primary={value} />
        </ListItemStyled>
      ))}
    </ListStyled>
  );
}

FarmerFilter.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

FarmerFilter.default = {

}

export default FarmerFilter;
