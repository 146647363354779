import React, {Component, Fragment} from 'react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components';
import Menu from '../../components/Menu'
import Topbar from '../../components/CommonTopBar'
import {LoadingSpinnerPage} from '../../components/LoadingSpinner'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Grid from '@material-ui/core/Grid';
import Button from '../../components/atomic/buttons/Button';
import Divider from '@material-ui/core/Divider';
import moment from 'moment'
import { message } from 'antd'
import OpenInNewLink from '../../components/atomic/links/OpenInNewLink';
import RequestModal from '../../components/organisms/RequestModal';
import { NumberPaginator } from '../../components/molecules/Pagination'
import { OrderBundleService } from "../../core/api"
import SpreadsheetDownloadModal from "./ViewOrders/SpreadsheetDownloadModal";
import Bottombar from "components/CommonBottomBar";

const NumberPaginatorStyled = styled(NumberPaginator)`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`

const IdText = styled(Typography)`
  font-size: 10px; 
  color: #;
  opacity: 0.2;
`;

const DownloadSpreadsheetButton = (props) => {
	return (
		<Button variant="contained" color="secondary" className={`button`} {...props}>
			Spreadsheet
			&nbsp; <Icon className="icon">get_app</Icon>
		</Button>
	);
};

@inject('invoiceStore', 'orderStore', 'userStore', 'profileStore')
@observer
class History extends Component {
	state = {
		isLoadingHistory: false,
		history: null,
		requestModalOpen: false,
		selectedOrder: null,
		spreadsheetModalOpen: false
	}

	async componentDidMount() {
		const { invoiceStore, orderStore } = this.props;
		try {
			// await Promise.all([invoiceStore.refresh()]);
			// let history = invoiceStore.items;
			// this.setState({
			// 	isLoadingHistory: false,
			// 	history
			// })
			window.scroll(0, 0);
		}
		catch (e) {
			message.error(e.message);
			console.error(e);
			Sentry.captureException(e);
		}
	}

	createTableItem = (list) => {
		return list.map((item) => {
			const statusMapping = {
				"created": "New",
				"accepted": "In Progress",
				"cancelled": "Cancelled",
				"declined": "Declined",
				"completed": "Completed"
			};
			const status = statusMapping[item.status] || item.status;
			return (
				<Tr key={`${item['id']}-history-item`} className="info-container">
					<Td><OpenInNewLink text={item.productListing.product.name} onClick={() => {
						this.setState({ selectedOrder: item });
						this.openRequestModal();
					}}/></Td>
					<Td>{item.quantityRequested} {item.unit}(s)</Td>
					<Td>{item.totalCost ? item.totalCost.toFixed(2) : (0).toFixed(2)}</Td>
					<Td>{status}</Td>
					<Td> {item.exchange.replace(/^\w/, c => c.toUpperCase())}</Td>
					<Td>{<Icon color={item.exchangeStatus ? "primary" : "secondary"}>{item.exchangeStatus ? "check" : "close"}</Icon>}</Td>
					<Td>{item.needByDate ? moment(item.needByDate).format("MM/DD/YY") : "N/A"}</Td>
					{/* <Td><Icon color={item.paymentStatus ? "primary" : "secondary"}>{item.paymentStatus ? "check" : "close"}</Icon></Td> */}
				</Tr>
			);
		})
	};

	handleAppeal = () => {
		return;
	}

	openRequestModal = () => {
		this.setState({ requestModalOpen: true });
	}

	closeRequestModal = () => {
		this.setState({ requestModalOpen: false });
	}

	getHistoryItems(items) {

		if(items.length === 0){
			return (
			  <div className="empty-text">
				<Typography variant="title" className="text">
				  No Records Found
				</Typography>
			  </div>
			)
		}

		return items.map((history) => {
			const { timestamp, id, orders, totalCost, seller, buyer} = history
			return (
					<ExpansionPanel key={`${id}-history-item`} className="table-container">
						<ExpansionPanelSummary expandIcon={<Icon>expand_more</Icon>} className="table-summary">
							<Grid container justify="space-between">
								<Grid container item xs={12} sm={6} direction="column" justify="center">
									<IdText>
										{id}
									</IdText>
									<Typography  className="md">
										<span className="colored-text">Buyer: </span>  {buyer.name}
									</Typography>
									<Typography  className="md">
										<span className="colored-text">Seller: </span> {seller.name}
									</Typography>
								</Grid>
								<Grid container item xs={12} sm={6} direction="column" justify="center" className="bottom-info">
									<Typography  className="md">
									<span className="colored-text">Date: </span> {moment(timestamp).format("MM/DD/YYYY h:mma")}
									</Typography>
								</Grid>
							</Grid>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails className="product-list">
							<Table>
								<Thead>
									<Tr>
										<Th>Product</Th>
										<Th>Quantity</Th>
										<Th>Total</Th>
										<Th>Status</Th>
										<Th>Exchange Method</Th>
										<Th>Exchanged?</Th>
										<Th>Need by Date</Th>
										{/* <Th>Payment Status</Th> */}
									</Tr>
								</Thead>
								<Tbody>
									{this.createTableItem(orders)}
								</Tbody>
							</Table>
							<div className="summary">
							<Grid container justify="space-between">
								<Grid container item xs={12} sm={7} direction="column" justify="center" className="right-bound">
									<Typography  className="sm">
										<span className="colored-text spaced">Subtotal:</span>  ${totalCost.toFixed(2)}
									</Typography>
									<Typography  className="sm">
										<span className="colored-text spaced">Discount:</span> $0.00
									</Typography>
									<Divider/>
									<Typography  className="sm">
									<span className="colored-text spaced">Total:</span> ${totalCost.toFixed(2)}
									</Typography>
								</Grid>
							</Grid>
							</div>
							<Button style={{visibility: 'hidden'}}variant="contained" disabled={true} color="secondary" className="appeal-button" onClick={(e) => this.handleAppeal(e)}>
									Appeal
							</Button>
						</ExpansionPanelDetails>
					</ExpansionPanel>
			)
		})
	}

	getTabIndex(tabs) {
    const path = this.props.location.pathname;
    for (let i of tabs.keys()) {
      if(tabs[i].path.indexOf(path) > -1){
        return i;
      }
    }
	}
	
	getTotalInCategory(list) {
    let sum = 0;
    if(list.length > 0){
      let cloneList = list.peek();
      cloneList.slice();
      for (let i = 0; i <= cloneList.length; i++) {
        if(cloneList[i]) {
          sum += cloneList[i].orders.slice().length;
        }
      }
    }
    return sum;
  }

	handleSpreadsheetButtonClick = (e) => {
		this.setState({ spreadsheetModalOpen: true });
	};

	render() {
		const { orderStore } = this.props;
		const { isLoadingHistory } = this.state
		// const tabs = [
		// 	{
		// 		name: 'History',
		// 		child: false,
		// 		path: '/history'
		// 	},
		// ];
		const tabs = [
      {
        name: `Requests`,
        child: false,
        path: '/request'
      },
      {
        name: `In Progress`,
        child: false,
        path: '/active'
      },
      {
        name: `Summary`,
        child: false,
        path: '/history'
      },
		];
		const mobileNavButtons = [
			{
				text: null
			},
			{
				text: "Spreadsheet",
				icon: (<Icon>get_app</Icon>),
				onClick: this.handleSpreadsheetButtonClick,
			},
		];

		const tabIndex = this.getTabIndex(tabs)
		return (
			<React.Fragment>
				<Menu />
				<Topbar
					title="Order Summary"
					sub="Overview of all current and past orders"
					tabs={tabs} tabIndex={tabIndex}
					isChildPath={false}
					mobilft={true}
					topBarButton={
						<DownloadSpreadsheetButton
							onClick={this.handleSpreadsheetButtonClick}
						/>
					}
				/>
				<Bottombar buttons={mobileNavButtons}/>
				<div className="historys-container">
					{
						isLoadingHistory ?
							<LoadingSpinnerPage />
							:
							<NumberPaginatorStyled
								getPage={async (page, limit) => {
									const { business } = this.props.profileStore;
									const query = {
										page,
										orderBy: {
												timestamp: "desc",
										},
										limit,
										buyerUID: business.isBuyer() ? business.id : undefined,
										sellerUID: business.isSeller() ? business.id : undefined
									};
									const [res, resp] = await OrderBundleService.getAll(query, true);
									return [res, resp.count];
								}}
								limit={9}
								renderItems={(items) => (
									<ul className="historys-list">
										{this.getHistoryItems(items)}
									</ul>
								)}
							/>
					}
				</div>
				{
					this.state.requestModalOpen && this.state.selectedOrder && 
          		<RequestModal open={this.state.requestModalOpen} onClose={this.closeRequestModal} hideController request={this.state.selectedOrder} />
				}
				{ this.state.spreadsheetModalOpen && <SpreadsheetDownloadModal
					open={this.state.spreadsheetModalOpen}
					onClose={() => this.setState({ spreadsheetModalOpen: false })}
				/>}
			</React.Fragment>
		)
	}

}

export default withRouter(History);
