import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toJS } from 'mobx'
import { TextField } from '@material-ui/core';
import Button from '../../atomic/buttons/Button';
import EditPanel from './EditPanel';
import Header from './Header'
import BusinessChipInput from '../BusinessChipInput'
import ChipAutoComplete from "../ChipAutoComplete";
import Label from '../../../core/model/Label'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 10px 5px;
`;

const EditPanelStyled = styled(EditPanel)`
`;

const ButtonStyled = styled(Button)`
  display: flex;
  margin-left: 8px;
  text-transform: none;
`;

const ChipContainer = styled.div`
  z-index: 300;
`

const ChipAutoCompleteStyled = styled(ChipAutoComplete)`

`

const NoteCreator = ({
  id,
  onSave,
  onCancel,
  labels,
  options,
  initialText,
 ...other 
}) => {
  const [customOptions, updateOptions] = useState(options)
  const [text, setText] = useState(initialText);
  const [multi, setLabels] = useState(toJS(labels));

  const handleChange = value => {
    setLabels(value)
  };

  const slugify = text => text.toString().toLowerCase()
  .replace(/\s+/g, '-')           // Replace spaces with -
  .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
  .replace(/\-\-+/g, '-')         // Replace multiple - with single -
  .replace(/^-+/, '')             // Trim - from start of text
  .replace(/-+$/, '');            // Trim - from end of text
  
  const handleCreate = value => {
    const newLabel = new Label(undefined,value,{ value: slugify(value), type: 'label' });
    const newMulti = [newLabel, ...multi]
    const newOptions = [newLabel, ...customOptions]
    setLabels(newMulti)
    updateOptions(newOptions)
  }

  const submit = () => {
    const noteData = { text, labels: multi }
    onSave(noteData)
  }

  return (
    <Container {...other}>
      <ChipContainer>
        <ChipAutoCompleteStyled
          value={multi}
          onChange={handleChange}
          options={customOptions}
          onCreateOption={handleCreate}
          creatable={true}
          placeholder="Labels" />
      </ChipContainer>
      <EditPanelStyled
        initialValue={text}
        onChange={setText}
      />
      <ButtonWrapper>
        <ButtonStyled disabled={!(text != null && text !== '<p><br></p>')} onClick={submit} variant="contained">{id ? 'Update':'Post'}</ButtonStyled>
        <ButtonStyled onClick={onCancel} variant="contained" color="secondary">Cancel</ButtonStyled>
      </ButtonWrapper>
    </Container>
  )
}

NoteCreator.propTypes = {
  labels: PropTypes.array,
  options: PropTypes.array
}

NoteCreator.defaultProps = {
  labels: [],
  options: []
}

export default NoteCreator;