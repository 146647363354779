import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import Popup from 'reactjs-popup';
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import BottomBar from '../../CommonBottomBar'
import Check from '@material-ui/icons/Check'
import Button from '../../atomic/buttons/Button'
import { LoadingSpinnerPage } from '../../LoadingSpinner';

// Inventory Edit Fields
import EditWrapper from '../EditWrapper';
import ColumnImage from '../../organisms/InventorySheet/ColumnImage';
import ColumnInput from '../../organisms/InventorySheet/ColumnInput';
import ColumnText from '../../organisms/InventorySheet/ColumnText';
import ColumnDropdown from '../../organisms/InventorySheet/ColumnDropdown';
import ColumnChipSelect from '../../organisms/InventorySheet/ColumnChipSelect';
import ColumnQuantity from '../../organisms/InventorySheet/ColumnQuantity';
import ColumnPackaging from '../../organisms/InventorySheet/ColumnPackaging';
import SheetEditModal from '../SheetEditModal';

const ColumnTextStyled = styled(ColumnText)`
  min-width: 200px;
`
const ColumnDropdownStyled = styled(ColumnDropdown)`

`
const ColumnPackagingStyled = styled(ColumnPackaging)`
  width: 100%;
`
const ColumnQuantityStyled = styled(ColumnQuantity)`
  width: 100%;
`
const ColumnChipSelectStyled = styled(ColumnChipSelect)`

`
const ColumnImageStyled = styled(ColumnImage)`
  width: 160px;
  height: 160px;
`
const ColumnInputStyled = styled(ColumnInput)`
  width: 100%;
`

const SheetEditModalStyled = styled(SheetEditModal)`
  flex-direction: column;
`

const CombineRow = styled.div`
  display: flex;
  flex-direction: row;
`

const EditWrapperStyled = styled(EditWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${'' /* padding-bottom: 20px; */}
`

const EditWrapperHalfStyled = styled(EditWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  ${'' /* padding-bottom: 20px; */}
`

const ButtonStyled = styled(Button)`
  margin: 3px 7px;
  padding: 6px 25px;
  text-transform: none;
  ${'' /* font-size: 0.9em; */}
`

const BubbleStyles = {
  margin: '4px',
  padding: '5px 0 5px 13px'
}

class InventoryEditModal extends Component {
  constructor(props) {
    super(props)
    // this indicates that bulk edit was clicked inistially
    this.bulkEntry = false;
    this.state = {
      data: [],
    }
  }

  firstInEdit = () => {
    const { rowsInEdit } = this.props;
    if(rowsInEdit && rowsInEdit.length > 0) {
      if(rowsInEdit.length > 1) {
        this.bulkEntry = true;
        return null;
      }
      if(!this.bulkEntry) {
        return rowsInEdit[0];
      }
    }
    this.bulkEntry = false;
    return null;
  }

  checkIfFile = (value,index) => {
    const { data } = this.props;
    if(isNaN(index)) {
      return null;
    }

    if (!value) {
      return null;
    } else if (typeof value === 'object'){
      return data[index]['pictures'];
    } else {
      return value;
    }
  }

  getError = name => {
    const { data } = this.props;
    const index = this.firstInEdit();
    let error = null;

    if (data && data.length > 0) {
      switch(name) {
        default:
          error = data[index][name].error;
          break;
      }
    }

    return error;
  }

  getValue = name => {
    const { data } = this.props;
    const index = this.firstInEdit();
    let value = null;

    if (data && data.length > 0) {
      switch(name) {
        case 'picture':
          value = this.checkIfFile(data[index][name].value, index);
          break;
        default:
          value = data[index][name].value;
          break;
      }
    }
    return value;
  }

  closeModal = () => {
    const { data, onCancel, onDelete, } = this.props;
    const index = this.firstInEdit();
    if(data && data.length > 0) {
      const isNew = data[index]['id'] === null;
      if(isNew) {
        onDelete(true, index);
      } else {
        onCancel(index);
      }
    }
  }

  render() {
    const { rowsToSave, rowsInEdit, onFieldChange, renderHash, foodUnits, foodOptions, onSave, ...other } = this.props;
    const index = this.firstInEdit();
    const NavButtons = [
      {
        text: 'Save',
        onClick: (() => onSave(index)),
      }
    ]
    const hasIndex = index != null; 
    const foodOptionsAlt = Object.assign({}, foodOptions);
    delete foodOptionsAlt['OTHER'];
    return !hasIndex ? null : (
      <SheetEditModalStyled
        header="Edit Inventory Item"
        bottomNavigation={NavButtons}
        onClose={this.closeModal}
        open={hasIndex}
        modalButtons={[
          <ButtonStyled color="primary" variant="contained" onClick={() => onSave(index)}>Save</ButtonStyled>,
          <ButtonStyled variant="contained" onClick={this.closeModal}>Cancel</ButtonStyled>
        ]}
      >
        <EditWrapperStyled header='Image'>
          <ColumnImageStyled
            key={`img-${index}-${renderHash}`}
            name='picture'
            value={this.getValue('picture')}
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            editMode
          />
        </EditWrapperStyled>
        <EditWrapperStyled header='Name'>
          <ColumnInputStyled
            key={`name-${index}-${renderHash}`}
            name="name" 
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('name')}
            error={this.getError('name')}
            fullWidth
            editMode 
          />
        </EditWrapperStyled>
        <CombineRow>
          <EditWrapperHalfStyled header='Logistics'>
            <ColumnChipSelectStyled
              key={`logistics-${index}-${renderHash}`}
              name="logistics"
              index={index}
              toSave={rowsToSave}
              onSave={onFieldChange}
              value={this.getValue('logistics')}
              error={this.getError('logistics')}
              editMode
              options={{
                DELIVERY: 'Delivery',
                PICKUP: 'Pickup',
              }}
            />
          </EditWrapperHalfStyled>
          <EditWrapperHalfStyled header='Details'>
            <ColumnChipSelectStyled
              key={`details-${index}-${renderHash}`}
              name="details"
              index={index}
              toSave={rowsToSave}
              onSave={onFieldChange}
              value={this.getValue('details')}
              error={this.getError('details')}
              editMode
              options={{
                ORGANIC: 'Organic',
                LOCAL: 'Locally Grown',
              }}
            />
          </EditWrapperHalfStyled>
        </CombineRow>
        <EditWrapperStyled header='Quantity'>
          <ColumnQuantityStyled
            key={`qty-${index}-${renderHash}`}
            name="quantity" 
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('quantity')}
            error={this.getError('quantity')}
            options={foodUnits}
            editMode
          />
        </EditWrapperStyled>
        <EditWrapperStyled header='Pack Formats (Optional)'>
          <ColumnPackagingStyled
            key={`qty-${index}-${renderHash}`}
            name="packFormats" 
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('packFormats')}
            error={this.getError('packFormats')}
            options={foodUnits}
            editMode
          />
        </EditWrapperStyled>
        <EditWrapperStyled header='Category'>
          <ColumnDropdownStyled
            key={`food-${index}-${renderHash}`}
            name="foodCategory" 
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('foodCategory')}
            error={this.getError('foodCategory')}
            editMode
            options={foodOptionsAlt}
            errorText={"Must be less than 26 characters"}
            allowOther={true}
          />
        </EditWrapperStyled>
        <EditWrapperStyled header='Description'>
          <ColumnTextStyled
            key={`description-${index}-${renderHash}`}
            name="description"
            index={index}
            toSave={rowsToSave}
            onSave={onFieldChange}
            value={this.getValue('description')}
            error={this.getError('description')}  
            editMode
          />
        </EditWrapperStyled>
      </SheetEditModalStyled>
    )
  }
}

InventoryEditModal.PropTypes = {
  index: PropTypes.number.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  rowsToSave: PropTypes.array.isRequired,
  rowsInEdit: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

InventoryEditModal.defaultProps = {
  rowsToSave: [],
  rowsInEdit: [],
  data: [],
}

export default InventoryEditModal; 