import React, { Component } from 'react'
import styled, {css} from 'styled-components'
import Button from './Button'
import breakpoint from 'styled-components-breakpoint'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import { userStore } from '../../../stores';

const StyledButton = styled(Button)`
    width: 100%;
`

const Container = styled.div`
    display: flex;
    justify-content: center;
    ${props => {
        if (props.withText) {
            return css`
                width: 100px;
            `
        }
        else {
            return css`
                width: 50px;
            `
        }
    }}
    ${breakpoint('mobile_min', 'mobile_max')`
        font-size: 13px;
    `}
    
`

class LogoutButton extends Component {
    logout = async () => {
        await userStore.logout();
    }

    render() {
        const {withText, withIcon, children, className, ...other} = this.props;
        return (
            <Container withText={withText} className={className}>
                    <StyledButton onClick={this.logout} {...other}>
                        {withIcon && (<Icon style={{color: "smokewhite"}}>exit_to_app</Icon>)}
                        {withText ? "Logout" : ''}
                        {children}
                    </StyledButton>
            </Container>
        )
    }
}

LogoutButton.propTypes = {
    withText: PropTypes.bool,
    withIcon: PropTypes.bool
}

LogoutButton.defaultProps = {
    withText: false,
    withIcon: true
}

export default LogoutButton;