import React, { Component } from 'react'
import Button from '../../atomic/buttons/Button';
import ChipInput from '../../molecules/ChipInput/';
import PendingList from '../../molecules/PendingList/';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import DatabaseClient from '../../../core/DatabaseClient'
import { confirmAlert } from 'react-confirm-alert';
import { observer, inject } from 'mobx-react'
import { message } from 'antd'
import { LoadingSpinnerPage } from '../../LoadingSpinner';
import { InvitationService } from "../../../core/api";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 10px;
  width: 100%;
`
@inject('profileStore', 'clientListStore')
@observer
class BuyerInvites extends Component {
    constructor (props) {
        super(props)
        this.state = {
            pendingInvites: [],
            pendingEmails: [],
            chips: [],
            message: null,
        }
        this.chipInputPanel = React.createRef();
    }

    submitEmails = async () => {
        let pendingIDs = [], emails = [];

        if(this.state.chips.length === 0 ){
            return;
        }

        emails = this.state.chips.filter( item => {
            let indexFound = this.props.pendingEmails.indexOf(item);
            if(indexFound !== -1) {
                pendingIDs.push(this.props.pendingInvites[indexFound].id)
            } else {
                return true;
            }
        })
        for (let i = 0; i < emails.length; i++) {
            emails[i] = emails[i].toLowerCase();
        }
        try {
            if(this.props.pendingEmails.length > 0){
                await DatabaseClient.resendInvitations(pendingIDs, this.state.message);
            }
            await Promise.all(emails.map((email) => InvitationService.create({
                email,
                sender: this.props.profileStore.business.id,
                message: this.state.message || null
            })));
            await this.props.clientListStore.refreshPendingInvitations();
            message.success('Invitations successfully sent.');
        }
        catch(e) {
            message.error('Some invitations were not sent successfully');
            message.error(e.message);
            console.error(e);
            Sentry.captureException(e);
        }
    }

    clearChips = () => this.setState({chips: [], message: null})

    customMessage = event => this.setState({ message: event.target.value || null })

    addChip = arr => {
        const chips = [...new Set([...this.state.chips, ...arr])];
        this.setState({chips})
    }

    deleteChip = (chip,index) => {
        var chips = [...this.state.chips]; // make a separate copy of the array
        chips.splice(index, 1);
        this.setState({chips});
    }

    onImport = (arr) => {
        this.addChip(arr)
    }

    render () {
        return (
            <MuiThemeProvider>
                <FormContainer>
                    <ChipInput
                        ref={(instance) => { this.chipInputPanel = instance }}
                        chips={this.state.chips}
                        addChip={this.addChip}
                        deleteChip={this.deleteChip}
                        handleSubmit={this.submitEmails}
                        clearChips={this.clearChips}
                        customMessage={this.customMessage}
                        message={this.state.message}
                        onImport={this.onImport}
                        isSeller={this.props.profileStore.isSeller}
                    />
                    {
                        this.props.pendingEmails.length > 0 && (
                            <PendingList
                                chips={this.state.chips}
                                emails={this.props.pendingEmails}
                                transferEmails={this.addChip}
                            />
                        )
                    }
                </FormContainer>
            </MuiThemeProvider>
        )
    }
}

export default BuyerInvites
