import { action, observable , computed} from 'mobx'
import AuthManager from '../../core/AuthManager'
import DatabaseClient from '../../core/DatabaseClient'
import { ReasonForPostOptions, FoodOptions, FoodUnits } from '../../core/core';
import { getFlattenedValues } from '../FormHelper';
import moment from 'moment';
import validate from 'core/utilities/FormValidator';
import firebase from 'firebase';
import { Product, ProductListing, Picture } from '../../core/model'
import { profileStore } from '..';

class InventoryStore {
  @observable deals = []
  @observable currentDealID = null
  @observable form = null;
  @observable priceDisabled = false;
  defaultForm = {
    fields: {
      name: {
        value: null,
        validation: {
          presence: true,
          length: {
            minimum: 1,
            maximum: 50,
            message: 'is required and must be less than 50 characters',
          },
        },
        error: null,
      },
      packFormats: {
        value: [],
        validation: (() => {
          return {
            validFormat: {
              message: '^A package format is invalid. Please, ensure all fields are complete.',
            },
          };
        }),
        error: null,
      },
      quantity: {
        value: null,
        validation: {
          presence: {
            message: '^Must have at least 1 valid quantity unit.'
          }
        },
        error: null,
      },
      organic: {
        value: false,
        validation: {
          presence: true,
          inclusion: {
            within: [true, false],
          },
        },
        error: null,
      },
      local: {
        value: false,
        validation: {
          presence: true,
          inclusion: {
            within: [true, false],
          },
        },
        error: null,
      },
      delivery: {
        value: false,
        validation: (() => {
          return {
            atLeastOneOf: {
              otherField: this.form.fields.pickup,
              message: '^Item must be available for either pickup, delivery, or both',
            },
            inclusion: {
              within: [true, false],
            },
          };
        }),
        error: null,
      },
      pickup: {
        value: false,
        validation: (() => {
          return {
            atLeastOneOf: {
              otherField: this.form.fields.delivery,
              message: '^Item must be available for either pickup, delivery, or both',
            },
            inclusion: {
              within: [true, false],
            },
          };
        }),
        error: null,
      },
      description: {
        value: null,
        validation: {
          presence: false,
          length: {
            maximum: 300,
            message: 'must be less than 300 characters',
          },
        },
        error: null,
      },
      foodCategory: {
        value: null,
        validation: {
          presence: {
            message: "^This field is required",
          },
          length: {
            minimum: 1,
            maximum: 25,
            message: '^This field is required and must be less than 25 characters',
          },
        },
        error: null,
      },
      picture: {
        value: null,
        validation: {
          presence: false,
        },
        error: null,
      }
    },
    validation: {
      error: null,
    },
  }

  @action
  resetForm() {
    this.priceDisabled = false;    
    this.form = Object.assign({}, this.defaultForm);
  }

  @action
  onFieldChangeAddDeal = (field, value) => {
    // Go ahead and update the value
    // Treat empty strings as null
    if (value === '') {
      value = null;
    }
    this.form.fields[field].value = value;
    this.validateField(field);
  }
  @action
  validateField = (field) => {
    let data = {}; // The data to validate
    data[field] = this.form.fields[field].value;
    let validationRules = {}; // The rules to use in validation
    validationRules[field] = this.form.fields[field].validation;
    let err = validate(data, validationRules);
    // Update error message
    if (err !== undefined) {
      this.form.fields[field].error = err[field][0];
      return false;
    }
    else {
      this.form.fields[field].error = null;
      return true;
    }
  }

  @action
  validateAll = () => {
    // Start off assuming no errors are present
    this.form.validation.error = null;
    // let data = getFlattenedValues(this.form, 'value');
    //let validationRules = getFlattenedValues(this.form, 'validation');
    Object.keys(this.form.fields).forEach( (field) => {
      let validated = this.validateField(field);
      if (!validated) {
        this.form.validation.error = "Form failed validation.";
      }
    })
    return (this.form.validation.error === null);
    //this.form.validation.error = validate(data, validationRules);
  }

  @action
  onCheckboxChange = (field, subfield) => {
      var index = this.form.fields[field].value.indexOf(subfield);
      if (index > -1) {
          this.form.fields[field].value.splice(index, 1);
      }
      else {
          this.form.fields[field].value.push(subfield);
      }
  }

  @action
  validateValue = (field) => {
    let data = {}; // The data to validate
    data[field] = this.form.fields[field].value;
    let validationRules = {}; // The rules to use in validation
    validationRules[field] = this.form.fields[field].validation;
    let err = validate(data, validationRules);

    return err;
  }

  populateForm = (deal) => {

    const dealID = deal['id'];
    deal['delivery'] = { value: false };
    deal['pickup'] = { value: false };
    deal['logistics'].value.map( item => {
      switch (item) {
        case 'Delivery':
          deal['delivery'] = { value: true };
          break;
        case 'Pickup':
          deal['pickup'] = { value: true };
          break;
        default:
          break;
        }
    })
    deal['details'].value.map( item => {
      switch (item) {
        case 'Locally Grown':
          deal['local'] = { value: true };
          break;
        case 'Organic':
          deal['organic'] = { value: true };
          break;
        default:
          break;
        }
    })

    delete deal['id'];
    delete deal['inStore'];
    delete deal['edit'];
    delete deal['quote'];
    delete deal['logistics'];
    delete deal['details'];

    
    Object.keys(this.form.fields).forEach( (field) => {
      this.form.fields[field].value = deal[field] !== undefined ? deal[field].value : this.defaultForm.fields[field].value;
    });

    this.resetErrors();
    this.currentDealID = dealID;
  }

  getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; // January is 0!
    var yyyy = today.getFullYear();

    if(dd<10) {
        dd = '0'+dd
    } 

    if(mm<10) {
        mm = '0'+mm
    } 

    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  constructor() {
    this.resetForm();
  }

  /**
   * Populates the deals observable with all the deals of the currently
   * logged in user.
   */
  async init() {
    // TODO: autopopulate the deals observable
    this.resetForm();
  }

  @action
  async refreshDeals() {
    let _deals = await DatabaseClient.getProductsBySeller(profileStore.business.id);
    // Format deals properly
    this.deals.replace(_deals);
  }

  @action
  async getDeals() {
    // Refresh the deals
    await this.refreshDeals();
    return this.deals;
  }

  /**
   * Prepares a deal object from the given form
   * @param {Object} form 
   * @return an object with the contents of the form and the pictures. 
   * The format is {deal: {}, pictures: <array>}, where pictures may or
   * may not exist depending on whether the user has uploaded pictures.
   */
  prepareDeal() {
    let obj = {};
    let vals = getFlattenedValues(this.form, 'value');

    let id = this.currentDealID;

    obj['deal'] = new Product(vals.delivery, vals.description, id, vals.foodCategory, vals.local, vals.organic, vals.name,
                  vals.packFormats, vals.pickup, undefined, undefined, vals.quantity, 0, profileStore.business.id);
    if (vals.picture) {
      obj.picture = vals.picture; // TODO: integrate pictures with InventoryItem
    }
    return obj;
  }

  prepareStoreFrontItem() {

    let obj = {};
    let id = this.currentDealID;
    const { deal } = this.prepareDeal();
    const unit = Object.keys(deal.quantity)[0];

    obj['productListing'] = new ProductListing(undefined, undefined, undefined, undefined, 
      deal, id, undefined, undefined, profileStore.business.id, unit, undefined);
    return obj;
  }

  @action
  async createStoreFrontItem() {
    let { productListing } = this.prepareStoreFrontItem();
    const [id, err] = await DatabaseClient.createProductListing(productListing, true);
    return id;
  }

  /**
   * Creates a deal using all the fields in the form observable
   * @throws an error when the form fails to be validated or there is
   *  a problem during database insertion
   */
  @action
  async createDeal() {
    // Validate fields
    if (!this.validateAll()) {
      throw new Error('Form failed validation.');
    }
    let {deal, picture} = this.prepareDeal();
    const id = await DatabaseClient.createProduct(deal, picture);
    this.resetForm();
    return id;
  }

  @action
  loadDeal(dealID) {
    // Get the deal of interest
    for (let i = 0; i < this.deals.length; i++) {
      if (this.deals[i]['id'] === dealID) {
        let deal = Object.assign({}, this.deals[i]);

        // Populate the form with the given deal details
        Object.keys(this.form.fields).forEach( (field) => {
          this.form.fields[field].value = deal[field] !== undefined ? deal[field] : this.defaultForm.fields[field].value;
        });

        this.resetErrors();
        this.currentDealID = dealID;
        return;
      }
    }
  }

  @action
  getDeal(dealID) {
    for (let i = 0; i < this.deals.length; i++) {
      if (this.deals[i]['id'] === dealID) {
        return this.deals[i];
      }
    }
  }

  @action
  async editDeal(dealID) {
    // Validate fields
    if (!this.validateAll()) {
      throw new Error('Form failed validation.');
    }
    let {deal, picture} = this.prepareDeal();
    if (picture === undefined) picture = null;

    if (typeof picture === 'string') {
      await DatabaseClient.updateProduct(deal); // Just the OG picture. TODO: change this flaky logic
    } else {
      await DatabaseClient.updateProduct(deal, picture);
    }
    this.resetForm();
  }

  @action
  resetErrors() {
    Object.keys(this.form.fields).forEach( (field) => {
      this.form.fields[field].error = null;
    });
    this.form.validation.error = null;
  }

  @action
  async archiveDeal(dealID) {
    await DatabaseClient.deleteProduct(dealID);
    for (let i = 0; i < this.deals.length; i++) {
      if (this.deals[i]['id'] === dealID) {
        this.deals.splice(i, 1);
        return;
      }
    }
  }
}


//inventoryStore.init();
export default new InventoryStore();
