import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import CloseButton from '../../atomic/buttons/CloseButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const CloseButtonStyled = styled(CloseButton)`

`

const CheckboxStyled = styled(Checkbox)`

`

const LinkStyled = styled(Link)`
  color: #40c388;
  font-family: ${props => props.theme.fonts.ms_font500};
  font-size: 18px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 550px) {
    flex: 1;
    ${'' /* flex-direction: column; */}
    justify-content: space-between; 
  }
`;

const TextContainer = styled.div`
  margin-left: 40px;
  @media (max-width: 550px) {
    margin-left: 0;
    flex-direction: column;
    justify-content: space-between; 
  }
`;

const SubheaderContainer = styled(ListSubheader)`
  line-height: inherit; 
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(237, 251, 244);
  border-bottom: 1px solid #eee;
  @media (max-width: 550px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 10px;
    align-items: flex-start;
  }
`

const HyperLinkIcon = styled(Icon)`
  font-size: 10px;
  margin-bottom: 4px;
`;

class SellerHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  handleSellerClick = (event) => {
    if (document.body.clientWidth <= 768) {
      event.preventDefault();
    }
  }

  render() {
    const { 
      seller, 
      sellerID, 
      cost, 
      checked, 
      onSelected,
      onDelete,
      indeterminate
    } = this.props;
    return (
      <SubheaderContainer disableSticky key={`basket-seller-${sellerID}`}>
        <CheckboxStyled
          checked={checked}
          indeterminate={indeterminate}
          onChange={(e) => onSelected(e.target.checked)}
        />
        <ContentContainer>
          <LinkStyled to={`/profile/${sellerID}`}>
            {seller}<HyperLinkIcon>open_in_new</HyperLinkIcon>
          </LinkStyled>
          <TextContainer>{`Total: $${cost.toFixed(2)}`}</TextContainer>
        </ContentContainer>
        <CloseButtonStyled color='secondary' onClick={async() => onDelete()}/>
      </SubheaderContainer>
    )
  }
}

SellerHeader.propTypes = {
  seller: PropTypes.string.isRequired,
  sellerID: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
};

SellerHeader.defaultProps = {

};

export default SellerHeader;