import { observable, toJS } from 'mobx'
import { orderSchema as schema } from '../schemas'
import joi from 'joi-browser'
import moment from 'moment'

export const orderStatus = Object.freeze({
	created: "created",
	accepted: "accepted",
	declined: "declined",
	cancelled: "cancelled",
	completed: "completed"
});

class Order {
    @observable bundleID
    @observable buyer
    @observable _buyerUID
    @observable productListing
    @observable exchange
    @observable exchangeStatus
    @observable id
    @observable needByDate
    @observable paymentStatus
    @observable price
    @observable quantityRequested
    @observable seller
    @observable _sellerUID
    @observable status
    @observable timestamp
    @observable totalCost
    @observable unit
    // also has quantityAvailable and recentProductListing (not shown here but set by DBClient)

    constructor(bundleID = null, buyer = null, buyerUID = null, productListing = null,
                exchange = null, exchangeStatus = false, id = null, needByDate = null, paymentStatus = false,
                price = null, quantityRequested = 0, seller = null, sellerUID = null, status = null, timestamp = 0,
                unit = null, totalCost = 0) {
        Object.assign(this, { bundleID, buyer, buyerUID, productListing, exchange, exchangeStatus, id, needByDate, paymentStatus, price, quantityRequested, seller, sellerUID, status, timestamp, unit, totalCost });
    }

    validate(ignoreLimit = false) {
        if (!this.buyerUID || !this.sellerUID || this.buyerUID.length === 0 || this.sellerUID.length === 0) {
            throw new Error("No associated user");
        }

        // ProductListing must not be null
        if (this.productListing == null || this.productListing.product == null) {
            throw new Error("No associated product listing and/or product");
        }
        const product = this.productListing.product;

        // Exchange must be pickup or delivery
        if (product.delivery && product.pickup) {
            if (this.exchange !== "delivery" && this.exchange !== "pickup") {
                throw new Error(`Exchange for ${product.name} must be one of delivery or pickup`);
            }
        }
        else if (product.delivery) {
            if (this.exchange !== "delivery") {
                throw new Error(`${product.name} is only available for delivery`);
            }
        }
        else if (product.pickup) {
            if (this.exchange !== "pickup") {
                throw new Error(`${product.name} is only available for pickup`);
            }
        }

        // Need by date either a timestamp or null - OK
        if (this.needByDate !== null && isNaN(this.needByDate)) {
            throw new Error(`Need by date for ${product.name} must be a timestamp date or be empty`);
        }
        if (this.needByDate !== null) {
            // Expect timestamp to be a UTC one
            const currentTimestampUTC = +moment().startOf("day");
            const needByDate = +moment(this.needByDate);
            if (currentTimestampUTC > needByDate) {
                throw new Error(`Need by date for ${product.name} must be on or after ${moment().startOf("day").format("MM-DD-YYYY")}`);
            }
        }

        // Quantity requested
        if (this.quantityRequested == null || this.quantityRequested < 1) {
            throw new Error("Quantity requested must be a positive integer");
		}

		// Can make quantity above limit if order has been accepted
		if (!ignoreLimit) {
			const unit = this.productListing.unit;
			if (unit in product.packFormats && product.quantity[product.packFormats[unit].conversionUnit] !== null && product.quantity[product.packFormats[unit].conversionUnit] < this.quantityRequested) {
				throw new Error(`${this.quantityRequested} ${unit}(s) requested for ${product.name} but only ${this.productListing.getBaseQuantity()} ${unit}(s) available`);
			}
			else if (unit in product.quantity && product.quantity[unit] !== null && product.quantity[unit] < this.quantityRequested) {
				throw new Error(`${this.quantityRequested} ${unit}(s) requested for ${product.name} but only ${this.productListing.getBaseQuantity()} ${unit}(s) available`);
			}
		}

        if (this.price != null && this.price < 0) {
            throw new Error("Price must be non-negative");
        }
    }

    formatForDB() {
        this.validate();

        // Make sure the price and totalCost match up
        if (this.productListing.price === null) {
            this.totalCost = null;
        }
        else {
            this.totalCost = this.productListing.price * this.quantityRequested;
        }

        let data = observable({
            bundleID: this.bundleID,
            buyerUID: this.buyerUID,
            exchange: this.exchange,
            exchangeStatus: this.exchangeStatus,
            needByDate: this.needByDate,
            paymentStatus: this.paymentStatus,
            price: this.price,
            quantityRequested: this.quantityRequested,
            sellerUID: this.sellerUID,
            status: this.status,
            timestamp: this.timestamp,
            totalCost: this.totalCost,
            unit: this.unit
        });
        const jsData = toJS(data);
        const result = joi.validate(jsData, schema);
        if (result.error) {
            throw new Error(result.error.details[0].message)
        }
        return jsData;
	}
	
	isAccepted() {
		return this.status === "accepted";
	}
}

Order.displayName = "Order";
export default Order;
