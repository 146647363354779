import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import ImageUpload from '../../molecules/ImageUpload';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 35px;
  height: 35px;
`

const EditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  width: 100px;
  height: 100px;
`

const Image = styled.img`
  max-width: 10%;
  max-height: 10%;
  ${props => {
    return css`
      transform: rotate(${props.rotation}deg) scale(10)
    `
  }}
`

@inject('sheetStore')
@observer
class ColumnImage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rotation: 0,
      source: ''
    }
  }

  componentDidMount() {
    const { value, index, sheetStore } = this.props;
    // sheetStore.setImageAtIndex(index,null);
    this.setState({ source: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index, sheetStore } = this.props
    const { source } = this.state
    const src = (index < sheetStore.unsavedImages.length && sheetStore.unsavedImages[index]) ? sheetStore.unsavedImages[index] : source;
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,src)
    }
    if(editMode && !nextProps.editMode) {
      let source = null;
      if (!nextProps.value) {
        source = null;
      } else if (typeof nextProps.value === 'object'){
        source = nextProps['value'].value;
      } else {
        source = nextProps.value;
      }
      this.setState({ source });
    }
  }

  onDrop = (file) => {
    const { sheetStore, index } = this.props;
    sheetStore.setImageAtIndex(index,file[0]);
    this.setState({ source: file[0] });
  }

  setRotation = (rotation) => {
    this.setState({ rotation });
  }

  onClearDrop = event  => {
    const { sheetStore, index } = this.props;
    event.stopPropagation();
    sheetStore.setImageAtIndex(index,null);
    this.setState({ source: null, rotation: 0 });
  }

  getURL = value => {
    if (!value) {
      return null;
    } else if (typeof value === 'object'){
      return value['preview'];
    } else {
      return value;
    }
  }

  render () {
    const { editMode, value, sheetStore, index, ...other } = this.props;
    const { source, rotation } = this.state;
    const data = (index < sheetStore.unsavedImages.length && sheetStore.unsavedImages[index]) ? sheetStore.unsavedImages[index] : source;
    const src = data ? (data.preview ? data.preview : data) : null;
    return editMode ? (
      <EditContainer {...other}>
        <ImageUpload onDrop={this.onDrop} srcRotation={rotation} setRotation={this.setRotation} onClearDrop={this.onClearDrop} source={src} placeholderIcon={false}/>
      </EditContainer>
    ) : (
      <ImageContainer>
        <Image src={src?src:require('../../../assets/placeholder.png')} rotation={rotation}/>
      </ImageContainer>
    )
  }
}

ColumnImage.propTypes = {
  value: PropTypes.string,
  editMode: PropTypes.bool
};

ColumnImage.defaultProps = {
  editMode: false,
};

export default ColumnImage;