import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import OpenInNew from '@material-ui/icons/OpenInNew';

const Container = styled.div`
`;

const LinkStyled = styled(Link)`
  color: ${props => props.theme.colors.spearmint_main};
  text-decoration: none;
  text-decoration-color: ${props => props.theme.colors.spearmint_main};
  &:hover {
      text-decoration: underline;
  }
`;

const IconStyled = styled(OpenInNew)`
  font-size: 10px;
  position: absolute;
`;

class SellerName extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleClick = event => {
    if (document.body.clientWidth <= 768) {
      event.preventDefault();
    }
  }

  render() {
    const { text, uid, ...other } = this.props
    
    return (
      <LinkStyled to={`/profile/${uid}`} onClick={this.handleClick}>
        <Container {...other}>
          {text}<IconStyled/>
        </Container>
      </LinkStyled>
    )
  }
}

SellerName.propTypes = {

}

export default SellerName;
