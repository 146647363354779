import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import ChipInput from 'material-ui-chip-input'

class RejectedPanel extends Component {

  entryList() {
    return this.props.rejected.map(item => {
        return(
            <li key={item}>{item}</li>
        )
    })
  }

  render () {
    return (
        <div>
            <ul>
                {this.entryList()}
            </ul>
        </div>
    )
  }
}

export default RejectedPanel