import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import BusinessMenu from './BusinessMenu';

const Container = styled.div`
`

const BusinessMenuStyled = styled(BusinessMenu)`
  margin-top: 30px;
`

const ButtonStyled = styled(Button)`
  padding: ${props => {
    if(props.minimized){
      return css`
        padding: 0px;
        min-width: 40px;
        max-width: 40px;
      `
    } else {
      return css`
        0 10px 0 8px;
      `
    }
  }}
  margin-left: ${props => props.minimized ? '0' : '10px'};
  text-transform: none;
  color: ${props => props.color ? props.theme.colors.spearmint_main : 'white'};
  border-color: ${props => props.color ? props.theme.colors.spearmint_main : 'white'};
`

class BusinessSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      width: 0
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    this.setState({ width: width });
  }

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  }

  handleClose = () => {
    this.setState({anchorEl: null});
  }

  render() {
    const { onSelect, businesses, isLoading, minimized, ...other } = this.props;
    const { anchorEl, width } = this.state;
    const tabletWidth = width < 1024;

    return (
      <Container>
        <ButtonStyled variant={(minimized && tabletWidth) ? "default" : "outlined"} minimized={minimized && tabletWidth} onClick={this.handleClick} {...other}>
          <Icon>autorenew</Icon>{(minimized && tabletWidth) ? null : 'Switch Business' }
        </ButtonStyled>
        <BusinessMenuStyled
          onClick={onSelect}
          anchorEl={anchorEl}
          keepMounted
          data={businesses}
          isLoading={isLoading}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        />
      </Container>
    )
  }
}

BusinessSwitch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  businesses: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

BusinessSwitch.defaultProps = {
};

export default BusinessSwitch;