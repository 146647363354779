import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import GenericModal from '../../../components/organisms/GenericModal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';


const ModalStyled = styled(GenericModal)`
  &-content {
    max-width: 570px !important;
    width: 600px !important;
   }
`
const ListStyled = styled(List)`
  width: 100%;
`
const Container = styled.div`
  width: 100%;
  height: 50vh;
  overflow-y: scroll;
  @media (max-width: 550px) {
    height: auto;
    overflow-y: auto;
  }
`

class RecipientModal extends React.Component {

  renderListItems = (array) => {
    return array.map((elm, index) => {
      const { _picture, name, id } = elm
      const pic = (_picture && _picture.url) ? _picture.url : require('../../../assets/placeholder_user.png');
      return (
        <ListItem key={`recipient-${index}-${id}`} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={pic} />
          </ListItemAvatar>
          <ListItemText
            primary={name}
          />
        </ListItem>
      )
    })
  }

  render() {
    const { open, recipients, ...other } = this.props
    return (
      <ModalStyled open={open} showSubmitButton={false} heading="Recipients" {...other}>
        <Container>
          <ListStyled>
            {this.renderListItems(recipients)}
          </ListStyled>
        </Container>
      </ModalStyled>
    )
  }
}

RecipientModal.propTypes = {

}

export default RecipientModal