import { action, observable , computed } from 'mobx';
import { Product, PackFormat } from '../../core/model';

class SheetStore {
  @observable unsavedUnits
  @observable unsavedPackingUnits
  @observable unsavedImages
  @observable isDeletingUnits
  @observable isAddingUnits
  @observable unsavedQuotes
  @observable unsavedProductIDs
  @observable productOptions
  @observable unitOptions
  @observable clientOptions
  @observable isUpdatingProduct
  @observable listingErrors

  constructor(unsavedUnits = [], unsavedPackingUnits = [], unsavedImages = [], isDeletingUnits = [], isAddingUnits = [], unsavedQuotes = [], unsavedProductIDs = [], listingErrors = [], productOptions = {}, unitOptions = {}, clientOptions = {}) {
    this.unsavedUnits = unsavedUnits;
    this.unsavedPackingUnits = unsavedPackingUnits;
    this.unsavedImages = unsavedImages
    this.isDeletingUnits = isDeletingUnits;
    this.isAddingUnits = isAddingUnits;
    this.unsavedQuotes = unsavedQuotes;
    this.unsavedProductIDs = unsavedProductIDs;
    this.productOptions = productOptions;
    this.unitOptions = unitOptions;
    this.clientOptions = clientOptions;
    this.listingErrors = listingErrors;
  }

  @action
  reset() {   
    this.unsavedUnits = [];
    this.unsavedPackingUnits = [];
    this.unsavedQuotes = [];
    this.unsavedProductIDs = [];
    this.isDeletingUnits = [];
    this.isAddingUnits = [];
    this.productOptions = {};
    this.unitOptions = {};
    this.clientOptions = {};
    this.listingErrors = [];
  }

  @action
  setUnitsAtIndex(index,val) {
    return this.unsavedUnits[index] = val;
  }

  @action
  removeUnitsAtIndex(index) {
    if (index < this.unsavedUnits.length) return this.unsavedUnits.splice(index,1);
  }

  @action
  setPackingUnitsAtIndex(index,val) {
    return this.unsavedPackingUnits[index] = val;
  }

  @action
  removePackingUnitsAtIndex(index) {
    if (index < this.unsavedPackingUnits.length) return this.unsavedPackingUnits.splice(index,1);
  }

  @action
  setQuotesAtIndex(index,val) {
    return this.unsavedQuotes[index] = val;
  }

  @action
  removeQuotesAtIndex(index) {
    if (index < this.unsavedQuotes.length) return this.unsavedQuotes.splice(index,1);
  }

  @action
  setImageAtIndex(index,val) {
    return this.unsavedImages[index] = val;
  }

  @action
  removeImageAtIndex(index) {
    if (index < this.unsavedImages.length) return this.unsavedImages.splice(index,1);
  }

  @action
  setProductsAtIndex(index,val) {
    return this.unsavedProductIDs[index] = val;
  }

  @action
  removeProductsAtIndex(index) {
    if (index < this.unsavedProductIDs.length) {
      this.listingErrors.splice(index, 1);
      return this.unsavedProductIDs.splice(index,1);
    }
  }

  @action
  setProductOption(id,product) {
    return this.productOptions[id] = {
      value: id,
      label: product['name'],
      product: product,
    };
  }

  @action
  setUnitOption(id,units) {
    return this.unitOptions[id] = units;
  }

  @action
  setListingError(index,error) {
    return this.listingErrors[index] = error;
  }

  @action
  setClientOption(clients) {
    return this.clientOptions = clients;
  }

  @action
  setUnitBools(index) {
    this.isDeletingUnits[index] = false;
    this.isAddingUnits[index] = false;
  }

}

export default new SheetStore();