import React, { Component } from 'react'
import styled from 'styled-components'
import Image from '../../atomic/Image'
import breakpoint from 'styled-components-breakpoint'

const ImageContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    min-width: 100px;
    overflow: hidden;
    margin: 8px;
    border-radius: 20px;
    ${breakpoint('mobile_max', 'tablet_min')`
        height: 90px;
        min-width: 90px;
    `}
    ${breakpoint('mobile_min', 'mobile_max')`
        height: 85px;
        min-width: 85px;
    `}
`
/*
.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 107px;
    min-width: 107px;
    margin: auto 8px;

    .image-floater {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90%;
      min-width: 90%;
      overflow: hidden;
      border-radius: 10px;

      .card-image {
        max-width: 10%;
        max-height: 10%;
        transform: scale(10);
      }
    }
  }
  */

class ItemImage extends Component {
    render() {
        return (
            <ImageContainer>
                <Image scale={0.10} src={this.props.picture || require('../../../assets/placeholder_user.png')}/>
            </ImageContainer>
        )
        
    }
}

export default ItemImage