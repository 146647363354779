import React from 'react';
import styled from 'styled-components';
import CircularImage from '../atomic/CircularImage';
import PropTypes from 'prop-types';

const Container = styled.div`
    position: relative;
    height: ${props => props.height}px;
    width: ${props => props.width}px;
`;

const StyledCircularImage = styled(CircularImage)`
    position: absolute;
    z-index: ${props => props.z};
    left: ${props => props.offset}px;
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    border: ${props => props.border};
`;

class ImageOverlap extends React.Component {
    getImages = () => {
        const { images } = this.props;
        const res = [];
        for (let i = 0; i < Math.min(images.length, 5); i++) {
            const image = images[i];
                res.push(
                    <StyledCircularImage key={i} src={image} z={images.length -1 - i} offset={i*7} height={this.props.dim} width={this.props.dim}/>
                )
        }
        return res;
    }

    render() {
        return (
            <Container className={this.props.className} onClick={this.props.onClick} width={this.props.dim + 7 * this.props.images.length} height={this.props.dim}>
                {this.getImages()}
            </Container>
        );
    }
}

ImageOverlap.propTypes = {
    images: PropTypes.array.isRequired,
    dim: PropTypes.number,
    border: PropTypes.string
}
ImageOverlap.defaultProps = {
    dim: 40,
    border: "2px solid white;"
}

export default ImageOverlap;