import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Button from './Button';
import styled from 'styled-components';
import Message from '@material-ui/icons/Message';

const Text = styled.span`
    margin-right: 10px;
`;
class MessageButton extends React.Component {
    onClick = (e) => {
        this.props.onClick(e);
    }
    
    render() {
        return (
            <Button variant={this.props.variant} {...this.props} color={("color" in this.props) ? this.props.color : "primary"} className={this.props.className} onClick={this.onClick}>
                {!this.props.onlyIcon && 
                    <Text>
                        Message
                    </Text>
                }
				<Message/>
			</Button>
            
        );
    }
}

MessageButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.string,
    onlyIcon: PropTypes.bool
};

MessageButton.defaultProps = {
    variant: "contained",
    onlyIcon: false
}

export default MessageButton;