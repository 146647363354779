import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { observe } from 'mobx';
import QuantitySelector from '../../molecules/QuantitySelector';
import { FoodUnits } from '../../../core/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const QuantitySelectorStyled = styled(QuantitySelector)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const ChipContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`

const StyledFormHelper = styled(FormHelperText)`
  min-width: 109px;
`

const Label = styled.div`
  display: flex;
  margin: 5px 0px;
  padding: 5px 10px;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  background-color: #ccc;
  border-radius: 5px;
  flex-grow: 1;
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`
@inject('sheetStore')
@observer
class ColumnText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: {},
      hash: 0,
    }
    this.disposer1 = observe(props.sheetStore.unsavedUnits, (change) => this.setState({hash: this.state.hash + 1}));
    this.disposer2 = observe(props.sheetStore.unsavedPackingUnits, (change) => this.setState({hash: this.state.hash + 1}));
  }

  componentWillMount() {
    const { value, sheetStore, index } = this.props;
    let newValue = value || {};
    let unitsObj = new Object();
    Object.keys(newValue).map(key => unitsObj[key] = key);
    sheetStore.setUnitsAtIndex(index,unitsObj);
    this.setState({ values: newValue });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { values } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,values)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ values: nextProps.value });
    }
  }
  
  getChips = (value) => {
    if(!value) return;
    const units = Object.keys(value)
    return units.map(unit => {
      return this.createChip(unit, value[unit])
    })
  }

  createChip = (key,value) => {
    return (
      <Label>
        <LabelWrapper>
          {`${value === null ? 'unlimited' : value} ${key}`}
        </LabelWrapper>
      </Label>
    )
  }
  
  signalDelete = () => {
    const { sheetStore, index } = this.props
    if (index < sheetStore.isDeletingUnits.length) sheetStore.isDeletingUnits[index] = true;
  }

  signalAdd = () => {
    const { sheetStore, index } = this.props
    if (index < sheetStore.isAddingUnits.length) sheetStore.isAddingUnits[index] = true;
  }

  update = values => {
    const { sheetStore, index } = this.props
    let unitsObj = new Object();
    Object.keys(values).map(key => unitsObj[key] = key);
    sheetStore.setUnitsAtIndex(index,unitsObj)
    this.setState({ values });
  }

  updateAmount = values => {
    this.setState({ values });
  }

  render () {
    const { editMode, options, value, error, sheetStore, index, ...other } = this.props;
    const { values, hash } = this.state;
    const unitOptions = new Object();
    if(index < sheetStore.unsavedPackingUnits.length && index < sheetStore.unsavedUnits.length && sheetStore.unsavedPackingUnits[index]) {
      Object.keys(FoodUnits).map(key => {
        const unit = FoodUnits[key];
        if( sheetStore.unsavedUnits[index][unit] || sheetStore.unsavedPackingUnits[index][unit] ){
          return false;
        }
        unitOptions[key] = unit;
      })
    }
    return editMode ? (
      <React.Fragment>
        <QuantitySelectorStyled
          key={`quantity-selector-${index}-${hash}`}
          values={values}
          options={unitOptions}
          onDelete={this.signalDelete}
          onAdd={this.signalAdd}
          onChange={this.update}
          onUpdate={this.updateAmount}
          {...other}
        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </React.Fragment>
    ) : (
      <ChipContainer>
        {this.getChips(value)}
      </ChipContainer>
    )
  }
}

ColumnText.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  editMode: PropTypes.bool,
};

ColumnText.defaultProps = {
  editMode: false,
};

export default ColumnText;