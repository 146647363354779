import React, { Component } from 'react'
import Button from '../../atomic/buttons/Button';
import ChipInput from '../../molecules/ChipInput/';
import PendingList from '../../molecules/PendingList/';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {AlertMessage} from '../../AlertMessage';
import Typography from '@material-ui/core/Typography';
import styled, { css, withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import DatabaseClient from '../../../core/DatabaseClient'
import { confirmAlert } from 'react-confirm-alert';
import { observer, inject } from 'mobx-react'
import { message } from 'antd'
import { LoadingSpinnerPage } from '../../LoadingSpinner';
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import ColumnText from '../InventorySheet/ColumnText';
import ColumnPrice from './ColumnPrice';
import ColumnBoolean from './ColumnBoolean';
import ColumnChipInput from './ColumnChipInput';
import ColumnUnit from './ColumnUnit';
import ColumnProduct from './ColumnProduct';
import ColumnDate from './ColumnDate';
import ColumnEdit from './ColumnEdit';
import ActionToolBar from '../InventorySheet/ActionToolBar';
import CustomToolbar from '../InventorySheet/CustomToolbar';
import CustomFooter from '../InventorySheet/CustomFooter';
import moment from 'moment';
import { ReasonForPostOptions, FoodOptions, FoodUnits } from '../../../core/core';
import Product from '../../../core/model/Product';
import cloneDeep from 'clone-deep';
import { toJS } from 'mobx';

import ProductListingEditModal from '../../molecules/ProductListingEditModal';

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

function getCurrentDate() {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth()+1; // January is 0!
  var yyyy = today.getFullYear();

  if(dd<10) {
      dd = '0'+dd
  } 

  if(mm<10) {
      mm = '0'+mm
  } 

  today = yyyy + '-' + mm + '-' + dd;
  return today;
}

const dealFormat = {
  id: null,
  product: {
    value: null,
    error: null,
  },
  unit: {
    value: null,
    error: null,
  },
  visibility: {
    value: {},
    error: null,
  },
  quote: {
    value: false,
    error: null,
  },
  price: {
    value: 0,
    error: null,
  },
  endListingDate: {
    value: null,
    error: null,
  },
  notes: {
    value: null,
    error: null,
  },
  order: null,
};

@inject('storefrontStore', 'clientListStore', 'sheetStore')
@observer
class StorefrontSheet extends Component {
  constructor (props) {
    super(props)
    this.renderHash = 0;
    this.errorRowsAfter = [];
    this.state = {
      data: [],
      unsavedData: [],
      rowsInEdit: [],
      rowsToSave: [],
      editIndex: 13,
    }
    this.options = {
      filterType: 'checkbox',
      responsive: 'scroll',
      filter: false,
      print: false,
      download: false,
      selectableRows: false,
      pagination: false,
      elevation: 0,
      customToolbar: () => {
        return <CustomToolbar addItem={this.addNewDeal}/>
      },
      customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage
      ) => {
        return <CustomFooter addItem={this.addNewDeal} />;
      }
    };
    this.columns = [
      {
        name: "ID",
        options: {
          display: 'excluded',
          filter: false,
          sort: false,
          download: false,
        }
      },
      {
        name: "Product",
        options: {
          hint: `Select the product you want to create a published listing for.  You can create multiple listings for the same product.`,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnProduct
                key={`product-${indx}-${this.renderHash}`}
                name="product" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                options={props.products}
                editMode={editMode} 
              />
            );
          }, 
        }
      },
      {
        name: "Unit (Stock Remaining)",
        options: {
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnUnit
                key={`uom-${indx}-${this.renderHash}`}
                name="unit" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                editMode={editMode}
                errorText={"Must be less than 10 characters"}
              />
            );
          }, 
        }
      },
      {
        name: "Visibility",
        options: {
          hint: `Choose which buyers can see this particular listing, unit, and price.`,
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnChipInput
                key={`visibility-${indx}-${this.renderHash}`}
                name="visibility"
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                editMode={editMode} 
              />
            );
          }, 
        }
      },
      {
        name: "Request Quote",
        options: {
          hint: `Click this box if you would like to leave your price unlisted until an order has been placed.`,
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnBoolean
                key={`rq-${indx}-${this.renderHash}`}
                name="quote" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                editMode={editMode} 
              />
            );
          }, 
        }
      },
      {
        name: "Price",
        options: {
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnPrice
                key={`price-${indx}-${this.renderHash}`}
                name="price" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                editMode={editMode} 
              />
            );
          }, 
        }
      },
      {
        name: "End Listing Date (Optional)",
        options: {
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnDate
                key={`eld-${indx}-${this.renderHash}`}
                name="endListingDate" 
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}
                editMode={editMode}
              />
            );
          }, 
        }
      },
      {
        name: "Notes",
        options: {
          display: true,
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const data = JSON.parse(value)
            const indx = tableMeta['rowData'][tableMeta['rowData'].length - 1]
            const editMode = this.state.rowsInEdit.includes(indx)
            return (
              <ColumnText
                key={`notes-${indx}-${this.renderHash}`}
                name="notes"
                index={indx}
                toSave={this.state.rowsToSave}
                onSave={this.onFieldChange}
                value={data['value']}
                error={data['error']}  
                editMode={editMode} 
              />
            );
          }, 
        }
      },
      {
        name: "",
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const indx = value
            const ID = tableMeta['rowData'][0]
            const isNew = ID === 'null'
            const editMode = this.state.rowsInEdit.includes(indx)
            const saving = this.state.rowsToSave.includes(indx)
            return (
              <ColumnEdit
                key={`button-${indx}-${this.renderHash}`}
                editMode={editMode}
                saving={saving}
                isNew={isNew}
                onSave={()=>this.saveRow(indx)}
                onEdit={()=>this.editRow(indx)}
                onDuplicate={()=>this.duplicateRow(indx)}
                onCancel={()=>this.cancelRow(indx)}
                onDelete={()=>this.quickDelete(isNew,indx)}
              />
            );
          }, 
        }
      }
    ];
  }

  formatNewDealData = (deal,index) => {
    const { sheetStore} = this.props
    let data = cloneDeep(dealFormat)

    data['quote'].value = ''
    data['product'].value = null
    data['price'].value = deal?deal[1]:0
    data['endListingDate'].value = null
    data['notes'].value = null
    data['order'] = index

    sheetStore.setProductsAtIndex(index,null)

    return data;
  }

  randomizeHash = () => {
    return (Math.random() * (10.00 - 1.00 + 1.00) + 1.00).toFixed(7);
  }
  
  formatDealData = (deal,index) => {
    const { sheetStore } = this.props
    let data = cloneDeep(dealFormat)

    data['id'] = deal['id']
    data['product'].value = deal['product']
    data['unit'].value = deal['unit']
    data['visibility'].value = deal['visibility']
    data['quote'].value = deal['price'] === null
    data['price'].value = deal['price']?deal['price']:0
    data['endListingDate'].value = deal['endListingDate'] ? moment(deal['endListingDate']).format('YYYY-MM-DD') : null
    data['notes'].value = deal['notes']?deal['notes']:null
    data['order'] = index

    const productID = deal['product']['id'];
    sheetStore.setProductsAtIndex(index,productID);
    sheetStore.setListingError(index,deal['error'])

    return data;
  }

  async componentWillMount() {
    const { data, sheetStore, products } = this.props
    try {
      let deals = data ? data : []
      const dealData = deals.map((deal,index) => this.formatDealData(toJS(deal),index))
      await this.createOptions();
      this.setState({
        data: cloneDeep(dealData),
        unsavedData: dealData,
      })
      window.scroll(0, 0);
    }
    catch (e) {
      message.error(e.message);
      console.error(e);
      Sentry.captureException(e);
    }
  }

  componentWillReceiveProps(props){
    if(!props.newData)
      return

    this.addNewDeals(props.newData)
  }

  async componentDidUpdate(prevProps,prevState) {
    const { rowsToSave, rowsInEdit, rowToDelete, data } = this.state
    let savedRows = rowsToSave.filter(item => prevState.rowsToSave.indexOf(item) < 0);
    if(rowToDelete) {
      this.setState({ 
        rowsToSave: [],
        rowToDelete: null
      })
      await this.deleteSingleRow(rowToDelete)
    } else {
      if(savedRows.length > 0) {
        this.errorRowsAfter = rowsInEdit.slice();
        await Promise.all(savedRows.map( async item => {
          return this.handleSave(parseInt(item))
        }))
        this.setState({
          rowsToSave: [],
          rowsInEdit: this.errorRowsAfter
        })
      }
    }
  }

  createOptions = async () => {
    const { sheetStore, clientListStore, products } = this.props
    products.map(product => {
      const productID = product['id'];
      const productUnits = [...Object.keys(product['quantity']), ...Object.keys(product['packFormats'])];
      const arry = this.getUnitQuantities(productUnits,product);
      sheetStore.setProductOption(productID,product);
      sheetStore.setUnitOption(productID,arry);
    })
    const clientOptions = clientListStore.totalClientList.map( client => { return { value: client.id, label: client.name }} )
    sheetStore.setClientOption(clientOptions)
  }

  getUnitQuantities = (units, product) => {
    let unitSet = {}

    if(!product)
      return unitSet;

    units.map(unit => {
      unitSet[unit] = {};
      unitSet[unit].value = unit;
      unitSet[unit].label = `${unit} (${product.getBaseQuantity(unit)})`;
    });
    
    return unitSet;
  }

  checkIfFile = (value,indx) => {
    if(isNaN(parseInt(indx))){
      return null;
    }

    if (!value) {
      return null;
    } else if (typeof value === 'object'){
      return this.state.unsavedData[parseInt(indx)]['pictures'];
    } else {
      return value;
    }
  }

  handleSave = async (indx) => {
    const { unsavedData, data } = this.state
    const { storefrontStore } = this.props
    const errorCheckArray = unsavedData
    let saveArray = data;
    const obj = errorCheckArray[indx];
    let foundError = false;
    let dataID = null;
    
    if(obj['notes'].value === '') {
      obj['notes'].value = null
    }
    if(obj['endListingDate'].value === '') {
      obj['endListingDate'].value = null
    }
    if(obj['quote'].value) {
      obj['price'].value = 0
    }

    storefrontStore.populateForm(Object.assign({},obj))
    Object.keys(obj).map(item => {
      
      let err = null;

      switch (item) {
        case 'id':
          dataID = obj[item]
          break;
        case 'order':
          break;
        case 'quote':
          break;
        default:
          err = storefrontStore.validateValue(item)
          break;
      }

      if(err) {
        foundError = true;
        errorCheckArray[indx][item].error = err[Object.keys(err)[0]][0];
      } else {
        if(item !== 'id' && item !== 'order') {
          errorCheckArray[indx][item].error = null
        }
      }
    })
    if(!foundError) {
      if (dataID) {
        if(!this.dataUnchanged(unsavedData, data, indx)){
          try {
            await storefrontStore.editDeal(dataID, indx);
          }
          catch (e) {
            message.error(e.message);
            console.error(e);
            Sentry.captureException(e);
            return;
          }
        }
      } else {
        try {
          const dealID = await storefrontStore.createDeal(indx)
          errorCheckArray[indx]['id'] = dealID
        }
        catch (e) {
          message.error(e.message);
          console.error(e);
          Sentry.captureException(e);
          return;
        }
      }
      let indexOf = this.errorRowsAfter.indexOf(indx.toString());
      this.errorRowsAfter.splice(indexOf, 1);
      saveArray[indx] = errorCheckArray[indx];
      this.setState({
        data: cloneDeep(saveArray)
      })
    }
    this.setState({
      unsavedData: errorCheckArray,
    })
  }

  duplicateRow = async (indx) => {
    const { unsavedData, data } = this.state
    const { storefrontStore, sheetStore } = this.props
    const errorCheckArray = unsavedData
    let saveArray = data;
    const obj = errorCheckArray[indx];
    let foundError = false;
    
    if(obj['notes'].value === '') {
      obj['notes'].value = null
    }
    if(obj['endListingDate'].value === '') {
      obj['endListingDate'].value = null
    }
    if(obj['quote'].value) {
      obj['price'].value = 0
    }

    storefrontStore.populateForm(Object.assign({},obj))
    // Object.keys(obj).map(item => {
      
    //   let err = null;

    //   switch (item) {
    //     case 'id':
    //       dataID = obj[item]
    //       break;
    //     case 'order':
    //       break;
    //     case 'quote':
    //       break;
    //     default:
    //       err = storefrontStore.validateValue(item)
    //       break;
    //   }
    // })
    
    if(!foundError) {
      const newIndex = errorCheckArray.length;
      try {
        const duplicateDeal = await storefrontStore.duplicateProductListing(newIndex)
        const newProduct = this.formatDealData(toJS(duplicateDeal), newIndex);
        errorCheckArray.push(newProduct);
        saveArray.push(newProduct);
      }
      catch (e) {
        message.error(e.message);
        console.error(e);
        Sentry.captureException(e);
        return;
      }
      this.setState({
        data: cloneDeep(saveArray)
      })
    }
    this.setState({
      unsavedData: errorCheckArray,
    })
  }

  dataUnchanged = (unsavedData, data, indx) => {
    const savedDataItem =  JSON.stringify(data[indx]);
    const unsavedDataItem =  JSON.stringify(unsavedData[indx]);
    return savedDataItem === unsavedDataItem;
  }

  addNewDeals = (deals) => {
    const dealData = deals.map(deal => this.formatNewDealData(deal))
    this.setState({
      data: dealData,
      unsavedData: dealData,
    })
  }

  addNewDeal = () => {
    const { data, unsavedData, rowsInEdit } = this.state
    const dealData = []
    let newRowsInEdit = rowsInEdit.slice();
    const indx = data.length;
    dealData.push(this.formatNewDealData(null,indx))
    newRowsInEdit.push(indx.toString());
    this.setState({
      data: [...data,...cloneDeep(dealData)],
      unsavedData: [...unsavedData,...dealData],
      rowsInEdit: newRowsInEdit,
    })
  }

  onFieldChange = (index, field, value) => {
    const { unsavedData } = this.state;
    let newData = unsavedData.slice(0);
    newData[index][field].value = value;
    this.setState({
      unsavedData: newData
    });
  }

  saveRow = (indx) => {
    const { rowsToSave } = this.state
    this.setState({
      rowsToSave: [...rowsToSave,indx]
    })
  }
  
  editRow = (indx) => {
    const { rowsInEdit } = this.state;
    let newRowsInEdit = rowsInEdit.slice();
    let indexInArray = newRowsInEdit.indexOf(indx)>=0?true:false;

    if(!indexInArray){
      newRowsInEdit.push(indx);
    }

    this.setState({
      rowsInEdit: newRowsInEdit
    });
  }

  quickDelete = async (isNew, indx) => {
    if(isNew) {
      await this.deleteRow(indx);
    } else {
      AlertMessage('Delete Item','Are you sure you want to delete this inventory item?',(async () => {await this.deleteRow(indx)}))
    }
  }

  deleteRow = (indx) => {
    const { rowsInEdit } = this.state;
    let modRowsInEdit = [...rowsInEdit];
    const indexOfRow = rowsInEdit.indexOf(indx);
    modRowsInEdit.splice(indexOfRow,1);
    
    this.setState({
      rowToDelete: indx,
      rowsToSave: rowsInEdit
    })
  }
  
  deleteSingleRow = async (indx) => {
    const { rowsInEdit, unsavedData, data } = this.state;
    const { storefrontStore, sheetStore } = this.props;
    const id = unsavedData[indx]['id'];
    const dataIndex = indx;

    if(id) {
      try {
        await storefrontStore.archiveDeal(id);
      }
      catch (e) {
        message.error(e.message);
        console.error(e);
        Sentry.captureException(e);
        return;
      }
    }

    let newRowsInEdit = [...rowsInEdit];
    let unsavedDelete = [...unsavedData];
    let dataDelete = [...data];

    const indexOf = newRowsInEdit.indexOf(indx);

    unsavedDelete.splice(dataIndex,1);
    dataDelete.splice(dataIndex,1);
    newRowsInEdit.forEach((item,index) => {
      if(index > indexOf){
        const num = parseInt(item) - 1;
        newRowsInEdit[index] = num.toString();
      }
    })
    newRowsInEdit.splice(indexOf, 1);

    sheetStore.removeProductsAtIndex(indx);

    unsavedDelete.forEach((item,index) => { item['order'] = index; })
    dataDelete.forEach((item,index) => { item['order'] = index; })

    ++this.renderHash;

    this.setState({
      unsavedData: unsavedDelete,
      data: dataDelete,
      rowsInEdit: newRowsInEdit,
    })
  }

  cancelRow = (indx) => { 
    const { rowsInEdit, data, unsavedData } = this.state;
    let newRowsInEdit = rowsInEdit.slice();
    let indexOf = newRowsInEdit.indexOf(indx)
    let newData = unsavedData.slice(0);
    newRowsInEdit.splice(indexOf, 1);
    newData[indx] = data[indx];
    this.setState({
      rowsInEdit: newRowsInEdit,
      unsavedData: newData
    });
  }

  isInEdit = () => {
    return this.state.rowsInEdit.length > 0;
  }

  editAll = () => {
    let newRowsInEdit = [];
    this.state.unsavedData.forEach((item,index) => newRowsInEdit.push(index.toString()))
    this.setState({
      rowsInEdit: newRowsInEdit
    });
  }

  saveAll = () => {
    this.setState({
      rowsToSave: this.state.rowsInEdit
    });
  }

  cancelAll = () => {
    const { rowsInEdit, data, unsavedData } = this.state;
    let newRowsInEdit = rowsInEdit.slice();
    let newData = unsavedData.slice(0);
    let NonIDs = [];
    newRowsInEdit.forEach((item) => {
      const indx = parseInt(item);
      const id = data[indx]['id'];
      if(!id){
        NonIDs.push(data[indx]['order'])
      }
      newData[indx] = data[indx];
    })
    this.setState({
      rowsInEdit: [],
      unsavedData: newData
    }, () => this.deleteMultiple(NonIDs))
    
  }

  deleteMultiple = (arr) => {
    const { rowsInEdit, unsavedData, data } = this.state;
    const { sheetStore } = this.props;
    // const id = unsavedData[indx]['id'];
    // const dataIndex = indx;

    // if(id) {
    //   try {
    //     await storefrontStore.archiveDeal(id);
    //   }
    //   catch (e) {
    //     message.error(e.message);
    //     return;
    //   }
    // }

    let newRowsInEdit = [...rowsInEdit];
    let unsavedDelete = [...unsavedData];
    let dataDelete = [...data];

    arr.reverse().forEach(dataIndex => {
      const indexOf = newRowsInEdit.indexOf(dataIndex);
  
      unsavedDelete.splice(dataIndex,1);
      dataDelete.splice(dataIndex,1);
      sheetStore.removeProductsAtIndex(dataIndex);
      newRowsInEdit.forEach((item,index) => {
        if(index > indexOf){
          const num = parseInt(item) - 1;
          newRowsInEdit[index] = num.toString();
        }
      })
      newRowsInEdit.splice(indexOf, 1);
  
      unsavedDelete.forEach((item,index) => { item['order'] = index; })
      dataDelete.forEach((item,index) => { item['order'] = index; })
    })

    ++this.renderHash;

    this.setState({
      unsavedData: unsavedDelete,
      data: dataDelete,
      rowsInEdit: newRowsInEdit,
    })
  }

  render () {
    const { products } = this.props;
    const { unsavedData } = this.state;
    const jsonData = unsavedData.map(item => {
      return Object.keys(item).map(key => {
        return JSON.stringify(item[key]);
      })
    })
    return (
      <MuiThemeProvider>
        <FormContainer>
          <ActionToolBar
            editAll={this.editAll}
            saveAll={this.saveAll}
            cancelAll={this.cancelAll}
            editMode={this.isInEdit()}
          />
          <MUIDataTable
            data={jsonData}
            columns={this.columns}
            options={this.options}
          />
          <ProductListingEditModal
            renderHash={this.renderHash}
            data={unsavedData}
            products={products}
            onFieldChange={(index,field,value) => this.onFieldChange(index,field,value,true)}
            rowsToSave={this.state.rowsToSave}
            rowsInEdit={this.state.rowsInEdit}
            onSave={(indx)=>this.saveRow(indx)}
            onCancel={(indx)=>this.cancelRow(indx)}
            onDelete={(isNew, indx) => this.quickDelete(isNew, indx)}
          />
        </FormContainer>
      </MuiThemeProvider>
    )
  }
}

StorefrontSheet.propTypes = {
  data: PropTypes.array,
};

export default StorefrontSheet
