import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import Input from '@material-ui/core/Input';
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '../../molecules/Tooltip';
import Bubble from '../../molecules/Bubble';

const TextArea = styled(Bubble)`
`

const StyleIcon = styled(Icon)`

`

const StyleCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: center;
`

@inject('sheetStore')
@observer
class ColumnText extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bool: false
    }
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ bool: value });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { bool } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,bool)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ bool: nextProps.value });
    }
  }

  update = bool => {
    this.setState({ bool });
  }

  getError = () => {
    const { sheetStore, name, index } = this.props
    if (index >= sheetStore.listingErrors.length) {
      return null;
    }
    const listingError = sheetStore.listingErrors[index];
    if(!listingError) return null;
    
    const stringArry = listingError.split(`${name}:`);

    if (stringArry && stringArry.length > 1) {
      return stringArry[1];
    } else {
      return null;
    }
  }

  render () {
    const { editMode, value, ...other } = this.props;
    const { bool } = this.state;

    const tipError = this.getError();
    return editMode ? (
      <React.Fragment>
        <StyleCheckbox
          checked = {bool}
          onChange={(e) => this.update(e.target.checked)}
          {...other}
        />
      </React.Fragment>
    ) : (
      <Tooltip text={tipError}>
        <TextArea enabled={tipError}>
          <StyleIcon color={value?'primary':'secondary'}>{value?'check':'close'}</StyleIcon>
        </TextArea>
      </Tooltip>
    )
  }
}

ColumnText.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  editMode: PropTypes.bool,
};

ColumnText.defaultProps = {
  editMode: false,
};

export default ColumnText;