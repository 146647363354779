import React from 'react';
import Button from './Button';
import AuthManager from '../../../core/AuthManager';
import { inject, observer } from 'mobx-react';
import { message } from 'antd';

@inject('userStore')
@observer
class PasswordResetButton extends React.Component {
    state = {
        sent: false
    }

    resetPassword = async () => {
        try {
            await AuthManager.sendPasswordResetEmail(this.props.userStore.user.email);
            message.success('An email containing a link to reset your password has been sent to you!');
            this.setState({ sent: true });
        }
        catch (e) {
            message.error(e);
        }
    }

    render() {
        return (
            this.state.sent ?
                <Button variant="outlined" onClick={this.resetPassword}>Resend Email</Button>
                :
                <Button variant="contained" color="primary" onClick={this.resetPassword}>Reset Password</Button>
        );
    }
}

export default PasswordResetButton;