import { observable, toJS } from 'mobx';
import joi from 'joi-browser';
import { MIN_STRING, MAX_STRING_SHORT } from '../schemas'

export const certification_status = Object.freeze({
    PENDING: "pending",
    ACTIVE: "active",
    EXPIRED: "expired",
    REJECTED: "rejected"
});

const schema = joi.object().keys({
    expirationDate: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).allow(null).required(),
    id: joi.string().allow(null).required(),
    issuedDate: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).allow(null).required(),
    name: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    status: joi.string().valid(Object.values(certification_status)).required(),
    uid: joi.string().required(),
    url: joi.string().allow(null).required()
});

class Certification {
    @observable expirationDate
    @observable id
    @observable issuedDate
    @observable name
    @observable status
    @observable uid
    @observable url

    constructor(expirationDate = null, id = null, issuedDate = null, name = null, status = "pending", uid = null, url = null) {
        this.expirationDate = expirationDate;
        this.id = id;
        this.issuedDate = issuedDate;
        this.name = name;
        this.status = status;
        this.uid = uid;
        this.url = url;
    }

    formatForDB() {
        let data = observable({
            expirationDate: this.expirationDate,
            id: this.id,
            issuedDate: this.issuedDate,
            name: this.name,
            status: this.status,
            uid: this.uid,
            url: this.url
        });
        return toJS(data);
    }

    validate() {
        const result = joi.validate(toJS(this), schema);
        return {
            valid: !result.error,
            error: result.error,
        };
    }
}

Certification.displayName = "Certification";
export default Certification
