import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import ChipInput from 'material-ui-chip-input';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ChipAutoComplete from "../../molecules/ChipAutoComplete";

const ChipAutoCompleteStyled = styled(ChipAutoComplete)`
  flex: 1 0 auto;
  min-height: 250px;
  width: 400px;
`

const InputContainer = styled.div`

`

const StyleChipInput = styled(ChipInput)`
  width: 400px;
`

const StyleSelect = styled(Select)`
  width: 200px;
`

const StyleCheckbox = styled(Checkbox)`

`

const TextArea = styled.div`
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyleListItemText = styled(ListItemText)`

`

const MenuProps = {
  PaperProps: {
    style: {
      width: '250px',
    },
  },
};

@inject('sheetStore')
@observer
class ColumnChipInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: {},
      multi: null
    }
  }

  componentDidMount() {
    const { value } = this.props;
    const inputFormat = this.setInputFormat(value);
    this.setState({ values: value, multi: inputFormat });
  }

  componentWillReceiveProps(nextProps) {
    const { editMode, name, index } = this.props
    const { values } = this.state
    if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
      this.props.onSave(index,name,values)
    }
    if(editMode && !nextProps.editMode) {
      this.setState({ values: nextProps.value });
    }
  }

  handleChange = value => {
    const visibleUIDs = this.formatSet(value)
    this.setState({
      multi: value,
      values: visibleUIDs
    });
  };

  formatSet = value => {
    if(!value)
      return {};

    let set = new Object();
    value.map(item => set[item.value] = true )
    return set;
  }

  setInputFormat = value => {
    const { sheetStore } = this.props
    if(!value)
      return {};

    const arry = Object.keys(value).map(key => {return { value:key, label: sheetStore.clientOptions[key] }} )
    return arry;
  }

  setInputFormat = values => {
    const { sheetStore } = this.props
    const uids = Object.keys(values);
    let clients = [];
    let iterator = 0;
    for (const item of sheetStore.clientOptions) {
      if(iterator >= uids.length)
        break;

      if(uids.includes(item.value)){
        iterator++;
        clients.push({ value: item.value, label:item.label });
      }
    }
    return clients;
  }

  getClientNames = () => {
    const { sheetStore } = this.props
    const { values } = this.state
    const uids = Object.keys(values);
    let clients = [];
    let iterator = 0;
    for (const item of sheetStore.clientOptions) {
      if(iterator >= uids.length)
        break;

      if(uids.includes(item.value)){
        iterator++;
        clients.push(item.label);
      }
    }
    return clients.length > 0 ? clients.join(', ') : 'Affiliates';
  }

  render () {
    const { editMode, value, error, name, options, sheetStore, subheader, ...other } = this.props;
    const { multi } = this.state;
    return editMode ? (
      <React.Fragment>
        <ChipAutoCompleteStyled
          value={multi}
          onChange={this.handleChange}
          options={sheetStore.clientOptions}
          placeholder="Affiliates (default)"
          {...other}
        />
      </React.Fragment>
    ) : (
      <TextArea>
        {this.getClientNames()}
      </TextArea>
    )
  }
}

ColumnChipInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
  subheader: PropTypes.string,
  value: PropTypes.array,
  editMode: PropTypes.bool,
};

ColumnChipInput.defaultProps = {
  editMode: false,
};

export default ColumnChipInput;