import React, { Fragment, Component } from 'react';
import Menu from '../../components/Menu';
import Topbar from '../../components/CommonTopBar';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import EditableLabel from '../../components/molecules/EditableLabel';
import { User } from '../../core/model';
import DatabaseClient from '../../core/DatabaseClient';
import AuthManager from '../../core/AuthManager';
import EditablePhoneLabel from '../../components/molecules/EditablePhoneLabel';
import { observer, inject } from 'mobx-react';
import PasswordResetButton from '../../components/atomic/buttons/PasswordResetButton';
import breakpoint from 'styled-components-breakpoint';
import CheckboxList from '../../components/molecules/CheckboxList';
import Button from '../../components/atomic/buttons/Button';
import { message } from 'antd';

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    margin-top: 112px;
    margin-bottom: 120px;
    ${props => props.theme.mixins.sidebar}
`;

const Wrapper = styled.div`
    max-width: 1100px;
    width: 100%;
    margin: auto;
    padding-top: 30px;
    text-align: center;
`;

const StyledPaper = styled(Paper)`
    padding: 10px;
    box-shadow: none;
    border-radius: 10px;
`;

const GridItem = styled.div`
    margin: auto;
    width: 66%;
    min-width: 300px;
    max-width: 600px;
    margin-bottom: 20px;
    text-align: center;
`;

const Subheading = styled.div`
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.185);
    h2 {
        font-size: 1.6em;
    }
`;

const InformationContainer = styled.div`
    width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    padding: 5px;
    margin-bottom: 10px;
    &:first-child{
        margin-top: 10px;
    }
    &:last-child{
        margin-bottom: auto;
    }
`;


const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
    ${breakpoint('mobile_min', 'sidebar_off_max')`
        width: 90%;
    `}
    ${breakpoint('sidebar_fixed_min', 'sidebar_fixed_max')`
        width: 300px;
    `}
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    ${breakpoint('mobile_min', 'sidebar_off_max')`
        margin-left: 5px;
        margin-right: 5px;
    `
    }
`;

const StyledButton = styled(Button)`
    width: 100%;
`;


const SaveButton = (props) => {
    return <StyledButton {...props}>Save</StyledButton>
}

const CancelButton = (props) => {
    return <StyledButton {...props}>Cancel</StyledButton>
}

const ButtonContainer = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    * {
        margin-right: 5px;
    }
    :last-child {
        margin-right: 0;
    }
    width: 100%;
`;

@inject('userStore')
@observer
class NotificationSettings extends Component {
    state = {
        notificationOptions: [],
        edits: false
    }

    componentWillMount() {
        const not_pref = Object.keys(this.props.userStore.user.preferences.notifications).reduce((arr, key) => {
            if (this.props.userStore.user.preferences.notifications[key]) {
                arr.push(key);
            }
            return arr;
        }, []);
        this.setState({ notificationOptions: not_pref });
    }

    createNotificationCheckboxes = () => {
        const items = [
            {
                label: "New client requests",
                value: "clientRequests"
            },
            {
                label: "Order requests",
                value: "orderRequests"
            },
            {
                label: "Order updates",
                value: "orderUpdates"
            },
            {
                label: "New messages",
                value: "messages"
            }
        ];
        return <CheckboxList label={"Send me emails about..."} items={items} defaultValues={this.state.notificationOptions} onChange={this.onChange}/>
    }

    onChange = (value, checked) => {
        let opts = this.state.notificationOptions;
        const idx = opts.indexOf(value);
        if (!checked && idx > -1) {
            opts.splice(idx, 1);
            this.setState({ notificationOptions: opts });
        }
        else if (checked && idx == -1) {
            opts.push(value);
            this.setState({ notificationOptions: opts });
        }

        const pref = this.props.userStore.user.preferences.notifications;
        const newPref = this.prepareNewPrefs(opts);
        const keys = Object.keys(pref);
        let edits = false;
        for (let i = 0; i < keys.length; i++) {
            if (pref[keys[i]] !== newPref[keys[i]]) {
                edits = true;
                break;
            }
        }
        if (edits !== this.state.edits) {
            this.setState({ edits });
        }
    }

    prepareNewPrefs = (opts = this.state.notificationOptions) => {
        const pref = Object.assign({}, this.props.userStore.user.preferences.notifications);
        Object.keys(pref).forEach((key) => {
            if (opts.includes(key)) {
                pref[key] = true;
            }
            else {
                pref[key] = false;
            }
        });
        return pref;
    }

    onSave = async () => {
        const pref = this.prepareNewPrefs();
        try {
            await this.props.userStore.updateNotificationPreferences(pref.clientRequests, pref.orderRequests, pref.orderUpdates, pref.messages);
            message.success("Preferences updated successfully!");
            this.setState({ edits: false });
        }
        catch (e) {
            message.error(e.message);
            console.error(e);
            Sentry.captureException(e);
        }
    }

    // onCancel = () => {
    //     const not_pref = Object.keys(this.props.userStore.user.preferences.notifications).reduce((arr, key) => {
    //         if (this.props.userStore.user.preferences.notifications[key]) {
    //             arr.push(key);
    //         }
    //         return arr;
    //     }, []);
    //     this.setState({ notificationOptions: not_pref, edits: false });

    // }

    render() {
        const tabs = [
			{
				name: 'Account Settings',
				child: false,
				path: '/settings/account'
            },
            {
				name: 'Notifications',
				child: false,
				path: '/settings/notifications'
            }
        ];
		return (
			<Fragment>
				<Menu />
				<Topbar
                    title="Settings"
                    sub="Change your information and preferences here."
                    tabs={tabs}
                    tabIndex={1}
                    isChildPath={false}
                    mobilft={true} />
				<Container>
					<Wrapper>
					<Grid direction="column">
                        <GridItem item>
                            <StyledPaper>
                                <Content>
                                    <Subheading>
                                    <Typography component="h2" variant="subheading">
                                        Email Preferences
                                    </Typography>
                                    </Subheading>
                                <InformationWrapper>
                                    <InformationContainer>
                                        {this.createNotificationCheckboxes()}
                                    </InformationContainer>
                                    {
                                        this.state.edits && 
                                            <ButtonContainer>
                                                <SaveButton variant={"contained"} color={"primary"} onClick={this.onSave}/>
                                                {/* <CancelButton variant={"contained"} onClick={this.onCancel}/> */}
                                            </ButtonContainer>
                                    }
                                </InformationWrapper>
                                </Content>
                            </StyledPaper>
                        </GridItem>
                        {/* <GridItem item>
                            <StyledPaper>
                                <Content>
                                    <Subheading>
                                        <Typography component="h2" variant="subheading">
                                            Account Information
                                        </Typography>
                                    </Subheading>
                                    <InformationWrapper>
                                        <InformationContainer>
                                            <EditableLabel 
                                                onFinish = {async (val) => await this.handleEmailChange(val)}
                                                label = "Email"
                                                defaultValue = {this.state.email}
                                            />
                                        </InformationContainer>
                                    </InformationWrapper>
                                </Content>
                            </StyledPaper>
                        </GridItem>
                        <GridItem item>
                            <StyledPaper>
                                <Content>
                                    <Subheading>
                                        <Typography component="h2" variant="subheading">
                                            Password Reset
                                        </Typography>
                                    </Subheading>
                                    <InformationWrapper>
                                        <InformationContainer>
                                            <PasswordResetButton/>
                                        </InformationContainer>
                                    </InformationWrapper>
                                </Content>
                            </StyledPaper>
                        </GridItem> */}
					</Grid>
					</Wrapper>
				</Container>
			</Fragment>
		);
    }
}

export default NotificationSettings;