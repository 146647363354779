import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import Popup from 'reactjs-popup';
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import BottomBar from '../../CommonBottomBar'
import Check from '@material-ui/icons/Check'
import Button from '../../atomic/buttons/Button'
import { LoadingSpinnerPage } from '../../LoadingSpinner';

const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(0,0,0,0);
  display: flex;    
  flex-direction: column;
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 550px) {
    padding-bottom: 0;
  }
`
const StyledPopup = styled(Popup)`
  padding: 0 !important;
  background: rgba(0,0,0,0) !important;
  border-style: none !important;
  font-family: ${props => props.theme.fonts.ms_font300};
  max-width: 800px;
  width: auto !important;
  min-width: 540px;
  @media (max-width: 550px) {
    width: 100vw !important;
    min-width: 0;
  }
  z-index: 99;
`

const PopupContainer = styled.div`
  ${'' /* @media (min-width: 551px) {
    display: none;
  } */}
`

const TopBarContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  top: env(safe-area-inset-top);
  width: 100%;
  z-index: 300;
  @media (max-width: 550px) {
    display: block;
  }
`
const StyledAppBar = styled(AppBar)`
  background-color: #fff !important;
`

const StyledToolbar = styled(Toolbar)`
  padding-left: 0 !important;
`

const StyledBottomBar = styled(BottomBar)`
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;

  &.highest-stack {
      z-index: 3;
  }
  @media (max-width: 550px) {
    display: block;
  }
`
// popup
const Container = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  ${props => props.showSubmitButton && "margin-bottom: 0;"}
  @media (max-width: 550px) {
    padding-bottom: 0;
    margin: 0;
  }
`

const ModalContentScroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1rem;
  @media (max-width: 550px) {
    padding: 48px 1rem 112px;
  }
`

const Shaper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ModalTitle = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: 48px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  @media (max-width: 550px) {
    display: none;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px 0 0;
  min-height: 55px;
  @media (max-width: 550px) {
    padding: 0;
    display: none;
  }
`

const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: 550px) {
    display: none;
  }
`

// modal-container
const ModalContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 48px 0 1.4rem;
  background: #f9f9f9;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  max-height: 95vh;
  ${props => !props.showSubmitButton && css`
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  `}
  margin-bottom: auto;
  @media (max-width: 550px) {
    min-height: calc(100vh - 40px);
    margin: 40px 0 0;
    padding: 0;
    flex-grow: 1;
    ${props => !props.showSubmitButton && css`
      border-radius: 0px;
    `}
  }
`

class SheetEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.lockScroll(true);
  }

  componentWillUnmount() {
    this.lockScroll(false);
  }

  lockScroll = (bool) => document.body.style.overflowY = bool? 'hidden':'auto';

  render () {
    const {
      bottomNavigation,
      modalButtons,
      header,
      children,
      open,
      onClose,
      ...other
    } = this.props;

    return (
      <PopupContainer>
        <StyledPopup
          modal
          closeOnDocumentClick
          open={open}
          onClose={onClose}
        >
          {close => (
            <Content>
              <TopBarContainer>
                <StyledAppBar position="static" color="default">
                  <StyledToolbar>
                    <IconButton className='return' color="inherit" onClick={close}>
                      <Icon>
                        arrow_back
                      </Icon>
                    </IconButton>
                    <Typography variant="subheading" color="inherit">
                      {header}
                    </Typography>
                  </StyledToolbar>
                </StyledAppBar>
              </TopBarContainer>
              {
                (bottomNavigation && bottomNavigation.length > 0) &&
                <StyledBottomBar buttons={bottomNavigation}/>
              }
              <Container>
                <ModalContainer {...other}>
                  <ModalTitle>
                    <Typography variant="h6" color="inherit">
                      {header}
                    </Typography>
                  </ModalTitle>
                  <IconButtonStyled onClick={close}>
                    <Icon>close</Icon>
                  </IconButtonStyled>
                  <Shaper>
                    <ModalContentScroll>
                      {children}
                    </ModalContentScroll>
                    <ButtonContainer>
                      {modalButtons}
                    </ButtonContainer>
                  </Shaper>
                </ModalContainer>
              </Container>
            </Content>
          )}
        </StyledPopup>
      </PopupContainer>
    )
  }
}

SheetEditModal.propTypes = {
  bottomNavigation: PropTypes.array,
  header: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

SheetEditModal.defaultProps = {

}

export default SheetEditModal; 