import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types';

const Container = styled.div`
`;

class ProductTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { text, ...other } = this.props
    
    return (
      <Container {...other}>
        {text}
      </Container>
    )
  }

}

ProductTitle.propTypes = {

}

export default ProductTitle;