import React from 'react'
import Proptypes from 'prop-types'
import styled from 'styled-components';
import GenericModal from '../GenericModal'
import TextField from "@material-ui/core/TextField";

const StyledGenericModal = styled(GenericModal)`
  width: 600px !important;
  @media (max-width: 600px) {
      width: 550px;
  }
`;
const TextFieldStyled = styled(TextField)`
  height: 100%;
  overflow-y: scroll;
`
const PlainText = styled.div`
  flex: 1;
  height: 95%;
  overflow-y: scroll;
  padding: 0 12px;
  font-size: 18px;

  @media (max-width: 550px) {
    height: 100%;
  }
`
const Content = styled.div`
  max-height: 500px;
  min-height: 300px;
  width: 100%;

  @media (max-width: 550px) {
    height: 75vh;
    width: 550px;
  }
`
      
class OrderNoteModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notes: props.orderNotes ? props.orderNotes : ''
    }
  }

  onSubmit = () => {
    this.props.submit(this.state.notes)
  }

  updateNotes = event => {
    const value = event.target.value
    this.setState({notes: value})
  }

  render() {
    const { editMode, ...other } = this.props
    const { notes } = this.state

    return (
      <StyledGenericModal onSubmit={editMode ? this.onSubmit : undefined} {...other}>
        <Content>
          {editMode ? (
            <TextFieldStyled
              name="orderNotes"
              fullWidth
              multiline
              placeholder="Add notes to your orders"
              value={notes}
              onChange={this.updateNotes}
              // InputProps={{
              //   style:{
              //     height: '100%'
              //   }
              // }}
            />
          ):(
            <PlainText>
              {notes}
            </PlainText>
          )}
        </Content>
      </StyledGenericModal>
    )
  }
}

OrderNoteModal.propTypes = {
  editMode: Proptypes.func,
}

OrderNoteModal.defaultProps = {
  editMode: false
}

export default OrderNoteModal
