import { observable, toJS } from 'mobx'
import joi from 'joi-browser'

class Note {
    @observable id
    @observable text
    @observable business
    @observable labels
    @observable timestamp
    @observable lastModified

    constructor(id = null, text = null, business = null, labels = [], timestamp = 0, lastModified = 0) {
		this.id = id;
		this.text = text;
		this.business = business;
		this.labels = labels;
		this.timestamp = timestamp;
		this.lastModified = lastModified;
    }

    formatForDB() {
        let data = observable({
          text: this.text,
          labels: this.labels
        });
        return toJS(data);
    }
}

Note.displayName = "Note";
export default Note;