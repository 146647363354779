import React, { Component } from 'react'
import styled, { css, withTheme } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IntegerSelector from '../../atomic/selectors/IntegerSelector'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CurrencyInput from '../../atomic/inputs/CurrencyInput'
import breakpoint from 'styled-components-breakpoint'
import DatePicker from "components/atomic/pickers/DatePicker";

const StyledContainer = styled(Grid)`
    margin: 10px 0 0;
    border: 1px solid ${props => props.theme.colors.spearmint_main};
    padding: 10px 20px 0;
    border-radius: 10px;
    color: ${props => props.theme.colors.spearmint_main} !important;
    font-family: ${props => props.theme.fonts.ms_font300} !important;
    > :last-child {
        border-top: 1px solid #ddd;
        margin-top: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
`

const StyledTypography = styled(Typography)`
    color: ${props => props.theme.colors.spearmint_main} !important;
    font-size: 1.2em !important;
    font-family: ${props => props.theme.fonts.ms_font300} !important;
    ${breakpoint('mobile_min', 'mobile_max')`
        margin-left: 0 !important;
    `}
`

const TotalTypography = styled(Typography)`
    color: ${props => props.theme.colors.spearmint_main} !important;
    font-size: 1.5em !important;
`

const DatePickerWrapper = styled(Grid)`
    width: 175px !important;
    transform: scale(0.8);
    border: 2px solid ${props => props.theme.colors.spearmint_main} !important;
    border-radius: 10px !important;
`

const StyledDatePicker = styled(TextField)`
    width: 100% !important;
    font-size: 1.2em !important;
    font-family: ${props => props.theme.fonts.ms_font300} !important;
`

const TotalCost = styled(Typography)`
    font-size: 1.5em;
    color: ${props => props.theme.fonts.ms_font300};
    font-family: ${props => props.theme.fonts.ms_font500};
`

const StyledIntegerSelector = styled(IntegerSelector)`
    
`

const StyledCurrencyInput = styled(CurrencyInput)`
`

const RadioText = styled(Typography)`
    font-size: 1.2em !important;
    font-family: $ms-font300 !important;
    margin: 8px 0 10px !important;
`

class Controller extends Component {
    constructor(props) {
        super(props)
        this.state = {
            exchange: props.exchange,
            needByDate: props.needByDate,
            quantityRequested: props.quantityRequested,
            price: props.price
        }
    }

    radioCheck(delivery, pickup) {
        let snippet;
        if (delivery && pickup) {
          snippet = (
            <FormControl>
              <RadioGroup
                name="delivery-status"
                value={this.state.exchange}
                onChange={this.handleDeliveryChange}
                row={true}
              >
                <FormControlLabel value="delivery" control={<Radio />} label="Delivery" className="radio-button" />
                <FormControlLabel value="pickup" control={<Radio />} label="Pickup" className="radio-button" />
              </RadioGroup>
            </FormControl>
          )
        } else if (delivery) {
          snippet = (<RadioText variant="headline" color="secondary">Delivery Only</RadioText>)
        } else {
          snippet = (<RadioText variant="headline" color="secondary">Pickup Only</RadioText>)
        }
        return snippet;
    }

    handleDeliveryChange = event => {
        this.setState({ exchange: event.target.value })
        this.props.onExchangeChange(event.target.value)
    }

    handleQuantityRequestedChange = val => {
        this.setState({ quantityRequested: val})
        this.props.onQuantityRequestedChange(val)
    }

    handlePriceChange = val => {
        this.setState({ price: val})
        this.props.onPriceChange(val)
    }

    handleNeedByDateChange = value => {
        this.setState({ needByDate: value })
        this.props.onNeedByDateChange(value)
    }

    render() {
        return (
            <StyledContainer container>
                <Grid container item wrap="nowrap" alignItems="center">
                    <Grid container item xs={5}>
                        <StyledTypography variant="subheading">
                            Quantity:
                        </StyledTypography>
                    </Grid>
                    <Grid container item xs={7} justify="center" >
                        <StyledIntegerSelector
                            defaultValue={this.props.quantityRequested}
                            min={0}
                            max={this.props.maxQuantity}
                            onChange={this.handleQuantityRequestedChange}
                            />
                    </Grid>
                </Grid>
                { this.props.displayPrice &&
                (<Grid container item wrap="nowrap" alignItems="center" spacing={16}>
                    <Grid container item xs={5}>
                        <StyledTypography variant="subheading">
                            New Price:
                        </StyledTypography>
                    </Grid>
                    <Grid container item xs={7} wrap="nowrap" justify="center">
                        <Grid container item xs={10} alignItems="baseline" wrap="nowrap">
                            <Grid container item xs={7} justify="flex-end">
                                <StyledCurrencyInput
                                    defaultValue={this.props.price}
                                    onChange={this.handlePriceChange}
                                    />
                            </Grid>
                            <Grid container item xs={5} justify="flex-start">
                                <Typography variant="subheading">
                                    /{this.props.priceUnit}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>) }
                
                <Grid container item wrap="nowrap" alignItems="center">
                    <Grid container item xs={5}>
                        <StyledTypography variant="subheading">
                            Need by:
                        </StyledTypography>
                    </Grid>
                    <Grid container item xs={7} justify="center">
                        <DatePickerWrapper container item alignItems="center" justify="flex-start">
                            <DatePicker
                                value={this.state.needByDate}
                                onChange={this.handleNeedByDateChange}
                            />
                        </DatePickerWrapper>
                    </Grid>
                    
                </Grid>
                <Grid container item wrap="nowrap" alignItems="center">
                    <Grid container item wrap="nowrap" justify="center">
                            {this.radioCheck(this.props.delivery, this.props.pickup)}
                    </Grid>
                </Grid>
                <Grid container item alignItems="center">
                    <Grid container item xs={5}>
                        <StyledTypography variant="subheading">
                            Total:
                        </StyledTypography>
                    </Grid>
                    <Grid container item justify="center" xs={7}>
                        <TotalTypography variant="subheading">
                            ${(this.state.quantityRequested * this.state.price).toFixed(2)}
                        </TotalTypography>
                    </Grid>
                    
                </Grid>
            </StyledContainer>
        )
    }
}

export default Controller
