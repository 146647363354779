import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
var abbreviate = require('number-abbreviate')

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextLabel = styled.div`
  font-size: 12px;
  margin-bottom: -2px;
  opacity: 0.9;
`

const StyledFormHelper = styled(FormHelperText)`
  ${'' /* min-width: 109px; */}
`
const StyledInput = styled(Input)`
  font-size: 14px;
  width: 100px;
  @media (max-width: 768px) {
    width: ${props => props.width}px;
  }
`

const InputAdornmentStyled = styled(InputAdornment)`
  font-size: 12px;
`;

class QuantityInput extends Component {
  constructor (props) {
    super(props)
    this.input = React.createRef();
    this.state = {
      input: '',
      abbrv: null,
    }
  }

  componentDidMount() {
    const { value, qty } = this.props;
    let abbrv = abbreviate(qty,1);
    this.setState({ input: value, abbrv });
    this.props.captureRef(this.input);
  }

  // componentWillReceiveProps(nextProps) {
  //   const { editMode, name, index } = this.props
  //   const { date } = this.state
  //   if((nextProps.toSave.includes(index) && !this.props.toSave.includes(index))&& editMode){
  //     this.props.onSave(index,name,date)
  //   }
  //   if(editMode && !nextProps.editMode) {
  //     this.setState({ date: nextProps.value });
  //   }
  // }

  update = input => {
    this.setState({ input });
  }
  componentDidUpdate() {
    this.props.captureRef(this.input);
  }

  render () {
    const { value, error, inputWidth, updateField, onKeyPress, captureRef, unit, ...other } = this.props;
    const { abbrv } = this.state;
    return (
      <TextContainer>
        <TextLabel>
          {`Qty (${unit})`}
        </TextLabel>
        <StyledInput
          className='qty-input'
          value={value == null ? null : Number(value).toString()}
          onChange={(e) => updateField(e.target.value)}
          width={inputWidth}
          inputRef={(ref) => this.input = ref}
          error={error}
          type='number'
          endAdornment={!isNaN(abbrv) && <InputAdornmentStyled position="end"><div>{`/${abbrv}`}</div></InputAdornmentStyled>}
          onKeyDown={(event) => {
            if (onKeyPress(event.keyCode)) {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
          {...other}
        />
        {
          error &&
          <StyledFormHelper error >{error}</StyledFormHelper>
        }
      </TextContainer>
    )
  }
}

QuantityInput.propTypes = {
  value: PropTypes.string,
};

QuantityInput.defaultProps = {
  inputWidth: 30
};

export default QuantityInput;