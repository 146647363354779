import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'
import {LoadingSpinnerPage, LoadingSpinnerButton} from '../../components/LoadingSpinner'
import Topbar from '../../components/CommonTopBar'
import Menu from '../../components/Menu';
import styled from 'styled-components';
import { message } from 'antd'
import { withRouter} from 'react-router-dom';
import { NoteCreator } from '../../components/molecules/NoteCreator';
import { clientListStore } from '../../stores'
import { Label } from '../../core/model'
import LabelSelect from '../../components/organisms/LabelSelect';
import NotesPage from '../../components/organisms/NotesPage'

const Container  = styled.div`

`

const Scroller = styled.div`
  display: flex;
	flex-direction: column;
	margin: 10px;
`;

const LabelSelectStyled = styled(LabelSelect)`
	position: absolute;
`

const NoteCreatorStyled = styled(NoteCreator)`
  margin-bottom: 20px;
`;

@inject('errorStore', 'noteStore', 'clientListStore')
@observer
class Notes extends Component {
	state = {
		labelFilters: null
	}

	render() {
		const tabs = [
			{
				name: 'Notes',
				child: false,
				path: '/notes'
			},
		];

		return (
			<Fragment>
				<Menu />
				<Topbar 
					title="Notes"
					sub="Create notes and labels for better organization"
					tabs={tabs}
					tabIndex={0}
					isChildPath={false}
				/>
				<div className="baskets-container">
					<NotesPage defaultLabels={[]} />
				</div>
			</Fragment>
		)
	}
}

export default withRouter(Notes)