import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={inputRef}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
        // showMask
        />
    );
}

const INITIAL_STATE = {
    phoneNumber: '',
    shrink: false
}

class PhoneNumberInput extends Component {

    constructor(props) {
        super(props)
        if (this.props.value) {
            this.state = {
                phoneNumber: this.props.value,
                shrink: true
            };
        }
        else {
            this.state = INITIAL_STATE;
        }
    }

    changeStateValue = (e) => {
        this.setState({ phoneNumber: e.target.value })
        this.props.onChange && this.props.onChange(e.target.value)
    }

    render() {
        const { ...other } = this.props
        return (
                this.props.displaylabel ?
                (
                    <FormControl>
                        <InputLabel shrink = {this.state.shrink} error = {this.props.error}> Phone Number </InputLabel>
                        <Input
                            { ...other }
                            name="phone"
                            placeholder="(555) 555-5555"
                            value={this.props.value || INITIAL_STATE.phoneNumber}
                            onChange={(e) => this.changeStateValue(e)}
                            onFocus={() => this.setState({shrink: true})}
                            onBlur={() => this.setState({ shrink: this.state.phoneNumber !== ""})}
                            inputComponent={TextMaskCustom}
                            error = {this.props.error}
                        />
                    </FormControl>
                )
                :
                (
                    <Input
                        { ...other }
                        name="phone"
                        placeholder="(555) 555-5555"
                        value={this.props.value || INITIAL_STATE.phoneNumber}
                        onChange={(e) => this.changeStateValue(e)}
                        onFocus={() => this.setState({shrink: true})}
                        onBlur={() => this.setState({ shrink: this.state.phoneNumber !== ""})}
                        inputComponent={TextMaskCustom}
                        error = {this.props.error}
                    />
                )
        )
    }
}

PhoneNumberInput.propTypes = {
    onChange: PropTypes.func,
    displaylabel: PropTypes.bool,
    value: PropTypes.string,
}

export default PhoneNumberInput