import joi from 'joi-browser';
import { MIN_STRING, MAX_STRING_SHORT, preferencesSchema } from '../schemas'
import { observable, toJS } from 'mobx'

const schema = joi.object().keys({
    businesses: joi.object().required(),
    email: joi.string().email().required(),
    invitationID: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).allow(null).required(),
    firstName: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    lastName: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    phoneNumber: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    preferences: preferencesSchema.required(),
    status: joi.string().min(MIN_STRING).max(MAX_STRING_SHORT).required(),
    uid: joi.string().required(),
});

const DEFAULT_PREFERENCES = {
    notifications: {
        clientRequests: true,
        orderRequests: true,
        orderUpdates: true,
        messages: true
    }
}

class User {
    constructor(businesses = {}, email = null, invitationID = null, firstName = null, lastName = null, phoneNumber = null, preferences = DEFAULT_PREFERENCES, status = null, uid = null) {
        this.businesses = businesses;
        this.email = email;
        this.invitationID = invitationID;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.preferences = preferences || DEFAULT_PREFERENCES;
        this.status = status;
        this.uid = uid;
    }

    formatForDB() {
        let data = {
            businesses: this.businesses,
            email: this.email,
            invitationID: this.invitationID,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            preferences: this.preferences,
            status: this.status
        }
        return data;
    }

    isActive() {
        return (this.status === "active");
    }

    isCreated() {
        return (this.status === "created");
    }

    isPendingVerification() {
        // For now, rejected users will just see the "verification pending" page
        return (this.status === "pending" || this.status === "rejected");
    }

    isVerified() {
        return (this.status === "verified");
    }

    isRejected() {
        return (this.status === "rejected");
    }

    validate() {
        const result = joi.validate(toJS(this), schema);
        return (result.error ? false : true); // lol
    }
}

User.displayName = "User";
export default User;
